import React from 'react';
import { Grid } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import image12 from '../../assets/images/images.jpg';


const Chatbot = () => {
 
  return (
    <></>
  );
};

export default Chatbot;
