import React, { useState } from 'react';
import { Box, Button, Stepper, Step, StepLabel, Typography, TextField } from '@mui/material';

const steps = ['Step 1', 'Step 2', 'Step 3'];

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  state:string;
  city:string;
}

const  MultiStepForm:React.FC=() =>{
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    state:" ",
    city:" "
  });

  const handleNext = () => {
    setActiveStep((activeStep) => activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <Box>
          <br/>
          <Typography variant="h5">Thank you for submitting the form!</Typography>
        </Box>
      ) : (
        <Box>
         
          {activeStep === 0 && (
          
            <Box>  
              <br/>
              <TextField
                name="firstName"
                label="First Name"
                variant='outlined'
                fullWidth
                value={formData.firstName}
                onChange={handleChange}
              />
              <br/>
              <br/>
              <TextField
              variant='outlined'
                name="lastName"
                label="Last Name"
                fullWidth
                value={formData.lastName}
                onChange={handleChange}
              />
            </Box>
          )}
          {activeStep === 1 && (
            <Box>
              <br/>
              <TextField
                name="email"
                label="Email"
                variant='outlined'
                fullWidth
                value={formData.email}
                onChange={handleChange}
              />
              <br/>
              <br/>
               <TextField
                name="Password"
                label="Password"
                variant='outlined'
                fullWidth
                value={formData.email}
                onChange={handleChange}
              />
            </Box>
          )}
           {activeStep === 2 && (
            <Box>
              <br/>
              <TextField
                name="Permanent address"
                label=" Enter the state"
                type='address'
                variant='outlined'
                fullWidth
                value={formData.state}
                onChange={handleChange}
              />
              <br/>
              <br/>
              <TextField
              variant='outlined'
                name="lastName"
                label="Enter City"
                fullWidth
                value={formData.city}
                onChange={handleChange}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              color="secondary"
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              variant="outlined"
              color="primary"
            >
              {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default MultiStepForm;
