/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing view for Purchase Order
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select,
    Popover, Typography, InputAdornment, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Stack,
    OutlinedInput,
    CircularProgress,
    InputBase,
    Backdrop,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { approveOrRejectPo, createPO, getPOByPurchaseId, getPOItems, getPoCount, getPoDetails } from '../../services/PoService';
import { color, useTheme } from '@mui/system';
import SearchIcon from "@mui/icons-material/Search";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { error } from 'console';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from '../../pages/Dialog';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DoneIcon from '@mui/icons-material/Done';
import { getInquire } from '../../services/PaymentService';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getTenantDetails } from '../../services/TenantService';
import gmslogo from "../../assets/logo.gif";


interface Data {
    id: number;
    // customerid: number;
    purchaseOrder: string;
    vendorname: string;
    date: string;
    service: string;

    amount: number;
    status: string
}

function createData(
    id: number,
    // customerid: number,
    purchaseOrder: string,
    vendorname: string,
    date: string,
    service: string,
    amount: number,
    status: string
): Data {
    return {
        id,
        // customerid,
        purchaseOrder,
        vendorname,
        date,
        service,
        amount,
        status
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    // {
    //     id: 'customerid',
    //     numeric: false,
    //     disablePadding: true,
    //     label: 'Customer Id',
    // },
    // {
    //     id: 'customerid',
    //     numeric: false,
    //     disablePadding: true,
    //     label: 'Purchase Order',
    // },
    {
        id: 'purchaseOrder',
        numeric: true,
        disablePadding: false,
        label: 'Purchase Order',
    },
    {
        id: 'vendorname',
        numeric: true,
        disablePadding: false,
        label: 'Vendor Name',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: ' Date',
    },
    {
        id: 'service',
        numeric: true,
        disablePadding: false,
        label: 'Service',
    },
    {
        id: 'amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >Action</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const POList: React.FC<any> = (props: any) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('purchaseOrder');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const theme = useTheme();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const navigate = useNavigate();
    const [poList, setPOList] = React.useState([] as any[]);
    const projectId = props.projectId;
    const projectStatus = props.status;
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [purchaseData, setPurchaseData] = React.useState<any>();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [successMsg, setSuccessMsg] = React.useState<any>();
    const [isCreatingPO, setIsCreatingPO] = React.useState(projectStatus !== "PO Generation");
    const [tenantId, setTenantId] = React.useState<String>();
    const [searchParams, setSearchParams] = React.useState<any>(null);
    const [count, setCount] = React.useState(Number);

    // const bole=projectStatus !== "PO Generation";
    const handleClickOpen = (purchaseId: any) => {
        getPurchaseByPurchaseId(purchaseId);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const getPurchaseByPurchaseId = async (purchaseId: any) => {
        //console.log(props.projectId);
        let result = await getPOByPurchaseId(props.projectId, purchaseId, accessToken);
        setPurchaseData(result.data);
    }

    const approveRejectPO = async (purchaseId: any, status: any) => {
        setIsLoading(true);
        if (status === "Approved") {
            let result = await approveOrRejectPo(tenantId, purchaseId, status, accessToken);
            setOpenDialogs(true);
            if (result.status === 200 || result.status === 201) {
                setSuccessMsg("Counter Offer Accepted")
                setIsLoading(false);
                setCreateSuccess(true);
                setOpen(false);
                fetchData(null);
            }
            else {
                setIsLoading(false);
                setCreateError(true);
                setOpen(false);
            }
        }
        else {
            let result = await approveOrRejectPo(tenantId, purchaseId, status, accessToken);
            setOpenDialogs(true);
            if (result.status === 200 || result.status === 201) {
                setSuccessMsg("New Po Generated")
                setIsLoading(false);
                setCreateSuccess(true);
                setOpen(false);
                fetchData(null);
            }
            else {
                setIsLoading(false);
                setCreateError(true);
                setOpen(false);
            }
        }
    }

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
        // navigate("/salesestimate");
    }

    const getPOData = async (searchParams:any) =>{

        const res = await getPoCount(props.projectId, searchParams, accessToken);
        console.log(res);
        setCount(res.data);

        const response = await getPoDetails(props.projectId, searchParams, page, rowsPerPage, accessToken);
        console.log(response);
        setPOList(response.data);

    }

    const inputchange = async (e: any) => {
        var value = e.target.value === "" ? null : e.target.value;
        if(e.target.value.length >= 3){
          setSearchParams(value);
          fetchData(value);
        }
        if(e.target.value.length === 0){
          setSearchParams(value);
          fetchData(value);
        }
      };

    const fetchData = async (searchParams:any) => {
        getPOData(searchParams);
        const result = await getTenantDetails(accessToken);
        setTenantId(result.data.tenantid);
        //console.log(res.data);
    }

    React.useEffect(() => {
        fetchData(null);
    }, [page, rowsPerPage])

    const poFormView = (purchaseId: any) => {
        navigate("/podetails", { state: { projectId, purchaseId } });
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = poList.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const createPODeatails = async () => {
        setIsCreatingPO(true);

        const file = {
            // your file object
        };
        try {
            const response = await createPO(file, props.projectId, accessToken);
            console.log(response);
            if (response.ok && response.data===true) {
                const poDetailsResponse = await getPoDetails(props.projectId, searchParams, page, rowsPerPage, accessToken);
                //console.log(poDetailsResponse);
                setPOList(poDetailsResponse.data);
                setSuccess(true);
            } else {
                setError(true);
                setIsCreatingPO(false);
            }
        } catch (error) {
            console.error(error);
            setError(true);
            setIsCreatingPO(false);
        }
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: theme.palette.primary.main, // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    return (
        <>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={12} sm={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                        <Grid container mt={1}>
                            <Grid item xs={2}  mt={2} p={3}> 
                                <Paper
                                    variant="outlined"
                                    component="form"
                                    className='searchField'
                                    sx={{ p: "2px 4px", display: "flex", alignItems: "center"}}
                                >
                                    <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search"
                                    inputProps={{ "aria-label": "search google maps" }}
                                    onChange={(e) => inputchange(e)}
                                    />
                                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                    <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item xs={6} mt={2}>

                            </Grid>
                            <Grid item xs={4} mt={2} alignSelf={'center'}  className='alignEnd' pr={2}>
                                <Box>
                                    {poList?.length === 0 && (
                                        <>
                                            <Button
                                                variant="contained"
                                                size="large"
                                                onClick={createPODeatails}
                                                disabled={isCreatingPO}
                                            >
                                                &nbsp; Create Purchase Order
                                            </Button>
                                            {/* {isCreatingPO && <CircularProgress size={24} style={{ marginLeft: 16 }} />} */}
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={count}
                                    />
                                    <TableBody>
                                        {poList?.map((row, index) => {
                                            const isItemSelected = isSelected(row.purchaseOrderId);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.purchaseOrderId)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.purchaseOrderId}
                                                    selected={isItemSelected}


                                                >
                                                    <TableCell padding="checkbox" size='small'>
                                                        <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {/* <TableCell
                                                        // className='invoiceRows'
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >

                                                    </TableCell> */}
                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>
                                                        <a className="anchor"
                                                            onClick={() => poFormView(row.purchaseOrderId)}
                                                            style={{ textDecoration: 'none', }} // Custom styles for the anchor tag
                                                        >{row.purchaseOrderId}
                                                        </a></TableCell>
                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>{row.vendorName}</TableCell>
                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>{dayjs(row.purchaseOrderDate).format("DD MMM YYYY")}</TableCell>
                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>{row.referenceType}</TableCell>
                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>{row.purchaseAmount}</TableCell>
                                                    {
                                                        (row?.purchaseStatus) === "Counter Offer" ?
                                                            <TableCell size='small' sx={{ textDecoration: "none", cursor: 'pointer' }} onClick={() => handleClickOpen(row.purchaseOrderId)}>
                                                                <Stack direction={'row'} className='counterOffer'>
                                                                    <WarningAmberIcon sx={{ fontSize: '15px', marginRight: '5px' }} />
                                                                    <span>{row.purchaseStatus}</span>
                                                                </Stack>
                                                            </TableCell>
                                                            :
                                                            <TableCell size='small' sx={{ textDecoration: "none" }}>
                                                                {(row?.purchaseStatus === "Rejected") && <span className='rejected'>{row.purchaseStatus}</span>}
                                                                {(row?.purchaseStatus === "Pending Approval" || row?.purchaseStatus === "Draft" || row?.purchaseStatus === "Generated") && <span className='pendingApproval'>{row.purchaseStatus}</span>}
                                                                {(row?.purchaseStatus === "Approved") && <span className='pendingApproval'>{row.purchaseStatus}</span>}
                                                            </TableCell>
                                                    }

                                                    <TableCell size='small' sx={{
                                                        cursor: 'pointer',
                                                        textDecoration: row.purchaseStatus === "Rejected" ? 'line-through' : 'none' // Apply textDecoration based on condition
                                                    }}>

                                                        {row.purchaseStatus !== "Rejected" ? (
                                                            <IconButton className="anchor"
                                                                onClick={() => poFormView(row.purchaseOrderId)}
                                                                style={{ textDecoration: 'none', }} ><RemoveRedEyeOutlinedIcon /></IconButton>
                                                        ) : (
                                                            <IconButton className="anchor"

                                                                style={{ textDecoration: 'none', }} ><VisibilityOffIcon /> </IconButton>

                                                        )}

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={success} >
                <DialogTitle className='success'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Success
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Purchase Order Created</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>

            <Dialog open={error} >
                <DialogTitle className='error'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Error
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Error Couldn't Find Vendor </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                    </DialogActions>
                </Box>
            </Dialog>
            {/* Dialog box for counter offer received */}
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}

            >
                <DialogTitle sx={{ m: 0, p: 2, fontSize: "16px" }} id="customized-dialog-title">
                    Counter Offer Received
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers sx={{ padding: 5 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    format='DD MMM YYYY'
                                    label="Old Date"
                                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                    disabled
                                    value={dayjs(purchaseData?.deliveryDate)}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    format='DD MMM YYYY'
                                    label="New Date"
                                    slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                    value={dayjs(purchaseData?.counterOfferDate)}
                                    disabled
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                    {/* <Stack direction={'row'} spacing={2} marginBottom={2}>
                        <Typography sx={{ color: 'blue' }}>Counter Offer Date: </Typography>
                        <Typography>{dayjs(purchaseData?.counterOfferDate).format("DD MMM YYYY")}</Typography>
                    </Stack> */}
                    <Grid container spacing={2} pt={2}>
                        <Grid item xs={12}>

                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Reason"
                                multiline
                                rows={3}
                                value={purchaseData?.reason}
                            />
                            {/* <Typography>{purchaseData?.reason}</Typography> */}
                        </Grid>
                    </Grid>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        onClick={handleClose}
                      >
                        <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
                    </Backdrop>
                </DialogContent>
                <DialogActions>
                    <Button startIcon={<DoneIcon fontSize='small' />} autoFocus variant="contained" color="success" onClick={() => approveRejectPO(purchaseData?.id, "Approved")}>
                        Accept
                    </Button>
                    <Button startIcon={<CloseIcon fontSize='small' />} autoFocus variant="contained" color="error" onClick={() => approveRejectPO(purchaseData?.id, "Rejected")}>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomDialog
                open={openDialogs}
                onClose={() => setOpenDialogs(false)}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? successMsg : " Error"} />
        </>

    );
}

export default POList;