/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import React, { useCallback, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import { UserContext, getUserSessionDetails, isUserHasPermission } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CustomerTable from './CustomerTable';
import { importCustomers } from '../../services/ImportService';
import CustomDialog from '../Dialog';
import "../../../src/index.scss";


const CustomerManagement: React.FC = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const [userSessionDetails, setUserDetails] = useState(getUserSessionDetails(accessToken));
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [dataa, setFormData] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleChange = useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
                // setFile(files[0]);
            }
        },
        []
    );

    let formData = new FormData();

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file)
        setFormData(formData);
    };

    const importCustomer = async () => {
        const res = await importCustomers(dataa, accessToken);
        console.log(res);
        setOpenDialog(false);
        setOpen(true);
        if (res.ok === true) {
            setSuccess(true);

        } else {
            setError(true);
        }
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={9}>
                    <Grid item xs={12} className='alignEnd' sx={{ padding: '15px' }}><Button variant="contained" size="large" onClick={handleDialogOpen}>Import Customer</Button></Grid>
                    <Grid item xs={12}><CustomerTable /></Grid>
                </Grid>
                {/* <Grid item xs={3}>
                <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && <Grid item xs={12}><GPS /></Grid>}
                    <Grid item xs={12} className='headerTable'>
                        <Stack direction="column" spacing={2}>
                            <InsertChartIcon />&nbsp; Completed Task Feed
                            <CompletedTaskFeed />
                        </Stack>
                    </Grid>
                    </Stack>
                  
                       
                    
                </Grid> */}
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Upload
                        </Typography>
                        <Typography textAlign={'center'}>
                            <form>
                                <div className="input_container">
                                    <input type="file" onChange={handleChange} />
                                </div>
                            </form>
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">Cancel</Button>
                    <Button
                        onClick={importCustomer}
                        autoFocus className="primaryTonalButton">
                        Upload
                    </Button>
                </DialogActions>

            </Dialog>
            <CustomDialog
                open={open}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Imported  Successfully" : "Failed To Import"}
            />
        </>
    )
}

export default CustomerManagement;