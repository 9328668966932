/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For adding invoice 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Button, Card, CardHeader, Divider, Grid, MenuItem, Select, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, useMediaQuery, useTheme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import logo from '../../../assets/logo.gif';
import dayjs from 'dayjs';
import Header from '../../Header';
import BillItemDetails from '../../Bill/billItemDetails/BillItemDetails';
import { generateInvoice, saveInvoice } from '../../../services/InvoiceServices';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import DeleteIcon from '@mui/icons-material/Delete';


export default function Invoiceform() {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [invoiceData, setInvoiceData] = React.useState({} as any);
    const [data, setData] = React.useState<any>({
        createdAt: new Date(),
        updatedAt: new Date(),
        invoiceDate: new Date(),
        dueDate: '',
        deadLineDate: '',
        version: 1,
        status: "Not Paid",
        customerUid: "187f9522-8ded-46cd-9433-3fa7061d43fb",
        description: '',
        balanceDue: '',
        amountDue: '',
        unitCost: '',
        price: '',
        totalAmount: '',
        tenantName: "name",
        tenantAddress: "address",
        project: ''
    });
    const navigate = useNavigate();
    const onPODetails = () => {
        navigate("/polayout"); // Navigate to the "/task" route
    };
    const FileUploaderContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '120px',
        border: '2px dashed #ccc',
        borderRadius: '8px',
    });
    const location = useLocation();
    const rowData = location.state?.object;
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    console.log(rowData);

    //   const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    //   };

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));


    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const [openToast, setOpenToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState({
        message: '',
        security: ''
    });
    const [item, setItem] = React.useState(0);
    const handleItemChange = (newAmount: any) => {
        // setItem(newAmount);
        // console.log(newAmount,item);
        // rowData.amount=newAmount
        if (newAmount !== null && newAmount !== undefined) {
            // newAmount= rowData.amount;
            setItem(newAmount)
        }
        else {
            newAmount = 0.00
            setItem(newAmount)

        }

    };

    const [cleared, setCleared] = React.useState<boolean>(false);
    const id = location.state?.projectId;
    // const getInvoice = async () => {
    //     const data = location.state?.object;
    //     console.log(data.projectId);
    //     let result = await generateInvoice(data.projectId, data.terms, accessToken);
    //     setInvoiceData(result.data);
    // }
    const onInputChange = (e: any) => {
        setInvoiceData({
            ...invoiceData,
            [e.target.name]: e.target.value
        });

    }
    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenToast(false);
    };
    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length == 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + date.getDate() + "T00:00:00.000Z";
    }
    const handleSubmit = async () => {
        setData({
            ...data,
            price: invoiceData.total, dueDate: invoiceData.dueDate, deadLineDate: invoiceData.dueDate,
            description: invoiceData.description, balanceDue: invoiceData.total, amountDue: invoiceData.total,
            unitCost: invoiceData.total, totalAmount: invoiceData.total, project: id
        });
        console.log(data);
        let result = await saveInvoice(data, accessToken);
        if (result.status === 201) {
            setOpenToast(true);
            setToastMessage({ ...toastMessage, message: "Inserted Successfully", security: "success" });
        }
        else {
            setOpenToast(true);
            setToastMessage({ ...toastMessage, message: "Not Inserted", security: "error" });
        }
    }
    const updateDate = (date: any) => {
        setData({ ...data, invoiceDate: dateFormatter(date.$d) });
    }
    React.useEffect(() => {
        // const rowData = location.state?.rowData;
        // if (typeof (rowData) === 'object') {
        //     setInvoiceData(rowData);
        // }
        // else {
        // getInvoice();
        // }

        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => { };
    }, [cleared]);

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="10px 0"
            >
                <Header title="Invoice" subtitle="" />

            </Box>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Snackbar open={openToast} autoHideDuration={2000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                        <Alert onClose={handleToastClose} security={toastMessage.security} sx={{ width: '100%' }}>
                            {toastMessage.message}
                        </Alert>
                    </Snackbar>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined">
                            <Grid item xs={12} mt={1} p={3}>
                                <Grid container spacing={0} mb={2}>
                                    <Grid className="poLogo" item xs={12}>
                                        <div className='poImgText'>
                                            <div><img className='imgLogo' src={logo} alt='Logo' /></div>
                                            <div className='formHeader'>Invoice</div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} mt={5}>
                                        <Grid item xs={12} mb={1} className='headDetails'>{invoiceData?.tenantName}</Grid>
                                        <Grid item xs={12}>{invoiceData?.address}</Grid>
                                    </Grid>
                                    <Grid item xs={5}></Grid>
                                    <Grid item xs={3}>
                                        <Grid container mt={5} >
                                            <Grid item xs={3} mt={2}>Date:&nbsp;</Grid>
                                            <Grid item xs={9} className='headDetails'> <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    &nbsp;<DatePicker label="" slotProps={{ textField: { size: 'small' } }}
                                                        // value={dayjs(rowData ? rowData.invoiceDate : '')} 
                                                        value={dayjs(`${month}-${day}-${year}`)}
                                                        onChange={(date) => updateDate(date)}
                                                        disabled
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider></Grid>
                                            <Grid item xs={3} mt={2}>Invoice no:</Grid>
                                            {/* <Grid item xs={9} mt={2} className='headDetails'>&nbsp;{rowData ? rowData.invoiceNumber : ''}</Grid> */}
                                            <Grid item xs={9} mt={2} className='headDetails'>&nbsp;{Math.floor(Math.random() * 9000000000) + 1}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </Grid>
                            {/* <CardHeader
                                title={
                                    <div className='customerSubHeader'>
                                        &nbsp; Invoice
                                    </div>
                                }
                            /> */}
                            <Grid container spacing={2} p={2} pl={5} pr={5}>
                                <Grid item xs={3} mt={3}>
                                    {/* <label><small>Customer</small></label> */}
                                    <TextField

                                        id="demo-helper-text-aligned"
                                        label="Customer Name"
                                        size="small"
                                        name="customerName"
                                        fullWidth
                                        onChange={(e) => onInputChange(e)}
                                        // value={rowData ? rowData?.customerName : invoiceData.customerName}
                                        value={invoiceData?.customerName}
                                        //disabled
                                    />
                                    {/* <Select
                                        fullWidth
                                        value={rowData ? rowData.customerName : ""}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            Choose a customer
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select> */}
                                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}
                                </Grid>
                                <Grid item xs={4} mt={3} >
                                    {/* <label><small className='tags'>Customer email</small></label> */}
                                    <TextField

                                        id="demo-helper-text-aligned"
                                        label="Email"
                                        size="small"
                                        fullWidth
                                        name='email'
                                        // value={rowData ? rowData?.customerEmail : invoiceData?.email}
                                        value={invoiceData?.email}
                                        onChange={(e) => onInputChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={5} >
                                    {/* <Grid item xs={12} mt={2}>
                                        <div className='amount'>
                                            <div className='amountHead'>BALANCE DUE</div>
                                            <div className='amountDetails'>${item}</div>
                                        </div>
                                    </Grid> */}
                                </Grid>
                                <Grid item xs={5} mt={3} >
                                    <TextField
                                        id="address-input"
                                        label="Billing Address"
                                        multiline
                                        minRows={3}
                                        name="billingAddress"
                                        fullWidth
                                        onChange={(e) => onInputChange(e)}
                                        helperText=""
                                        // value={rowData ? rowData.address : invoiceData?.address}
                                        value={invoiceData?.billingAddress}
                                    />
                                    
                                </Grid>
                                <Grid item xs={4} mt={2} mb={2}>
                                    <Grid container spacing={1} p={2} pt={0} >
                                        {/* <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker value={dayjs(rowData ? rowData.billDate : '')} label="Invoice date" slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid> */}
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker value={dayjs(invoiceData?.dueDate)} label="Due date" slotProps={{ textField: { fullWidth: true, size: 'small' } }} disabled />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <Grid container spacing={1} pt={0} >
                                        <Grid item xs={12}>  <label><small>Terms</small></label>
                                            <Select
                                                fullWidth
                                                value=""
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                size='small'
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Customer For Address
                                                </MenuItem>
                                                <MenuItem value="1">Option 1</MenuItem>
                                                <MenuItem value="2">Option 2</MenuItem>
                                                <MenuItem value="3">Option 3</MenuItem>
                                            </Select>
                                        </Grid>
                                    </Grid> */}
                                </Grid>

                                <CardHeader
                                    title={
                                        <div className='customerSubHead'>
                                            &nbsp; Item Details
                                        </div>
                                    }
                                />
                                <Grid container spacing={2} p={2} pt={2} >
                                    <Grid item xs={12}>
                                        {/* <BillItemDetails props={rowData ? rowData?.itemDetails : ''} function={handleItemChange} /> */}
                                        <TableContainer >
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell ></TableCell>
                                                        <TableCell className='poTableDetails'>#</TableCell>
                                                        <TableCell className='poTableDetails'>Product/service</TableCell>
                                                        <TableCell className='poTableDetails'>Description</TableCell>
                                                        <TableCell className='poTableDetails'>Quantity</TableCell>
                                                        <TableCell className='poTableDetails'>Rate</TableCell>
                                                        <TableCell className='poTableDetails'>Amount</TableCell>
                                                        {/* <TableCell className='poTableDetails'>Customer</TableCell> */}
                                                        {/* <TableCell >Action</TableCell> */}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {/* {items
  ? items.map((row: any) => ( */}
                                                    <TableRow>
                                                        <TableCell>
                                                            <AppsOutlinedIcon />
                                                        </TableCell>
                                                        <TableCell className='invoiceRow'>1</TableCell>
                                                        <TableCell className='invoiceRow'>
                                                            <TextField
                                                                variant='standard'
                                                                size='small'
                                                                value={invoiceData?.trade}
                                                                name='trade'
                                                                onChange={(e) => onInputChange(e)}
                                                                disabled
                                                            // onChange={(e) => handleNameChange(e, 'product', row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className='invoiceRow'>
                                                            <TextField
                                                                variant='standard'
                                                                size='small'
                                                                value={invoiceData?.description}
                                                                name='description'
                                                                onChange={(e) => onInputChange(e)}
                                                                disabled
                                                            // onChange={(e) => handleNameChange(e, 'description', row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className='invoiceRow'>
                                                            <TextField
                                                                variant='standard'
                                                                size='small'
                                                                value={invoiceData?.quantity}
                                                                name='quantity'
                                                                onChange={(e) => onInputChange(e)}
                                                                disabled
                                                            // onChange={(e) => handleNameChange(e, 'quantity', row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className='invoiceRow'>
                                                            <TextField
                                                                variant='standard'
                                                                size='small'
                                                                value={invoiceData?.rate}
                                                                name='rate'
                                                                onChange={(e) => onInputChange(e)}
                                                                disabled
                                                            // onChange={(e) => handleNameChange(e, 'rate', row.id)}
                                                            />
                                                        </TableCell>
                                                        <TableCell className='invoiceRow'>
                                                            <TextField
                                                                variant='standard'
                                                                size='small'
                                                                name='subtotal'
                                                                onChange={(e) => onInputChange(e)}
                                                                value={invoiceData?.subtotal}
                                                                disabled
                                                            // onChange={(e) => handleNameChange(e, 'amount', row.id)}
                                                            />
                                                        </TableCell>
                                                        {/* <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.project || ''}
            name='project'
            onChange={(e) => handleNameChange(e, 'project', row.id)}
          />
        </TableCell> */}
                                                        {/* <TableCell>
                                                            <DeleteIcon />
                                                        </TableCell> */}
                                                    </TableRow>
                                                    {/* ))
  : // Render placeholder content or return null when items is null or undefined
    null} */}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <div className="totalAmount">
                                            <div>Sub Total :<span className="amount">&nbsp; {invoiceData?.subtotal}</span></div>
                                            <div> Total :<span className="amount">&nbsp; {invoiceData?.total}</span></div> </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} mt={1} >
                                    <TextField
                                        id="address-input"
                                        label="Message on invoice"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        helperText=""
                                        value={rowData ? rowData.message : ''}
                                    />
                                </Grid>
                                <Grid item xs={6} mt={1} >
                                    <TextField
                                        id="address-input"
                                        label="Message on statement"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid>
                                {/* <Grid container spacing={2} pl={2} pt={3}>
                                    <Grid item xs={12} pb={3}><FileUploaderContainer>
                                        <Box
                                            sx={{
                                                flexGrow: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        > */}
                                {/* <FileUploadIcon /> */}
                                {/* <div ><div><a href="#" id="link"><input type="file" id="file" />Add Attachment</a></div>
                                                <div>Max File size :20MB</div>
                                            </div>
                                        </Box>

                                    </FileUploaderContainer>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Card>
                    </Box>
                    <Box m={2} className="poFooter">
                        <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>SAVE</Button>
                        <Button variant="outlined" size="medium" onClick={onPODetails}>Cancel</Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}