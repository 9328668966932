import * as React from 'react';
import Box from '@mui/system/Box';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';



import { Chart } from "react-google-charts";



import bargraph from '../asset/images/sales/bar-graph.svg';
import moneybag from '../asset/images/sales/money-bag.svg';
import piechart from '../asset/images/sales/pie-chart.svg';
import dollar from '../asset/images/sales/dollar.svg';
import chart from '../asset/images/sales/chart.svg';
import Tenantfinance from './Tenantfinance';



const Item = styled('div')(({ theme }) => ({
    //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    //   border: '1px solid',
    //   borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    //   padding: theme.spacing(1),
    //   borderRadius: '4px',
    //   textAlign: 'center',
}));


export const data = [
    [
        "Element",
        "Density",
        { role: "style" },
        {
            sourceColumn: 0,
            role: "annotation",
            type: "string",
            calc: "stringify",
        },
    ],
    ["20 days out", 25.94, "#f2f4f9", null],
    ["15-20 days out", 10.49, "#f2f4f9", null],
    ["5-3 days out", 29.3, "#656ee0", null],
    ["10 days out", 11.45, "#f2f4f9", null],
    ["10 days out", 9.45, "#f2f4f9", null],
];

export const options = {
    title: "Bid Status",

    bar: { groupWidth: "50%" },
    legend: { position: "none" },
    bars: 'vertical',
    hAxis: {
        title: "Bids",
        minValue: 'none' ,
        color: '#fff'
    },
    vAxis: { 
    textPosition: "none", 
    gridlines: { count: 0 },
    titleTextStyle:	{
        color: '#fff',
    }
  },



  baselineColor:"transparent",

  

};



export default function Customerfinance() {
    return (
        // <Box sx={{ flexGrow: 1 }}>
        //     <Grid container spacing={2}>
              
                <Tenantfinance/>
        //     </Grid>
        // </Box>
    );
}
