/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For bill item details
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/ 
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react";

interface Row {
  id: number; // adjust with your type
  quantity: number; // adjust with your type
  rate: number; // adjust with your type
  // other properties
}
const BillItemDetails: React.FC<any> = (props) => {


  const [items, setItems] = useState(props.props || null|| []);
  const [amount, setAmount] = useState(0);

  //console.log(props)
  const [rows, setRows] = useState({
    description: '',
    quantity: 0,
    product: '',
    project: '',
    rate: 0,
    amount: 0
  });
  // Storing props inside a variable
  const [tableData, setTableData] = useState(props.props);

  const addTableRow = () => {
    const newRow = {
      id: items.length + 1,
      product: '',
      description: '',
      project: '',
      quantity: 0,
      rate: 0,
      amount: 0
    };
    const updatedRows = [...items, newRow];
    setItems(updatedRows);
  };
  const handleNameChange = (e: any, field: string, id: any) => {

    const updatedRows = items.map((row: Row) => {
      if (row.id === id) {
        return { ...row, [field]: e.target.value };
      }
      return row;
    });
      // Sum up the 'updatedValue' for all rows
    let totalAmount = 0;
    updatedRows.forEach((row: Row) => {
      totalAmount += row.quantity * row.rate;
    });
    props.function(totalAmount);
    setAmount(totalAmount);
    setItems(updatedRows);
  };
  React.useEffect(() => {
    const updatedRows = items.map((row: Row) => {
    // console.log(...items)
         if (row.id ) {
           return { ...row};
         }
         return row;
       });
       let totalAmount = 0;
       updatedRows.forEach((row: Row) => {
         totalAmount += row.quantity * row.rate;
       });
       setAmount(totalAmount);
       props?.function(totalAmount);
   }, []);
                  
  return (
    <div className="itemDetails">
      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell ></TableCell>
              <TableCell className='poTableDetails'>#</TableCell>

              <TableCell className='poTableDetails'>Product/service</TableCell>
              <TableCell className='poTableDetails'>Description</TableCell>
           

              <TableCell className='poTableDetails'>Quantity</TableCell>
              <TableCell className='poTableDetails'>Rate</TableCell>
              <TableCell className='poTableDetails'>Amount</TableCell>
              {/* <TableCell className='poTableDetails'>Customer</TableCell> */}
              <TableCell >Action</TableCell>
         

            </TableRow>
          </TableHead>
          <TableBody>
          {items
  ? items.map((row: any) => (
      <TableRow key={row.id}>
        <TableCell>
          <AppsOutlinedIcon />
        </TableCell>
        <TableCell className='invoiceRow'>{row.id}</TableCell>
        <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.product || ''}
            name='product'
            onChange={(e) => handleNameChange(e, 'product', row.id)}
          />
        </TableCell>
        <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.description || ''}
            name='description'
            onChange={(e) => handleNameChange(e, 'description', row.id)}
          />
        </TableCell>

        <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.quantity || ''}
            name='quantity'
            onChange={(e) => handleNameChange(e, 'quantity', row.id)}
          />
        </TableCell>
        <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.rate || ''}
            name='rate'
            onChange={(e) => handleNameChange(e, 'rate', row.id)}
          />
        </TableCell>
        <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            name='amount'
            value={(row.rate || 0) * (row.quantity || 0)}
            onChange={(e) => handleNameChange(e, 'amount', row.id)}
          />
        </TableCell>
        {/* <TableCell className='invoiceRow'>
          <TextField
            variant='standard'
            size='small'
            value={row.project || ''}
            name='project'
            onChange={(e) => handleNameChange(e, 'project', row.id)}
          />
        </TableCell> */}
        <TableCell>
          <DeleteIcon />
        </TableCell>
      </TableRow>
    ))
  : // Render placeholder content or return null when items is null or undefined
    null}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}> <Button className="addTableRow" variant="outlined" onClick={addTableRow}>
        Add Lines
      </Button>
        <Button variant="outlined" >
          Clear all lines
        </Button>
        <div className="totalAmount">
          <div>Sub Total :<span className="amount">&nbsp; $0.00</span></div>
          <div> Total :<span className="amount">&nbsp; ${amount}</span></div> </div> </Box>
    </div>
  );
};
export default BillItemDetails;