/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 21/06/24 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  24/06/24   | Aish       | created  employee time sheet view based on date filteration
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import { Button, Grid, InputAdornment, Stack, TablePagination, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Data } from '@react-google-maps/api';
import timezone from 'dayjs/plugin/timezone';
import { getUserTimeSheetByDate, getUserTimeSheetCountByDate, searchAllTimeSheetByDate, searchAllTimeSheetByName, searchUserTimeSheetByDate } from '../../../services/TimelogService';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import './TimeSheet.scss'
import UpdateTimeSheet from './UpdateTimeSheet';
import SearchIcon from "@mui/icons-material/Search";
import BookLeave from '../bookLeave/BookLeave';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const TimeSheet = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  // const [order, setOrder] = React.useState<Order>('asc');
  // const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dense, setDense] = React.useState(false);
  const EMPLOYEETIME = "Awaiting Approval";
  const ADMINAPPROVE = 'Approve';
  const ADMINREJECT = 'Reject';
  const { accessToken } = useOidcAccessToken();
  const [rows, setRows] = React.useState<any>([]);
  const [count, setCount] = React.useState(Number);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [vendorId, setVendorId] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState('');
  const [searchParams, setSearchParams] = React.useState('null');
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [deleteSuccess, setDeleteSuccess] = React.useState(false);
  const [deleteError, setDeleteError] = React.useState(false);
  const [showAnotherComponent, setShowAnotherComponent] = React.useState(false);
  const [rowData, setRowData] = React.useState();
  const [alignment, setAlignment] = useState('month');
  const [loading, setLoading] = React.useState(false);
  const [showLeaveComponent, setShowLeaveComponent] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const alignmentRef = React.useRef<string>('day');
  const startDateRef = React.useRef<any>(null);
  const endDateRef = React.useRef<any>(null);
  const countRef =React.useRef<any>(Number);
  const pageRef=React.useRef<any>(0);
  const rowsPerPageRef=React.useRef<any>(5);
  const handleEditCustomer = (row: any) => {
    setRowData(row)
    setShowAnotherComponent(true);
  };
  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof Data,
  // ) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };
  const [selectedRow, setSelectedRow] = useState(null);
  const [openRow, setOpenRow] = React.useState<string | null>(null);
  const handleRowClick = (rowIndex) => {
    setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
    setOpenRow(openRow === rowIndex ? null : rowIndex);

  };
  const closeConfirmation = () => {
    setShowAnotherComponent(false);
  }


  const handleOpenConfirmation = () => {
    setShowLeaveComponent(true);
  };



  // const handleChangePage = (event: unknown, newPage: number) => {
  //pageRef.current=newPage;

  // };


  const handleChangePage = async (event: unknown, newPage: number) => {
    //const result = await getProjectBycustomerUId(accessTokenPayload.oid, newPage, rowsPerPage, accessToken);
    //console.log(result.data)
    //setProjects(result.data);
    // const result = await getCustomerDetails(ROLES, page, rowsPerPage, accessToken);
    // setRows(result.data);
 pageRef.current=newPage;
console.log(newPage)
    if (alignmentRef.current === 'day') {
      Day();
    }
    else if (alignmentRef.current === 'week') {
      Week();
    }
    else {
      Month();
    }
  };

  const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value);
    const newPage = Math.floor(page * rowsPerPage / newRowsPerPage);
    console.log(newRowsPerPage,newPage)
    rowsPerPageRef.current=newRowsPerPage;
    pageRef.current=newPage;
    // setRowsPerPage(newRowsPerPage);
    // setPage(0);
    if (alignmentRef.current === 'day') {
      Day();
    }
    else if (alignmentRef.current === 'week') {
      Week();
    }
    else {
      Month();
    }

  };


  const theme = useTheme();



  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    setLoading(true)
    console.log(newAlignment)
    alignmentRef.current=newAlignment;
    if (newAlignment === "day") {
      Day();
    }
    else if (newAlignment === "week") {
      Week();
    }
    else {
      Month();
    }
  };

  const dateFormatter = (value: any, isEndOfDay: boolean = false) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${date.getFullYear()}-${month}-${day}`;

    if (isEndOfDay) {
      formattedDate += "T23:59:59.999Z";
    } else {
      formattedDate += "T00:00:00.000Z";
    }

    return formattedDate;
  };
  const fetchProject = async () => {
    // Define your local timezone
    const localTimezone = dayjs.tz.guess();

    // Start of the week (Monday) in local timezone
    const startOfMonth = dateFormatter(dayjs().startOf('month').tz(localTimezone));

    // End of the week (Sunday) in local timezone
    const endOfMonth = dateFormatter(dayjs().endOf('month').tz(localTimezone));
    try {
      const res = await getUserTimeSheetCountByDate(accessToken, startOfMonth, endOfMonth);
       countRef.current=res.data;

      const response = await getUserTimeSheetByDate(accessToken, startOfMonth, endOfMonth, page, rowsPerPage);
      setRows(response.data);
      // setCount(response.data.length)

      startDateRef.current=startOfMonth;
      endDateRef.current=endOfMonth;
      alignmentRef.current="month";
      console.log(response)
    } catch (error: any) {

    } finally {

    }
  };


  React.useEffect(() => {
    fetchProject();
    
  }, []);


  const dateFormatterDetails = (value: any) => {
    const date = new Date(value);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const formattedDate = date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
    const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    return formattedDate;
  }

  const Month = async () => {
    setLoading(false)
    const localTimezone = dayjs.tz.guess();
    let startOfMonth;
    let endOfMonth;
    if ( startDateRef.current) {

      const inputDate =  startDateRef.current ? dayjs( startDateRef.current) : dayjs();

      startOfMonth = dateFormatter(inputDate.startOf('month').tz(localTimezone));
      endOfMonth = dateFormatter(inputDate.endOf('month').tz(localTimezone), true);
      setEndDate(endOfMonth);
      console.log(startOfMonth, startOfMonth)
      startDateRef.current=startOfMonth;
      endDateRef.current=endOfMonth;
    } else {
    // Start and end of the month in local timezone
     startOfMonth = dateFormatter(dayjs().startOf('month').tz(localTimezone));
     endOfMonth = dateFormatter(dayjs().endOf('month').tz(localTimezone), true);
    console.log("Start of Month:", startOfMonth);
    console.log("End of Month:", endOfMonth);
    startDateRef.current=startOfMonth;
    endDateRef.current=endOfMonth;

    // setEndDate(endOfMonth);
    // setStartDate(startOfMonth);

    }
    const res = await getUserTimeSheetCountByDate(accessToken, startOfMonth, endOfMonth);
     countRef.current=res.data;

    const response = await getUserTimeSheetByDate(accessToken, startOfMonth, endOfMonth, pageRef.current, rowsPerPageRef.current);
    console.log(page, rowsPerPage)

    if (response.data) {
      setLoading(false); // Set loading to false when data is fetched
      setRows(response.data);
      console.log(response.data)
      // setCount(response.data.length)
    }

  };
  const Day = async () => {
    setLoading(true); // Set loading to false when data is fetched
console.log(alignmentRef.current)
    // Start of the current day at 12:00 AM local time
    const localTimezone = dayjs.tz.guess();
    let startOfDay;
    let endOfDay;
    if (startDateRef.current) {

      const inputDate = startDateRef.current ? dayjs(startDateRef.current) : dayjs();
      console.log(startDateRef.current,inputDate, localTimezone)

      startOfDay = dateFormatter(inputDate.startOf('day').tz(localTimezone));
      endOfDay = dateFormatter(inputDate.endOf('day').tz(localTimezone), true);
      console.log(startOfDay, endOfDay)
      // setStartDate(startOfDay);
      // setEndDate(startOfDay);
      startDateRef.current=startOfDay;
      endDateRef.current=endOfDay;

    } else {
      startOfDay = dateFormatter(dayjs().startOf('day').tz(localTimezone));
      endOfDay = dateFormatter(dayjs().endOf('day').tz(localTimezone), true);
      startDateRef.current=startOfDay;
      endDateRef.current=endOfDay;
    }
    const res = await getUserTimeSheetCountByDate(accessToken, startOfDay, endOfDay);
     countRef.current=res.data;

    const response = await getUserTimeSheetByDate(accessToken, startOfDay, endOfDay, pageRef.current, rowsPerPageRef.current);
    if (response.data) {
      setLoading(false); // Set loading to false when data is fetched
      setRows(response.data);
      // setCount(response.data.length)

    }


    console.log(startOfDay, endOfDay);
  };
  const handleTextChange = async (event: any) => {
    console.log(event.target.value)
    // setValue(event.target.value);
    if (event.target.value?.length >= 1) {
      // if(role ==='customer') {
      const localTimezone = dayjs.tz.guess();

      // Start and end of the month in local timezone
      const startOfMonth = dateFormatter(dayjs().startOf('month').tz(localTimezone));
      const endOfMonth = dateFormatter(dayjs().endOf('month').tz(localTimezone), true);
      console.log("Start of Month:", startOfMonth);
      console.log("End of Month:", endOfMonth);
      //   searchAllTimeSheetByName(accessToken,startOfMonth,endOfMonth,page,rowsPerPage,event.target.value).then((res)=>{
      //     console.log(res)
      //     setRows(res.data)
      //   })

      searchUserTimeSheetByDate(accessToken, startOfMonth, endOfMonth, page, rowsPerPage, event.target.value).then((res) => {
        console.log(res)
        setRows(res.data)
      })
      // }
      // else {
      // const response1 = await getSearchBySales(accessTokenPayload.oid,statuses, event.target.value, accessToken);
      // console.log(response1.data);
      // setData(response1.data);
      // }
    }
    else {
      fetchProject();
    }
  }
  const timeFormatterDetails = (value: any) => {
    const date = new Date(value);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    return formattedTime;
  }
  const dateFormat = (value: any, isEndOfDay: boolean = false) => {
    console.log(value, isEndOfDay)
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${date.getFullYear()}-${month}-${day}`;
    console.log(isEndOfDay)
    if (isEndOfDay) {
      formattedDate += "T23:59:59.999Z";
    } else {
      formattedDate += "T00:00:00.000Z";
    }

    return formattedDate;
  };

  const handleDateChange = (newValue, name) => {
    alignmentRef.current = 'day';
    console.log(name);
    const eventLikeObject = {
      target: {
        name,
        value: newValue,
      },
    };
  
    console.log(eventLikeObject);
    if (eventLikeObject.target.name === "startDate") {
      const formattedStartDate = dateFormat(newValue);
      startDateRef.current = formattedStartDate;

      // setAlignment("day");
      console.log(dateFormat(newValue));
    } else {
      console.log(dateFormat(newValue, true));
      let enndDate = dateFormat(newValue, true);
      console.log(enndDate);
      // setEndDate(enndDate);
    }
  console.log(startDate)
    // Ensure alignment state is updated before calling the respective function
    setTimeout(() => {
      if (alignmentRef.current === 'day') {
        Day();
      } else if (alignmentRef.current === 'week') {
        Week();
      } else if (alignmentRef.current === 'month') {
        Month();
      }
    }, 0);
  };
// const handleNextAndPreviousTimes=()=>{
//   if (alignmentRef.current === 'day') {
//     startDateRef.current
//   }

// }

  const updateSetCount = (data: any) => {
    countRef.current=data; // Update setCount with res.data or any other data
  };
  const Week = async () => {
    setLoading(true);

    const localTimezone = dayjs.tz.guess();
    let startOfWeek;
    let endOfWeek;
    console.log(startDate, localTimezone)
    if ( startDateRef.current) {
      console.log(startOfWeek, endOfWeek)

      const inputDate =  startDateRef.current ? dayjs( startDateRef.current) : dayjs();

      startOfWeek = dateFormatter(inputDate.startOf('week').tz(localTimezone));
      endOfWeek = dateFormatter(inputDate.endOf('week').tz(localTimezone), true);
      console.log( endOfWeek)
      setStartDate(startOfWeek);
      setEndDate(endOfWeek);
      startDateRef.current=startOfWeek;
      endDateRef.current=endOfWeek;
    } else {
      startOfWeek = dateFormatter(dayjs().startOf('week').tz(localTimezone));
      endOfWeek = dateFormatter(dayjs().endOf('week').tz(localTimezone), true);
      startDateRef.current=startOfWeek;
      endDateRef.current=endOfWeek;
    }

    try {
      console.log(startOfWeek, endOfWeek)

      const res = await getUserTimeSheetCountByDate(accessToken, startOfWeek, endOfWeek);
      console.log(res);
      countRef.current=res.data;

      const response = await getUserTimeSheetByDate(accessToken, startOfWeek, endOfWeek, pageRef.current, rowsPerPageRef.current);
      if (response.data) {
        setRows(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }

    console.log(startOfWeek, endOfWeek);
  };
  const closeLeaveComponent = () => {
    setShowLeaveComponent(false);
  }
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div className='searchBar'>
            <Grid container alignItems={'center'} rowSpacing={1} mt={1}>
                            <Grid item xs={4}>
                                {/* <Paper
                    variant="outlined"
                    component="form"
                    className='searchField'
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                  > */}
                                {/* <Grid item xs={6} >
                      <Autocomplete
                        size='small'
                        disablePortal
                        id="combo-box-demo"
                        options={rows}
                        getOptionLabel={(option: any) => `${option.trade}${option.customerName}`} // Customize as needed
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Project Name" />}
                        
                      />     </Grid> */}

                                    <TextField
                                        label="Search by task,employee,project,customer"
                                        size='small'
                                        name="materialName"
                                        onChange={(e) => handleTextChange(e)}
                                        // value={value}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <br />
                                    <br />
                            </Grid>
                            <Grid item xs={3} alignSelf={'center'} sx={{ padding: '15px', paddingLeft: 5}}>
                                    <Stack direction="row" spacing={1} >
                                        {(startDateRef.current !== null && endDateRef.current !== null) &&
                                        <>
                                        <Stack className='timeSheetStart'>
                                                <Typography variant='h6'>{dayjs((startDateRef.current)?.replace(/Z/g, '')).format("YYYY-MMM-DD HH:mm")}</Typography>
                                        </Stack>
                                        <Stack>-</Stack>
                                        <Stack className='timeSheetEnd'>
                                            <Typography variant='h6'>{dayjs((endDateRef.current)?.replace(/Z/g, '')).format("YYYY-MMM-DD HH:mm")}</Typography>
                                        </Stack>
                                        </>
                                        }
                                    </Stack>
                            </Grid> 
                            <Grid item xs={2} className='alignEnd' sx={{ padding: '15px' }}>    
                                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                            <DatePicker
                                                label="Start Date"
                                                // value={value}
                                                name="startDate"
                                                onChange={(e) => {
                                                    handleDateChange(e, "startDate");
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        // fullWidth: true,
                                                        size: 'small',
                                                    }
                                                }}
                                            // renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} className='alignEnd' sx={{ padding: '15px' }}> 
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignmentRef.current}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="day">Day</ToggleButton>
                                    <ToggleButton value="week">Week</ToggleButton>
                                    <ToggleButton value="month">Month</ToggleButton>
                                </ToggleButtonGroup>
                                &nbsp;    &nbsp;
                                {/* <Button
                      variant="contained" size="small"
                      onClick={Day}
                    >
                   &nbsp; This day
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained" size="small"
                      onClick={Week}
                    >
                   &nbsp; This week
                   </Button>
                   &nbsp;
                    <Button
                      variant="contained" size="small"
                      onClick={Month}
                    >
                   &nbsp; This month
                    </Button>
                    &nbsp; */}
                                {/* <Button
                    className='success'
                      variant="contained" size="large"
                      onClick={handleOpenConfirmation}
                    >
                   &nbsp; Book Leave
                    </Button> */}
                            </Grid>
                            <Grid item xs={1}>
                            <Button
                  className='success'
                  variant="contained" size="large"
                  onClick={handleOpenConfirmation}
                >
                  &nbsp; Book Leave
                </Button>
    </Grid>
    </Grid>
                    </div>
                    </Grid>
          <Box sx={{ width: '100%', }}>
            <Paper>
              <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                <Table
                  sx={{ minWidth: 750, border: 'none', }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <TableHead className='tableHead'>
                    <TableRow    >
                      {/* <TableCell className='tableTimeSheet'>Name</TableCell> */}
                      <TableCell className='tableTimeSheet'>Project Name</TableCell>
                      <TableCell className='tableTimeSheet'>Task Name</TableCell>
                      <TableCell className='tableTimeSheet'>Time In - Time Out </TableCell>
                      <TableCell className='tableTimeSheet'>Duration </TableCell>
                      <TableCell className='tableTimeSheet'>Location </TableCell>
                      <TableCell className='tableTimeSheet'>Status </TableCell>
                      <TableCell  >Action </TableCell>

                      {/* Add more headers as needed */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(rows).length === 0 ? (<TableRow>
                      <TableCell colSpan={10}>
                        <Typography align="center">No Timesheet details</Typography>
                      </TableCell>
                    </TableRow>) :

                      (<>
                        {Object.entries(rows).map(([date, items]) => (
                          <React.Fragment key={date}>
                            <TableRow style={{ backgroundColor: 'rgb(191 162 128)', color: 'white' }} onClick={() => handleRowClick(date)}>
                              <TableCell className='collapsibleTable'>{date}   <IconButton style={{ backgroundColor: 'rgb(191 162 128)', color: 'white' }}
                                aria-label="expand row"
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(!open);
                                  handleRowClick(date);
                                }}
                              >
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                              </IconButton></TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />

                              {/* Add more empty cells as needed */}
                            </TableRow>
                            {openRow === date && (
                              <>
                                {(items as any[]).map((row, index) => (
                                  <TableRow key={index}>

                                    <TableCell size='small'>{row?.trade}<br />
                                      {row.customerName}<br />
                                      {(() => {
                                        const address = JSON.parse(row?.siteAddress);
                                        return `${address.address}`;
                                      })()}<br />
                                      {dateFormatterDetails(row?.projectStartDate)}</TableCell>
                                    <TableCell size='small'>{row?.taskName}</TableCell>
                                    <TableCell size="small">
                                      <Stack direction="row" alignItems="center" spacing={1}>
                                        <Stack className='timeSheetStart'>{timeFormatterDetails(row?.startTime)}</Stack>
                                        <Stack>-</Stack>
                                        <Stack className='timeSheetEnd'>{dateFormatterDetails(row?.endTime)}</Stack>
                                      </Stack>
                                    </TableCell>                            <TableCell size='small'>{row?.timeSpent?.toFixed(2)}</TableCell>

                                    <TableCell size='small'>  {(() => {
                                      const address = JSON.parse(row?.siteAddress);
                                      return `${address.address}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
                                    })()}</TableCell>
                                    {row.timeLogStatus === EMPLOYEETIME && (<TableCell size='small' > <span className='statusAwaitingApproval'>{row.timeLogStatus}</span></TableCell>)}
                                    {row.timeLogStatus === ADMINAPPROVE && (<TableCell size='small' > <span className='salesApproval'>{row.timeLogStatus}</span></TableCell>)}
                                    {row.timeLogStatus === ADMINREJECT && (<TableCell size='small' > <span className='statusAwaitingApproval'>{row.timeLogStatus}</span></TableCell>)}

                                    {row.timeLogStatus === 'active' && (<TableCell size='small'> <span className=' activeProject'>{row.timeLogStatus}</span></TableCell>)}
                                    {row.timeLogStatus === 'break' && (<TableCell size='small'> <span >{row.timeLogStatus}</span></TableCell>)}
                                    {row.timeLogStatus === 'completed' && (<TableCell size='small'> <span className=' activeProject'>{row.timeLogStatus}</span></TableCell>)}
                                    <TableCell size='small'>
                                      <Stack direction={'row'}>
                                        {/* {(row.status === 'invited' || !row.status || row.status === 'Invited') && ( */}
                                        <Tooltip title="Edit">
                                          <IconButton onClick={() => handleEditCustomer(row)} >
                                            <EditIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {/* )}
                                {row.status === 'Active' && (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => onEdit(row.id)}>
                                      <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )} */}
                                        {/* <Tooltip title="Delete">

                                  <IconButton onClick={() => onDelete(row.id)}  >
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Tooltip> */}
                                      </Stack>

                                    </TableCell>
                                  </TableRow>

                                ))}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </>)}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5, 10]}
                component="div"
                // count={rows.length}
                count={countRef.current}
                rowsPerPage={rowsPerPageRef.current}
                page={pageRef.current}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /></Paper>
          </Box>
        </Grid>
      {showLeaveComponent && (<BookLeave open={showLeaveComponent} onClose={() => closeLeaveComponent()} setRows={setRows} rowDatas={rowData} timeView={alignment} setCount={setCount} />)}

      {showAnotherComponent && (<UpdateTimeSheet open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} timeView={startDateRef.current} rowDatas={rowData} setCount={updateSetCount}  />)}
    </>
  );
}
export default TimeSheet;