import React from "react";
import { Task, Task2,ViewMode } from "../components/react-gantt-component/types/public-types";
import { Gantt  } from "../components/react-gantt-component/components/gantt/gantt";
import { Toolbar, IconButton } from "@mui/material";
import { ViewSwitcher } from "./view-switcher";
import { getStartEndDateForProject} from "./helper";
import { CardHeader } from "@mui/material";
import { Typography } from "@mui/material";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import { CustomTask, exportToExcel, exportToPdf } from "./ReactGantchartDataOptionsToolBar.tsx";
import { initTasks } from "./helper";
// import "gantt-task-react/dist/index.css";

// Init
const GanttChart: React.FC<any> = (props) => {
  console.log("props",props)
  const [view, setView] = React.useState<ViewMode>(ViewMode.Hour); 
  const [tasks, setTasks] = React.useState<Task[]>(props.tasks);//initTasks()--hardcode value

  const [updated, setUpdated] =  React.useState<any>([]);
  console.log(tasks);
 const [tasks2, setTasks2] = React.useState<Task2[]>();
  const [isChecked, setIsChecked] = React.useState(true);
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
   console.log("On date change Id:" + task.id);
   console.log(task);
    let newTasks = tasks.map(t => (t.id === task.id ? task : t));
    const newTasks2 = tasks.filter(t => t.id === task.id);

    console.log(newTasks2);
    console.log(newTasks);
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      console.log(start,end);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    console.log(newTasks);

    if(updated.length !== 0){
      const updatedTask = updated.filter(t => t.id === newTasks2[0].id);
      const index = updated.indexOf(updatedTask[0]);
      if(index >= 0){
        updated[index] = newTasks2[0];
      }else{
        updated.push(newTasks2[0]);
        }
    }else{
    updated.push(newTasks2[0]);
    }
    //setUpdated(newTasks2);
    // console.log("updated tasks",updated);

   
    // console.log("updated tasks", updated);
    setTasks(newTasks);
  };
  // console.log("updated tasks2",updated);

  // const handleSave = async () => {
    
  //     console.log("save",updated);
    
  // };
  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter(t => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
    //console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task: Task) => {
  //  console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
   // console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task:Task) => {
    setTasks(tasks.map(t => {
      // console.log(t.id,task.id); 
      if(t.id === task.id){
        // console.log(task);
        return task;
      }
      
      else {
        // console.log(t);
        return t;
      }
      // return t.id === task.id ? task : t;
    }));
    
    // console.log(task);
    console.log("On expander click Id:" + task.id);
  };
  
  const handleExpandAllClick = (tasks: CustomTask[], expand: boolean) => {
  // Create a new array with updated tasks
  const updatedTasks = tasks.map(task => {
    // Update hideChildren property
    task.hideChildren = !expand;

    // Recursively update children
    if (task.children) {
      task.children = handleExpandAllClick(task.children, expand);
    }

    return task;
  });

  // Update state with the new array
  setTasks(updatedTasks);

  // Return the updated array (optional, depending on your use case)
  return updatedTasks;
};


  const handleCollapseAllClick = (tasks: CustomTask[]) => {
    handleExpandAllClick(tasks, false);
  };
  const updateTasks = (newTasks: Task[]) => {
    setTasks(newTasks);
  };

  return (
    <div id="GChart" style={{ maxWidth: "100%", marginRight: "10px",height:"100%",    width: "1409px"}}>
    <div className="Wrapper">
      {/* <CardHeader
      className="cardHeader"
      title={
        <Typography
          className="cardTitle"
          fontSize={18}
          fontWeight="bold"
        >
          Schedule
        </Typography>
      }
    /> */}
    {/* <Toolbar className="Toolbar">
      <Button className="toolButton" style={{border:'1px solid #ccc'}}
        startIcon={<KeyboardDoubleArrowDownIcon />}
        onClick={() => handleExpandAllClick(tasks, true)}
      >Expand All
      </Button>

      <Button className="toolButton" style={{marginLeft:'10px',border:'1px solid #ccc'}} 
        startIcon={<KeyboardDoubleArrowUpIcon />}
        onClick={() => handleCollapseAllClick(tasks)}
      >
        Collapse All
      </Button>
      <div style={{ flexGrow: 1 }}></div>
      <IconButton onClick={() => exportToPdf(tasks)}>
        <PictureAsPdfIcon />
      </IconButton>
      <IconButton onClick={() => exportToExcel(tasks)}>
        <GetAppIcon />
      </IconButton>
    </Toolbar> */}
      <ViewSwitcher
        onViewModeChange={viewMode => setView(viewMode)}
        onViewListChange={setIsChecked}
        isChecked={isChecked}
        updateTasks={updateTasks}
        selected={tasks}
      />
      {/* <h3>Gantt With Unlimited Height</h3> */}

      {/* <button onClick={handleSave}>Save</button> */}
      <Gantt
          tasks={tasks}
          viewMode={view}
          onDateChange={handleTaskChange}
          onDelete={handleTaskDelete}
          onProgressChange={handleProgressChange}
          onDoubleClick={handleDblClick}
          onClick={handleClick}
          onSelect={handleSelect}
          onExpanderClick={handleExpanderClick}
          listCellWidth={isChecked ? "155px" : ""}
          ganttHeight={300}
          columnWidth={columnWidth}        
      />
      {/* <h3>Gantt With Limited Height</h3>
      <Gantt
        tasks={tasks}
        viewMode={view}
        onDateChange={handleTaskChange}
        onDelete={handleTaskDelete}
        onProgressChange={handleProgressChange}
        onDoubleClick={handleDblClick}
        onClick={handleClick}
        onSelect={handleSelect}
        onExpanderClick={handleExpanderClick}
        listCellWidth={isChecked ? "155px" : ""}
        ganttHeight={300}
        columnWidth={columnWidth}
      /> */}
    </div></div>
  );
};
export default GanttChart;
