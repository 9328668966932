/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-apr-2024
* @author      : Karthikeyan.A,Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create user implementation
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |     DATE    |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2061           |  27/05/24   |   Vanitha     |  redirect implementation
* 2073           |  28/05/24   |   Vanitha     |  Success Pop up message display implementation
*
*/

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BadgeIcon from '@mui/icons-material/Badge';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { findUserDetails, getEmployeeAll, saveCreateUserAsset, updateUser } from '../services/UserService';
import { useLocation,  } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { getRoles } from '../services/RoleSevice';
import CustomDialog from './Dialog';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://gracefulmanagement.com/">
        Graceful Management Systems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

const Createuser: React.FC<any> = (props) => {
  const [roles, setRoles] = React.useState<any[]>([]);
  const [openDialogEmail, setOpenDialogEmail] = React.useState<boolean>(false);

  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [user, setUser] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    mobileNo: '',
    roles: '',
    deleted: false,
    eulaAccepted: false,
    active: false,
    gender: null,
    avatar: null,
    birthdate: null,
    middlename: null,
    updatedAt: null,
    registeredDate: null,
    objectId: null,
    alternateMobileNo: null,
    id: null,
    status: 'Invited'



  });

  const { state } = useLocation();
  const rowData = state && state.rowsData;


  //console.log(rowData);
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const hasEmailRef = React.useRef<boolean>(false);
  const [isSubmited, setIsSubmited] = React.useState<any>(false);
  const [noChange, setNoChange] = React.useState(false);

  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  const [content, setContent] = React.useState<any>('');

  const employeeRoles = async () => {
    try {
      const response = await getRoles(accessToken);
      setRoles(response.data)

    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  const findUserAlreadyExist = async (email: any) => {
    const res = await findUserDetails(email, accessToken);
    hasEmailRef.current = res.data;
  };

  const onClose = () => {
    setOpenDialogs(false);
    onCancel();
  }

  React.useEffect(() => {
    employeeRoles();

    if (rowData) {

      setUser({
        firstname: rowData.firstname || '',
        lastname: rowData.lastname || '',
        email: rowData.email || '',
        mobileNo: rowData.mobileNo || '',
        roles: rowData.roles || '',
        deleted: rowData.deleted || false,
        eulaAccepted: rowData.eulaAccepted || false,
        active: rowData.active || false,
        gender: rowData.gender || null,
        avatar: rowData.avatar || null,
        birthdate: rowData.birthdate || null,
        middlename: rowData.middlename || null,
        updatedAt: rowData.updatedAt || null,
        registeredDate: rowData.registeredDate || null,
        objectId: rowData.objectId || null,
        alternateMobileNo: rowData.alternateMobileNo || null,
        id: rowData.id || null,
        status: rowData.status || '',
      });
    }
  }, [rowData]);
  const handleEmailClose = () => {
    setOpenDialogEmail(false);

  };
  const handleInputChange = (fieldName: string) => (e:any) => {
    if(fieldName === "mobileNo"){
      if (!isNaN(e.target.value)) {
        setUser((prevUser) => ({
          ...prevUser,
          [fieldName]: e.target.value,
        }));
      }
    }else{
      setUser((prevUser) => ({
        ...prevUser,
        [fieldName]: e.target.value,
      }));
    }
  };

  const handleSelectChange = (fieldName: string) => (event: SelectChangeEvent<string>) => {
    setUser((prevUser) => ({
      ...prevUser,
      [fieldName]: event.target.value,
    }));
  };

  const onCancel = () => {
    props.onClose();
  };

  const formValidated = (data:any) =>{
    var validated = false;
    if (data.firstname !== '' && data.lastname !== '' && data.email !== '' && data.mobileNo !== '' && data.roles !== '') {
      validated = true;
    }
    return validated;
  }


  // Mobile number validation function
  const validateMobileNumber = (mobileNo) => {
    // Check if mobile number consists of exactly 10 digits
    return /^\d{10}$/.test(mobileNo);
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmited(true);
    event.preventDefault();
    await findUserAlreadyExist(user.email);
    if (rowData) {
      // If rowData is present, it's an edit operation
      // Use the appropriate update API (replace 'updateUser' with your actual update API)
      //console.log(rowData.id)
      // setOpenDialogs(true);
      if (rowData.firstname === user.firstname && rowData.lastname === user.lastname && rowData.email === user.email && rowData.mobileNo === user.mobileNo && rowData.roles === user.roles) {
        setNoChange(true);
      }
      else {
        if(formValidated(rowData) && validateMobileNumber(rowData.mobileNo) && validateEmail(rowData.email)){
          const result = await updateUser(rowData.id, user, accessToken);
          setOpenDialogs(true)

          if (result.status === 200) {
            setCreateSuccess(true);
            setContent('Employee details updated successfully');
          } else {
            setCreateError(true)
            setContent('Error updates employee details');
          }
        }
      }
    }
    else {
      // If rowData is not present, it's a create operation
      if (hasEmailRef.current === true) {
        setOpenDialogEmail(true);
      
      } else {
        if(formValidated(user) && validateMobileNumber(user.mobileNo) && validateEmail(user.email)){
        const result = await saveCreateUserAsset(user, accessToken);
        setOpenDialogs(true)

        if (result.status === 201) {
          setCreateSuccess(true);
          setContent('Employee details Created successfully');
        } else {
          setCreateError(true)
          setContent('Error creating employee details');
        }
      }
    }
    }


  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [open, setOpen] = React.useState(true);

  const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

    setNoChange(false)

  }

  return (
    <ThemeProvider theme={defaultTheme}>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          // onClose={handleClose}
          //onBackdropClick={handleBackdropClick}
          disableEscapeKeyDown
          PaperProps={{
            sx: {
              borderRadius: '15px', // Set the border radius to 15px
            },
          }}

        >
          <DialogContent sx={{ backgroundColor: "#f8f7fa", padding: '0px' }} >


            <Typography>
              <Typography className="addEmployee" sx={{ bgcolor: 'primary.main' }}>
                {/* <Button className='primaryTonalButton' startIcon={<ReplyAllIcon/>} onClick={onCancel}>
             <CloseIcon/>
            </Button> */}
                <Stack direction="row" spacing={1}>

                  {/* <Avatar sx={{ bgcolor: 'secondary.main' }}> */}
                  <BadgeIcon />
                  {/* </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Invite Employee
                  </Typography>
                </Stack>
              </Typography>
              {/* <CloseIcon  /> */}

            </Typography>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

              }}
            >

              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0, m: 5 }}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={12} mb={1}>    <Typography component="h1" variant="h6">Who's your new team member?</Typography></Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      autoComplete="given-name"
                      name="firstname"
                      required
                      fullWidth
                      id="firstname"
                      label="First Name"
                      autoFocus
                      size='small'
                      value={user.firstname}
                      onChange={handleInputChange('firstname')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      // required
                      fullWidth
                      id="middlename"
                      label="Middle name"
                      name="middlename"
                      size='small'

                      // autoComplete="family-name"
                      value={user.middlename}
                      onChange={handleInputChange('middlename')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      name="lastName"
                      size='small'
                      value={user.lastname}
                      onChange={handleInputChange('lastname')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      size='small'
                      name="email"
                      value={user.email}
                      onChange={handleInputChange('email')}
                      error={user.email !== '' && !validateEmail(user.email) && isSubmited}
                      helperText={user.email !== '' && !validateEmail(user.email)  && isSubmited? 'Invalid email address' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={1}>
                    <TextField
                      required
                      fullWidth
                      name="mobileNo"
                      label="Mobile No"
                      type="text"
                      id="mobileNo"
                      size='small'
                      value={user.mobileNo}
                      onChange={handleInputChange('mobileNo')}
                      error={user.mobileNo !== '' && !validateMobileNumber(user.mobileNo)  && isSubmited} // Error condition
                      helperText={user.mobileNo !== '' && !validateMobileNumber(user.mobileNo)  && isSubmited? 'Invalid mobile number' : ''}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={user.roles}
                        label="Role"
                        onChange={handleSelectChange('roles')}
                        size='small'
                      >
                        {roles?.filter(role => role.name !== 'customer' && role.name !== 'admin') // Filter out 'labour' and 'admin' roles
                          .map(role => (
                            <MenuItem key={role.id} value={role.name}>
                              {role.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='DD MMM YYYY'
                            label="Choose Date"
                            // disablePast   
                            // disableHighlightToday   
                            // shouldDisableDate={date => date.day() === 0 || date.day() === 6}            
                            // minDate = {dayjs().add(1, 'day')}
                            slotProps={{ textField: { fullWidth: true, size: "small", error: (user.registeredDate === undefined) } }}
                            value={parsedDate}
                            onChange={(e:any)=>handleDateChange(e)}
                     
                          />
                        </LocalizationProvider> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
        <DatePicker
          label="Controlled picker"
          value={parsedDate}
          onChange={(e:any)=>handleDateChange(e)}
        />
      </DemoContainer>
    </LocalizationProvider> */}

                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button className="dangerButton" variant="contained" size="medium" onClick={onCancel} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Button type="submit" variant="contained" fullWidth >
                      Submit
                    </Button>
                  </Grid>
                </Grid>

              </Box>
            </Box>
            <Copyright sx={{ mt: 0, mb: 3 }} />
          </DialogContent>
          <Dialog open={openDialogEmail} >
            <DialogTitle className='warning'>
              <Box paddingLeft={8} paddingRight={8}>
                <Typography textAlign={'center'}>
                  <PriorityHighIcon sx={{ fontSize: "50px", color: "white" }} />
                </Typography>
                <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                  Warning
                </Typography>
              </Box>
            </DialogTitle>
            <Box paddingLeft={8} paddingRight={8}>
              <DialogContent>
                <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                  Email already registered
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: "center" }}>
                <Button variant='contained' className='warning' onClick={handleEmailClose}>Close</Button>
              </DialogActions>
            </Box>
          </Dialog>
        </Dialog>

        <CustomDialog
          open={openDialogs}
          onClose={() => onClose()}
          success={createSuccess}
          error={createError}
          Content={createSuccess ? content : "Failed To Create "}
        />

        <Dialog open={noChange} >
          <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>

      </Container>

    </ThemeProvider>
  );
}
export default Createuser;