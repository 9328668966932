import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { UserContext } from "../hooks/UserSession";
import { useContext, useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Customerfinance from "../components/finance/Customerfinance";
import Finance from "../components/finance/Finance";

const FinanceManangement: React.FC = () => {
  let navigate = useNavigate();
  const navigateToCreateBid = () => {
    navigate('/createbid');
  }
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const { userSessionDetails, setUserDetails } = useContext(UserContext);

  console.log(userSessionDetails.role.name)

  // useEffect(() => {
  //   const fetchRoleInform = async () => {
  //     try {
  //       const response = await getUserSession(accessToken, accessTokenPayload.oid);
  //       console.log(response.role.name);
  //       setRole(response.role.name);
  //       setLoading(false); // Set loading to false when data is fetched
  //     } catch (error) {
  //       console.error('Error fetching user session:', error);
  //       setLoading(false); // Set loading to false in case of error
  //     }
  //   }
  //   console.log(role)
  // }, []);


  return (<>
    <Grid container spacing={2} >
      <Grid item xs={12} justifyContent="center" className="projectRendering">
        {/* {loading ? (

          <CircularProgress className="loaderCircularBar" />
        ) : (  
          <> */}

        {userSessionDetails.role.name === 'customer' ? (
          <Finance />
        ) : (
          <Customerfinance />
        )}

        {/* </> 
        )} */}
      </Grid>

    </Grid>
  </>)
}

export default FinanceManangement;