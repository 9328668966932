import React, { useMemo } from "react";
import styles from "./task-list-table.module.css";
import { Task,Task2 } from "../../types/public-types";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltip from "@mui/material/Tooltip";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const localeDateStringCache:any = {};
const toLocaleDateStringFactory =
  (locale: string) =>
  (date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
    const key = date.toString();
    let lds = localeDateStringCache[key];
    if (!lds) {
      lds = date.toLocaleDateString(locale, dateTimeOptions);
      localeDateStringCache[key] = lds;
    }
    return lds;
  };
const dateTimeOptions: Intl.DateTimeFormatOptions = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  // tasks2: Task2[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  // tasks2,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
}) => {
  const toLocaleDateString = useMemo(
    () => toLocaleDateStringFactory(locale),
    [locale]
  );

  return (
    <div
      className={styles.taskListWrapper}
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
        border:'1px solid #ccc',
        overflowY:'scroll',
        width:"200px"
       
      }}
    >
      
      {tasks.map(t => {
        let expanderSymbol 
        if (t.hideChildren === false) {
          expanderSymbol = <ExpandMoreIcon sx={{color:"#3c5bfa"}}/>;
        } else if (t.hideChildren === true) {
          expanderSymbol = <ExpandLessIcon sx={{color:"#3c5bfa"}}/>;
        }
      //  console.log(t);
       
        return (
          <Tooltip
          title={`Name: ${t.name}\nID: ${t.id}\nPhase: ${t.Phase}`}
          arrow
        >
          <div
            className={styles.taskListTableRow}
            style={{ height: rowHeight, overflowY:'scroll', }}
            key={`${t.id}row`}
          >
            {/* <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
              title={t.name}
            > */}
              <div className={styles.taskListNameWrapper}>
                <div
                  className={
                    expanderSymbol
                      ? styles.taskListExpander
                      : styles.taskListEmptyExpander
                  }
                  onClick={() => onExpanderClick(t)}
                >
                  {expanderSymbol}
                </div>
                <div style={{fontWeight:t.type === 'project' ? '600' : '400px',color:"grey", fontFamily: "'Poppins', 'Helvetica Neue', 'Arial', sans-serif",  marginLeft: t.type === 'project' ? '0' : '15px',}}>{t.name}</div>
              </div>
            {/* </div> */}
          
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
                color:"grey",
                fontFamily: "'Poppins', 'Helvetica Neue', 'Arial', sans-serif"
              }}
            >
              &nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
                color:"grey",
                fontFamily: "'Poppins', 'Helvetica Neue', 'Arial', sans-serif"
              }}
            >
              &nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
                color:"grey",
                fontFamily: "'Poppins', 'Helvetica Neue', 'Arial', sans-serif"
              }}
            >
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t.id}
              </div>
              <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
                color:"grey",
                fontFamily: "'Poppins', 'Helvetica Neue', 'Arial', sans-serif"
              }}
            >
&nbsp;&nbsp;&nbsp;&nbsp;{t. Phase}
              </div>


          </div>
          </Tooltip>
        );
      })}


    </div>
  );
};

