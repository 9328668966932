/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Timelogd
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1816           |  06/05/24   | Vanitha  |  Change the Table style
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TableSortLabel, Paper, Checkbox, IconButton,
  Tooltip, Button, Divider, Grid, MenuItem, Select, Menu, Typography, useMediaQuery, SelectChangeEvent, Snackbar, Alert, InputAdornment, TextField, tableCellClasses, 
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Print as PrintIcon,
  Share as ShareIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { getInvoiceByProjectId, getInvoiceList, getInvoicesByProjectId, uploadPaymentReceipt, getPaymentreceipt, generateInvoice } from '../../services/InvoiceServices';
import { Stack, color, useTheme } from '@mui/system';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { dateFormatter } from '../../utils/DateFormatter';
import Link from '@mui/material/Link';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { rows } from '../../data/dataTable';
import { getPoDetails } from '../../services/PoService';
import { getAdminTimeLogDetailsByProjectId, getTimeLogDetailsByProjectId } from '../../services/TimelogService';
import { format } from 'date-fns';
import { getUserSession, UserContext } from '../../hooks/UserSession';
import Header from '../Header';


interface Data {
  id: number;

  taskName: any;
  startTime: number
  endTime: any;
  timeSpent: any;


}

function createData(
  id: number,
  taskName: any,
  startTime: any,
  endTime: any,
  timeSpent: any,

): Data {
  return {
    id,
    taskName,
    startTime,
    endTime,
    timeSpent,

  };
}
// const rows = [
//   createData(1, '28/5/2023', 1001, 'John', 670, "Overdue 52 days"),
//   createData(2, '12/3/2023', 452, 'Jack', 51, "Overdue 30 days"),
//   createData(3, '8/11/2023', 262, 'Wiiliam', 24, "Overdue 15 days"),
//   createData(4, '18/7/2023', 159, 'Alice', 24, "Overdue 90 days")
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
//   const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'taskName',
    numeric: true,
    disablePadding: false,
    label: 'Task Name',
  },
  {
    id: 'startTime',
    numeric: true,
    disablePadding: false,
    label: 'Start Time',
  },
  {
    id: 'endTime',
    numeric: false,
    disablePadding: true,
    label: 'Issue Date',
  },
  {
    id: 'timeSpent',
    numeric: true,
    disablePadding: false,
    label: 'Customer Name',
  },


];


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  const [openmatrec, setOpenmatrec] = useState<boolean>(false); // Declare openMatrec state variable

  const handlematrecCancel = () => {
    setOpenmatrec(false);
  };
  const handlematrecOpen = () => {
    setOpenmatrec(true);
  };
  return (
    <>
      <TableHead >
        <TableRow className='tablerows tableHead'>
          {/* <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell> */}
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel

                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const TimeLogDetails: React.FC<any> = (props: any) => {
    const newDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('customerid' as keyof Data);
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const theme = useTheme();
    const navigate = useNavigate();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [poList, setPOList] = React.useState([] as any[]);
    const [userRole, setUserRole] = React.useState<any>();
    const { userSessionDetails } = useContext(UserContext);

    const [timelogList, setTimelogList] = React.useState([] as any[]);
    React.useEffect(() => {
        const fetData = async () => {
          // const res = await getUserSession(accessToken);

          if(userSessionDetails?.role.name=='admin'){
            const response = await getAdminTimeLogDetailsByProjectId(props?.projectId,accessToken);
            // console.log(response);
            setTimelogList(response.data)
          }
          else{
            const response = await getTimeLogDetailsByProjectId(props?.projectId,accessToken);
            // console.log(response);
            setTimelogList(response.data)
          }
       


            
        }
        fetData();
    }, [])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: theme.palette.primary.main, // Customize the fill color when checked
        },
    });
const dateFormatterDetails = (value: any) => {
      const date = new Date(value);
      var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const formattedDate = date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
      const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
      return formattedDate + " " + formattedTime;
  }
  
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const SearchBar = () => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClicks = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className='searchBar'>
              
                <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
             
                    {/* <Grid item xs={2} mt={2}>
                        <Select
                            className='filter'
                            fullWidth
                            value=""
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            size='small'
                        >
                            <MenuItem value="" disabled>
                                Batch actions
                            </MenuItem>
                            <MenuItem value="1">Option 1</MenuItem>
                            <MenuItem value="2">Option 2</MenuItem>
                            <MenuItem value="3">Option 3</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3} mt={2}>

                    </Grid>
                    <Grid item xs={1}>  </Grid>
                    <Grid item xs={4}>  </Grid>
                    <Grid item xs={2} className='createInvoice'>
                        <Grid item xs={12} mt={2}>
                            <Tooltip title="Print">
                                <IconButton>
                                    <PrintIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Share">
                                <IconButton>
                                    <ShareIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Settings">
                                <IconButton>
                                    <SettingsIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                     {/* <Grid item xs={6} mt={2}>
                      <TextField
                                            label="Search "
                                            size='small'
                                            name="materialName"
                               
                                           
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton >
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <br/>
                                        <br/>
                </Grid> */}
                </Grid>
            </div>
        )
    };
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={12} sm={12} >
                <Grid item xs={12} md={12}>
                <Box
                        display={smScreen ? "flex" : "block"}
                        flexDirection={smScreen ? "row" : "column"}
                        justifyContent={smScreen ? "space-between" : "start"}
                        alignItems={smScreen ? "center" : "start"}
                        m="10px 0"
                    >
                        <Header title="Project TimeSheet" subtitle="" />
                    </Box>
                    </Grid>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='cardBoxShadow' sx={{ width: '100%', mb: 2 }}>
                            <SearchBar />
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, }}
                                    aria-label="customized table"
                                    // size={dense ? 'small' : 'medium'}
                                >
                                       <TableHead >
                                        <TableRow className='tableHead'>
                                            <TableCell>Task Name    </TableCell>
                                            <TableCell>Start Time   </TableCell>
                                            <TableCell>End Time     </TableCell>
                                            <TableCell>TimeSpent    </TableCell>
                                            <TableCell>Total BreakTimes</TableCell>
                                            <TableCell>No of breaks</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {timelogList?.map((row, index) => {
                                            const isItemSelected = isSelected(row.taskId);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    hover
                                                    onClick={(event) => handleClick(event, row.taskId)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.taskId}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    {/* <TableCell padding="checkbox">
                                                        <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell> */}
                                                    {/* <TableCell
                                                        // className='invoiceRows'
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >

                                                    </TableCell> */}
                                                    <TableCell>{row.taskName}</TableCell>
                                                    <TableCell>{dateFormatterDetails(row.startTime)}</TableCell>
                                                    <TableCell>{dateFormatterDetails(row.endTime)}</TableCell>
                                                    <TableCell>{row.timeSpent}</TableCell>
                                                    <TableCell>{row.totalBreakTimes}</TableCell>
                                                    <TableCell>{row.breaksTaken}</TableCell>
                                                    
                                                </TableRow>
                                            );
                                        })}
                                        {/* {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )} */}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>

    );
}

export default TimeLogDetails;