
export interface OidcConfiguration {
    client_id: string;
    client_secret: string;
    redirect_uri: string;
    silent_redirect_uri: string;
    scope: string;
    authority: string;
}

const oidcConfiguration: OidcConfiguration = {
    client_id: "f2bf96c0-3457-47e0-a5bf-4edfd3f6ef5c",
    client_secret: "secret",
    redirect_uri: "https://qa.gracefulmanagement.com/callback",
    silent_redirect_uri: "https://qa.gracefulmanagement.com/silent-callback",
    scope: "openid",
    authority: "https://gmscloudqa.b2clogin.com/gmscloudqa.onmicrosoft.com/B2C_1_signin_only_nomfa"
};

export const ConfigUrl = {
    baseURL: "https://ca-gmscloud-tenant-api-qa-ncus.agreeabledesert-6a142711.northcentralus.azurecontainerapps.io/gms-provider/api/v1"
};


export default oidcConfiguration;
