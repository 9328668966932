import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

export const Imageupload = () => {
  const [selectedFile, setSelectedFile] = useState<File | string>();
 
  const getFile = (event:any) => {
    const file = event.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
  };
 
  const handleCardClick = () => {
    const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
    fileInput.click();
  };
 
  return (
    <div>
      <Card
        sx={{
          width: "300px",
          height: "300px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        onClick={handleCardClick}
      >
        <label >
          {typeof selectedFile === "string" ? (
            <img src={selectedFile} alt="Selected" />
          ) : (
           <Typography>Upload</Typography>
          
          )}
        </label>
        <input
          type="file"
          id="image-upload-input"
          onChange={getFile}
          style={{ display: "none" }}
          accept="image/*"
        />
      </Card>
    </div>
  );
};
 