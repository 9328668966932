import { executeDelete, executePost } from "./Api";
import { executeGetData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";



export const getCartCount = async (customerUid: any, accesstoken: any) => {
    const url = `${ConfigUrl.baseURL}/bids/getCartCount/customerUid/${customerUid}`;
    return await executeGetData(url, accesstoken);
};


export const getCartList = async (customerUid: any, status: any, accesstoken: any) => {
    const url = `${ConfigUrl.baseURL}/bids/getBidsByCustomerUidAndStatus/customerUid/${customerUid}/status/${status}`;
    return await executeGetData(url, accesstoken);
};

export const deleteCart = async (id: any) => {
    const url = `${ConfigUrl.baseURL}/bids/${id}`;
    return await executeDelete(url);
};