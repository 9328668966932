import React from 'react';
import Header from '../../components/Header';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FolderIcon from '@mui/icons-material/Folder';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { green, pink, deepOrange, deepPurple, blue, yellow } from '@mui/material/colors';
import AvatarGroup from '@mui/material/AvatarGroup';
import './Avatar.scss';

import { Card, CardContent, CardActions, Grid } from '@mui/material';

const Avatars: React.FC = () => {
    return (
        <div>
            <Header title="Progress Components Templates" subtitle={null} />

            <Card className="icon-avatar-card">
                <CardContent>
                    <h4>Icon Avatar</h4>
                    <Stack direction="row" spacing={2}>
                        <Avatar>
                            <FolderIcon />
                        </Avatar>
                        <Avatar style={{ backgroundColor: 'pink' }}>
                            <PageviewIcon />
                        </Avatar>
                        <Avatar style={{ backgroundColor: 'green' }}>
                            <AssignmentIcon />
                        </Avatar>
                    </Stack>
                    <br />
                    <h4>Avatar Variant</h4>
                    <Stack direction="row" spacing={3}>
                        <Avatar style={{ backgroundColor: 'deepOrange', borderRadius: '0' }} variant="square">
                            N
                        </Avatar>
                        <Avatar style={{ backgroundColor: 'green', borderRadius: '50%' }} variant="rounded">
                            <AssignmentIcon />
                        </Avatar>
                    </Stack>
                </CardContent>
            </Card>


            <br />
            <Card className="letter-avatar-card">
                <CardContent>
                    <h4>Letter Avatar</h4>
                    <Stack direction="row" spacing={2}>
                        <Avatar>H</Avatar>
                        <Avatar style={{ backgroundColor: 'deepOrange' }}>N</Avatar>
                        <Avatar style={{ backgroundColor: 'deepPurple' }}>OP</Avatar>
                        <Avatar style={{ backgroundColor: 'blue' }}>NR</Avatar>
                        <Avatar style={{ backgroundColor: 'yellow' }}>Sk</Avatar>
                    </Stack>
                </CardContent>
            </Card>

            <br />
            <Card className="custom-card">
                <CardContent>
                    <h4>Grouped Avatar</h4>
                    <Stack direction="row" spacing={2}>
                        <AvatarGroup max={4}>
                            <Avatar alt="Remy Sharp" />
                            <Avatar alt="Travis Howard" />
                            <Avatar alt="Cindy Baker" />
                            <Avatar alt="Agnes Walker" />
                            <Avatar alt="Trevor Henderson" />
                        </AvatarGroup>
                    </Stack>
                </CardContent>
            </Card>


        </div>
    )
}

export default Avatars;
