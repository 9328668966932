/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1231     dec-11,2023     xyz buildGetResult function implementation
*
*
*/ 
import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData } from "./ApiBase";

export const getBOMList= async (projectId:any, searchParams:any, page:any, size:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/boms/getBom/projectId/${projectId}/searchParams/${searchParams}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getBOMCount= async (projectId:any, searchParams:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/boms/getBomCount/projectId/${projectId}/searchParams/${searchParams}`;
  return await executeGetData(url, accessToken);
};