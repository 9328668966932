/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 1-3-2024
* @author      : Vijaykumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Vendor Register 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1517          |  28/3/24    |   Nandha    |  Vendor dialog implements for add ,edit and update 
* 1755         |  02/5/24     |   Vanitha   |  Responsive the  Vendor list table 
* 1756         |  02/5/24     |   Vanitha   |  Validation for Mobile Number
* 2249         | 07/Jun/2024  | vijaykumar  |  Removed material and tools add feature 
*/

import * as React from 'react';
import {
    Box, Button, TextField,
    Grid, Card, CardHeader,
    InputAdornment, CardActions, Checkbox,
    FormControlLabel, CardContent,
    styled, useMediaQuery, SelectChangeEvent, FormControl, InputLabel, Select, OutlinedInput, Chip, MenuItem, Snackbar, Alert, TableContainer, Table, TableHead, Paper, TableCell, TableRow, TableBody, ListItemText, Tooltip, IconButton, Stack, Autocomplete, Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import Header from '../Header';
import { Theme, useTheme } from '@mui/material/styles';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BadgeIcon from '@mui/icons-material/Badge';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FeedIcon from '@mui/icons-material/Feed';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import "./VendorRegister.scss"
import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import { editVendor, getVendorsById, saveVendor } from '../../services/VendorService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useLocation, useNavigate } from 'react-router-dom';
import { getMaterialNames } from '../../services/materialService';
import { getToolsName } from '../../services/ToolService';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Link from '@mui/material/Link';
import CustomDialog from '../../pages/Dialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getPreviousPageDetails } from '../../hooks/UserSession';


const FileUploaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100px',
    border: '1px dashed black',
    borderRadius: '8px',
    padding: '2px'
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Service',
    'Material',
    // 'Resource',
];
const trades = [
    'Roofing',
    'Siding',
    'Gutter',
];

const units = [
    'SQ',
    'Bundle-100 LF',
    'Bundle-33 LF',
    'RL',
    'EA',
    '1.85inches - 10',
    'BD', '5LB', 'QTY', 'BX', 'TB', 'LF', 'Hour'
];

const grades = [
    'a', 'b', 'c'
]

const manufacturers = [
    'Owens Corning', 'GAF', 'Certain Teed', 'Norandex', 'James Hardie', 'Smart Side', 'ACM', 'Berger', ' US Aluminum Inc'
]
const containerStyle = {
    width: '100%',
    height: '300px'
};

const days = ["1 Day", "2 Days", "3 Days", "4 Days", "5 Days", "6 Days", "7 Days"]
const libraries: any = ['places'];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

// const FileUploadIcon = styled(CloudUploadIcon)({
//     fontSize: '4em',
// });

const VendorRegister: React.FC = () => {

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    })
    const [service, setService] = React.useState<string[]>([]);
    const [vendorData, setVendorData] = React.useState<any>({
        companyName: "",
        displayName: "",
        status: "Active",
        primaryMobileNumber: '',
        email: "",
        website: "",
        accountNo: "",
        services: "Service",
        description: "",
        approvedBy: "",
        createdBy: '',
        checkName: '',
        zipCode: '',
        address: '',
        location: '',
        city: '',
        country: '',
        state: '',
        leadTime: '',
        createdAt: new Date(),
        updatedAt: '',
        routerNo: '',
        payTerms: '',
        // tools: [],
        // materials: []
    });
    const [message, setMessage] = React.useState<any>({
        companyName: '',
        primaryMobileNumber: '',
        email: '',
        address: '',
        state: '',
        city: '',
        country: '',
        accountNo: null,
        area: '',
        services: '',
        tool: '',
        material: '',
        leadTime: '',

    });
    const [map, setMap] = React.useState(null);
    const inputRef = React.useRef<any>();
    const componentData = React.useRef<any>()
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [count, setCount] = React.useState(0);
    const navigate = useNavigate();
    let location = useLocation()
    const id = location.state?.vendorId;
    const [openToast, setOpenToast] = React.useState(false);
    // const [toastMessage, setToastMessage] = React.useState({
    //     message: '',
    //     security: ''
    // });
    const [material, setMaterial] = React.useState('');
    const [tool, setTool] = React.useState('');
    const [materialName, setMaterialName] = React.useState<any>([]);
    const [toolName, setToolName] = React.useState<any>([]);
    const [vendorDataObject, setVendorDataObject] = React.useState<any>();
    const [unit, setUnit] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const [success, setSuccess] = React.useState(false);
    const numberRegex = /^\d{10}$/;
    const routeNoRegex = /^\d{9}$/;
    const accountNoRegex = /^\d{5,17}$/;




    const setCancel = () => {
        setOpenDialogs(false);
    }
    const handleMaterialChange = (event: SelectChangeEvent) => {
        if (event.target.value !== undefined) {
            setMaterial(event.target.value as string);
            addItem(event.target.value);
        }
        else {
            setMaterial("");
            addItem("");
        }
    };

    const handleToolChange = (e: any) => {
        if (e.target.value !== undefined) {
            setTool(e.target.value as string);
            addToolItem(e.target.value);
        }
        else {
            setTool("");
            addToolItem("");
        }
    };

    const getMaterials = async () => {
        let result = await getMaterialNames(accessToken);
        setMaterialName(result.data);
    }
    const getTools = async () => {
        let result = await getToolsName(accessToken);
        setToolName(result.data);
    }
    const handleChange = (event: SelectChangeEvent<typeof service>) => {
        const {
            target: { value },
        } = event;
        setService(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );

        if (value.includes("Service")) {
            getTools();
        }
        if (value.includes("Material")) {
            getMaterials();
        }
    };
    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const handleUnitChange = (event: SelectChangeEvent) => {
        setUnit(event.target.value as string);
    };

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])
    const getLocation = () => {

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    }

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
        let locationObject: any = {}
        const geocoder = new google.maps.Geocoder();
        const fulladdress: any[] = [];
        geocoder
            .geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    // eslint-disable-next-line array-callback-return
                    response.results[0].address_components.map((address: any) => {
                        if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise") || address.types.includes("plus_code")) {
                            fulladdress.push(address.long_name);
                            locationObject['address'] = fulladdress.join(",");
                        }
                        else if (address.types.includes("locality")) {
                            locationObject['city'] = address.long_name;
                        }
                        else if (address.types.includes("administrative_area_level_1")) {
                            locationObject['state'] = address.long_name
                        }
                        else if (address.types.includes("country")) {
                            locationObject['country'] = address.long_name
                        }
                        else if (address.types.includes("postal_code")) {
                            locationObject['zipCode'] = address.long_name
                        }
                    })
                }
                setVendorData({
                    ...vendorData, zipCode: locationObject.zipCode, country: locationObject.country,
                    address: locationObject.address, city: locationObject.city, state: locationObject.state,
                    location: Object.values(componentData.current).join(",")
                });
            })
    }
    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })
            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setVendorData({
                ...vendorData, zipCode: locationObject.zipCode, country: locationObject.country,
                address: locationObject.address, city: locationObject.city, state: locationObject.state,
                location: Object.values(componentData.current).join(",")
            });
        }
    }
    const inputchange = (e: any) => {
        // if(e.target.name === "primaryMobileNumber"){
        //     if(e.target.value.length !== 10){
        //         message.primaryMobileNumber = 'Enter Your Mobile Number';
        //     }
        // }
        setVendorData({
            ...vendorData,
            [e.target.name]: e.target.value
        });
        console.log(vendorData);
    };

    // const inputMaterialChange = (e: any, index: any) => {
    //     let newArray = [...vendorData.materials];
    //     newArray[index][e.target.name] = e.target.value;
    //     setVendorData({ ...vendorData, materials: newArray });
    // };
    // const dateFormatter = (value: any) => {
    //     const date = new Date(value);
    //     const month = date.getMonth().toString();
    //     return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
    // }
    // const onChangeDate = (e: any, index: any) => {
    //     let newArray = [...vendorData.materials];
    //     newArray[index]["versionStartdate"] = dateFormatter(e.$d);
    //     setVendorData({ ...vendorData, materials: newArray });
    // };
    // const onChangeToolVersionDate = (e: any, index: any) => {
    //     let newArray = [...vendorData.tools];
    //     newArray[index]["versionStartdate"] = dateFormatter(e.$d);
    //     setVendorData({ ...vendorData, tools: newArray });
    // };


    // const handleMaterialRemoveItem = (index: any) => {
    //     let newArray = [...vendorData.materials];
    //     newArray.splice(index, 1)
    //     setVendorData({ ...vendorData, materials: newArray });
    // }

    // const handleToolRemoveItem = (index: any) => {
    //     let newArray = [...vendorData.tools];
    //     newArray.splice(index, 1)
    //     setVendorData({ ...vendorData, tools: newArray });
    // }

    // const inputToolChange = (e: any, index: any) => {
    //     let newArray = [...vendorData.tools];
    //     newArray[index][e.target.name] = e.target.value;
    //     setVendorData({ ...vendorData, tools: newArray });
    // };
    const validation = () => {
        let validated: any = true;
        if (vendorData.companyName === '') {
            message.companyName = 'Enter Company Name';
            validated = false;

        }
        if (vendorData.state === '') {
            message.state = 'Enter Your State';
            validated = false;

        }
        if (vendorData.city === '') {
            message.city = 'Enter Your City';
            validated = false;

        }
        if (vendorData.country === '') {
            message.country = 'Enter Your Country';
            validated = false;

        }
        // if (vendorData.primaryMobileNumber === null) {
        //     message.primaryMobileNumber = 'Enter Your Mobile Number';
        //     validated = false;

        // }
        if (!numberRegex.test(vendorData.primaryMobileNumber)) {
            message.primaryMobileNumber = 'Enter Your Mobile Number with exactly 10 digits';
            validated = false;
        }

        if (vendorData.zipCode === '') {
            message.zipCode = 'Enter Your ZipCode';
            validated = false;

        }
        if (vendorData.address === '') {
            message.address = 'Enter Your address';
            validated = false;

        }
        if (vendorData.email === '' && regex.test(vendorData.email) === false) {
            message.email = 'Enter Your Email';
            validated = false;

        }
        // if (service.length === 0) {
        //     message.services = 'Enter  services';
        //     validated = false;
        // }
        if (vendorData.leadTime === '') {
            message.leadTime = 'Enter Delivery Lead Time';
            validated = false;
        }
        // if(service.includes("Service") === true &&  tool === ''){
        //     message.tool = 'Enter  services';
        //     validated = false;
        // }
        // if(service.includes("Material") === true &&  material === ''){
        //     message.material = 'Enter  services';
        //     validated = false;
        // }

        return validated;
    }

    const handleSubmit = async (e: any) => {
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            setOpenDialogs(true);
            let result = await saveVendor(vendorData, accessToken)
            if (result.status === 201) {
                // setOpenToast(true);
                // setToastMessage({ ...toastMessage, message: "Inserted Successfully", security: "success" });
                setCreateSuccess(true);
                setTimeout(() => {
                    handleCancel();
                }, 2000);
            }
            else {
                // setOpenToast(true);
                // setToastMessage({ ...toastMessage, message: "error", security: "error" });
                setCreateError(true);
            }
        }
        else {
            setCreateError(true);
        }
    }

    const handleCancel = () => {
        const previousPageDetails = getPreviousPageDetails();
        navigate(previousPageDetails.path,
            { state: { selectedTab: previousPageDetails.tabPosition } }
        );
    }
    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }
    const updateHandleSubmit = async () => {
        setCount(count + 1);
        let validated = validation();
        if (vendorDataObject.companyName === vendorData.companyName && vendorDataObject.displayName === vendorData.displayName
            && vendorDataObject.email === vendorData.email
            && vendorDataObject.primaryMobileNumber === vendorData.primaryMobileNumber &&
            vendorDataObject.website === vendorData.website && vendorDataObject.checkName === vendorData.checkName
            && vendorDataObject.services === vendorData.services && vendorDataObject.address === vendorData.address &&
            vendorDataObject.state === vendorData.state && vendorDataObject.city === vendorData.city &&
            vendorDataObject.country === vendorData.country && vendorDataObject.zipCode === vendorData.zipCode &&
            vendorDataObject.description === vendorData.description && vendorDataObject.accountNo === vendorData.accountNo &&
            vendorDataObject.routeNo === vendorData.routeNo) {
            setOpenToast(true);
            // setToastMessage({ ...toastMessage, message: "No value Changed", security: "error" });
            setSuccess(true);
        }
        else {
            if (validated === true) {
                setOpenDialog(true);
                let result = await editVendor(vendorData, accessToken);
                if (result.status === 200 || result.status === 201) {
                    setUpdateSuccess(true);
                    setTimeout(() => {
                        handleCancel();
                    }, 2000);
                }
                else {
                    setUpdateError(true)
                }
            }
        }
    };

    const addItem = (materialName: any) => {
        const material = {
            name: materialName,
            itemCode: "",
            grade: "",
            versionStartdate: "",
            manufacturer: "",
            tags: "",
            unit: "",
            cost: "",
        };

        const updateUsers = [
            // copy the current users state
            ...vendorData.materials,
            // now you can add a new object to add to the array
            material,
        ];
        // update the state to the updatedUsers
        setVendorData({ ...vendorData, materials: updateUsers });
    };
    const addToolItem = (toolName: any) => {
        const tool = {
            name: toolName,
            itemCode: "",
            tags: "",
            unit: "",
            versionStartdate: "",
            cost: "",
        };

        const updateTools = [
            ...vendorData.tools,
            tool,
        ];
        setVendorData({ ...vendorData, tools: updateTools });
    };

    const getVendorsByVendorId = async (vendorId: any) => {
        let result = await getVendorsById(vendorId, accessToken);
        console.log(result.data);
        let vendorList = result.data;
        // if ((vendorList.tools).length !== 0 && !service.includes("Service")) {
        //     service.push("Service");
        //     getTools();
        // }
        // if ((vendorList.materials).length !== 0 && !service.includes("Material")) {
        //     service.push("Material");
        //     getMaterials();
        // }
        setVendorData(vendorList);
        setVendorDataObject(vendorList);
        componentData.current = convertLocation((result.data).location);
    }

    // program to convert first letter of a string to uppercase
    const capitalizeFirstLetter = (str: any) => {
        // converting first letter to uppercase
        const capitalized = str.replace(/^./, str[0].toUpperCase());
        return capitalized;
    }
    React.useEffect(() => {
        getLocation();
        const id = location.state?.vendorId;
        if (id !== undefined) {
            getVendorsByVendorId(id);
            setShow(true);
        }
    }, []);

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);

    };
    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="10px 0"
            >
                <Header title="Vendor Registration" subtitle="" />
            </Box>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={10} sm={12}>
                    <CardContent sx={{
                        display: 'block',
                        alignItems: 'center',
                        '& > :not(style)': { m: 2 },
                    }}>
                        <Card className='vendorcard'>
                            <CardHeader
                                title={
                                    <div className='VendorTitle'><BadgeIcon />&nbsp;Name and Contact
                                    </div>
                                }
                            />
                            <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                <Grid item xs={6}>
                                    <TextField

                                        id="demo-helper-text-aligned"
                                        label="Company name"
                                        size="small"
                                        name="companyName"
                                        fullWidth
                                        value={vendorData?.companyName}
                                        error={(vendorData.companyName === '' && count !== 0)}
                                        helperText={(vendorData.companyName === '' && count !== 0) ? message.companyName : null}
                                        onChange={(e) => inputchange(e)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Vendor display name"
                                        size="small"
                                        fullWidth
                                        name="displayName"
                                        value={vendorData?.displayName}
                                        onChange={(e) => inputchange(e)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={(count !== 0 && (regex.test(vendorData.email) === false))}
                                        id="demo-helper-text-aligned"
                                        label="Primary Email"
                                        size="small"
                                        fullWidth
                                        name="email"
                                        helperText={(vendorData.email === '' && count !== 0) ? message.email : null}
                                        value={vendorData?.email}
                                        onChange={(e) => inputchange(e)}
                                        required
                                    />
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextField
                                        error={(vendorData.primaryMobileNumber === '' && count !== 0)}
                                        id="demo-helper-text-aligned"
                                        label="Primary Mobile number"
                                        size="small"
                                        fullWidth
                                        name="primaryMobileNumber"
                                        value={vendorData.primaryMobileNumber}
                                        helperText={(vendorData.primaryMobileNumber === '' && count !== 0) ? message.primaryMobileNumber : null}
                                        onChange={(e) => inputchange(e)}
                                        required
                                    />
                                </Grid> */}

                                <Grid item xs={6}>
                                    <TextField
                                        error={count !== 0 && !numberRegex.test(vendorData.primaryMobileNumber)}
                                        id="demo-helper-text-aligned"
                                        label="Primary Mobile Number"
                                        size="small"
                                        fullWidth
                                        name="primaryMobileNumber"
                                        value={vendorData?.primaryMobileNumber || ''}
                                        helperText={(count !== 0 && !numberRegex.test(vendorData.primaryMobileNumber)) ? message.primaryMobileNumber : null}
                                        onChange={inputchange}
                                        required
                                        InputProps={{
                                            inputProps: {
                                              type: 'number',
                                              max:"10",
                                            },
                                          }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Website"
                                        size="small"
                                        fullWidth
                                        name="website"
                                        value={vendorData?.website}
                                        onChange={(e) => inputchange(e)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Name to print on checks"
                                        size="small"
                                        name="checkName"
                                        onChange={(e) => inputchange(e)}
                                        value={vendorData?.checkName}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                        {/* <Card className='vendorcard'>
                            <CardHeader
                                title={
                                    <div className='VendorTitle'><MiscellaneousServicesIcon />&nbsp;Service Provided
                                    </div>
                                }
                            />
                            <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size='small'>
                                        <InputLabel id="demo-multiple-chip-label">Services</InputLabel>
                                        <Select
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={service}
                                            name="services"
                                            error={(service?.length === 0 && count !== 0)}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="Services" />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            disabled={show}
                                            required
                                        >
                                            {names?.map((name) => (
                                                <MenuItem key={name} value={name}>
                                                    <Checkbox checked={service.indexOf(name) > -1} />
                                                    <ListItemText primary={name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Delivery Lead Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={vendorData?.leadTime}
                                            label="Delivery Lead Time"
                                            name="leadTime"
                                            onChange={(e) => inputchange(e)}
                                            error={(vendorData.leadTime === '' && count !== 0)}
                                        >
                                            {days.map((day, index) => (
                                                <MenuItem value={index + 1}>{day}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Card> */}
                        {/* {service?.includes("Service") ?
                            <Card className='vendorcard' sx={{ paddingTop: 3 }}>
                                <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">Service</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={tool}
                                                label="Service"
                                                onChange={handleToolChange}
                                                disabled={show}
                                            >
                                                {toolName?.map((name) => (
                                                    <MenuItem value={name}>{capitalizeFirstLetter(name)}</MenuItem>
                                                ))}
                                                <MenuItem><Link underline="none" onClick={() => handleToolChange}>
                                                    {'Add New Service'}
                                                </Link></MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12}>
                                        <TableContainer>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableBody>
                                                    {vendorData?.tools?.map((tool, index) => (
                                                        <TableRow
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="right">
                                                                <TextField label="Name"
                                                                    variant="standard"
                                                                    name="name"
                                                                    value={tool?.name}
                                                                    error={tool?.name === '' && count !== 0}
                                                                    onChange={(e) => inputToolChange(e, index)}
                                                                    disabled={show}
                                                                    required />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <TextField
                                                                    label="ItemCode"
                                                                    variant="standard"
                                                                    name="itemCode"
                                                                    value={tool?.itemCode}
                                                                    error={tool?.itemCode === '' && count !== 0}
                                                                    disabled={show}
                                                                    onChange={(e) => inputToolChange(e, index)} required />
                                                            </TableCell>
                                                            <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DatePicker
                                                                       
                                                                        label="Version Start Date"
                                                                        slotProps={{ textField: { fullWidth: true, size: "small", error: tool?.versionStartdate === '' && count !== 0 } }}
                                                                        disablePast
                                                                      
                                                                        value={dayjs(tool?.versionStartdate)}
                                                                        disabled={show}
                                                                        onChange={(e) => onChangeToolVersionDate(e, index)}
                                                                   
                                                                    />
                                                                </LocalizationProvider>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                    <InputLabel id="demo-simple-select-label">Trade</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        error={tool?.tags === '' && count !== 0}
                                                                        value={tool?.tags}
                                                                        label="trade"
                                                                        name="tags"
                                                                        onChange={(e) => inputToolChange(e, index)}
                                                                        required
                                                                        disabled={show}
                                                                    >
                                                                        {trades?.map((trade) => (
                                                                            <MenuItem value={trade}>{trade}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={tool?.unit}
                                                                        error={tool?.unit === '' && count !== 0}
                                                                        label="Unit"
                                                                        name="unit"
                                                                        disabled={show}
                                                                        onChange={(e) => inputToolChange(e, index)}
                                                                        required
                                                                    >
                                                                        {units.map((unit) => (
                                                                            <MenuItem value={unit}>{unit}</MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <TextField
                                                                    label="cost"
                                                                    name="cost"
                                                                    error={tool?.cost === '' && count !== 0}
                                                                    type="number"
                                                                    variant="standard"
                                                                    value={tool?.cost}
                                                                    disabled={show}
                                                                    onChange={(e) => inputToolChange(e, index)}
                                                                    required />
                                                            </TableCell>
                                                            <TableCell align="right"><Tooltip title="Delete">
                                                                {show ? <></> :
                                                                    <IconButton onClick={() => handleToolRemoveItem(index)}>
                                                                        <DeleteRoundedIcon />
                                                                    </IconButton>
                                                                }
                                                            </Tooltip></TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Card>
                            : <></>} */}
                        {/* {service.includes("Material") ?
                            <Card className='vendorcard' sx={{ paddingTop: 3 }}>
                                <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">Material</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={material}
                                                label="Material"
                                                onChange={handleMaterialChange}
                                                disabled={show}
                                          
                                            >
                                                {materialName?.map((name) => (
                                                    <MenuItem value={name}>{capitalizeFirstLetter(name)}</MenuItem>
                                                ))}
                                                <MenuItem><Link underline="none" onClick={() => handleMaterialChange}>
                                                    {'Add New Material'}
                                                </Link></MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12}>
                                        <Box sx={{ width: '100%' }}>
                                            <TableContainer>
                                                <Table size='small' sx={{ minWidth: 850 }} aria-label="simple table">
                                                    <TableBody>
                                                        {vendorData?.materials?.map((material, index) => (
                                                            <TableRow
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                               
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                    <TextField
                                                                        name="name"
                                                                        label="Name"
                                                                        error={material?.name === '' && count !== 0}
                                                                        variant="standard"
                                                                        value={material?.name}
                                                                        onChange={(e) => inputMaterialChange(e, index)}
                                                                        disabled={show}
                                                                        required />
                                                                </TableCell>
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                    <TextField
                                                                        name="itemCode"
                                                                        label="ItemCode"
                                                                        variant="standard"
                                                                        error={material?.itemCode === '' && count !== 0}
                                                                        value={material?.itemCode}
                                                                        disabled={show}
                                                                        onChange={(e) => inputMaterialChange(e, index)}
                                                                        required />
                                                                </TableCell>
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DatePicker
                                                                            
                                                                            label="Version Start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small", error: material?.versionStartdate === '' && count !== 0 } }}
                                                                            disablePast
                                                                            
                                                                            value={dayjs(material?.versionStartdate)}
                                                                            disabled={show}
                                                                            onChange={(e) => onChangeDate(e, index)}
                                                                       
                                                                        />
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} md={4}>

                                                                            <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 60 }}>
                                                                                <InputLabel id="demo-simple-select-label">Grade</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={material?.grade}
                                                                                    error={material?.grade === '' && count !== 0}
                                                                                    label="Grade"
                                                                                    name="grade"
                                                                                    disabled={show}
                                                                                    onChange={(e) => inputMaterialChange(e, index)}
                                                                                    required
                                                                                >
                                                                                    {grades.map((unit) => (
                                                                                        <MenuItem value={unit}>{unit}</MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>                                                                 
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} md={4}>
                                                                            <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                                <InputLabel id="demo-simple-select-label">Manufacturer</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={material?.manufacturer}
                                                                                    error={material?.manufacturer === '' && count !== 0}
                                                                                    label="manufacturer"
                                                                                    disabled={show}
                                                                                    name="manufacturer"
                                                                                    onChange={(e) => inputMaterialChange(e, index)}
                                                                                    required
                                                                                >
                                                                                    {manufacturers.map((m) => (
                                                                                        <MenuItem value={m}>{m}</MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                              
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} md={4}>
                                                                            <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                                <InputLabel id="demo-simple-select-label">Trade</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={material?.tags}
                                                                                    label="trade"
                                                                                    disabled={show}
                                                                                    name="tags"
                                                                                    error={material?.tags === '' && count !== 0}
                                                                                    onChange={(e) => inputMaterialChange(e, index)}
                                                                                    required
                                                                                >
                                                                                    {trades.map((trade) => (
                                                                                        <MenuItem value={trade}>{trade}</MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>

                                                                </TableCell>
                                                              
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                   

                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={12} sm={6} md={4}>
                                                                            <FormControl fullWidth size="small" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                                <Select
                                                                                    labelId="demo-simple-select-label"
                                                                                    id="demo-simple-select"
                                                                                    value={material?.unit}
                                                                                    label="Unit"
                                                                                    name="unit"
                                                                                    error={material?.unit === '' && count !== 0}
                                                                                    onChange={(e) => inputMaterialChange(e, index)}
                                                                                    disabled={show}
                                                                                    required
                                                                                >
                                                                                    {units.map((unit) => (
                                                                                        <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    </Grid>

                                                                </TableCell>
                                                               
                                                                <TableCell sx={{ padding: '2px', fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                    <TextField
                                                                        name="cost"
                                                                        type="number"
                                                                        label="Cost"
                                                                        variant="standard"
                                                                        value={material?.cost}
                                                                        error={material?.cost === '' && count !== 0}
                                                                        onChange={(e) => inputMaterialChange(e, index)}
                                                                        disabled={show}
                                                                        required />
                                                                </TableCell>
                                                              
                                                                <TableCell sx={{ fontSize: '0.775rem', '&:last-child': { paddingRight: '10px' } }}>

                                                                    {show ? <></> :
                                                                        <IconButton onClick={() => handleMaterialRemoveItem(index)}>
                                                                            <DeleteRoundedIcon sx={{ padding: 0 }} fontSize='small' />
                                                                        </IconButton>

                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Card>
                            : <></>} */}
                        <Card className='vendorcard'>
                            <CardHeader className='VendorTitle'
                                title={
                                    <div className='VendorTitle'><LocationOnIcon />&nbsp;Address
                                    </div>
                                }
                            />
                            <Grid container spacing={2} pb={3} pr={3} pl={3} pt={0}>
                                <Grid item xs={6}>
                                    {isLoaded
                                        &&
                                        <StandaloneSearchBox
                                            onLoad={ref => inputRef.current = ref}
                                            onPlacesChanged={handlePlaceChanged}
                                        >
                                            <TextField
                                                className='textfield'
                                                error={(vendorData.address === '' && count !== 0)}
                                                id="demo-helper-text-aligned"
                                                label="Street Address"
                                                size="small"
                                                name="address"
                                                value={vendorData?.address}
                                                helperText={(vendorData.address === '' && count !== 0) ? message.address : null}
                                                onChange={(e) => inputchange(e)}
                                                fullWidth
                                                required
                                            />
                                        </StandaloneSearchBox>}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={(vendorData.city === '' && count !== 0)}
                                        id="demo-helper-text-aligned"
                                        label="City"
                                        size="small"
                                        fullWidth
                                        name="city"
                                        value={vendorData?.city}
                                        helperText={(vendorData.city === '' && count !== 0) ? message.city : null}
                                        onChange={(e) => inputchange(e)}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={(vendorData.state === '' && count !== 0)}
                                        id="demo-helper-text-aligned-no-helper"
                                        label="State"
                                        type="text" // Set the input type to password
                                        size="small"
                                        name="state"
                                        value={vendorData?.state}
                                        helperText={(vendorData?.state === '' && count !== 0) ? message?.state : null}
                                        onChange={(e) => inputchange(e)}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={(vendorData?.country === '' && count !== 0)}
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Country"
                                        size="small"
                                        name="country"
                                        value={vendorData?.country}
                                        helperText={(vendorData?.country === '' && count !== 0) ? message?.country : null}
                                        onChange={(e) => inputchange(e)}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={(vendorData?.zipCode === '' && count !== 0)}
                                        id="demo-helper-text-aligned"
                                        label="Zip Code"
                                        size="small"
                                        name="zipCode"
                                        value={vendorData?.zipCode}
                                        helperText={(vendorData?.zipCode === '' && count !== 0) ? message?.zipCode : null}
                                        onChange={(e) => inputchange(e)}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={13} pt={1}>
                                        {isLoaded ? (
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={componentData?.current}
                                                zoom={10}
                                                onLoad={onLoad}
                                                onUnmount={onUnmount}
                                                onClick={onClick}
                                            >
                                                <MarkerF
                                                    position={componentData.current}
                                                >
                                                </MarkerF>

                                                <></>

                                            </GoogleMap>
                                        ) : <></>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className='vendorcard'>
                            <CardHeader className='VendorTitle'
                                title={
                                    <div className='VendorTitle'><NoteAltIcon />&nbsp;Descriptions and Attachments
                                    </div>
                                }
                            />
                            <Grid item xs={12} pb={3} pr={3} pl={3} pt={0}>
                                <TextField
                                    className='textfield'
                                    id="demo-helper-text-aligned"
                                    label="Descriptions"
                                    multiline
                                    minRows={3}
                                    name="description"
                                    value={vendorData?.description}
                                    onChange={(e) => inputchange(e)}
                                    fullWidth
                                />
                            </Grid>
                            {/* <Grid item xs={12} pl={4}>Attachments</Grid>
                            <Grid item xs={12} p={4}><FileUploaderContainer>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <div>
                                        <div><a href="#" id="link"><input type="file" id="file" />Add Attachment</a></div>
                                        <div>Max file size: 20MB</div>
                                    </div>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                </Box>
                            </FileUploaderContainer></Grid> */}
                        </Card>
                        <Card className='vendorcard'>
                            <CardHeader className='VendorTitle'
                                title={
                                    <div className='VendorTitle'><AccountBalanceIcon />&nbsp;ACH payments
                                    </div>
                                }
                            />
                            <Grid container spacing={1} pb={3} pr={3} pl={3} pt={0} >
                                <Grid item xs={6}>
                                    {/* <label className=' textfield'>Account no.</label> */}
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        label="Account No"
                                        id="demo-helper-text-aligned"
                                        error={count !== 0 && !accountNoRegex.test(vendorData?.accountNo)}
                                        size="small"
                                        fullWidth
                                        name="accountNo"
                                        required
                                        value={vendorData?.accountNo}
                                        inputProps={{
                                            minLength: 5,
                                            maxLength: 17
                                        }}
                                        onChange={(e) => inputchange(e)}
                                    />
                                    <label className=' textfield'>Account no. is usually 5-17 digits</label>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <label className=' textfield'>Routing no.</label> */}
                                    <TextField
                                        className='textfield'
                                        id="demo-helper-text-aligned"
                                        size="small"
                                        helperText=""
                                        name='routeNo'
                                        fullWidth
                                        type="number"
                                        error={count !== 0 && !routeNoRegex.test(vendorData?.routeNo)}
                                        value={vendorData?.routeNo}
                                        onChange={(e) => inputchange(e)}
                                        label="Routing No"
                                        inputProps={{
                                            maxLength: 9
                                        }}
                                    />
                                    <label className=' textfield'>Routing no. is 9 digits</label>
                                </Grid>
                            </Grid>
                        </Card>
                        <Card className='vendorcard'>
                            <CardHeader className='VendorTitle'
                                title={
                                    <div className='VendorTitle'><FeedIcon />&nbsp;Additional Info
                                    </div>
                                }
                            />
                            <Grid container spacing={1} pb={3} pr={3} pl={3} pt={0} >
                                {/* <Grid item xs={12}>
                                    <label className='subtitle'>Taxes</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label="Business ID No. /Social Security No."
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} md={2}>
                                </Grid>
                                <Grid item xs={12}>
                                    <label className='subtitle'>Expense rates</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Cost rate(/hr)"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Billing rate(/hr)"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid> */}
                                <Grid item xs={12}>
                                    <label className='subtitle'>Payments</label>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Payment Terms"
                                        size="small"
                                        name="payTerms"
                                        error={(vendorData.payTerms === '' && count !== 0)}
                                        value={vendorData?.payTerms}
                                        onChange={(e) => inputchange(e)}
                                        fullWidth
                                    /> */}
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Payment Terms</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={vendorData?.payTerms}
                                            error={(vendorData.payTerms === '' && count !== 0)}
                                            label="Payment Terms"
                                            name="payTerms"
                                            onChange={(e) => inputchange(e)}
                                        >
                                            <MenuItem value={30}>Net 30 Days</MenuItem>
                                            <MenuItem value={60}>Net 60 Days</MenuItem>
                                            <MenuItem value={90}>Net 90 Days</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="demo-simple-select-label">Delivery Lead Time</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={vendorData?.leadTime}
                                            label="Delivery Lead Time"
                                            name="leadTime"
                                            onChange={(e) => inputchange(e)}
                                            error={(vendorData.leadTime === '' && count !== 0)}
                                        >
                                            {days.map((day, index) => (
                                                <MenuItem value={index + 1}>{day}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Account no"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                    <label className='subtitle'>Accounting</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className=' textfield'>Default expense category</label>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Choose account"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <label className='subtitle'>Opening balance</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <label className=' textfield'>Opening balance</label>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <label className=' textfield'>As of</label>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid> */}
                            </Grid>
                        </Card>
                        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                            <Stack spacing={2} direction="row">
                                {id ? (
                                    <Button variant="contained" className='sucessButton' size="medium" onClick={updateHandleSubmit}>
                                        Update
                                    </Button>
                                ) : (
                                    <Button className='sucessButton' variant="contained" size="medium" onClick={handleSubmit}>
                                        Save
                                    </Button>
                                )}
                                {/* <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button> */}
                                <Button className='primaryTonalButton' variant="contained" size="medium" onClick={handleCancel}>Cancel</Button>
                            </Stack>
                        </Box>
                        <CustomDialog
                            open={openDialogs}
                            onClose={() => setCancel()}
                            success={createSuccess}
                            error={createError}
                            Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} />
                        <CustomDialog
                            open={openDialog}
                            onClose={() => setCancel()}
                            success={updateSuccess}
                            error={updateError}
                            Content={updateSuccess ? "Updated Successfully" : "Not Updated Successfully  "}
                        />
                        <Dialog open={success} >
                            <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                                <Box paddingLeft={8} paddingRight={8}>
                                    <Typography textAlign={'center'}>
                                        <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                    {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                                </Box>
                            </DialogTitle>
                            <Box paddingLeft={8} paddingRight={8}>
                                <DialogContent >
                                    <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                                </DialogContent>
                                <DialogActions style={{ justifyContent: "center" }}>
                                    <Button variant='contained' className="NoChangeButton" onClick={handleAlertClose}>Ok</Button>
                                </DialogActions>
                            </Box>
                        </Dialog>
                    </CardContent>
                    {/* <CardActions> <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button>
                    </CardActions> */}
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
};

export default VendorRegister;