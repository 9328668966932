/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For bill generating
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/ 
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Autocomplete, Button, Card, CardHeader, Divider, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './AddBill.scss'
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import logo from '../../../assets/logo.gif';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { getVendor, getBillList } from '../../../services/BillService';
import BillItemDetails from '../billItemDetails/BillItemDetails';



const AddBill: React.FC = () => {

    const navigate = useNavigate();
    const onPODetails = () => {
        navigate("/polayout"); // Navigate to the "/task" route
    };
    const FileUploaderContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '120px',
        border: '2px dashed #ccc',
        borderRadius: '8px',
    });
    const location = useLocation();
    const rowData = location.state?.rowData;
    // const [isShow, setIsShow] = React.useState(false);




    //console.log(rowData)
    const [item, setItem] = React.useState<any>(0);
    const handleItemChange = (newAmount: any) => {
        // setItem(newAmount);
        //console.log(newAmount);
        if (newAmount !== null && newAmount !== undefined) {
            // newAmount= rowData.amount;
            setItem(newAmount)
        }
        else {
            newAmount=0.00
            setItem(newAmount)

        }

    };
    const [billData, setBillData] = React.useState<any | null>(null);

    const [value, setValue] = React.useState<any | null>(null);
    const [rows, setRows] = useState<any>([]);

    useEffect(() => {

        const fetchRows = async () => {
            const data = await getVendor();
           // console.log(data)
            setRows(data);
            //console.log(item);
        };
        fetchRows();


    }, []);
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',
                            '& > :not(style)': { m: 2 },
                        }}
                    >
                        {/* {billData.map((row: any) => {
                            return ( */}
                        <Card className='FinanceCard' variant="outlined">
                            <Grid container spacing={2} p={2} pl={5} pr={5}>
                                <Grid item xs={12} mt={1}>
                                    <Grid container spacing={0} mb={2}>
                                        <Grid className="poLogo" item xs={12}>
                                            <div className='poImgText'>
                                                <div><img className='imgLogo' src={logo} alt='Logo' /></div>

                                                <div className='formHeader'>Bill</div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} mt={5}>
                                            <Grid item xs={12} mb={1} className='headDetails'>{rowData ? rowData.companyName : ''}</Grid>
                                            <Grid item xs={12}>{rowData ? rowData.vendorAddress : ''}</Grid>
                                        </Grid>
                                        <Grid item xs={5}></Grid>
                                        <Grid item xs={3}>
                                            <Grid container mt={5} >

                                                <Grid item xs={3} mt={2}>Bill Date:</Grid>
                                                <Grid item xs={9} className='headDetails'> <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker label="" value={dayjs(rowData ? rowData.dueDate : '')} slotProps={{ textField: { size: 'small' } }} />
                                                    </DemoContainer>
                                                </LocalizationProvider></Grid>
                                                <Grid item xs={3} mt={2}>Bill no:</Grid>
                                                <Grid item xs={9} mt={2} className='headDetails'>{rowData ? rowData.billNumber : ''}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </Grid>

                                <Grid item xs={6} mt={3} >
                                    {/* <label><small>Vendor</small></label>
                                    <Select
                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            Choose a vendor
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select> */}
                                </Grid>
                                <Grid item xs={6} >
                                    <Grid item xs={12} mt={2}>
                                        <div className='billBalance'>
                                            <div className='amountHead'>Balance Due</div>
                                            <div className='amountDetails'>   ${item}</div>

                                        </div>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={3} mt={3}>
                                   
                                </Grid> */}

                                <Grid item xs={6}>
                                    {/* <label><small>Vendor</small></label> */}
                                    {rows.map((vendor: any, index: any) => {
                                        return (<Autocomplete
                                            value={rowData ? rowData.vendorName : ''}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            options={vendor.vendor}
                                            renderInput={(params) => <TextField {...params} variant="outlined" label="Choose your vendor" />}
                                            renderOption={(props, option) => <li {...props}>{option}</li>}
                                            getOptionLabel={(option) => option}
                                            isOptionEqualToValue={(option, value) => option === value}
                                            freeSolo
                                            size='small'
                                            PaperComponent={({ children }) => {
                                                return (
                                                    <Paper>
                                                        {children}
                                                        <Button
                                                            color="primary"
                                                            fullWidth
                                                            sx={{ justifyContent: "flex-start", pl: 2 }}
                                                            onMouseDown={() => {
                                                                //console.log("Add new");
                                                            }}
                                                        >
                                                            + Add New Vendor
                                                        </Button>
                                                    </Paper>
                                                );
                                            }}
                                        />
                                        )
                                    })}
                                </Grid>

                                <Grid item xs={6}>

                                    <div className='paidDetails'>
                                        {/* <div><Button className="button" variant="contained" size="medium" >ScHEDULE ONLINE PAYMENT</Button></div> */}
                                        <div><Button className="button" variant="outlined" size="medium" >Mark as Paid</Button></div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} mt={1} >
                                    <TextField
                                        id="address-input"
                                        label="Mailing Address"
                                        multiline
                                        minRows={3}
                                        fullWidth
                                        value={rowData ? rowData.address : ''}

                                    />
                                </Grid>

                                <Grid item xs={6} mt={1}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12}>
                                            <TextField

                                                id="demo-helper-text-aligned"
                                                label="Customer Name"
                                                size="small"
                                                fullWidth
                                                helperText=""
                                                value={rowData ? rowData.customerName : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={6} mt={1}>
                                            <Autocomplete
                                                value={rowData ? rowData.terms : ''}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                                options={rowData ? rowData.terms : ''}
                                                renderInput={(params) => <TextField {...params} variant="outlined" label="Terms" />}
                                                renderOption={(props, option) => <li {...props}>{option}</li>}
                                                getOptionLabel={(option) => option}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                freeSolo
                                                size='small'

                                                PaperComponent={({ children }) => {
                                                    return (
                                                        <Paper>
                                                            {children}
                                                            <Button
                                                                color="primary"
                                                                fullWidth
                                                                sx={{ justifyContent: "flex-start", pl: 2 }}
                                                                onMouseDown={() => {
                                                                    //console.log("Add new");
                                                                }}
                                                            >
                                                                + Add Terms
                                                            </Button>
                                                        </Paper>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker label="Due Date" value={dayjs(rowData ? rowData.dueDate : '')} slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                {/* <Grid item xs={1}></Grid> */}
                                < CardHeader
                                    title={
                                        < div className='customerSubHead mt-1' >
                                            &nbsp; Item Details
                                        </div>
                                    }
                                />
                                <Grid container spacing={2} p={2} pl={5} pr={5} pt={2}>
                                    <Grid item xs={12}>
                                        <BillItemDetails props={rowData ? rowData?.itemDetails : ''} function={handleItemChange} />                                    </Grid>
                                </Grid>

                                {/* 
                                <Grid item xs={6} mt={1} >
                                    <TextField
                                        id="address-input"
                                        label="Memo"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid> */}
                                <Grid item xs={12} pb={5} mt={1}><FileUploaderContainer className="addAttachments">
                                    <Box
                                        sx={{
                                            flexGrow: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {/* <FileUploadIcon /> */}
                                        <div > <div><a href="#" id="link"><input type="file" id="file" />Add Attachment</a></div>
                                            <div>Max File size :20MB</div>
                                        </div>
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                    </Box>
                                </FileUploaderContainer></Grid>
                            </Grid>
                        </Card>
                        {/* )
                        })} */}
                    </Box>
                    <Box m={2} mb={4} className="poFooter">
                        <Button className="button" variant="contained" size="medium" >SAVE</Button>
                        <Button variant="outlined" size="medium" onClick={onPODetails}>SAVE AND NEW</Button>
                    </Box>
                </Grid>
            </Grid >
        </>
    );
}
export default AddBill;