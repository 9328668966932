import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled, useTheme } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';



import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Header from '../../../components/Header';
import TabOne from '../TabOne/TabOne';
import TabThree from '../TabThree/TabThree';
import TabTwo from '../TabTwo/TabTwo';
import { tokens } from '../../../hooks/theme';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1, pb: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const SampleView: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Header title="Sample Page Header" subtitle={null} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab className="text-trans-none tab-font-size" label="Tab One" {...a11yProps(0)} />
            <Tab className="text-trans-none tab-font-size" label="Tab Two" {...a11yProps(1)} />
            <Tab className="text-trans-none tab-font-size" label="Tab Three" {...a11yProps(2)} />
            <Tab className="text-trans-none tab-font-size" label="Tab Invoice" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <TabOne></TabOne>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TabTwo></TabTwo>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TabThree></TabThree>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          hi
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default SampleView;