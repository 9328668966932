
/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for adding employee
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* #2256          11/06/2024     asish         rich text editor for showing contrac template         
*  
*
*
*/
import React, { useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

interface EditorProps {
  value: string;
  readOnly: boolean;
  onChange: (content: string) => void;
}

const Editor: React.FC<EditorProps> = ({ value, readOnly ,onChange}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);
  const [editorHtml, setEditorHtml] = useState('')
  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
      ['table'],
    ],
  };
  useEffect(() => {
    console.log(value, editorRef.current, quillRef.current )
    if (editorRef.current && value !== undefined) {
      if (quillRef.current != null) {
        // Remove existing Quill instance
        quillRef.current.off('text-change'); // Remove event listener
        quillRef.current = null; // Reset the Quill instance
      }
  if(readOnly ===true){
    quillRef.current = new Quill(editorRef.current, {
      // theme: 'snow',
      // modules: null,
      readOnly: readOnly,
    });
  }
  else{
    quillRef.current = new Quill(editorRef.current, {
      theme: 'snow',
      modules: {modules},
      readOnly: readOnly,
    });
  }
      // Create new Quill instance
  
  
      // Set the content
      quillRef.current.root.innerHTML = ''; // Clear existing content
      quillRef.current.clipboard.dangerouslyPasteHTML(0, value);
  
      // Handle text changes
      quillRef.current.on('text-change', () => {
        if (quillRef.current) {
          const content = quillRef.current.root.innerHTML;
          onChange(content); // Call the onChange callback with the new content
        }
      });
    }
  }, [value, readOnly]);

  return <div ref={editorRef} style={{ height: '200%' }} />;
};

export default Editor;
