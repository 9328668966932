import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getEstimateByYear } from "../services/BidService";
import { useEffect, useState } from "react";


  
  
  
  export const bidsThisYearoptions: any = {
    title: "",
    curveType: "function",
    intervals: { style: "bars"},
    legend: "bottom",
    orientation: "horizontal",
    baselineColor:'#ccc',
    chartArea: { width: "65%",  },
    xAxis:{
      barPercentage: 0.4
    },
    hAxis: {
      title: "",
      textStyle: {
        fontSize: 10
      },
    
    },
    vAxis: {
      format: "currency",
      minValue: 0,
      gridlines: {
        color: "#ccc",
    
      }, textStyle: {
        fontSize: 10
      },
  
    },
    series: [  { 
      bar: { groupWidth: '10%' } 
    }],
    annotations: {
      textStyle: {
        fontName: 'Times-Roman',
        fontSize: 10,
        bold: true,
        italic: true,
        color: '#871b47',
        auraColor: '#d799ae',
        opacity: 0.8
      }
    }, animation: {
      duration: 1000,
      easing: 'inAndOut', 
      startup: true 
    },
    
  };
  
