/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For showing Payment details of invoice and bills
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/ 
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TableSortLabel, Paper, Checkbox, IconButton,
  Tooltip, Button, Divider, Grid, MenuItem, Select, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Print as PrintIcon,
  Share as ShareIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getInvoiceList } from '../../services/InvoiceServices';
import { getPaymentList } from '../../services/PaymentService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";







interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

interface Data {
  id: number;
  date: any;
  invoiceNo: number;
  customerName: string;
  amount: number;
  status: string;
  state: any;
  vendorName:any;
  type:any

}

function createData(
  id: number,
  date: any,
  invoiceNo: number,
  customerName: string,
  amount: number,
  status: string,
  state: any,
  vendorName:any,
  type:any
): Data {
  return {
    id,
    date,
    invoiceNo,
    customerName,
    amount,
    status,
    state,
    vendorName,
    type
    
  };
}
// const rows = [
//   createData(1, '28/5/2023', 1001, 'John', 670, "Overdue 52 days"),
//   createData(2, '12/3/2023', 452, 'Jack', 51, "Overdue 30 days"),
//   createData(3, '8/11/2023', 262, 'Wiiliam', 24, "Overdue 15 days"),
//   createData(4, '18/7/2023', 159, 'Alice', 24, "Overdue 90 days")
// ];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const headCells: readonly HeadCell[] = [


  {
    id: 'invoiceNo',
    numeric: true,
    disablePadding: false,
    label: 'No.',
  },
  {
    id: 'customerName',
    numeric: true,
    disablePadding: false,
    label: 'Customer',
  },
  {
    id: 'vendorName',
    numeric: true,
    disablePadding: false,
    label: 'Vendor',
  },

  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: true,
    label: 'Due Date',
  }, {
    id: 'state',
    numeric: true,
    disablePadding: false,
    label: 'State',
  }
];


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead >
        <TableRow className='tablerows tableHead'>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell 
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel

                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>Action</TableCell>
          <TableCell >Verification</TableCell>

        </TableRow>
      </TableHead>
    </>
  );
}
const ReceiveBillPaymentList: React.FC = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('customerName');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [invoiceList, setInoviceList] = React.useState([] as any[]);

  React.useEffect(() => {
    const fetData = async () => {
      const response = await getInvoiceList(accessToken);
     // console.log(response);
      setInoviceList(response.data);
    }
    fetData();
  }, [])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [rows, setRows] = React.useState<any>([]);

  useEffect(() => {
    const fetchRows = async () => {
      const data = await getPaymentList();
     // console.log(data)
      setRows(data);
    };
    fetchRows();
  }, []);
  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const invoiceEditForm = (rowData: any) => {
    navigate("/invoiceform", { state: { rowData } });

  };
  const invoiceForm = () => {
    navigate("/invoiceform"); // Navigate to the "/task" route
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const CustomCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
      width: '1em', // Customize the width of the checkbox
      height: '1em',
      fontWeight: '300',
      color: '#ccc'// Customize the height of the checkbox
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      fill: '#007bff', // Customize the fill color when checked
    },
  });
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };
  const onChange = async (event: any) => {
    const file = event.target.files[0];
    onSelectFile(file);
  }
  const [selectedFile, setSelectedFile] = React.useState();
  const [view, setView] = React.useState('');
  const [preview, setPreview] = React.useState();

  React.useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setView(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (file: any) => {
    if (!file || file === 0) {
      setSelectedFile(undefined)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(file);
  }
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );
  const SearchBar = () => (
    <div className='searchBar'>
      <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
        {/* <Grid item xs={12}>
          <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={10}></Grid>
            
          </Grid>
        </Grid> */}
        {/* <Grid item xs={2} mt={3}>
          <Select
            fullWidth
            value=""
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Batch Actions
            </MenuItem>
            <MenuItem value="1">Option 1</MenuItem>
            <MenuItem value="2">Option 2</MenuItem>
            <MenuItem value="3">Option 3</MenuItem>
          </Select>
      
        </Grid>
        <Grid item xs={2}>
          <label><small>Status</small></label>
          <Select
            fullWidth
            value=""
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              All
            </MenuItem>
            <MenuItem value="1">Option 1</MenuItem>
            <MenuItem value="2">Option 2</MenuItem>
            <MenuItem value="3">Option 3</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={2}>
          <label><small>Date</small></label>
          <Select
            fullWidth
            value=""
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
          >
            <MenuItem value="" disabled>
              Last 12 Months
            </MenuItem>
            <MenuItem value="1">Option 1</MenuItem>
            <MenuItem value="2">Option 2</MenuItem>
            <MenuItem value="3">Option 3</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={2} className='createInvoice'>
          <Grid item xs={12} mt={2}>
            <Tooltip title="Print">
              <IconButton>
                <PrintIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Share">
              <IconButton>
                <ShareIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton>
                <SettingsIcon fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
  return (
    <>
      <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={12} md={12}>
          <Box sx={{ width: '100%', }}>
            <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
              <SearchBar />
              <TableContainer >
                <Table
                  sx={{ minWidth: 750, border: 'none', }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {rows && rows.map((row: any, index: any) => {
                      const isItemSelected = isSelected(row.paymentNumber);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow

                          hover
                          onClick={(event) => handleClick(event, row.paymentNumber)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.paymentNumber}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <CustomCheckbox
                              className='check'
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                   
                        
                          <TableCell >{row.paymentNumber}</TableCell>
                          <TableCell >{row.customerName}</TableCell>
                          <TableCell >{row.vendorName}</TableCell>
                          <TableCell >${row.amount}</TableCell>
                          <TableCell >
                            <div className='iconTextCenter'> <ErrorOutlineIcon className='errorOutlineIcon' style={{ marginRight: '5px' }} />
                              {row.terms}</div>
                          </TableCell>
                          <TableCell
                            
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.invoiceDate}
                          </TableCell>
                          <TableCell sx={{ maxWidth: "150px" }} >
                       {row.state}
                          </TableCell>
                          

                          <TableCell>
                          {row.state === 'Paid' ? (
                              <>

                               {row.state && (
                                  <Button variant="outlined" size="small" onClick={handleOpen} >
                                    Upload  Receipt
                                  </Button>
                                )}
                              </>
                            ) : (
                                <Button disabled variant="outlined" size="small" onClick={handleOpen} >
                                Upload  Receipt
                              </Button>
                            )}
                            </TableCell>
                            <TableCell sx={{ maxWidth: "150px" }} >
                            <Button disabled variant="outlined" size="small" onClick={handleOpen} >
                               Approve
                              </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /><Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              // BackdropProps={{
              //   style: {
              //     backdropFilter: 'blur(5px)', // Adjust the blur radius as needed
              //    // Adjust the opacity and color as needed
              //   },
              // }}
              >
              <DialogTitle id="alert-dialog-title">
                <Button
                  variant="contained"
                  component="label"
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    onChange={onChange} />
                </Button>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedFile && <img width={350} height={250} src={view} />}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color='error' variant='contained'>Cancel</Button>
                <Button onClick={handleClose} color='success' variant='contained'>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            </Paper>
          </Box>
        </Grid>
        {/* <Grid item xs={1}></Grid> */}
      </Grid>
    </>

  );
}
export default ReceiveBillPaymentList;