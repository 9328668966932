/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 06-June-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Project Member Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { executeDeleteData, executeGetData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const getProjectMembers = async(projectId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/projectMembers/getProjectMembersByProjectId/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}
