import { ConfigUrl } from "../auth/OidcConfig";
import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";

//5498beaa-2699-44f2-a9e4-0f237d771563
export const getUserRolePermissions = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/GetMyUserRolePermissions`;
    return await executeGetData(url, accessToken);
}

// export const postCreateUser=async (accessToken: any,user: string) => {
// const url = `${ConfigUrl.baseURL}/userAccounts/GetUserRolePermissions/${userOId}`;
//     return await executeGetData(url, accessToken);
// }

export const saveCreateUserAsset = async (user: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/createUser`;
    console.log(user);
    return await executePostData(url, user, accessToken);
  };

  
export const getEmployeeAll = async (page:any,size:any,sortDir:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/all?page=${page}&size=${size}&sortDir=${sortDir}`;
    return await executeGetData(url,  accessToken);
  };

  export const getEmployeeDetails = async (searchParams:any, page:any, size:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/getEmployeeDetails/searchParams/${searchParams}?page=${page}&size=${size}`;
    return await executeGetData(url,  accessToken);
  };

  export const getEmployeeCount = async (searchParams:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/getEmployeeCount/searchParams/${searchParams}`;
    return await executeGetData(url,  accessToken);
  }; 

  export const updateUser = async (id:any,user: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/${id}`;
    
    return await executePutData(url, user, accessToken);
  };

  export const deleteUser = async (id:any ,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/${id}`;
    
    return await executeDeleteData(url, accessToken);
  };
  export const getCustomerDetails = async (searchParams:any, page: any, size: any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/getCustomerDetails/searchParams/${searchParams}?page=${page}&size=${size}`;
    return await executeGetData(url,  accessToken);
  }; 

  export const getCustomers = async (accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/getCustomers`
    return await executeGetData(url,  accessToken);
  }; 


  export const getCustomerDetailsCount = async (searchParams:any, accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/getCustomerCount/searchParams/${searchParams}`;
    return await executeGetData(url,  accessToken);
  }; 

  export const findUserDetails = async ( email:any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/userAccounts/hasUserInAd/email/${email}`;
    return await executeGetData(url,  accessToken);
  }; 