import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import GanttChart from "./GanttChart";
import { useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { Task } from "gantt-task-react";


const GanttDialog: React.FC<any> = (props) => {
  console.log(props)

  const [open, setOpen] = useState(props.open);

  const handleClose = () => {
    props.function(false);
  };


  function convertDateStringsToDates(tasks) {
    return tasks.map(task => {
      return {

        id: task.taskId,
        name: task.taskName,
        Phase: task.phase,
        start: new Date(task.startTime || task.startDate),
        end: new Date(task.endTime || task.endDate),
        
      };
    });
  }


  const modifiedObject = convertDateStringsToDates(props.selected);


  console.log(modifiedObject);

  // // Return the modified object if needed
  // return modifiedObject;


  return (
    <Dialog
      fullWidth={true}
      maxWidth={'lg'}
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} >
            <Stack direction="row" >
              <Typography className='cartheader'>Task Gantt</Typography>
            </Stack>
          </Box>
          <Box>
            <Button onClick={handleClose} className="dangerButton"
              endIcon={<ClearIcon />}>Close</Button>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent >
        {/* <DialogActions>
          <Button onClick={handleClose} className="dangerButton"
            endIcon={<ClearIcon />}>Close</Button>
        </DialogActions> */}
        <DialogContentText>
          <GanttChart tasks={modifiedObject} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>

      </DialogActions>
    </Dialog>
  )
}

export default GanttDialog;