import { isSameDay } from "date-fns";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { createTheme } from '@mui/material/styles';
import ThemeProvider from "@mui/material/styles";



const highlightedDays: Array<Dayjs> = [
    dayjs('2023-12-11'),
    dayjs('2023-11-01'),
    dayjs('2023-11-02'),
    dayjs('2023-11-03'),
    // dayjs('2023-11-14'),
    // dayjs('2023-11-05'),
    dayjs('2023-10-22'),
    dayjs('2023-10-23'),
    dayjs('2023-10-24'),
    dayjs('2023-10-25'),
    dayjs('2023-11-21'),
    dayjs('2023-11-22'),
    dayjs('2023-11-23'),
    dayjs('2023-11-24')
  ];
  
  const CustomDay: React.FC<PickersDayProps<Dayjs>> = (props) => {
    const matchedStyles = highlightedDays.reduce((a, v) => {
    
      const date = props.day.toDate();
  
      return isSameDay(date, v.toDate())
        ? {
            backgroundColor: 'green',
          
            borderTopRightRadius: '0%',
            borderBottomRightRadius: '0%',
            borderTopLeftRadius: '0%',
            borderBottomLeftRadius: '0%',
            margin: '0',
            width: '40px !important'

          }
        : a;
    }, {});
  
    return <PickersDay {...props} sx={{ ...matchedStyles }} />;
  };


const Calender: React.FC = () => {
    const disabledDates = [
        dayjs('2023-10-25'),
        dayjs('2023-11-26'),
        dayjs('2023-11-11'),
        dayjs('2023-11-10')

    ];

  
const isDateDisabled = (date: Dayjs) => {
  
    if (disabledDates.some((disabledDate) => disabledDate.isSame(date))) {
      return true;
    }
  

    return date.day() === 6 || date.day() === 0;
  };
  

   
   
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              
        
 

                <DateCalendar
        
        // sx={{
        //   '.MuiPickersDay-root': {
        //     color: 'blue',
          
          
        //     backgroundColor: 'grey',
        //   },
        // }}
                            slots={{ day: CustomDay }}//date reange
                        shouldDisableDate={isDateDisabled}//disable properties
                        defaultValue={dayjs('2023-09')}//default date is setted
                        
                />
              
               
            </LocalizationProvider>
             <div className="resize vertical" style={{resize:"vertical"}}>Resize me!</div>

        </div>
    )
}
export default Calender;

{/* <DateCalendar
shouldDisableDate={isDateDisabled}
views={['year', 'month', 'day']}
 defaultValue={dayjs('2023-10-21')}

/>

<DateCalendar defaultValue={dayjs('2023-11-21')} /> */}