/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Form For Add And Edit Trade
* 1622           |  16/04/24   | shiyam kumar  |  Made Changes For Bom Add & Edit
* 1791           |  04/05/24   | Vanitha       |  Dialog box implementation for no value has been change 
* 1793           |  04/05/24   | Vanitha       |  Dialog box implementation for Deleted successfuly 
* 1818           |  06/05/24   | Vanitha       |  Bom Add & Edit implementation changed
* 1871           |  09/05/24   |   Vanitha     |  Bom And Csv full form changed
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
* 2030           | 09-05-2024  |   Vanitha     | Material Namww,Unit,Quanity mandatory field implementation
* 2204           | 14-06-2024  |   Vanitha     | Remove Update and cancle button
* 2204           | 18-06-2024  |   Vanitha     | Update for BOM settings
* 


*/

import * as React from 'react';
import Table from '@mui/material/Table';
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from "@mui/material/TextField";
import DeleteIcon from '@mui/icons-material/Delete';
import TableViewIcon from '@mui/icons-material/TableView';
import "../Tenanatconfiguration.scss";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CloseIcon from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Menu,
    MenuItem,
} from '@mui/material';
import BomTemplateForm from './BomTemplateForm';
import { useCSVDownloader } from 'react-papaparse';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { deleteBoms, getBomTemplates, updateBoms } from '../../../services/BomTemplateService';
import CustomDialog from '../../../pages/Dialog';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';

const modalstyle = {
    width: '95%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '70vh',
    overflowY: 'auto',

};


export default function Bomtemplate() {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const { CSVDownloader } = useCSVDownloader();

    const [open, setOpen] = React.useState(false);
    const [anchorEll, setAnchorEll] = React.useState(null);
    const [editRows, setEditRows] = React.useState({});
    const [editedRows, setEditedRows] = React.useState<any>([]);
    const [bomFormDialog, setBomFormDialog] = React.useState(false);
    const [bomList, setBomList] = React.useState<any>([]);
    const [deleteRows, setDeleteRows] = React.useState({});
    const [deletedRows, setDeletedRows] = React.useState<any>([]);
    const [bompostopen, setbompostOpen] = React.useState(false);
    const handlebompostClose = () => setbompostOpen(false);
    const [openDialog, setOpenDialog] = React.useState<any>(false);
    const [updateError, setUpdateError] = React.useState<any>(false);
    const [updateSuccess, setUpdateSuccess] = React.useState<any>(false);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState<any>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [affectedRows, setAffectedRows] = React.useState<any[]>([]);
    const [validated, setValidated] = React.useState(false);
    const [initialBomList, setInitialBomList] = React.useState([]);


    const closeDialog = () => {
        setOpenDialog(false);
        setUpdateError(false);
        setUpdateSuccess(false);
        setShowDeleteDialog(false);
        setBomFormDialog(false);
    }

    let navigate = useNavigate();

    const handleClickEdit = (id) => {
        setEditRows((prevEditRows) => ({
            ...prevEditRows,
            [id]: true,
        }));
        editedRows.push(id);
    };
    const handleClickDelete = (id: any, index: any) => {
        setDeleteRows((prevEditRows) => ({
            ...prevEditRows,
            [id]: true,
        }));
        setSuccess(true);
        deletedRows.push(id);
        let newArray = [...bomList];
        newArray.splice(index, 1)
        setBomList(newArray);
    };

    const handleSave = (id) => {
        setEditRows((prevEditRows) => ({
            ...prevEditRows,
            [id]: false,
        }));
        editedRows.push(id);
    };

    const handleOpen = (boms: any) => {
        setBomList(boms);
        setInitialBomList(JSON.parse(JSON.stringify(boms)));
        setOpen(true);
    }

    const handleClose = () => {
        setEditRows({});
        setEditedRows([]);
        setDeleteRows({});
        setDeletedRows([]);
        setOpen(false);
        closeDialog();
        getTemplateList();
    }

    const openDeleteDialog = async (boms: any) => {
        boms.map((bom: any) => {
            deletedRows.push(bom.id);
        })
        setShowDeleteDialog(true);
    }


    const deleteBomTemplate = async () => {
        setShowDeleteDialog(false);
        const response = await deleteBoms(deletedRows, accessToken);
        if (response.status === 204) {
            getTemplateList();
        }
        setSuccess(true);
    }

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }


    const saveChanges = async () => {
        let valid = true;
        editedRows.forEach((id) => {
            const editedRow = bomList.find((row) => row.id === id);
            if (!editedRow.unit || !editedRow.name || !editedRow.quantity) {
                valid = false;
            }
        });

        if (!valid) {
            setValidated(true);
            return;
        }

        if (deletedRows.length !== 0) {
            const response = await deleteBoms(deletedRows, accessToken);
            if (response.status === 200) {
                setOpenDialog(true);
                setSuccess(true);

            } else {
                setUpdateError(true);
            }
        }


        if (editedRows.length !== 0) {
            setOpenDialog(true);


            try {
                const noChanges = bomList.every((row, index) =>
                    JSON.stringify(row) === JSON.stringify(initialBomList[index])
                );

                if (noChanges) {
                    setNoChange(true);
                }
                else {
                    const editedArray = bomList.filter((bom) => editedRows.includes(bom.id));
                    const updateResponse = await updateBoms(editedArray, accessToken);
                    if (updateResponse.status === 200) {
                        setUpdateSuccess(true);

                    } else {
                        setUpdateError(true);
                    }
                }


            } catch (error) {
                setUpdateError(true);
            }
        }
    };

    const handleChange = (event, index) => {
        let newArray = [...bomList];
        const { name, value } = event.target;

        if (newArray[index][name] !== value) {
            newArray[index][name] = value;

            if (!affectedRows.some(bom => bom.id === newArray[index].id)) {
                setAffectedRows([...affectedRows, newArray[index]]);
            }
        }

        setBomList(newArray);
    };


    const handlemoreClose = () => {
        setAnchorEll(null);
    };

    const handleMenuItemClick = (option) => {
        handlemoreClose();
    };

    const handleBomFormOpen = () => {
        setBomFormDialog(true);
    }

    const [templates, setTemplates] = React.useState<any>([]);

    const getTemplateList = async () => {
        let result = await getBomTemplates(accessToken);
        setTemplates(result.data);
    }

    React.useEffect(() => {
        getTemplateList();
    }, []);


    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
    };

    return (
        <Box>
            <Box sx={{ float: "right", mb: 3 }}>
                <Stack direction="row" spacing={2}>
                    <CSVDownloader
                        //type={Type.Button}
                        filename={'BomTemplate'}
                        //bom={true}
                        config={{
                            delimiter: ',',
                        }}
                        data={[
                            {
                                'name': '',
                                'quantity': '',
                                'unit': '',
                            }]}
                    >
                        <Button variant="contained" startIcon={<PostAddIcon />} >
                            Download CSV Format
                        </Button>
                    </CSVDownloader>
                    <Button variant="contained" startIcon={<PostAddIcon />} onClick={handleBomFormOpen} >
                        Add BOM Template
                    </Button>
                </Stack>
            </Box>
            {bomFormDialog && <BomTemplateForm open={bomFormDialog} function={handleClose} selected={undefined} />}
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead sx={{}} className="table">
                        <TableRow>
                            <TableCell align="center">Sr.No</TableCell>
                            <TableCell align="center">BOM Name</TableCell>
                            <TableCell align="center">Job DoneBy</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates?.map((trade: any, index: any) => (
                            <TableRow key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="trade" align="center">
                                    {index}
                                </TableCell>
                                <TableCell align="center">{trade.name + "-" + trade.materialType + "-" + trade.boms[0].manufacturer}</TableCell>
                                <TableCell align="center">{trade.templateType}</TableCell>
                                <TableCell align="center">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            '& > *': {
                                                m: 1,
                                            },
                                        }}
                                    >
                                        <Stack direction="row" spacing={2}>
                                            <Tooltip title="View" arrow>
                                                <IconButton className='prmaryiconButton' aria-label="View" onClick={() => handleOpen(trade.boms)}>
                                                    <TableViewIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Tooltip title="Delete" arrow>
                                                <IconButton className='erroriconButton' aria-label="delete" onClick={() => openDeleteDialog(trade.boms)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Grid
                container
                sx={{ p: 0 }}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
            >
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={modalstyle}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" >
                                BOM Settings
                            </Typography>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Grid
                                container
                                sx={{ pt: 4, pl: 3, mt: 5 }}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead className="table">
                                                <TableRow>
                                                    <TableCell align="center">Sr.No</TableCell>
                                                    <TableCell align="center">Material Name</TableCell>
                                                    <TableCell align="center">Quantity</TableCell>
                                                    <TableCell align="center">Unit</TableCell>
                                                    <TableCell align="center">Version</TableCell>
                                                    <TableCell align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {bomList.map((row: any, index: any) => (
                                                    <TableRow key={index}>
                                                        <TableCell align="center">{index}</TableCell>

                                                        <TableCell align="left">
                                                            {editRows[row.id] ? (
                                                                <TextField
                                                                    required
                                                                    value={row.name}
                                                                    name='name'
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    size='small'
                                                                    error={validated && !row.name}
                                                                    helperText={validated && !row.name ? '' : ''}
                                                                />
                                                            ) : (
                                                                row.name
                                                            )}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {editRows[row.id] ? (
                                                                <TextField
                                                                    required
                                                                    value={row.quantity}
                                                                    name='quantity'
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    size='small'
                                                                    error={validated && !row.quantity}
                                                                    helperText={validated && !row.quantity ? '' : ''}
                                                                />
                                                            ) : (
                                                                row.quantity
                                                            )}
                                                        </TableCell>

                                                        <TableCell align="center">
                                                            {editRows[row.id] ? (
                                                                <TextField
                                                                    required
                                                                    value={row.unit}
                                                                    name='unit'
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    size='small'
                                                                    error={validated && !row.unit}
                                                                    helperText={validated && !row.unit ? '' : ''}
                                                                />
                                                            ) : (
                                                                row.unit
                                                            )}
                                                        </TableCell>

                                                        <TableCell align="center">{row.version}</TableCell>

                                                        <TableCell align="center">
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    alignItems: 'center',
                                                                    '& > *': {
                                                                        m: 1,
                                                                    },
                                                                }}
                                                            >
                                                                <Stack direction="row" spacing={2}>
                                                                    <div>
                                                                        {editRows[row.id] ? (
                                                                            <Tooltip title="Update" arrow>
                                                                                {/* onClick={() => handleSave(row.id)} */}
                                                                                <IconButton size="small" >
                                                                                    <SaveIcon  onClick={saveChanges}    />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Tooltip title="Edit" arrow>
                                                                                <IconButton size="small" onClick={() => handleClickEdit(row.id)}>
                                                                                    <EditIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                    </div>

                                                                    <div>
                                                                        <Tooltip title="More" arrow>
                                                                            <IconButton className='erroriconButton' aria-label="delete" onClick={() => handleClickDelete(row.id, index)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Menu
                                                                            id="icon-button-menu"
                                                                            anchorEl={anchorEll}
                                                                            open={Boolean(anchorEll)}
                                                                            onClose={handlemoreClose}
                                                                        >
                                                                            <MenuItem onClick={() => handleMenuItemClick('Option 1')}>
                                                                                <Tooltip title="Delete" arrow>
                                                                                    <IconButton className='erroriconButton' aria-label="delete" >
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </MenuItem>
                                                                        </Menu>
                                                                    </div>
                                                                </Stack>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>

                                <Grid item xs={12} ></Grid>

                                {/* <Grid container justifyContent="flex-end">
                                    <Box m={2} className="poFooter">
                                        <Button
                                            sx={{ marginRight: "32px" }}
                                            variant="contained"
                                            className="ErrorTonalButton"
                                            size="medium"
                                            color="error"
                                            onClick={handleClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            className="sucessButton"
                                            variant="contained"
                                            size="medium"
                                            onClick={saveChanges}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Grid> */}
                            </Grid>
                        </Box>
                    </Modal>
                </div>

                <div>
                    <Modal
                        open={bompostopen}
                        onClose={handlebompostClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={modalstyle}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    edge="end"
                                    color="inherit"
                                    onClick={handlebompostClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                            <Typography id="modal-modal-title" variant="h6" component="h2" >
                                Add BOM
                            </Typography>

                            <Grid
                                container
                                sx={{ pt: 4, pl: 3, mt: 5 }}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead sx={{}} className="table">
                                                <TableRow>
                                                    <TableCell align="center">Sr.No</TableCell>
                                                    <TableCell align="center">Material Name</TableCell>
                                                    <TableCell align="center">Quantity</TableCell>
                                                    <TableCell align="center">Unit</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="trade" align="center">
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Sr.No"
                                                                size="small"
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Material Name"
                                                                size="small"
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Quantity"
                                                                size="small"
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Grid item xs={12} >
                                                            <TextField
                                                                id="outlined-multiline-static"
                                                                label="Unit"
                                                                size="small"
                                                                fullWidth
                                                                multiline
                                                            />
                                                        </Grid>
                                                    </TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12}></Grid>

                                <Grid container justifyContent="flex-end">
                                    <Box m={2} className="poFooter">
                                        <Button
                                            sx={{ marginRight: "32px" }}
                                            variant="contained"
                                            className="ErrorTonalButton"
                                            size="medium"
                                            color="error"
                                            onClick={handlebompostClose}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            className="button"
                                            variant="contained"
                                            size="medium"
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Box>
                    </Modal>
                </div>
                <Dialog
                    open={showDeleteDialog}
                    onClose={closeDialog}
                >
                    <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                        <DialogContent >
                            <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                Are you sure?
                            </Typography>
                            <Typography textAlign={'center'}>
                                You won't be able to revert this!
                            </Typography>
                        </DialogContent>
                    </Box>
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={closeDialog} className="dangerButton">No, Cancel!</Button>
                        <Button onClick={() => deleteBomTemplate()} autoFocus className="primaryTonalButton">
                            Yes, Delete it!
                        </Button>
                    </DialogActions>
                </Dialog>


                <CustomDialog
                    open={success}
                    onClose={() => setSuccess(false)}
                    success={success}
                    error={error}
                    Content={success ? "BOM has been deleted successfully" : "Error could not deleted "}
                />

            </Grid>
            <CustomDialog
                open={openDialog}
                onClose={handleClose}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Bom Updated Successfully" : "Failed To Update"} />

            <Dialog open={noChange}>
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            No Value has been Changed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>



        </Box>
    );
}
