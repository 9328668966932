
/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 12-April-2024
* @author      : vijay kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Finance Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const addFinanceData = async(payload:any, accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations`;
    return await executePostData(url, payload, accessToken);
  }

export const getInvoiceIdProviderParts = async(accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/getInvoiceIdProvider`;
    return await executeGetData(url, accessToken);
  }


  export const getTenantDataAll = async(accessToken:any) =>
    {
      const url = `${ConfigUrl.baseURL}/tenantConfigurations/findAll`;
      return await executeGetData(url, accessToken);
    }

    export const editFinanceData = async(payload:any, accessToken:any) =>
      {
        const url = `${ConfigUrl.baseURL}/tenantConfigurations/editTenantDetails`;
        return await executePutData(url, payload, accessToken);
      }

