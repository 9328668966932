import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

const ConfirmationDialog: React.FC<any> = (props) => {
    console.log(props);
    const [openDialog, setOpenDialog] = React.useState(props.open);

    const handleDialogClose = () => {
        setOpenDialog(false);
    };
    return (
        <Dialog
            open={openDialog}
            onClose={handleDialogClose}
        >
            <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                <DialogContent >
                    <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                    <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                        Are you sure?
                    </Typography>
                    <Typography textAlign={'center'}>
                        You won't be able to revert this!
                    </Typography>
                </DialogContent>
            </Box>
            <DialogActions sx={{ justifyContent: "center" }}>
                <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                {/* <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
                    Yes, Delete it!
                </Button> */}
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmationDialog;