/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 3-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Fine Tune Selection Form For Create Bid
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |     AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*       1454     | 26-03-2024 | Shiyam Kumar  | Create Bid Date Binding Issue.
*       1385     | 01-04-2024 | Shiyam Kumar  | Calculate Every Month Average For PriceTrend
*       1841     | 08-05-2024 | Vanitha       | Date Mandartory implement
*       1782     | 09-05-2024 | Shiyam Kumar  | MAde Changes For Trade Domain Change
*/

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { useRef, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Breadcrumbs, Card, Grid, Link, OutlinedInput, FormControl, useTheme, Typography, FormHelperText } from '@mui/material';
import "../CreateBid.scss"
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SelectionCard from '../selectorComponent/SelectionCard';
import dayjs from 'dayjs';
import { getManufacturers, getMaterials, getPriceChartData } from '../../../services/BidService';
import { tokens } from '../../../hooks/theme';
import MultiDimensionalChart from '../CreateBidService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

const CreateBidForm: React.FC<any> = (data) => {
  const [manufacturer, setManufactuer] = useState(false);
  const [material, setMaterial] = useState(false);
  const [bidData, setBidData] = useState(data.data);
  const componentData = useRef<any>();
  const [formValidator, setFormValidator] = useState(false);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  // const [asset, setAsset] = useState({} as any);
  const [count, setCount] = useState(0);
  const [chartParam, setChartParam] = useState<any>({
    trade: bidData.trade
  });
  const [priceChartData, setPriceChartData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>({
    manufacturer: '',
    materialType: '',
    stories: '',
    startdate: '',
    measurement: '',
    layers: '',
    sales: ''
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [manufacturers, setManufactuers] = useState<any>({
    title: "Select Manufacturer",
    type: "manufacturer",
    data: data.manufacturers,
    selectedValue: bidData.manufacturer,
    suggestion: true,
    returnValue: "manufacturer",
    displayValue: "manufacturer"
  });

  const [materials, setMaterials] = useState<any>({
    title: "Select Material Type",
    type: "materialType",
    data: data.materials,
    selectedValue: bidData.materialType,
    suggestion: false,
    returnValue: "materialType",
    displayValue: "materialType"
  });
  const [breadcrumbs, setBreadcrumbs] = useState<any>(data.breadcrumb);

  React.useEffect(() => {
    //getPriceChart(chartParam).then(res => setPriceChartData(res.data));
    data.setButton(false);
    getPriceChartData(chartParam, accessToken)
      .then((res) => {
        setPriceChartData(res.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        //console.error("Error fetching price chart data:", error);
      });

    // getAssetsByAssetUid(accessToken,bidData.assetUid).then(res => setAsset(res.data[0]))
    //getTrades(accessToken).then(res => {setManufactuers({ ...manufacturers, data: res.data });setMaterials({ ...materials, data: res.data });});
    getMaterials(accessToken, bidData.trade).then(res => setMaterials({ ...materials, data: res.data }));
    if (breadcrumbs.length === 0) {
      if (bidData.materialType === undefined && bidData.manufacturer === undefined) {
        breadcrumbs.push({
          type: 'trade',
          value: data.data.trade,
          element: <Link component="button" underline="none" key="1" color="inherit">
            {bidData.trade}
          </Link>
        }
        );
      }
      else {
        getManufacturers(accessToken, bidData.trade, data.data.materialType).then(res => setManufactuers({ ...manufacturers, data: res.data }));
        let elements = [{ type: 'trade', value: data.data.trade, element: <Link component="button" underline="none" key="1" color="inherit">{bidData.trade}</Link> },
        { type: 'materialType', title: data.data.materialType, element: <Link component='button' underline="none" key="1" color="inherit" id={'materialType'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{data.data.materialType}</Link> },
        { type: 'manufacturer', title: data.data.manufacturer, element: <Link component='button' underline="none" key="1" color="inherit" id={'manufacturer'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{data.data.manufacturer}</Link> }
        ]
        setBreadcrumbs(elements);
      }

      setMaterial(true);
    } else if (breadcrumbs.length === 1) {
      getManufacturers(accessToken, bidData.trade, data.data.materialType).then(res => setManufactuers({ ...manufacturers, data: res.data }));
      let elements = [{ type: 'trade', value: data.data.trade, element: <Link component="button" underline="none" key="1" color="inherit">{data.data.trade}</Link> },
    
      ]
      setBreadcrumbs(elements);
      setMaterial(true);
    }
    else {
      getManufacturers(accessToken, bidData.trade, data.data.materialType).then(res => setManufactuers({ ...manufacturers, data: res.data }));
      let elements = [{ type: 'trade', value: data.data.trade, element: <Link component="button" underline="none" key="1" color="inherit">{bidData.trade}</Link> },
      { type: 'materialType', title: data.data.materialType, element: <Link component='button' underline="none" key="1" color="inherit" id={'materialType'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{data.data.materialType}</Link> },
      { type: 'manufacturer', title: data.data.manufacturer, element: <Link component='button' underline="none" key="1" color="inherit" id={'manufacturer'} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >{data.data.manufacturer}</Link> }
      ]
      setBreadcrumbs(elements);
      setMaterial(true);
    }
    // getMaterials().then(res => setMaterials({...materials,data:res.data}));
    // getManufacturers().then(res => setManufactuers({...manufacturers,data:res.data}));
    setSelectedData({
      manufacturer: bidData.manufacturer,
      materialType: bidData.materialType,
      stories: bidData.stories,
      startdate: bidData.jobStartdate !== null ? dateFormatter(bidData.jobStartdate) : dateFormatter(bidData.startdate),
      measurement: bidData.measurement,
      layers: bidData.layers,
      sales: null
    })
    componentData.current = {
      manufacturer: bidData.manufacturer,
      materialType: bidData.materialType,
      stories: bidData.stories,
      startdate: bidData.jobStartdate !== null ? dateFormatter(bidData.jobStartdate) : dateFormatter(bidData.startdate),
      measurement: bidData.measurement,
      layers: bidData.layers,
      sales: null,
    };
    // return () => {
    //   data.function(componentData.current);
    // }
    if (data.count !== 0) {
      setFormValidator(true);
      setCount(count + 1);
    }
  }, [data.count])

  const validator = () => {
    const exist = Object.values(componentData.current).includes(undefined || "NaN-NaN-NaNT00:00:00.000Z");

    if (exist) {
      data.function({ data: componentData.current, validated: false, materials: materials, manufacturers: manufacturers });
    }
    else {
      Object.assign(bidData, componentData.current);
      //setOpen(true);
      // createBid(bidData).then(res => {
      // if(res.status === 200){
      //   setOpen(false);
      // }
      data.function({ data: componentData.current, validated: true, breadcrumbData: breadcrumbs, materials: materials, manufacturers: manufacturers });
      // });
    }
    // Object.entries(componentData.current).map((value)=>{
    //     if(value[1] === undefined){
    //         setCompleted(false);
    //         data.function({data:componentData.current,validated:false});
    //     }
    // })
  }

  const setData = async (e: any) => {
    //console.log(e.target.name)
    Object.assign(selectedData, { [e.target.name]: e.target.title });
    Object.assign(componentData.current, { [e.target.name]: e.target.title });
    const index = breadcrumbs.findIndex((f: any) => e.target.name === f.type);
    //Not found, add on end.
    let updated: any = []
    if (-1 === index) {
      updated = [...breadcrumbs, {
        type: e.target.name, title: e.target.title, element: <Link component='button' underline="none" key="1" color="inherit" id={e.target.name} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >
          {e.target.title}
        </Link>
      }];
    } else {
      updated = [...breadcrumbs.slice(0, index), {
        type: e.target.name, title: e.target.title, element: <Link component='button' underline="none" key="1" color="inherit" id={e.target.name} onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} >
          {e.target.title}
        </Link>
      }, ...breadcrumbs.slice(index + 1)];
    }
    if (e.target.name === 'materialType') {
      await getManufacturers(accessToken, bidData.trade, e.target.title).then(res => {setManufactuers({ ...manufacturers, data: res.data.split(",") });console.log(res)});
      Object.assign(materials, { selectedValue: e.target.title });
      Object.assign(chartParam, { material: e.target.title });
      setChartParam({ ...chartParam, material: e.target.title });
      getPriceChartData(chartParam, accessToken)
        .then((res) => {
          setPriceChartData(res.data);
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          //console.error("Error fetching price chart data:", error);
        });
      setMaterial(false);
      setManufactuer(true);
    } else {
      Object.assign(manufacturers, { selectedValue: e.target.title });
      Object.assign(chartParam, { manufacturer: e.target.title });
      setChartParam({ ...chartParam, manufacturer: e.target.title });
      getPriceChartData(chartParam, accessToken)
        .then((res) => {
          setPriceChartData(res.data);
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          //console.error("Error fetching price chart data:", error);
        });
      setManufactuer(false);
      data.setButton(true);
    }
    setBreadcrumbs(updated);
    validator();
    //getPriceChart(chartParam).then(res => { setPriceChartData(res.data) });
    // getPriceChartData("materialType",chartParam,accessTokenPayload.oid).then(res => {setPriceChartData(res.data);console.log(res.data)});

  }

  const onselect = (e: any) => {
    //console.log(typeof e.target.value)
    if (e.target.name === 'measurement' || e.target.name === 'stories') {
      setSelectedData({ ...selectedData, [e.target.name]: Number(e.target.value) });
      Object.assign(componentData.current, { [e.target.name]: Number(e.target.value) });
      validator();
    } else {
      setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
      Object.assign(componentData.current, { [e.target.name]: e.target.value });
      validator();
    }
    validator();
  };
  const dateFormatter = (value: any) => {
    const date = new Date(value);
    const month = date.getMonth().toString();
    return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T00:00:00.000Z";
  }

  const updateDate = (date: any) => {
    // console.log(dateFormatter(date.$d));
    // console.log("dayjs",dayjs(date.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z")
    // setSelectedData({ ...selectedData, startdate: dateFormatter(date.$d) });
    // Object.assign(componentData.current, { startdate: dateFormatter(date.$d) });
    setSelectedData({ ...selectedData, startdate: dayjs(date.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z" });
    Object.assign(componentData.current, { startdate:dayjs(date.$d).format('YYYY-MM-DD')+ "T00:00:00.000Z" });
    validator();
  }


  const handleClick = (e: any) => {
    if (e.target.id === 'materialType') {
      setMaterial(true);
      setManufactuer(false);
    } else {
      setManufactuer(true);
      setMaterial(false);
    }
  }



  return (
    <>


      <Grid container spacing={1}>



        <Grid item xs={12} pt={1}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >

            {breadcrumbs.map((data: any) => {
              return (
                <span key={data.key} style={{ fontWeight: 'bold' }}>
                  {data.element}
                </span>
              );
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={12}>
          {material ? (<SelectionCard data={materials} count={count} function={setData} />) :
            manufacturer ? (<SelectionCard data={manufacturers} count={count} function={setData} />) :
              (<Card variant="outlined" className='createBidFormCard'>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Typography color="rgb(60 60 60 / 87%)">Enter number of floors in your property:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                        <FormControl variant="outlined" fullWidth size="small" error={selectedData.stories === undefined && formValidator === true} >
                          <InputLabel htmlFor="outlined-adornment-password">Number of stories</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type="number"
                            readOnly
                            value={selectedData.stories}
                            //disabled
                            onChange={(e) => onselect(e)}
                            label="Number of stories"
                            name='stories'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box sx={{ display: { xs: "none", sm: "none", md: "block", xl: "block" } }}>
                          <Typography sx={{ padding: 2 }}></Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Typography color="rgb(60 60 60 / 87%)">Enter the exact measurements of the area of work:</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                        <FormControl variant="outlined" fullWidth size="small" error={selectedData.measurement === undefined && formValidator === true}>
                          <InputLabel htmlFor="outlined-adornment-password">Area in sq</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type="number"
                            value={selectedData.measurement}
                            readOnly
                            //disabled
                            onChange={(e) => onselect(e)}
                            label="Area in sq"
                            name='measurement'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Link component='button' underline="none" key="1" id='' onClick={(e) => handleClick(e)} sx={{ "&:hover": { color: colors.primary[500] + ' !important', background: 'none !important' } }} className='formSubTitle'>Upload Blueprint</Link>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Typography color="rgb(60 60 60 / 87%)">How many layers of shingles do you need?</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                        <FormControl variant="outlined" fullWidth size="small" error={selectedData.layers === undefined && formValidator === true} >
                          <InputLabel htmlFor="outlined-adornment-password">Number of Layers</InputLabel>
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type="number"
                            value={selectedData.layers}
                            onChange={(e) => onselect(e)}
                            label="Number of Layers"
                            name='layers'
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <Box pt={1}>
                          {/* <Link component='button' underline="none" key="1" color="inherit" id='' onClick={(e) => handleClick(e)}  sx={{"&:hover":{color: colors.primary[500] + ' !important',background:'none !important'}}}  className='formSubTitle'>Make this decision for me?</Link> */}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Typography color="rgb(60 60 60 / 87%)">When do you want to start the work?</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='DD MMM YYYY'
                            label="Choose Date"
                            disablePast   
                            disableHighlightToday   
                            shouldDisableDate={date => date.day() === 0 || date.day() === 6}            
                            minDate = {dayjs().add(1, 'day')}
                            slotProps={{ textField: { fullWidth: true, size: "small", error: (isNaN(Date.parse(selectedData.startdate)) && formValidator === true) } }}
                            value={(selectedData.startdate !== undefined) ? data.data.startdate !== undefined ? dayjs(data.data.startdate) : dayjs(data.data.jobStartdate) : (selectedData.startdate === undefined && formValidator === true) ? null : null}
                            onChange={(date) => updateDate(date)}
                          // slots={{
                          //   textField: {
                          //     error: !!dateError,
                          //     helperText: dateError,
                          //   },
                          // }}
                          // onError={(err) => console.log(err)}
                          // renderInput={(params:any) => (
                          //   <TextField
                          //     {...params}
                          //     error={selectedData.startdate === undefined && formValidator === true}
                          //     helperText={'Please select a vaild date'}
                          //   />
                          // )}
                          //   error={selectedData.startdate === undefined && formValidator === true}
                          //   renderInput={(params) => (
                          //     <TextField {...params} label="Customer Name" error={selectedData.customerId === undefined && formValidator === true}/>
                          // )}
                          />
                        </LocalizationProvider> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <FormControl fullWidth size="small" error={selectedData.startdate === undefined && formValidator === true}>
                            <DatePicker
                              format='DD MMM YYYY'
                              label="Choose Date"
                              disablePast
                              disableHighlightToday
                              shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                              minDate={dayjs().add(1, 'day')}
                              value={(selectedData.startdate !== undefined) ? data.data.startdate !== undefined ? dayjs(data.data.startdate) : dayjs(data.data.jobStartdate) : null}
                              onChange={(date) => updateDate(date)}
                              

                            />
                            {selectedData.startdate === undefined && formValidator === true && (
                              <FormHelperText>Please select a date</FormHelperText>
                            )}
                          </FormControl>

                        </LocalizationProvider>

                      </Grid>
                      {/* <Grid item xs={12} md={12}>
                        <Typography>Recommended Dates:</Typography>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={2}>
                          <Typography>
                            <Typography className='recommendationBadge'>Nov.</Typography>
                            <Fab color="primary" aria-label="add" variant='extended'>
                              <Typography>12</Typography>
                            </Fab>
                          </Typography>

                        </Stack>
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>)}

        </Grid>


        <Grid item xs={12}>
          <Card variant="outlined" className='createBidFormCard'>
            {priceChartData !== undefined ? <MultiDimensionalChart params={priceChartData} /> : <Typography>Data Not Found</Typography>}
          </Card>
        </Grid>
      </Grid>

    </>
  );
}

export default CreateBidForm;