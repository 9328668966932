// export const soldContractsdata: any = [
//     ["Month", "Alex Clifton", "Mike Salz", "Kevin Bobby"],
//     [{ v: 1, f: "Jan", }, 100, 120, 150],
//     [{ v: 2, f: "Feb", }, 300, 100, 120],
//     [{ v: 3, f: "Mar" }, 200, 105, 30],
//     [{ v: 4, f: "Apr" },250, 2500, 200],
//     [{ v: 5, f: "May" }, 950, 190, 1250],
//     [{ v: 6, f: "June" }, 120, 1000, 50],
//     [{ v: 7, f: "July" },  80, 250, 100],
//     [{ v: 8, f: "Aug" },  1850, 100, 250],
//     [{ v: 9, f: "Sep" },  170, 100, 150],
//   ];
  
//   export const soldContractsoptions: any = {
//     title: "",
//     curveType: "function",
//     intervals: { style: "bars" },
//     legend: "bottom", maxLines: 3,
//     orientation: "horizontal",
//     chartArea: { width: "70%", height: "65%" },
//     baselineColor:'#ccc',
//     // series: {
//     //  // Adjust the width of bars in the first series
//     // },
//     hAxis: {
//       title: "",
//       textStyle: {
//         fontSize: 12
//       },
//       titleTextStyle: {
//         fontName: "Oswald",
//         italic: false,
//         color: "#990000"
//       },
//        tooltip:{textStyle: {color: '#FF0000'}, showColorCode: true
  
//        },
//       baseline: 0,
//       gridlines: {
//         color: "#c4c4c4",
//         count: 4
//       },chartArea: {width: '100%', height: '100%'},
//       bar: { groupWidth: '100%' },
//       ticks: [
//         { v: 1, f: "Jan" },
//         { v: 2, f: "Feb" },
//         { v: 3, f: "Mar" },
//         { v: 4, f: "Apr" },
//         { v: 5, f: "May" },
//         { v: 6, f: "Jun" },
//         { v: 7, f: "Jul" },
//         { v: 8, f: "Aug" },
//         { v: 9, f: "Sep" },
//         { v: 10, f: "Oct" },
//         { v: 11, f: "Nov" },
//         { v: 12, f: "Dec" }
//       ], viewWindow: { min: 0,max:12 },slantedText:'false',
//     },
//     vAxis: {
//       format: "currency",
//       minValue: 0,
//       gridlines: {
//         color: "#ccc",
        
//         count: 4
//       }, textStyle: {
//         fontSize: 12
//       },
//       slantedText:'false',
//       ticks: [
//         {v: 0, f: '$0'},
//         {v: 1000, f: '$1,000'},
//         {v: 2000, f: '$2,000'},
//         {v: 3000, f: '$3,000'},
//         // {v: 8000, f: '$8,000'}
//       ]
      
  
//     },
    
//     // Define colors for each series
//     series: {
//       0: {
//         type: "bars",
//         color: "#00bdae",
//         visibleInLegend: true
//       },
//       1: {
//         type: "bars",
//         color: "#404041",
//         visibleInLegend: true
//       },
//       2: {
//         type: "bars",
//         color: "#357cd2",
//         visibleInLegend: true
//       }
//     },
//     annotations: {
//       textStyle: {
//         fontName: 'Times-Roman',
//         fontSize: 12,
//         bold: true,
//         italic: true,
//         // The color of the text.
//         color: '#871b47',
//         // The color of the text outline.
//         auraColor: '#d799ae',
//         // The transparency of the text.
//         opacity: 0.8
//       }
//     }, animation: {
//       duration: 1000, // set duration to 1 second
//       easing: 'inAndOut', // set easing to 'ease in and out'
//       startup: true // set startup to true to animate the chart on initial draw
//     },  // Add tooltip options
//     tooltip: {
//       textStyle: {innerWidth:'15px'},
//       showColorCode: true,
//       trigger: 'focus',
//       isHtml: true,
//       cssClassNames: {
//         tooltip: 'my-tooltip'
//       }
//     }, focusTarget: 'datum', // set the focusTarget property to 'category'

//      bar: { groupWidth: "81%" }
//   };
  

  
  
export const soldContractsoptions: any = {
  title: "",
  curveType: "function",
  intervals: { style: "bars"},
  legend: "bottom",
  orientation: "horizontal",
  baselineColor:'#ccc',
  chartArea: { width: "65%",  },
  xAxis:{
    barPercentage: 0.4
  },
  hAxis: {
    title: "",
    textStyle: {
      fontSize: 10
    },
  
  },
  vAxis: {
    format: "currency",
    minValue: 0,
    gridlines: {
      color: "#ccc",
  
    }, textStyle: {
      fontSize: 10
    },

  },
  series: [  { 
    bar: { groupWidth: '10%' } 
  }],
  annotations: {
    textStyle: {
      fontName: 'Times-Roman',
      fontSize: 10,
      bold: true,
      italic: true,
      color: '#871b47',
      auraColor: '#d799ae',
      opacity: 0.8
    }
  }, animation: {
    duration: 1000,
    easing: 'inAndOut', 
    startup: true 
  },
  
};

