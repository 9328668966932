
import Header from '../../components/Header';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { borderRadius, padding } from '@mui/system';
import Fab from '@mui/material/Fab/Fab';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import AlarmIcon from '@mui/icons-material/Alarm';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid } from '@mui/material';
import './Button.scss';


const Buttons: React.FC = () => {

   



    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h4>Outlined Button Type</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Tooltip title="Border radius 8px" placement="bottom">
                                            <Button variant="outlined" color="success" size="large" sx={{ borderRadius: '8px' }}  >
                                                Save
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="Border radius 8px" placement="bottom">
                                            <Button variant="outlined" color="error" size="large" sx={{ borderRadius: '8px' }}>
                                                Delete
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="default mui Border radius" placement="bottom">
                                            <Button variant="outlined" color="primary" size="large" >
                                                Next
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tooltip title="default mui Border radius" placement="bottom">
                                            <Button variant="outlined" color="secondary" size="large" >
                                                Previous
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Contained Button Type</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Button className='hi' variant="contained" color="success" size="large" >
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="error" size="large">
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="primary" size="large" >
                                            Next
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="secondary" size="large">
                                            Previous
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h4>TEXT BUTTON TYPE</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Button variant="text" color="success" size="large">
                                            Save
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="text" color="error" size="large" >
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="text" color="primary" size="large" >
                                            Next
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="text" color="secondary" size="large" >
                                            previous
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <h4> Button With Icon Type</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="success" size="large" startIcon={< SaveIcon />}>
                                            save
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="error" size="large" startIcon={<DeleteIcon />}>
                                            Delete
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="primary" size="large" startIcon={<SkipNextIcon />}>
                                            Next
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="secondary" size="large" startIcon={<SkipPreviousIcon />}>
                                            Previous
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h4>Button Type Sizes</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={4}>
                                        <Button variant="contained" color="primary" size="small" >
                                            Small
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" color="primary" size="medium" >
                                            Medium
                                        </Button>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" size="large" >
                                            LARGE
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Button Colors</h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="secondary">Secondary</Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="success">
                                            Success
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="error">
                                            Error
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" color="primary">
                                            Primary
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <h4>Fab Button </h4>
                        <Card variant="outlined" className='btn-card-style' >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={3}>
                                        <Fab color="info" >
                                            +
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Fab color="secondary" aria-label="edit">
                                            <EditIcon />
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Fab color="secondary" aria-label="edit">
                                            <FavoriteIcon />
                                        </Fab>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Fab variant="extended">
                                            Extended
                                        </Fab>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Icon Button </h4>
                        <Card variant="outlined" className='btn-card-style height-custom'>
                            <CardContent sx={{ alignContent: "center", alignItems: "center" }}>
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={4}>
                                        <IconButton aria-label="delete" disabled color="primary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <IconButton color="secondary" aria-label="add an alarm">
                                            <AlarmIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <IconButton color="primary" aria-label="add to shopping cart">
                                            <AddShoppingCartIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={12}>
                        <h4>Tonal Button Type</h4>
                        <Card variant="outlined"  >
                            <CardContent >
                                <Grid container spacing={2} columns={12} columnSpacing={2}>
                                    <Grid item xs={2}>
                                        <Button variant="contained" className="primaryTonalButton">
                                            Primary
                                        </Button>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" className="secondaryButton" >
                                            Secondary
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" className="dangerButton" >
                                            Danger
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" className="sucessButton" >
                                            Sucess
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        < Button variant="contained" className="warningButton" >
                                            Warning
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>


            </div>
        </>
    );
}


export default Buttons;