import { TabContext, TabList, TabPanel } from '@mui/lab';
import {  Tab } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import VendorManagement from '../../components/vendor/VendorManagement';
import CustomerManagement from './CustomerManagement';
import EmployeeManagement from './EmployeeManagement';
import { setCurrentPageDetails } from '../../hooks/UserSession';
import Personalinfo from './Employment/Employer/Personalinfo';
import Employementdetails from './Employment/Employer/Employementdetails';
import Customer from './Employment/Customer';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import { getUserSession } from '../../hooks/UserSession';
import ToolList from '../../components/toolManagement/ToolList';
import MaterialList from '../../components/materialManagement/MaterialList';
import ResourceList from '../../components/resourceManagement/ResourceList';
import { useLocation } from 'react-router-dom';

const Admin : React.FC = () => {
  const [value, setValue] = React.useState<any>();
  const location = useLocation();
  const selectedTab = location.state?.selectedTab;


  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    console.log(event);
    setCurrentPageDetails(location.pathname, newValue, undefined);
    setValue(newValue);
  };

  React.useEffect(()=>{
    if(selectedTab !== undefined){
      setValue(selectedTab);
    }else{
      setValue('1');
      setCurrentPageDetails(location.pathname, '1', undefined);
    }
  },[])

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1',marginLeft:"0px" }}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          
            <Tab label="Employee Mananagement" value="1" />
            <Tab label="Vendor Management" value="2" />
            <Tab label="Customer Management" value="3" />
            <Tab label="Tool Management" value="4" />
            <Tab label="Material Management" value="5" />
            <Tab label="Resource Management" value="6" />
            {/* <Tab label="Employee Personal Info" value="4" />
            <Tab label="Employement details" value="5" />
            <Tab label="Employement details" value="6" /> */}


          </TabList>
        </Box>
        <TabPanel value="1"> <EmployeeManagement /></TabPanel>
        <TabPanel value="2"><VendorManagement/></TabPanel>
        <TabPanel value="3"><CustomerManagement/></TabPanel>
        <TabPanel value="4"><ToolList/></TabPanel>
        <TabPanel value="5"><MaterialList/></TabPanel>
        <TabPanel value="6"><ResourceList/></TabPanel>
        {/* <TabPanel value="4"><Personalinfo/></TabPanel> */}
        {/* <TabPanel value="5"><Employementdetails/></TabPanel>
        <TabPanel value="6"><Customer/></TabPanel> */}
      </TabContext>
    </Box>
    </div>
  )
}

export default Admin;
