/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : Otp entering ui for the customer side
* -----------------------------------------------------------------------------0
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1548         |  5/4/24    |   Nandha      |  Otp Generator toast message removed and dialog implemented
*
*
*/

import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Grid, Typography, Snackbar, IconButton, Tooltip } from '@mui/material';
import { Stack,  } from '@mui/system';
import './OtpGenerator.scss';
import {  getProjectConfirmationByprojectIdandCode } from '../../services/ProjectConfirmation';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { getProjectBycustomerUId, projectReschedule } from '../../services/ProjectService';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CustomDialog from '../../pages/Dialog';

interface OTPModalProps {
  open: boolean;
  onClose: () => void;
  props: any;
  projects:any;
}

const OTPModal: React.FC<OTPModalProps> = ({ open, onClose, props,projects }) => {

// console.log(props,projects)
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [isError, setIsError] = useState(false); 
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [updateError] = React.useState(false);


  const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
  const handleInputChange = (value: string, index: number) => {
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input field
    if (value !== '' && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };



  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';

      setOtp(newOtp);

      // Move to the previous input field
      if (index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleVerifyOtp = async () => {
    // Combine the entered digits to form the OTP
    setOpenDialog(true);
    const enteredOtp = otp.join('');
    // Perform verification logic with the entered OTP
    // For demonstration purposes, just logging it
    console.log('Verifying OTP:', enteredOtp);

    try {
      const response = await getProjectConfirmationByprojectIdandCode(props, enteredOtp, accessToken);
      
      console.log(response.data, props);

      // Assuming response.data is a boolean indicating verification success
      if (response.data) {
        projectReschedule(props, accessToken);
        // setConfirmationMessage('OTP Verified Successfully!');
        setUpdateSuccess(true);
        const response = await getProjectBycustomerUId(accessTokenPayload.oid,0,5 ,accessToken);
        console.log(response);
        
        projects(response.data);
        onClose();
        // setProjects(response.data);
     

      } else {
        // setConfirmationMessage('OTP Verification Failed. Please try again.');
        // setUpdateError(true);
        setIsError(true);
        setErrorCode("OTP is invalid. Please enter correct password");
        setOtp(Array.from({ length: 6 }, () => ''));

      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setConfirmationMessage('Error verifying OTP. Please try again.');
      setOtp(Array.from({ length: 6 }, () => ''));
    }
  };

  const handleCloseConfirmation = () => {
    setConfirmationMessage('');
    // Close the modal after closing the confirmation Snackbar
    onClose();
    setOtp(Array.from({ length: 6 }, () => ''));
  };

  useEffect(() => {
    setOtp(Array.from({ length: 6 }, () => ''));
    // const progressInterval = setInterval(() => {
    //   setConfirmationMessage((prevMessage) => {
    //     return prevMessage === 'Processing...' ? 'Completed!' : 'Processing...';
    //   });
    // }, 1000);

    // const timeoutId = setTimeout(() => {
    //   clearInterval(progressInterval);
    //   setConfirmationMessage('');
    // }, 6000);

    // return () => {
    //   clearInterval(progressInterval);
    //   clearTimeout(timeoutId);
    // };
  }, []);

const handleClose =()=>{
  onClose();
}

  return (

    <><Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>

      {/* <Card className='dialogOtp m-5 POCard paymentBox' > */}
      <Grid m={0} item textAlign={"end"} sx={{ backgroundColor: "#3c5bfa" }}><Tooltip title="close"><Button onClick={handleClose}><CloseOutlinedIcon sx={{ color: "red" }} /></Button></Tooltip></Grid>
      {/* <Grid item >
      <Typography className='otpImg pb-0'>
        <img
          src="https://icon-library.com/images/tick-icon-png/tick-icon-png-16.jpg" // replace with your image URL
          alt="Your Alt Text"
          style={{ width: '100%', maxWidth: '80px' }} // customize the width as needed
        /></Typography>
    </Grid> */}

      <DialogTitle className='otpTitle p-0' sx={{ backgroundColor: "#3c5bfa", color: "white" }}>

        <Stack direction="row" alignItems="center" mb={1} mt={0}>
          <LockOpenIcon />&nbsp;
          <Stack className="projectConfirm">OTP Verification</Stack>

        </Stack>
      </DialogTitle>        <DialogContent>
        <Grid mt={2}><Typography className='otpSubTitle '>Enter the Verification code</Typography></Grid>
        <Grid mt={2} mb={1}><Typography className='otpcodeTitle'><small>Type your 6 digit security code</small></Typography></Grid>

        {/* <CardContent> */}
        <Grid item container justifyContent="center">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => handleInputKeyDown(e, index)}
              ref={(el) => (inputRefs.current[index] = el)}
              style={{
                width: '2rem',
                height: '2rem',
                textAlign: 'center',
                fontSize: '1rem',
                margin: '0 0.5rem',
              }} />
          ))}
        </Grid>
      </DialogContent>
      {isError && (
        <Grid item textAlign="center">
          <Typography color="error">
            {errorCode}
          </Typography>
        </Grid>
      )}
      <Grid item padding={2} className="otpVerifyBtn">
        <Button variant="contained" color="primary" onClick={handleVerifyOtp}>
          Verify OTP
        </Button>

      </Grid>
      <Snackbar
        open={!!confirmationMessage}
        autoHideDuration={1000}
        onClose={handleCloseConfirmation}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className="progress-bar">
          <IconButton size="small" color="primary">
            <InfoOutlinedIcon />
          </IconButton>
          <span style={{ marginLeft: '8px', flex: 1 }}>{confirmationMessage}</span>
          {/* Close icon in the right corner */}
          <IconButton size="small" color="inherit" onClick={handleCloseConfirmation}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
      </Snackbar>
      {/* </CardContent> */}
      {/* </Card> */}

    </Dialog><CustomDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        success={updateSuccess}
        error={updateError}
        Content={updateSuccess ? "OTP Verified Successfully!" : "OTP Verification Failed. Please try again."} /></>
  );
};
export default OTPModal;
