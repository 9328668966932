/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : VijayaKumar.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Estimate Card View For Create Bid 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1953           |  16/05/24   | Vanitha       |  City,State,Country fields are binded
*
*
*/

import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Box, Button, CardContent, Divider, Stack, Typography, useTheme } from '@mui/material';
import '../CreateBid.scss';
import { useEffect } from 'react';
import { tokens } from '../../../hooks/theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GanttDialog from '../../ganttchart/components/GanttDialog';
import dayjs from 'dayjs';



const Estimate: React.FC<any> = (props) => {
    // console.log("estimate",props);
    const [selected, setSelected] = React.useState<any>();
    const [show, setShow] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [tasks, setTasks] = React.useState<any>([]);
    const [selectedAsset, setSelectedAsset] = React.useState<any>(props.asset);

    useEffect(() => {
        if (props.selectedBid !== undefined) {
            setSelected(props.selectedBid);
            setShow(true);
        }
        setShow(true);
    }, []);
;  


    const selectedBid = (data: any) => {
        setSelected(data);
        setTasks(JSON.parse(data.tasks));
        setShow(true);
        props.function({ selectedBid: data, validated: true });
    }



    const handleClose = (state: any) => {
        setOpen(state);
    };

    const handleOpen = (data: any) => {
        setOpen(true);
        setTasks(JSON.parse(data.tasks));
    };


    return (
        <>
            <Card variant="outlined" className='estimateCard'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <Grid container spacing={2}>
                            <Box marginLeft={12} padding={1}>
                                {props.bids.length <= 1 ? <Typography variant="h3" color="rgb(60 60 60 / 87%)" >Selected Estimate</Typography> :
                                    <Typography variant="h3" color="rgb(60 60 60 / 87%)"  >Choose Estimate</Typography>}
                            </Box>
                            <Grid item xs={12} lg={12} xl={12}>
                                <Grid container spacing={{ xs: "none", sm: "none", md: "none", lg: 2, xl: 4 }} columns={18}>
                                    <Grid item xs={0} xl={1.5} display={{ xs: "none", sm: "none", md: "none", lg: "none", xl: "block" }}><span> </span></Grid>
                                    {props.bids.map((data: any, index: any) =>
                                        <>
                                            {props.bids.length <= 1 && <Grid item xs={18} sm={18} md={18} lg={6} xl={5} className='alignCenter' key={index} width={'100%'} pb={1}>
                                            </Grid>}
                                            <Grid item xs={18} sm={18} md={18} lg={6} xl={5} className='alignCenter' key={index} width={'100%'} pb={1}>

                                                <Card variant="outlined" className={(data.materialGrade === 'a' ? "estimateACardContent" : data.materialGrade === 'b' ? "estimateBCardContent" : "estimateCCardContent") + `${selected !== undefined ? data.materialGrade === selected.materialGrade ? ' selected-estimate-card' : "" : ""}`} sx={{ backgroundColor: colors.primary[500], maxHeight: "350px" }}>
                                                    <Box className="alignCenter">
                                                        <CardContent sx={{ padding: 1 }}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={12} md={10} lg={12} xl={12}>
                                                                    <Typography sx={{ fontSize: "22px", color: "white" }}>
                                                                        {data.materialGrade === 'a' ? 'Best Quality' : data.materialGrade === 'b' ? 'Better Quality' : 'Good Quality'}
                                                                    </Typography>

                                                                    <Typography className='estimate'>${data.estimation}</Typography>
                                                                    <Divider sx={{ backgroundColor: "white" }} />
                                                                    <Typography className='estimateSubContent mt-1'>{data.trade}</Typography>
                                                                    <Typography className='estimateSubContent'>{data.materialType}</Typography>

                                                                    <Typography className='estimateSubContent'>{data.manufacturer}</Typography>

                                                                    <Typography className='estimateSubContent'>Grade {data.materialGrade.toUpperCase()}</Typography>
                                                                    <Typography className='estimateSubContent'> Starts on {dayjs(data.jobStartdate).format("DD MMM YYYY")}</Typography>
                                                                    <Typography className='estimateSubContent'> Ends on {dayjs(data.jobEnddate).format("DD MMM YYYY")}</Typography>

                                                                    <Divider sx={{ backgroundColor: "white" }} />
                                                                </Grid>
                                                                <Grid item xs={12} md={10} lg={12} xl={12}>
                                                                    <Typography className="alignCenter estimateSelect">

                                                                        {(data.materialGrade === selected?.materialGrade) ?
                                                                            <Button variant="contained" className={data.materialGrade === 'a' ? "estimateASelectButton" : data.materialGrade === 'b' ? "estimateBSelectButton" : "estimateCSelectButton"}
                                                                                onClick={() => handleOpen(data)} sx={{ color: colors.greenAccent[10] }}><Box width={100}>
                                                                                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                                                                                        <CheckCircleIcon />
                                                                                        <span style={{ whiteSpace: 'nowrap' }}>Show Gantt</span>
                                                                                    </Stack></Box>
                                                                            </Button> :
                                                                            <Button variant="contained" className="estimateunSelectButton"
                                                                                onClick={() => selectedBid(data)} sx={{ color: colors.primary[500] }}>Select
                                                                            </Button>}

                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </Box>
                                                </Card>
                                            </Grid>
                                            {props.bids.length <= 1 && <Grid item xs={18} sm={18} md={18} lg={6} xl={5} className='alignCenter' key={index} width={'100%'} pb={1}>
                                            </Grid>}
                                        </>
                                    )}
                                    <Grid item xs={0} xl={1.5} display={{ xs: "none", sm: "none", md: "none", lg: "none", xl: "block" }}><span> </span></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={12} xl={12}>
                                <Grid container spacing={{ xs: "none", sm: "none", md: "none", lg: 2, xl: 4 }} columns={18}>
                                    <Grid item xs={0} xl={1.5} display={{ xs: "none", sm: "none", md: "none", lg: "none", xl: "block" }}><span> </span></Grid>
                                    <Grid item xs={18} xl={16}>
                                        <Box >
                                            {show ?
                                                <>
                                                    <Grid container spacing={2} >
                                                        <Grid item xs={16}>
                                                            <Typography sx={{ marginTop: 2, fontSize: "20px", color: "rgb(60 60 60 / 87%)" }} >Estimate Details:</Typography>
                                                        </Grid>
                                                        <Grid container marginLeft={3} direction={"row"}>
                                                            <Grid item xs={4}>
                                                                <Stack direction={"row"}>
                                                                    <Typography >Name:</Typography>
                                                                    <Typography className='formSubTitle' paddingLeft={1}>{props.customerName}</Typography>
                                                                </Stack>
                                                            </Grid>
                                                            {selected !== undefined ?
                                                                <><Grid item xs={4}>
                                                                    <Stack direction={"row"}>
                                                                        <Typography>Trade:</Typography>
                                                                        <Typography className='formSubTitle' paddingLeft={1}>
                                                                            {selected.trade}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Stack direction={"row"}>
                                                                            <Typography>Material Type:</Typography>
                                                                            <Typography className='formSubTitle' paddingLeft={1}>
                                                                                {selected.materialType} - {selected.manufacturer}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Grid></>
                                                                : <></>}
                                                        </Grid>
                                                        <Grid container marginLeft={4} mt={1} direction={"row"}>
                                                            <Grid item xs={4}>
                                                                <Stack direction={"row"}>
                                                                    <Typography>Address:</Typography>
                                                                    <Typography className='formSubTitle' paddingLeft={1} sx={{
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap'
                                                                    }}>
                                                                        {/* {selectedAsset.name },<br/> */}
                                                                        {selectedAsset.assetAddress || selectedAsset.address}<br />
                                                                        {selectedAsset.city},
                                                                        {selectedAsset.state},<br />
                                                                        {selectedAsset.country},<br />
                                                                        {selectedAsset.zipCode || selectedAsset.zipcode}</Typography>                                            </Stack>
                                                            </Grid>
                                                            {selected !== undefined ?
                                                                <><Grid item xs={4}>
                                                                    <Stack direction={"row"}>
                                                                        <Typography>Start Date:</Typography>
                                                                        <Typography className='formSubTitle' paddingLeft={1}>
                                                                            {dayjs(selected.jobStartdate).format("DD MMM YYYY")}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Grid>
                                                                    <Grid item xs={4}>
                                                                        <Stack direction={"row"}>
                                                                            <Typography>Price:</Typography>
                                                                            <Typography className='formSubTitle' paddingLeft={1}>
                                                                                ${selected.estimation}
                                                                            </Typography>
                                                                        </Stack>
                                                                    </Grid></>
                                                                : <></>}
                                                        </Grid>
                                                    </Grid>
                                                </>
                                                : null}
                                        </Box>
                                    </Grid>
                                    {/* {selected !==undefined ?
                                <>
                                <Grid item xs={12}>
                                <Box marginLeft={12}>
                                <Grid container spacing={2}>
                                            
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                        <Typography sx={{ marginTop: 1 }}>Trade:</Typography>
                                        <Typography className='formSubTitle'>
                                            {selected.trade} 
                                            </Typography>
                                        <Typography sx={{ marginTop: 1 }}>Material Type:</Typography>
                                        <Typography className='formSubTitle'>
                                            {selected.materialType} - {selected.manufacturer}
                                            </Typography>

                                        <Typography className='formSubTitle'>
                                            {selected.stories} Storey Building - {selected.measurement}.
                                            </Typography>

                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <Typography sx={{ marginTop: 1 }}>Start Date:</Typography>
                                            <Typography className='formSubTitle'> 
                                            {dayjs(selected.jobStartdate).format("DD MMM YYYY")}
                                            </Typography>
                                            <Typography sx={{ marginTop: 1 }}>Price:</Typography>
                                            <Typography className='formSubTitle'> 
                                            ${selected.estimation}
                                            </Typography>

                                    </Grid>
                                    </Grid>  
                                    </Box>
                                    </Grid>  
                                    </> : <></>} */}
                                    {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                    <Typography sx={{ marginTop: 3, marginBottom: 1, fontSize: "17px", color: "rgb(60 60 60 / 87%)" }}>How do you want your estimates to be generated?</Typography>
                                    <FormControl>
                                        
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel value="female" style={{ color: 'rgb(60, 60, 60, 0.87)' }} control={<Radio />} label="By Time" />
                                            <FormControlLabel value="male" style={{ color: 'rgb(60, 60, 60, 0.87)' }} control={<Radio />} label="By Quality" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            <GanttDialog open={open} selected={tasks} function={handleClose} />
            {/* <Grid item xs={12}>
            {show?
                <Card variant="outlined" className='estimateCard' sx={{ marginTop: 2,minHeight:500 }}>
                    <GanttChart bid={selected}/>
                </Card>
                :null}
            </Grid> */}
        </>
    );
}


export default Estimate;