import { executeGetData, executePostImage, executePutImage } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const saveAssetDocument = async (imageFile: any, assetId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/assetDocument/upload/assetId/${assetId}`;
  return await executePostImage(url, imageFile, accessToken);
};


export const ImportImage = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/assetDocument/image`;
  return await executeGetData(url, accessToken);
};

export const editAssetDocument = async( payload:any, id:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/assetDocument/editAssetDocument/id/${id}`;
  return await executePutImage(url, payload, accessToken);
}

