import { executeGetData, executePostData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const getProjectConfirmationByprojectId = async (projectId: number,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/projectConfirmations/getOtp/projectId/${projectId}`;
    return await executeGetData(url,accessToken);
  };
  export const getProjectConfirmationByprojectIdandCode = async (projectId: number,confirmationCode:string,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/projectConfirmations/salesPersonVerification/projectId/${projectId}/confirmationCode/${confirmationCode}`;
    const data={

    }
    return await executePostData(url,data,accessToken);
  };