/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1495           |  25-03-2024    |     Nandha        | Gps next 2 task only one nexttask  is open other one is not open issue
* 1300           |  26-03-2024    |   shiyamkumar     | GPs Task Crew And Foreman Rendering Issue
* 1300           |  18-04-2024    |   shiyamkumar     | Gps Task ClockIn ClockOut Issue Fix
* 1729           |  29/4/24       |   Vanitha         | Gps Task Delay Related ui  changes
* 1300           |  06-05-2024    |   shiyamkumar     | Gps Task ClockIn ClockOut Issue Fix
* 2211           |  07-06-2024    |   shiyamkumar     | Task Rescheduling Api Removed
*/

import * as React from 'react';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Fab, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Paper, Radio, RadioGroup, Stack, styled, TextField, Tooltip, Typography } from "@mui/material";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useState, useEffect, useRef, useContext } from "react";
import { getCurrentTask, getMyActiveProjectTasks, getMyTask } from '../../services/TaskService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import TurnRightIcon from '@mui/icons-material/TurnRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useStopwatch } from "react-timer-hook";
import "../gps/Gps.scss"
import dayjs from 'dayjs';
import { breakIn, clockIn, getTimelogById } from '../../services/TimelogService';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import WarningIcon from '@mui/icons-material/Warning';
import { getUserSession, UserContext } from '../../hooks/UserSession';
import { TaskContext } from "../../hooks/UserSession";
import { taskReschedule } from '../../services/ProjectService';
import AddTaskForm from '../task/AddTaskForm';
import AddIcon from '@mui/icons-material/Add';



const libraries: any = ['places'];
interface GPSProps {
    onDataReady?: (status: string | undefined, taskName: string | undefined, bidId: any | undefined) => void;
    onData?: (coordinates: any, projectId: any) => void;
};


const GPS: React.FC<GPSProps> = ({ onDataReady, onData }) => {

    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [taskList, setTaskList] = useState([] as any[]);
    const [data, setData] = useState<any>();
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);
    const [toShow, setToShow] = useState(true);
    const [toShows, setToShows] = useState(true);
    const [endseconds, setEndSeconds] = useState<any>();
    const [startseconds, setStartSeconds] = useState<any>();
    const [timelogId, setTimelogId] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [promptData, setPromptData] = useState<any>();
    const { handleCompleted } = React.useContext(TaskContext);
    const [userRole, setUserRole] = React.useState<any>();
    const [completed, setCompleted] = React.useState<any>(false);
    const [completedUnits, setCompletedUnits] = React.useState<any>("0");
    const [completedPer, setCompletedPer] = React.useState<any>();
    const confirmationState = "confirmation";
    const SalespersonRole = "sales";
    const PurchaseOfficerRole = "purchaseOfficer";
    const active = "active";
    const interval = "break";
    const scheduled = "scheduled";
    const taskName = 'OTP Generation For Confirmation';
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const { userSessionDetails } = useContext(UserContext);
    // console.log(buttonClicked);

    const handleClicked = () => {
        setShow(false);
    };

    const handleOpen = () => {
        setHide(true);
    };

    const handleOpened = () => {
        setShow(true);
    };


    const handleClosed = () => {
        setHide(false);
    };
    const clickAddManual = () => {
        setOpenDialogs(true);
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setToShows(false);
        setOpen(false);
        setCompletedUnits('');
        setCompleted(false);
    };

    const containerStyle = {
        height: '200px'
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    });

    const [map, setMap] = useState(null);

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const onLoad = useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, []);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, []);


    const {
        seconds,
        minutes,
        hours,
        isRunning,
        start,
        pause,
        reset
    } = useStopwatch({ autoStart: false });

    let stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 300);

    const hourTime: any = hours < 10 ? `0${hours}` : `${hours}`;
    const minuteTime: any = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondTime: any = seconds < 10 ? `0${seconds}` : `${seconds}`;

    const [timelogData, setTimelogData] = useState<any>({
        startTime: undefined,
        endTime: undefined,
        taskUid: undefined,
        status: undefined,
        resourceId: undefined,
        id: undefined,
        taskId: undefined,
        resourceScheduleId: undefined,
        timeLogId: undefined,
        completedPercent: undefined
    });

    const clockData = useRef<any>({
        startTime: undefined,
        endTime: undefined,
        taskUid: undefined,
        status: undefined,
        resourceId: undefined,
        id: undefined,
        taskId: undefined,
        resourceScheduleId: undefined,
        timeLogId: undefined,
        completedPercent: undefined
    });

    const complete = {
        endTime: endTime,
        taskUid: taskList[0]?.taskUid,
        status: "completed",
        resourceId: taskList[0]?.resourceId,
        taskId: taskList[0]?.taskId,
        resourceScheduleId: taskList[0]?.scheduleId,
        timeLogId: timelogId,
        completedPercent: completedPer
    };

    const convertDecimalToTime = (decimalHours) => {
        const hours = Math.floor(decimalHours);
        const minutesDecimal = (decimalHours - hours) * 60;
        const minutes = Math.floor(minutesDecimal);
        const seconds = Math.floor((minutesDecimal - minutes) * 60);
        const endTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        //convertTimeToDecimal(hours, minutes, seconds);
        convertTimeToSeconds(endTime);
        return endTime;
    };

    let runTime: any;
    // let endseconds: any ;

    // let startseconds: number ;
    const convertTimeToSeconds = (endTime: any) => {

        var startTime = endTime.split(":");

        const hrs = parseInt(startTime[0]);
        const min = parseInt(startTime[1]);
        const sec = parseInt(startTime[2]);

        // Calculate the total seconds
        setEndSeconds(hrs * 3600 + min * 60 + sec);
        setStartSeconds(parseInt(hourTime) * 3600 + parseInt(minuteTime) * 60 + parseInt(secondTime));

        const totalseconds = (endseconds - startseconds);

        const currentSec = (totalseconds % 60);
        const currentMin = Math.floor((totalseconds % 3600) / 60);
        const currentHr = Math.floor(totalseconds / 3600);

        const totalSec = currentSec < 10 ? `0${currentSec}` : `${currentSec}`;
        const totalMin = currentMin < 10 ? `0${currentMin}` : `${currentMin}`;
        const totalHr = currentHr < 10 ? `0${currentHr}` : `${currentHr}`;

        runTime = `${String(totalHr).padStart(2, '0')}:${String(totalMin).padStart(2, '0')}:${String(totalSec).padStart(2, '0')}`

        return runTime;
    };

    // var estimated = (taskList[0]?.task.estimatedHours).split(":");
    const percentage = (startseconds / endseconds) * 100;

    const dateFormatter = (value: any) => {
        const date = new Date(value);
        const month = date.getMonth().toString();
        return date.getFullYear() + "-" + (month.length === 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length === 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T"
            + ((date.getHours()).toString().length === 1 ? "0" + (date.getHours()).toString() : (date.getHours()).toString()) + ":" + ((date.getMinutes()).toString().length === 1 ? "0" + (date.getMinutes()).toString() : (date.getMinutes()).toString()) + ":00.000Z";
    }

    // const dateComparator = async () =>{
    //     if(taskList[0]?.startTime !== undefined){
    //     console.log(new Date(new Date().setSeconds(0)) !== new Date((taskList[0]?.startTime).replace(/Z/g, '')))

    //     if(new Date(new Date().setSeconds(0)) !== new Date((taskList[0]?.startTime).replace(/Z/g, ''))){
    //         //stringdate = new Date(date).toISOString();
    //         console.log(dateFormatter(new Date(new Date().setSeconds(0))));
    //         let tasks = taskList;
    //         tasks[0].startTime = dateFormatter(new Date(new Date().setSeconds(0)));
    //         tasks[0].endTime = dateFormatter(new Date(new Date().setSeconds(0)).setHours(new Date(new Date().setSeconds(0)).getHours() + tasks[0].estimatedHours));
    //         setTaskList(tasks);
    //         return await taskReschedule(clockData.current.projectId, new Date().toISOString(), accessToken);
    //     }

    //     return undefined;
    // }
    //     // console.log(taskList[0].startTime);
    // }

    const timerOn = async () => {
        // console.log("clockin");
        setButtonClicked(prev => !prev); 
        if (clockData.current.status === scheduled) {
            setToShow(false);
            setStartTime(new Date().toISOString());
            setTimelogData({ ...timelogData, startTime: new Date().toISOString() })
            clockData.current.startTime = new Date().toISOString();
            // const result = await dateComparator();
            // if (new Date(new Date().setSeconds(0)) !== new Date((taskList[0]?.startTime).replace(/Z/g, ''))) {
                //stringdate = new Date(date).toISOString();
                let tasks = taskList;
                // console.log("before schedule",tasks);
                // tasks[0].startTime = dateFormatter(new Date(new Date().setSeconds(0)));
                // tasks[0].endTime = dateFormatter(new Date(new Date().setSeconds(0)).setHours(new Date(new Date().setSeconds(0)).getHours() + tasks[0].estimatedHours));
                // setTaskList(tasks);
                const wbs = (clockData.current.wbs).split(".")
                // console.log(wbs);
                wbs.pop();
                // console.log("after pop",wbs);
                // console.log("after join",wbs.join("."));
                let formattime = dateFormatter(new Date());

                // const result = await taskReschedule(clockData.current.projectId, clockData.current.taskId, wbs.join("."), dateFormatter(new Date()), accessToken);
                // console.log("reschedule",result);
                var newTasks:any;

                if (SalespersonRole === userRole) {
                    newTasks = await getMyTask(accessToken);
                }
                else if (PurchaseOfficerRole === userRole) {
                    newTasks = await getMyTask(accessToken);
                } else {
                    newTasks = await getMyActiveProjectTasks(accessToken);
                }
                
                console.log(" after NEW TASKS",newTasks);
                clockData.current.taskUid = newTasks.data[0].taskUid;
                // console.log(formattime);
                clockData.current.status = newTasks.data[0].scheduleStatus;
                clockData.current.resourceId = newTasks.data[0].resourceId;
                clockData.current.id = newTasks.data[0].id;
                clockData.current.taskId = newTasks.data[0].taskId;
                clockData.current.resourceScheduleId = newTasks.data[0].scheduleId;
                clockData.current.wbs = newTasks.data[0].wbs;
                // console.log("clockdata",clockData)
                // if((result.status === 200 || result.status === 201) && result.data === "Successful"){
                const response = await clockIn(clockData.current, accessToken);
                setData(taskList[0]);
                if (response.status === 201 && response.data !== null) {
                    setToShows(false);
                    clockData.current.timeLogId = response.data
                    clockData.current.status = "active"
                    reset();
                    setToShow(false);
                    setToShows(false);
                    // setDuration(percentage)
                    setHide(false);
                    setTimelogId(response.data);
                    setTimelogData({ ...timelogData, timelogId: response.data });

                    var response1: any;
                    if (SalespersonRole === userRole) {
                        response1 = await getMyTask(accessToken);
                    }
                    else if (PurchaseOfficerRole === userRole) {
                        response1 = await getMyTask(accessToken);
                    } else {
                        response1 = await getMyActiveProjectTasks(accessToken);
                    }
                    if (response1.data !== undefined || null) {
                        setTaskList(response1.data);
                        setTimelogData({
                            ...timelogData,
                            taskUid: response1.data[0].taskUid,
                            status: response1.data[0].scheduleStatus,
                            resourceId: response1.data[0].resourceId,
                            Id: response1.data[0].id,
                            taskId: response1.data[0].taskId,
                            resourceScheduleId: response1.data[0].scheduleId,
                            projectId: response1.data[0].projectId,
                            completedPercent: undefined
                        })
                        if (response1.data[0]?.scheduleStatus === active) {
                            const res = await getTimelogById(response1.data[0].scheduleId, accessToken);
                            setData(res?.data);
                        }
                        convertDecimalToTime(taskList[0]?.estimatedHours)
                        const jobNo = taskList[0].jobNo;
                        if (response1.data[0].taskname === taskName) {
                            onDataReady?.(active, taskName, jobNo);
                        }

                    }
                // }
                // else if(response.status === 201){
                //     start();
                //     setToShow(false);
                //     setToShows(false);
                //     // setDuration(percentage)
                //     setTimelogId(response.data);
                //     setHide(false);

                // }
                // }
                setToShows(false);
            }
            // else{
            //     alert(response?.data?.msg);
            // }
        } else {

            const responses = await breakIn(clockData.current.timeLogId,accessToken);
            if (responses.status === 200 || responses.status === 201) {
                setToShow(false);
                setToShows(false);
                setHide(false);
            }
            const res = await getTimelogById(clockData.current.resourceScheduleId, accessToken);
            const text = res?.data?.breakTimes;
            const myArray = text.replace(/[\[\]']+/g, '').replace(/},{/g, "};{").split(";");

            // var breakTimes = 0
            // for (let i = 0; i < myArray.length - 1; i++) {
            //     breakTimes += new Date(JSON.parse(myArray[i]).endTime).getTime() - new Date(JSON.parse(myArray[i]).startTime).getTime();
            // }

            var diff = new Date(JSON.parse(myArray[myArray.length - 1]).startTime).getTime() - new Date(res?.data?.startTime).getTime();
            var diffSeconds = diff / 1000 % 60;
            var diffMinutes = diff / (60 * 1000) % 60;
            var diffHours = diff / (60 * 60 * 1000) % 60;
            const date = new Date();
            date.setSeconds(date.getSeconds() + diffSeconds);
            date.setMinutes(date.getMinutes() + diffMinutes);
            date.setHours(date.getHours() + diffHours);
            reset(date)
        }
      
        setButtonClicked(prev => !prev); 
    };

    const handleSubmit = async () => {
        setOpen(false);
        clockData.current.endTime = new Date().toISOString();
        clockData.current.status = "completed";
        setEndTime(new Date().toISOString());
        const response = await clockIn(clockData.current, accessToken);
        // console.log("clock i response",response);
        if (response.status === 201 && response.data !== null) {
            var responses: any;
            if (SalespersonRole === userRole) {
                responses = await getMyTask(accessToken);
            }
            else if (PurchaseOfficerRole === userRole) {
                responses = await getMyTask(accessToken);
            } else {
                responses = await getMyActiveProjectTasks(accessToken);
            }
            // console.log("HANDLER",responses);
            if (responses.data.length !== 0) {
                // reset();
                // pause();
                const tasks = responses.data;
                setTaskList(tasks);
                clockData.current.taskUid = responses.data[0].taskUid;
                clockData.current.status = responses.data[0].scheduleStatus;
                clockData.current.resourceId = responses.data[0].resourceId;
                clockData.current.id = responses.data[0].id;
                clockData.current.taskId = responses.data[0].taskId;
                clockData.current.resourceScheduleId = responses.data[0].scheduleId;
                clockData.current.wbs = responses.data[0].wbs;
                clockData.current.timeLogId = undefined;
                convertEstimateHrsToTimes(0, 0);
                timerOn();
            } else {
                setTaskList([]);
            }
            // reset();
            // pause();
            // setToShow(true);
        } else {
            setToShows(false);
        }
        setCompletedUnits('');
    };

    const timerOff = async () => {
       
        // if (data?.timelog?.status === "break") {
        let responses = await breakIn(timelogId, accessToken);
        if (responses.status === 200 || responses.status === 201) {
             setToShow(true);
        setToShows(true);
            pause();
          

            setHide(false);
        }

    };

    const handleAddTaskDialog = () =>{
        setOpenDialogs(false);
        fetchData();
    }

    const timerStop = async () => {
        setButtonClicked(prev => !prev); 
        setToShow(false);
        setToShows(true);
        handleCompleted();
        const response = await getMyTask(accessToken);
        // if (taskList[0].queries !== undefined && taskList[0].queries !== "null" && (response.data[0].projectStatus === "Salesperson Approval" || response.data[0].projectStatus === "Salesperson Visit")) {
        //     var object: Object = String(taskList[0].queries).toString()
        //     const obj = JSON.parse(taskList[0].queries);
        //     Object.keys(obj).forEach((key) => {
        //         console.log(key);
        //         if (userRole === key) {
        //             setPromptData(obj[key]);
        //             setOpen(true);
        //         }
        //     })
        //     // setCurrentUser();
        //     // setOpen(true);
        // }
        // else {
        if (taskList[0].queries !== undefined && taskList[0].queries !== "null" && taskList[0].taskType !== confirmationState && taskList[0].queries !== null) {
            var object: Object = String(taskList[0].queries).toString()
            const obj = JSON.parse(taskList[0].queries);
            Object.keys(obj).forEach((key) => {
                if (userRole === key) {
                    setPromptData(obj[key]);
                    setOpen(true);
                }
            })
            // setCurrentUser();
            // setOpen(true);
        }
        else {
            handleSubmit();
        }
        setButtonClicked(prev => !prev); 
        // }
    };


    // const getCurrentTasks = async () => {
    //     let  response = await getCurrentTask(accessToken);
    //     console.log(response)
    // }

    const fetchData = async () => {
        // const res = await getUserSession(accessToken);
        setUserRole(userSessionDetails?.role.name);
        var response: any;
        if (SalespersonRole === userSessionDetails?.role.name) {
            response = await getMyTask(accessToken);
        }
        else if (PurchaseOfficerRole === userSessionDetails?.role.name) {
            response = await getMyTask(accessToken);
        } else {
            response = await getMyActiveProjectTasks(accessToken);
        }
        if (response?.data?.length !== 0) {
            clockData.current.taskUid = response.data[0].taskUid;
            clockData.current.status = response.data[0].scheduleStatus;
            clockData.current.resourceId = response.data[0].resourceId;
            clockData.current.id = response.data[0].id;
            clockData.current.taskId = response.data[0].taskId;
            clockData.current.projectId = response.data[0].projectId;
            clockData.current.resourceScheduleId = response.data[0].scheduleId;
            clockData.current.wbs = response.data[0].wbs;
            setTaskList(response.data);
            onData?.(response.data[0]?.location, response.data[0]?.projectId);
            if (response.data[0]?.scheduleStatus === active) {
                const res = await getTimelogById(response.data[0].scheduleId, accessToken);
                if (response.data[0].taskname === taskName) {
                    onDataReady?.(active, taskName, response.data[0]?.jobNo);
                }
                clockData.current.timeLogId = res?.data?.id
                if (res?.data?.status === interval) {
                    setToShow(true);
                    //setToShows(true);
                    let text = res?.data?.breakTimes;
                    const myArray = text.replace(/[\[\]']+/g, '').replace(/},{/g, "};{").split(";");

                    var breakTimes = 0
                    for (let i = 0; i < myArray.length - 1; i++) {
                        breakTimes += new Date(JSON.parse(myArray[i]).endTime).getTime() - new Date(JSON.parse(myArray[i]).startTime).getTime();
                    }
                    var diff = new Date(JSON.parse(myArray[myArray.length - 1]).startTime).getTime() - new Date(res?.data?.startTime).getTime();
                    var diffSeconds = diff / 1000 % 60;
                    var diffMinutes = diff / (60 * 1000) % 60;
                    var diffHours = diff / (60 * 60 * 1000) % 60;
                    const date = new Date();
                    date.setSeconds(date.getSeconds() + diffSeconds);
                    date.setMinutes(date.getMinutes() + diffMinutes);
                    date.setHours(date.getHours() + diffHours);
                    reset(date)
                    pause()
                    stopwatchOffset = date
                } else {
                    // stopwatchOffset = new Date();
                    // stopwatchOffset.setSeconds(stopwatchOffset.getSeconds());
                    var diff = new Date().getTime() - new Date(res?.data?.startTime).getTime();
                    var diffSeconds = diff / 1000 % 60;
                    var diffMinutes = diff / (60 * 1000) % 60;
                    var diffHours = diff / (60 * 60 * 1000) % 60;
                    const date = new Date();
                    date.setSeconds(date.getSeconds() + diffSeconds);
                    date.setMinutes(date.getMinutes() + diffMinutes);
                    date.setHours(date.getHours() + diffHours);
                    reset(date)
                    setToShow(false);
                    setToShows(false);
                    setTimelogId(res?.data?.id);
                    if (response.status === 201 && taskList[0]?.taskType === confirmationState) {
                        const jobNo = taskList[0].jobNo;
                        if (taskList[0]?.taskname === taskName) {
                            onDataReady?.(active, taskName, jobNo);

                        }
                        setData(res?.data);
                    }
                }
            }
            convertDecimalToTime(taskList[0]?.estimatedHours)
        }
    }

    useEffect(() => {
        // getCurrentTasks();
        fetchData();
    }, [buttonClicked]);



    function convertEstimateHrsToTimes(estimatedSecondsss, currentSecondsss) {
        const remainingSeconds = Math.max(estimatedSecondsss - currentSecondsss);
        const remainingHours = Math.floor(remainingSeconds / 3600);
        const remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);
        return (
            <span>
                <span>{remainingHours < 0 ? "00" : remainingHours < 10 ? '0' + remainingHours : remainingHours}</span><span className={!toShow ? 'timeProgress' : 'timeSeprator'}>:</span>
                <span>{remainingHours < 0 ? "00" : remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes}</span>
            </span>
        );
    };

    // Example usage:


    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    };

    function per(num: any, amount: any) {
        return num * amount / 100;
    };

    const handleInput = (e: any) => {
        setCompletedPer(per(e.target.value, taskList[0].measurement));



        const enteredValue = parseFloat(e.target.value);
        if (enteredValue >= 0) {
            setCompletedUnits(e.target.value);

        };



        if (e.target.value !== '') {
            setCompleted(true);
        }
        else {
            setCompleted(false);
        }
    };

    //forumula for runtime
    const totalSeconds = (taskList[0]?.estimatedHours) * 3600;
    const clampedSeconds = secondTime % 60;
    const currentseconds = (hourTime * 3600) + (minuteTime * 60) + clampedSeconds;
    const percent = (currentseconds / totalSeconds) * 100;

    return (
        <>
            <Grid container direction='column' >
                <Grid item md={12}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <AccessTimeFilledIcon sx={{ color: "#3c3c3cde" }} />
                        <Typography variant="h4" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>GPS</Typography>

                        <Grid container item textAlign={"end"}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>
                                    {dayjs().format("DD MMM YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>
                                    {dayjs().format("HH:mm A")}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>




            </Grid>
            <Box mt={2} />

            <Grid item  >

                {/* <Weathercard/> */}
            </Grid>

            <Box mt={2} />
            {/* <Grid item minHeight={200}>
                <Card>

                    {isLoaded && taskList.length !== 0 ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={convertLocation(taskList[0]?.location)}
                            zoom={50}
                            onUnmount={onUnmount}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            <MarkerF
                                position={convertLocation(taskList[0]?.location)}

                            />
                            <></>
                        </GoogleMap>
                    ) : <></>}
                </Card>
            </Grid> */}

            {taskList.length === 0 ?

                <Box justifyContent={'center'} textAlign={'center'} >
                    <Card className='timeclockcard p-4'>
                        <Stack direction={"row"} spacing={1}>

                            <TurnRightIcon sx={{ fontSize: "40px" }} />
                            <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> No Task Available</Typography>
                        </Stack>
                    </Card>
                </Box>
                :
                <>
                    <Grid item mt={1}>

                        <Card className='timeclockcard p-4'>



                            <Grid xs={12}>
                                <Stack direction={'row'} justifyContent="space-between">
                                    <Stack direction={"row"} sx={{ justifyContent: 'start', marginBottom: '1vh' }}>
                                        <TurnRightIcon sx={{ fontSize: "40px", marginRight: '1vh', flexShrink: 0, fontWeight: 'bold' }} />
                                        <Typography variant='h4' sx={{ flexShrink: 0, fontWeight: 'bold', color: "white", marginTop: "6px !important" }} textAlign={"start"}> Active Task</Typography>
                                    </Stack>
                                    <Tooltip title="Add Task">
                                        <Fab color="primary" size="small" aria-label="add" onClick={clickAddManual}>
                                            <AddIcon />

                                        </Fab>
                                    </Tooltip>

                                </Stack>
                            </Grid>
                            {percent >= 100 && (

                                <Grid xs={12} sx={{ display: 'flex', marginLeft: '2vh', justifyContent: 'flex-end', marginBottom: '1vh' }}>


                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '6px',
                                            overflow: 'hidden',

                                            height: '3vh',
                                            fontSize: { xs: '0.75rem', sm: '0.775rem', md: '.7rem' },
                                            marginTop: '1vh'

                                        }}
                                    >

                                        <WarningIcon className='timeProgress ' style={{ fontSize: '.7rem' }} />
                                        <Typography variant="body2" className='timeDelay' sx={{ flexShrink: 0, fontWeight: 'bold', fontSize: 'inherit', }}>
                                            Task delayed
                                        </Typography>
                                    </Box></Grid>
                            )}




                            <Typography className='taskhead'>{taskList[0]?.taskname}</Typography>
                            <br />
                            <div style={{ flexDirection: "row", position: "relative", alignItems: "center" }}>
                                <LinearProgress
                                    variant="determinate"
                                    color={percent >= 100 ? "error" : "inherit"}
                                    sx={{ width: "100%", borderRadius: "4px", backgroundColor: percent === 100 ? "red" : "" }}
                                    value={Math.min(percent, 100)}
                                />
                                <img
                                    src={require("../../assets/images/carIconTimeLog.png")}
                                    alt=""
                                    className="progressbar"
                                    style={{
                                        position: "absolute",
                                        left: `${Math.min(percent, 94)}%`,
                                        bottom: -6,
                                        transform: "translateX(-50%)",
                                        marginLeft: "11px",

                                    }}
                                />
                            </div>
                            <Grid container direction="row" mt={1}>
                                <Grid item xs={6} >
                                    <Stack direction="row">
                                        <span>{hourTime}</span><Stack className={!toShow ? 'timeProgress' : 'timeSeprator'}>:</Stack><span>{minuteTime}</span>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} className='alignEnd'>
                                    {convertEstimateHrsToTimes(totalSeconds, currentseconds)}
                                </Grid>
                            </Grid>
                            <Typography fontSize={16}></Typography>
                            <Grid container direction='row' mt={2}>
                                {/* <Grid xs={percent >= 100 ? 12 : 12}> */}
                                <Grid container direction='row' alignItems='center'>



                                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>

                                            <Typography fontSize={16}>
                                                Estimated Time
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction="row" >
                                                <Typography

                                                    sx={{
                                                        textAlign: "end",
                                                        flexShrink: 0, fontWeight: 'bold',
                                                        textOverflow: 'ellipsis',
                                                        marginLeft: '5px'
                                                    }}>
                                                    {taskList[0]?.estimatedHours} Hrs
                                                </Typography>

                                            </Stack>

                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={16}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }} paddingLeft={2}>{dayjs((taskList[0]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={16}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }} paddingLeft={2}>{dayjs((taskList[0]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container direction='row' mt={1}>
                                <Grid xs={12}>
                                    <Typography variant='h5' sx={{ marginBottom: '1vh', flexShrink: 0, fontWeight: 'bold', color: "white", marginTop: "6px !important" }} textAlign={"start"}>Project Name</Typography>

                                </Grid>


                                <Grid> <Stack spacing={1}>
                                    <Typography>{taskList[0]?.trade}&nbsp;{taskList[0]?.materialType}&nbsp;{taskList[0]?.manufacturer}</Typography>

                                    <Stack direction='row'>
                                        <Typography >Order No:<p style={{ flexShrink: 0, fontWeight: 'bold' }}></p> </Typography>
                                        <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }}>{taskList[0]?.jobNo}
                                        </Typography>
                                    </Stack>
                                </Stack></Grid>
                            </Grid>
                            <br />
                            <Grid container >
                                <Grid item md={12} sx={{ textAlign: "center" }} >
                                    <Stack spacing={2} direction="row"  >
                                        {toShow ? <Button
                                            className='sucessButtons'
                                            onClick={timerOn}
                                            startIcon={<QueryBuilderIcon />}
                                            disabled={!toShow}

                                        >
                                            Clock In
                                        </Button>
                                            :
                                            <Button className='dangerButtons' onClick={timerOff} startIcon={<QueryBuilderIcon />} >Clock Out</Button>}
                                        {toShows ?
                                            <Button className='disabledTonalButtons' disabled startIcon={<HourglassDisabledIcon />}>Complete Task</Button> :
                                            <Button className="primaryTonalButtons" onClick={timerStop} startIcon={<HourglassDisabledIcon />}>Complete Task</Button>}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {show ? <Grid item mt={1}>
                        <Card className='timeclockcard p-4'>
                            <Grid container >
                                <Grid item md={6} >
                                    <Stack direction={"row"} spacing={1}>

                                        <TurnRightIcon sx={{ fontSize: "40px" }} />
                                        <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> Next Task</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Typography className='taskhead'>{taskList[1]?.taskname}</Typography>
                            <Grid container direction='row' mt={2}>
                                <Grid xs={12}>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Estimated Time </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{taskList[1]?.estimatedHours} Hrs</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[1]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={14}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[1]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid>
                                <Grid xs={5} textAlign={"center"}> */}



                                </Grid>
                            </Grid>
                            <Grid container direction='row' mt={1} >
                                <Stack spacing={1}>
                                    <Typography>{taskList[1]?.trade}&nbsp;{taskList[1]?.materialType}&nbsp;{taskList[1]?.manufacturer}</Typography>
                                    <Typography>Order No: {taskList[1]?.jobNo}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item className='alignEnd'>
                                <Button onClick={handleClicked} sx={{ color: "white" }}><KeyboardArrowUpIcon /></Button>
                            </Grid>
                        </Card>
                    </Grid> : taskList.length >= 2 ?
                        <Grid item md={12} textAlign={"center"}>
                            <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={handleOpened}>Next Task<KeyboardArrowDownIcon /></Button>
                        </Grid> : <></>}

                    {hide ? <Grid item mt={1}>
                        <Card className='timeclockcard p-4'>
                            <Grid container >
                                <Grid item md={6} >
                                    <Stack direction={"row"} spacing={1}>

                                        <TurnRightIcon sx={{ fontSize: "40px" }} />
                                        <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> Next Task</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Typography className='taskhead'>{taskList[2]?.taskname}</Typography>
                            <Grid container direction='row' mt={2}>
                                <Grid xs={12}>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Estimated Time </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{taskList[2]?.estimatedHours} Hrs</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[2]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={14}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[2]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid>
                                <Grid xs={5} textAlign={"center"}> */}



                                </Grid>
                            </Grid>
                            <Grid container direction='row' mt={1}>
                                <Stack spacing={1}>
                                    <Typography>{taskList[2]?.trade}&nbsp;{taskList[2]?.materialType}&nbsp;{taskList[2]?.manufacturer}</Typography>
                                    <Typography>Order No: {taskList[2]?.jobNo}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item className='alignEnd'>
                                <Button onClick={handleClosed} sx={{ color: "white" }}><KeyboardArrowUpIcon /></Button>
                            </Grid>
                        </Card>
                    </Grid>
                        : taskList.length === 3 ?
                            <Grid item md={12} textAlign={"center"}>
                                <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={handleOpen}>Next Task<KeyboardArrowDownIcon /></Button>
                            </Grid> : <></>}
                    {/* {taskList.length !== 0 ?
                        <Grid item md={12} textAlign={"center"}>
                            <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={clickAddManual}>
                                Add Task
                            </Button> */}
                            {openDialogs && <AddTaskForm open={openDialogs} onClose={handleAddTaskDialog} projectId={taskList[0]?.projectId} id={taskList[0]?.taskId}/>}
                        {/* </Grid> :
                        <></>
                    } */}
                </>
            }
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
            >
                <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            {promptData}
                        </Typography>
                        {userRole === "labour" &&
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="outlined-number"
                                        label="No.of Units"
                                        type="number"
                                        value={completedUnits}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleInput(e)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>}
                        {userRole === "foreman" &&
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6} textAlign={'center'}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onClick={() => setCompleted(true)}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>}
                        {userRole === "sales" && <Typography textAlign={'center'}>
                            You won't be able to complete this task!
                        </Typography>}
                    </DialogContent>
                </Box>
                {userRole !== "sales" ? <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleClose} className="dangerButton">Close</Button>
                    <Button disabled={!completed} onClick={handleSubmit} autoFocus className={completed === true ? "primaryTonalButton" : ""}>
                        Submit
                    </Button>
                </DialogActions> :
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={handleClose} autoFocus className="primaryTonalButton">
                            OK
                        </Button>
                    </DialogActions>}
            </Dialog>
        </>
    );
}

export default GPS;
