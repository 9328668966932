import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import { CardContent } from '@mui/material';
import MultiStepForm from './StepperForm';
import VerticalStepper from './Vertical Stepper';
import StepperImageEffect from './StepperImageEffect';
import SwipeableText from './StepperImageEffect';
import Grid from '@mui/material/Grid/Grid';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import StepperIcon from './StepperIcon';
import DotStepper from './StepperDot';
import './Stepper.scss';

const Steppers: React.FC = () => {
  const steps = [
    ' personal information',
    ' Choose your preferences',

    ' Review and submit'
  ];
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
       <h4>Horizontal Stepper </h4>
      <Card>
        <CardContent>      
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>

              <Button onClick={handleReset} color='error'>Reset</Button>

            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>

              <Button
                color="secondary"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Button onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>

            </React.Fragment>
          )}

        </CardContent>
      </Card>
      <br />
      <br />
      <h4> Stepper With Register Form(alternativeLabel)</h4>
      <Card>
        <CardContent  sx={{ textAlign: 'center' }}>
        
          <MultiStepForm />
        </CardContent>
      </Card>
      <br />
      <br />
      <h4>Vertical Stepper</h4>
      <Card>
        <CardContent sx={{ textAlign: 'center' }}>  
          <VerticalStepper />
        </CardContent>
      </Card>
      <br />
      <br />
      <h4>Image Swibeable Stepper </h4>
      <Card>
        <CardContent sx={{ textAlign: 'center' }}>
         
          <SwipeableText />
        </CardContent>
      </Card>
      <br />
      <br />
      <h4>Horizontal Stepper With Icon</h4>
      <Card>
        <CardContent sx={{ textAlign: 'center' }}>
        <StepperIcon/>
        </CardContent>
      </Card>
      <br />
      <br />
      <h4>Dot Variant Stepper</h4>
      <Card>
        <CardContent>
        <DotStepper/>
        </CardContent>
      </Card>


    </div>
  )
}

export default Steppers;

