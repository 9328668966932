/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Form For Add And Edit Trade
* 1622           |  16/04/24   | shiyam kumar  |  Implement a Form For Upload Cvs File
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change

*/


import * as React from 'react';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CustomDialog from '../../../pages/Dialog';
import { getTemplatesByTradeId } from '../../../services/TradeTemplateService';
import { CircularProgress, IconButton, Paper} from '@mui/material';
import { getTrades } from '../../../services/TradeConfigurationService';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { addBomTemplate, getBomTemplates } from '../../../services/BomTemplateService';

const BomTemplateForm: React.FC<any> = (props) => {

    const templateModelStyle = {
        width: '60%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        maxHeight: '70vh', // Set the maximum height for scrolling
        overflow: 'hidden', // Enable vertical scrolling
    };

    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [materialNames, setMaterialNames] = React.useState<any>([]);
    const [manufacturerNames, setManufacturerNames] = React.useState<any>([]);
    const [validated, setValidated] = React.useState<any>(false);
    const [selectedTrade, setSelectedTrade] = React.useState<any>('');
    const [selectedMaterial, setSelectedMaterial] = React.useState<any>('');
    const [selectedManufacturer, setSelectedManufacturer] = React.useState<any>('');
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [templateTypes, setTemplateTypes] = React.useState<any>(["local", "with SubContractor"]);
    const [templateData, setTemplateData] = React.useState<any>({
        tradeId: props?.selectedTemplate?.trade?.id,
        templateType: props?.selectedTemplate?.templateType,
        description: props?.selectedTemplate?.description,
        tasks: props?.selectedTemplate?.tasks
    });
    const { accessToken } = useOidcAccessToken();
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [addSuccess, setAddSuccess] = React.useState(false);
    const [addError, setAddError] = React.useState(false);
    const [dragOver, setDragOver] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [fileName, setFileName] = React.useState<string | null>(null);
    const [tradeId, setTradeId] = React.useState<any>();
    const [tradeTemplateId, setTradeTemplateId] = React.useState<any>();
    const [selectedType, setSelectedType] = React.useState<any>('');
    const [csvfile, setCSVFile] = React.useState<any>({});

    const handleDragOver = React.useCallback((event: React.DragEvent) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = React.useCallback((event: React.DragEvent) => {
        event.preventDefault();
        setDragOver(false);
    }, []);


    const handleDrop = React.useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFileName(file.name);
        setCSVFile(file);
        setLoading(false);
        //onCSVFileupload(file);
    };

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            const selectedFile = event.target.files?.[0];
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    // const AddBomTemplate = async () => {
    //     if (selectedTrade !== '' || selectedMaterial !== '' || selectedManufacturer !== '' || selectedType !== '') {
    //         setOpenAddDialog(true);
    //         let formData = new FormData();
    //         formData.append('file', csvfile);
    //         const res = await addBomTemplate(tradeTemplateId, formData, accessToken);
    //         if ((res.status === 201 || res.status === 200) && res.data === "successful") {
    //             setAddSuccess(true);
    //         } else {
    //             setAddError(true);
    //         }
    //     } else {
    //         setValidated(true);
    //     }
    // }

    const AddBomTemplate = async () => {
        if (selectedTrade !== '' && selectedMaterial !== '' && selectedManufacturer !== '' && selectedType !== '') {
            setOpenAddDialog(true);
            let formData = new FormData();          
            formData.append('file', csvfile);
            try {
                const res = await addBomTemplate(tradeTemplateId, selectedManufacturer, formData, accessToken);
                
                if (res.status === 201 || res.status === 200 && res.data === "successful") {
                    var result = await getBomTemplates(accessToken);               
                    setAddSuccess(true);
                } else {
                    setAddError(true);
                }
            } catch (error) {           
                setAddError(true);
            }
        } else {
            setValidated(true);
        }
    }   
   

    const closeTemplate = () => {
        setValidated(false);
        setSelectedTrade(undefined);
        setSelectedMaterial(undefined);
        setSelectedManufacturer(undefined);
        setTemplateData(undefined);
        props.function();
    }

    const handleTradeSelect = (event: any) => {
        setSelectedTrade(event.target.value);
        let materials: any = [];
        var array = tradeData.filter(data => data.name === event.target.value);
        array.map((data) => {
            materials.push(data.materialType)
        })
        setManufacturerNames([]);
        setSelectedMaterial('');
        setSelectedManufacturer('');
        setSelectedType('');
        setMaterialNames(Array.from(new Set(materials)));
    }


    const handleMaterialSelect = (event: any) => {
        setSelectedMaterial(event.target.value);
        let manufacturers: any = [];
        var array = tradeData.filter(data => data.name === selectedTrade && data.materialType === event.target.value);
        array.map((data) => {
            setManufacturerNames(data.manufacturer);
        })
        setSelectedManufacturer('');
        setSelectedType('');
        
    }

    const handleManufacturerSelect = (event: any) => {
        setSelectedManufacturer(event.target.value);
        let materials: any = [];
        var array = tradeData.filter(data => data.name === selectedTrade && data.materialType === selectedMaterial && data.manufacturer.includes(event.target.value));
        setTemplateData({ ...templateData, tradeId: array[0].id, templateType: "" });
        //setTemplateTypes(["local", "with SubContractor"]);
        setTradeId(array[0].id);
        setSelectedType('');
        // setTemplateTypes([]);
        // setTemplateTypes(["local", "with SubContractor"]);
    }

    const closeDialog = () => {
        closeTemplate();
        setOpenAddDialog(false);
        setAddSuccess(false);
        setAddError(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);

    }

    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        console.log(result)
        setTradeData(result.data);
        result.data.map((data: any) => {
            tradenames.push(data.name)
        })
        setTradeNames(Array.from(new Set(tradenames)));

    }

    const selectTamplate = async (e: any) => {
        const result = await getTemplatesByTradeId(tradeId, accessToken);
        var array = result.data.filter(data => data.templateType === e.target.value);
        setTradeTemplateId(array[0].id);
        setSelectedType(e.target.value);
        //setTemplateData({ ...templateData, templateType: e.target.value });
    }

    React.useEffect(() => {
        if (props.selectedTemplate !== undefined) {
            setTemplateData({
                tradeId: props?.selectedTemplate?.trade?.id,
                templateType: props?.selectedTemplate?.templateType,
                description: props?.selectedTemplate?.description,
                tasks: props?.selectedTemplate?.tasks
            });
            setSelectedType(props.selectedTemplate?.templateType);
        }
        getTradeList();
    }, []);

    return (
        <><Modal
            open={props.open}
            onClose={closeTemplate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={templateModelStyle}>
                <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={6} p={0}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" p={1}>
                            Bom Template
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ pt: 4, pl: 0, mt: 5 }}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={12}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        {props.selectedTemplate?.trade?.name === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedTrade === '' && validated}>
                                                <InputLabel id="demo-select-small-label">Select Trade</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='trade'
                                                    label="Select Trade"
                                                    value={selectedTrade ? selectedTrade : ""}
                                                    onChange={handleTradeSelect}
                                                >
                                                    {tradeNames.map((trade: any) => (
                                                        <MenuItem value={trade}>{trade}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='trade' size="small" value={props.selectedTemplate?.trade?.name} disabled />}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {props.selectedTemplate?.trade?.materialType === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedMaterial === '' && validated}>
                                                <InputLabel id="demo-select-small-label">Select MaterialType</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='materialType'
                                                    label="Select MaterialType"
                                                    value={selectedMaterial ? selectedMaterial : ""}
                                                    onChange={handleMaterialSelect}
                                                >
                                                    {materialNames.map((name: any) => (
                                                        <MenuItem value={name}>{name}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='materialType' size="small" value={props.selectedTemplate?.trade?.materialType} disabled />}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {props.selectedTemplate?.trade?.manufacturer === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedManufacturer === '' && validated}>
                                                <InputLabel id="demo-select-small-label">Select Manufacturer</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='manufacturer'
                                                    label="Select Manufacturer"
                                                    value={selectedManufacturer ? selectedManufacturer : ""}
                                                    onChange={handleManufacturerSelect}
                                                >
                                                    {manufacturerNames.map((name: any) => (
                                                        <MenuItem value={name}>{name}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='manufacturer' size="small" value={props.selectedTemplate?.trade?.manufacturer} disabled />}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth size="small" required error={(templateData?.templateType === undefined && props?.selectedTemplate?.templateType === undefined) && validated}>
                                            <InputLabel id="demo-select-small-label">Select Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='templateType'
                                                label="Select Type"
                                                value={selectedType ? selectedType : ''}
                                                onChange={(e) => selectTamplate(e)}
                                            >
                                                {templateTypes.map((type: any) => (
                                                    <MenuItem value={type}>{type}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Paper
                                                variant="outlined"
                                                onDragOver={handleDragOver}
                                                onDragLeave={handleDragLeave}
                                                onDrop={handleDrop}
                                                style={{
                                                    border: dragOver ? '2px dashed #000' : (fileName === null && validated) ? '2px dashed red' : '2px dashed #aaa',
                                                    padding: 20,
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    background: dragOver ? '#eee' : '#fafafa',
                                                    position: 'relative',
                                                }}
                                            >
                                                <input
                                                    accept=".csv"
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    type="file"
                                                    onChange={handleChange}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Box display="flex" flexDirection="column" alignItems="center">
                                                        <IconButton color="primary" aria-label="upload csv" component="span">
                                                            <CloudUploadIcon style={{ fontSize: 60 }} />
                                                        </IconButton>
                                                        <Typography>Upload CSV File</Typography>
                                                        {fileName && <Typography color={"blue"}>File Name: {fileName}</Typography>}
                                                        <Typography>Drag and drop CSV file here or click to select</Typography>
                                                    </Box>
                                                </label>
                                                <Typography>Template file .csv only</Typography>

                                                {loading && (
                                                    <CircularProgress
                                                        size={24}
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Paper>
                                        </Box>
                                    </Grid>
                                    <Grid container justifyContent="flex-end">
                                        <Box m={2} className="poFooter">
                                            <Button
                                                sx={{ marginRight: "32px" }}
                                                className={`ErrorTonalButton`}
                                                variant="contained"
                                                size="medium"
                                                color="error"
                                                onClick={closeTemplate}
                                            >
                                                Close
                                            </Button>
                                            {props.selected === undefined ?
                                                <Button
                                                    className="button"
                                                    variant="contained"
                                                    size="medium"
                                                    onClick={AddBomTemplate}
                                                >
                                                    Save
                                                </Button> :
                                                <Button
                                                    className="button"
                                                    variant="contained"
                                                    size="medium"
                                                //onClick={handleUpdateTrade}
                                                >
                                                    Update
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </Box>
        </Modal>
            <CustomDialog
                open={openAddDialog}
                onClose={closeDialog}
                success={addSuccess}
                error={addError}
                Content={addSuccess ? "Template Added Successfully" : "Failed To Add"} />
            <CustomDialog
                open={openUpdateDialog}
                onClose={closeDialog}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Template Updated Successfully" : "Failed To Update"} />
        </>
    );
}

export default BomTemplateForm;