import { ComponentType } from 'react';
import { Container, Typography, CssBaseline } from '@mui/material';

const pageStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh', // Make sure the container stretches to the full height of the viewport  
};

const containerStyles: React.CSSProperties = {
  marginTop: '8rem',
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
  backgroundColor: 'white', // Container background color
};

const Loading: ComponentType<any> = ({ configurationName }) => (
<div style={pageStyles}>
<CssBaseline />
<Container component="main" maxWidth="xs" style={containerStyles}>
<Typography variant="h6" gutterBottom>
      {/* Loading for {configurationName}
       */}
       Loading...
</Typography>    
</Container>
</div>
 
  // <span className="oidc-loading" >
  //   Loading for {configurationName}
  // </span>
);

export default Loading;