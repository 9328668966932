/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 21/06/24 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  24/06/24   | Aish       | Admin Time sheet view based on Date filteration
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import { Grid, InputAdornment, Stack, TablePagination, TextField, ToggleButton, ToggleButtonGroup, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Data } from '@react-google-maps/api';
import timezone from 'dayjs/plugin/timezone';
import { getAllTimeSheetsByDate, getTimeSheetCountByDate, searchAllTimeSheetByDate, searchAllTimeSheetByName } from '../../../services/TimelogService';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import './TimeSheet.scss'
import UpdateTimeSheet from './UpdateTimeSheet';
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
    price: number,
) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            {
                date: '2020-01-05',
                customerId: '11091700',
                amount: 3,
            },
            {
                date: '2020-01-02',
                customerId: 'Anonymous',
                amount: 1,
            },
        ],
    };
} function Row(props: any) {
    const { date, rowData, tableHead } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow onClick={() => setOpen(!open)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpen(!open);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{date}</TableCell>
                {/* Render other properties as needed */}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={tableHead.length}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody>
                                    {rowData.map((row: any, index: number) => (
                                        <TableRow key={index}>
                                            {tableHead.map((header: string, idx: number) => (
                                                <TableCell key={idx}>{row}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];
const AdminTimeSheets = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const ROLES = "customer"
    // const [order, setOrder] = React.useState<Order>('asc');
    // const [orderBy, setOrderBy] = React.useState<keyof Data>('name');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [dense, setDense] = React.useState(false);
    const EMPLOYEETIME = "Awaiting Approval";
    const ADMINAPPROVE = 'Approve';
    const ADMINREJECT = 'Reject';
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [rows, setRows] = React.useState<any>([]);
    // const [count, setCount] = React.useState(Number);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [vendorId, setVendorId] = React.useState<any>();
    const [open, setOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const VENDORACTIVESTATUS = "Active";
    const VENDORINACTIVESTATUS = "Inactive";
    const [searchParams, setSearchParams] = React.useState('null');
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [deleteSuccess, setDeleteSuccess] = React.useState(false);
    const [deleteError, setDeleteError] = React.useState(false);
    const [showAnotherComponent, setShowAnotherComponent] = React.useState(false);
    const [rowData, setRowData] = React.useState();
    // const [alignment, setAlignment] = React.useState('month');
    const [loading, setLoading] = React.useState(false);
    const alignmentRef = React.useRef<string>('day');
    const startDateRef = React.useRef<any>(null);
    const endDateRef = React.useRef<any>(null);
    const countRef = React.useRef<any>(Number);
    const pageRef = React.useRef<any>(0);
    const rowsPerPageRef = React.useRef<any>(5);


    const handleEditCustomer = (row: any) => {
        setRowData(row)
        setShowAnotherComponent(true);
    };
    // const handleRequestSort = (
    //   event: React.MouseEvent<unknown>,
    //   property: keyof Data,
    // ) => {
    //   const isAsc = orderBy === property && order === 'asc';
    //   setOrder(isAsc ? 'desc' : 'asc');
    //   setOrderBy(property);
    // };
    const [selectedRow, setSelectedRow] = useState(null);
    const [openRow, setOpenRow] = React.useState<string | null>(null);
    const handleRowClick = (rowIndex) => {
        setSelectedRow(selectedRow === rowIndex ? null : rowIndex);
        setOpenRow(openRow === rowIndex ? null : rowIndex);

    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const closeConfirmation = () => {
        setShowAnotherComponent(false);
    }


    const handleDialogClose = () => {
        setOpenDialog(false);
    };


    // const handleChangePage = (event: unknown, newPage: number) => {
    //   pageRef.current=newPage;
    // };


    const handleChangePage = async (event: unknown, newPage: number) => {
        //const result = await getProjectBycustomerUId(accessTokenPayload.oid, newPage, rowsPerPage, accessToken);
        //console.log(result.data)
        //setProjects(result.data);
        // const result = await getCustomerDetails(ROLES, page, rowsPerPage, accessToken);
        // setRows(result.data);
        pageRef.current = newPage;
        if (alignmentRef.current === 'day') {
            Day();
        }
        else if (alignmentRef.current === 'week') {
            Week();
        }
        else {
            Month();
        }
    };
    const updateSetCount = (data: any) => {
        countRef.current = data; // Update setCount with res.data or any other data
    };
    const updateState = (newRowsPerPage, newPage) => {
        rowsPerPageRef.current = newRowsPerPage;
        pageRef.current = newPage;
    };

    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        const newRowsPerPage = parseInt(event.target.value);
        const newPage = Math.floor(pageRef.current * rowsPerPageRef.current / newRowsPerPage);
        pageRef.current = newPage;
        rowsPerPageRef.current = newRowsPerPage;
        // Update state variables using a functional update
        // updateState(newRowsPerPage, newPage);

        // Assuming Day(), Week(), and Month() are functions to update the view
        if (alignmentRef.current === 'day') {
            Day();
        } else if (alignmentRef.current === 'week') {
            Week();
        } else {
            Month();
        }
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setLoading(true)
        console.log(newAlignment)
        alignmentRef.current = newAlignment;
        if (newAlignment === "day") {
            Day();
        }
        else if (newAlignment === "week") {
            Week();
        }
        else {
            Month();
        }
    };

    const dateFormatter = (value: any, isEndOfDay: boolean = false) => {
        const date = new Date(value);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        let formattedDate = `${date.getFullYear()}-${month}-${day}`;

        if (isEndOfDay) {
            formattedDate += "T23:59:59.999Z";
        } else {
            formattedDate += "T00:00:00.000Z";
        }

        return formattedDate;
    };
    const fetchProject = async () => {  
        // Define your local timezone
        const localTimezone = dayjs.tz.guess();
        // Start of the week (Monday) in local timezone
        const startOfWeek = dateFormatter(dayjs().startOf('month').tz(localTimezone));
        // End of the week (Sunday) in local timezone
        const endOfWeek = dateFormatter(dayjs().endOf('month').tz(localTimezone));
        try {
            const res = await getTimeSheetCountByDate(accessToken, startOfWeek, endOfWeek);
            countRef.current = res.data
            const response = await getAllTimeSheetsByDate(accessToken, startOfWeek, endOfWeek, pageRef.current, rowsPerPageRef.current);
            setRows(response.data);
            // setCount(response.data.length)
            console.log(response)
        } catch (error: any) {

        } finally {

        }
    };


    React.useEffect(() => {
        fetchProject();
    }, []);


    const dateFormatterDetails = (value: any) => {
        const date = new Date(value);
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const formattedDate = date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
        const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
        return formattedDate + " " + formattedTime;
    }
    const handleDateChange = (newValue, name) => {
        alignmentRef.current = 'day';
        console.log(name);
        const eventLikeObject = {
            target: {
                name,
                value: newValue,
            },
        };

        console.log(eventLikeObject);
        if (eventLikeObject.target.name === "startDate") {
            const formattedStartDate = dateFormatter(newValue);
            startDateRef.current = formattedStartDate;

            // setAlignment("day");
            console.log(dateFormatter(newValue));
        } else {
            console.log(dateFormatter(newValue, true));
            let enndDate = dateFormatter(newValue, true);
            console.log(enndDate);
            // setEndDate(enndDate);
        }
        //   console.log(startDate)
        // Ensure alignment state is updated before calling the respective function
        setTimeout(() => {
            if (alignmentRef.current === 'day') {
                Day();
            } else if (alignmentRef.current === 'week') {
                Week();
            } else if (alignmentRef.current === 'month') {
                Month();
            }
        }, 0);
    };

    const Month = async () => {
        console.log("month", pageRef.current, rowsPerPageRef.current)
        setLoading(false)
        const localTimezone = dayjs.tz.guess();
        let  startOfMonth;
        let  endOfMonth;
        // Start and end of the month in local timezone
        if (startDateRef.current) {

            const inputDate = startDateRef.current ? dayjs(startDateRef.current) : dayjs();

            startOfMonth = dateFormatter(inputDate.startOf('month').tz(localTimezone));
            endOfMonth = dateFormatter(inputDate.endOf('month').tz(localTimezone), true);
            console.log(endOfMonth)
            // setStartDate(startOfWeek);
            // setEndDate(endOfWeek);
            startDateRef.current = startOfMonth;
            endDateRef.current = endOfMonth;
        } else {
      startOfMonth = dateFormatter(dayjs().startOf('month').tz(localTimezone));
      endOfMonth= dateFormatter(dayjs().endOf('month').tz(localTimezone), true);
        }
        console.log("Start of Month:", startOfMonth);
        console.log("End of Month:", endOfMonth);
        const res = await getTimeSheetCountByDate(accessToken, startOfMonth, endOfMonth);
        countRef.current = res.data;
        const response = await getAllTimeSheetsByDate(accessToken, startOfMonth, endOfMonth, pageRef.current, rowsPerPageRef.current)
        // setCount(response.data.length)

        if (response.data) {
            setLoading(false); // Set loading to false when data is fetched
            setRows(response.data);
            console.log(response.data)
            // setCount(response.data.length)
        }

    };
    const Day = async () => {
        setLoading(true); // Set loading to false when data is fetched

        // Start of the current day at 12:00 AM local time
        const localTimezone = dayjs.tz.guess();
        let startOfDay;
        let endOfDay
        if (startDateRef.current) {
            console.log(startOfDay, endOfDay)

            const inputDate = startDateRef.current ? dayjs(startDateRef.current) : dayjs();

            startOfDay = dateFormatter(inputDate.startOf('day').tz(localTimezone));
            endOfDay = dateFormatter(inputDate.endOf('day').tz(localTimezone), true);
            console.log(endOfDay)
            // setStartDate(startOfWeek);
            // setEndDate(endOfWeek);
            startDateRef.current = startOfDay;
            endDateRef.current = endOfDay;
        } else {
            // Start and end of the day in local timezone
            startOfDay = dateFormatter(dayjs().startOf('day').tz(localTimezone));
            endOfDay = dateFormatter(dayjs().endOf('day').tz(localTimezone), true);
        }
        const res = await getTimeSheetCountByDate(accessToken, startOfDay, endOfDay);
        countRef.current = res.data;
        const response = await getAllTimeSheetsByDate(accessToken, startOfDay, endOfDay, pageRef.current, rowsPerPageRef.current)
        if (response.data) {
            setLoading(false); // Set loading to false when data is fetched
            setRows(response.data);
            // setCount(response.data.length)

        }


        console.log(startOfDay, endOfDay);
    };
    const timeFormatterDetails = (value: any) => {
        const date = new Date(value);
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        const formattedDate = date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
        const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
        return formattedTime;
    }
    const handleTextChange = async (event: any) => {
        console.log(event.target.value)
        // setValue(event.target.value);
        if (event.target.value?.length >= 1) {
            // if(role ==='customer') {
            const localTimezone = dayjs.tz.guess();

            // Start and end of the month in local timezone
            const startOfMonth = dateFormatter(dayjs().startOf('month').tz(localTimezone));
            const endOfMonth = dateFormatter(dayjs().endOf('month').tz(localTimezone), true);
            console.log("Start of Month:", startOfMonth);
            console.log("End of Month:", endOfMonth);
            //   searchAllTimeSheetByName(accessToken,startOfMonth,endOfMonth,page,rowsPerPage,event.target.value).then((res)=>{
            //     console.log(res)
            //     setRows(res.data)
            //   })

            searchAllTimeSheetByDate(accessToken, startOfMonth, endOfMonth, pageRef.current, rowsPerPageRef.current, event.target.value).then((res) => {
                console.log(res)
                setRows(res.data)
            })
            // }
            // else {
            // const response1 = await getSearchBySales(accessTokenPayload.oid,statuses, event.target.value, accessToken);
            // console.log(response1.data);
            // setData(response1.data);
            // }
        }
        else {
            fetchProject();
        }
    }
    const Week = async () => {
        setLoading(true); // Set loading to false when data is fetched

        // Define your local timezone
        const localTimezone = dayjs.tz.guess();

        let  startOfWeek;
        let  endOfWeek;
        // Start and end of the month in local timezone
        if (startDateRef.current) {

            const inputDate = startDateRef.current ? dayjs(startDateRef.current) : dayjs();

            startOfWeek = dateFormatter(inputDate.startOf('week').tz(localTimezone));
            endOfWeek = dateFormatter(inputDate.endOf('week').tz(localTimezone), true);
            console.log(endOfWeek)
            // setStartDate(startOfWeek);
            // setEndDate(endOfWeek);
            startDateRef.current = startOfWeek;
            endDateRef.current = endOfWeek;
        } else {
            startOfWeek = dateFormatter(dayjs().startOf('week').tz(localTimezone));
      endOfWeek= dateFormatter(dayjs().endOf('week').tz(localTimezone), true);
        }
        const res = await getTimeSheetCountByDate(accessToken, startOfWeek, endOfWeek);
        console.log(res)
        countRef.current = res.data;
        const response = await getAllTimeSheetsByDate(accessToken, startOfWeek, endOfWeek, pageRef.current, rowsPerPageRef.current);
        if (response.data) {
            setLoading(false); // Set loading to false when data is fetched
            setRows(response.data);
            // setCount(response.data.length)

        } console.log(startOfWeek, endOfWeek);
    }

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    <div className='searchBar'>
                        <Grid container alignItems={'center'} rowSpacing={1} mt={1}>
                            <Grid item xs={4}>
                                {/* <Paper
                    variant="outlined"
                    component="form"
                    className='searchField'
                    sx={{ p: "2px 4px", display: "flex", alignItems: "center", maxWidth: 300 }}
                  > */}
                                {/* <Grid item xs={6} >
                      <Autocomplete
                        size='small'
                        disablePortal
                        id="combo-box-demo"
                        options={rows}
                        getOptionLabel={(option: any) => `${option.trade}${option.customerName}`} // Customize as needed
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Project Name" />}
                        
                      />     </Grid> */}

                                    <TextField
                                        label="Search by task,employee,project,customer"
                                        size='small'
                                        name="materialName"
                                        onChange={(e) => handleTextChange(e)}
                                        // value={value}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <br />
                                    <br />
                            </Grid>
                            <Grid item xs={4} alignSelf={'center'} sx={{ padding: '15px', paddingLeft: 10}}>
                                    <Stack direction="row" spacing={1} >
                                        {(startDateRef.current !== null && endDateRef.current !== null) &&
                                        <>
                                        <Stack className='timeSheetStart'>
                                                <Typography variant='h6'>{dayjs((startDateRef.current)?.replace(/Z/g, '')).format("YYYY-MMM-DD HH:mm")}</Typography>
                                        </Stack>
                                        <Stack>-</Stack>
                                        <Stack className='timeSheetEnd'>
                                            <Typography variant='h6'>{dayjs((endDateRef.current)?.replace(/Z/g, '')).format("YYYY-MMM-DD HH:mm")}</Typography>
                                        </Stack>
                                        </>
                                        }
                                    </Stack>
                            </Grid> 
                            <Grid item xs={2} className='alignEnd' sx={{ padding: '15px' }}>    
                                        <LocalizationProvider dateAdapter={AdapterDateFns}  >
                                            <DatePicker
                                                label="Start Date"
                                                // value={value}
                                                name="startDate"
                                                onChange={(e) => {
                                                    handleDateChange(e, "startDate");
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        // fullWidth: true,
                                                        size: 'small',
                                                    }
                                                }}
                                            // renderInput={(params) => <TextField {...params} fullWidth />}
                                            />
                                        </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2} className='alignEnd' sx={{ padding: '15px' }}> 
                                <ToggleButtonGroup
                                    color="primary"
                                    value={alignmentRef.current}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="day">Day</ToggleButton>
                                    <ToggleButton value="week">Week</ToggleButton>
                                    <ToggleButton value="month">Month</ToggleButton>
                                </ToggleButtonGroup>
                                &nbsp;    &nbsp;
                                {/* <Button
                      variant="contained" size="small"
                      onClick={Day}
                    >
                   &nbsp; This day
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained" size="small"
                      onClick={Week}
                    >
                   &nbsp; This week
                   </Button>
                   &nbsp;
                    <Button
                      variant="contained" size="small"
                      onClick={Month}
                    >
                   &nbsp; This month
                    </Button>
                    &nbsp; */}
                                {/* <Button
                    className='success'
                      variant="contained" size="large"
                      onClick={handleOpenConfirmation}
                    >
                   &nbsp; Book Leave
                    </Button> */}
                            </Grid>
                            {/* <Grid item xs={2}><Tooltip title="Print">
      <IconButton>
        <PrintIcon fontSize="medium" />
      </IconButton>
    </Tooltip>
      <Tooltip title="Share">
        <IconButton>
          <ShareIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Settings">
        <IconButton>
          <SettingsIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
    </Grid> */}
                        </Grid>
                    </div>
                    </Grid>

                    <Box sx={{ width: '100%', }}>
                        <Paper>
                            <TableContainer component={Paper} style={{ width: '100%', overflowX: 'auto' }}>
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead className='tableHead'>
                                        <TableRow    >
                                            <TableCell className='tableTimeSheet'>Name</TableCell>
                                            <TableCell className='tableTimeSheet'>Project Name</TableCell>
                                            <TableCell className='tableTimeSheet'>Task Name</TableCell>
                                            <TableCell className='tableTimeSheet'>Time In - Time Out </TableCell>
                                            <TableCell className='tableTimeSheet'>Duration </TableCell>
                                            <TableCell className='tableTimeSheet'>Location </TableCell>
                                            <TableCell className='tableTimeSheet'>Status </TableCell>
                                            <TableCell  >Action </TableCell>

                                            {/* Add more headers as needed */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(rows).length === 0 ? (<TableRow>
                                            <TableCell colSpan={10}>
                                                <Typography align="center">No Timesheet details</Typography>
                                            </TableCell>
                                        </TableRow>) :

                                            (<>
                                                {Object.entries(rows).map(([date, items]) => (
                                                    <React.Fragment key={date}>
                                                        <TableRow style={{ backgroundColor: 'rgb(191 162 128)', color: 'white' }} onClick={() => handleRowClick(date)}>
                                                            <TableCell className='collapsibleTable'>{date}   <IconButton style={{ backgroundColor: 'rgb(191 162 128)', color: 'white' }}
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOpen(!open);
                                                                    handleRowClick(date);
                                                                }}
                                                            >
                                                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                            </IconButton></TableCell>
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />
                                                            <TableCell />

                                                            {/* Add more empty cells as needed */}
                                                        </TableRow>
                                                        {openRow === date && (
                                                            <>
                                                                {(items as any[]).map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        <TableCell size='small' style={{ textAlign: 'center' }} >
                                                                            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                                                                                {/* <StyledBadge
                                  overlap="circular"
                                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                  <Avatar src={`${row.avatar}`} />
                                </StyledBadge>&nbsp; */}
                                                                                {row?.firstName}&nbsp;{row?.lastName}
                                                                            </Stack></TableCell>
                                                                        <TableCell size='small'>{row?.trade}<br />
                                                                            {row.customerName}<br />
                                                                            {(() => {
                                                                                const address = JSON.parse(row?.siteAddress);
                                                                                return `${address.address}`;
                                                                            })()}<br />
                                                                            {dateFormatterDetails(row?.projectStartDate)}</TableCell>
                                                                        <TableCell size='small'>{row?.taskName}</TableCell>
                                                                        <TableCell size="small">
                                                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                                                <Stack className='timeSheetStart'>{timeFormatterDetails(row?.startTime)}</Stack>
                                                                                <Stack>-</Stack>
                                                                                <Stack className='timeSheetEnd'>{dateFormatterDetails(row?.endTime)}</Stack>
                                                                            </Stack>
                                                                        </TableCell>                            <TableCell size='small'>{row?.timeSpent}</TableCell>

                                                                        <TableCell size='small'>  {(() => {
                                                                            const address = JSON.parse(row?.siteAddress);
                                                                            return `${address.address}, ${address.city}, ${address.state} ${address.zipcode}, ${address.country}`;
                                                                        })()}</TableCell>
                                                                        {row.timeLogStatus === EMPLOYEETIME && (<TableCell size='small' > <span className='statusAwaitingApproval'>{row.timeLogStatus}</span></TableCell>)}
                                                                        {row.timeLogStatus === ADMINAPPROVE && (<TableCell size='small' > <span className='salesApproval'>{row.timeLogStatus}</span></TableCell>)}
                                                                        {row.timeLogStatus === ADMINREJECT && (<TableCell size='small' > <span className='statusAwaitingApproval'>{row.timeLogStatus}</span></TableCell>)}

                                                                        {row.timeLogStatus === 'active' && (<TableCell size='small'> <span className=' activeProject'>{row.timeLogStatus}</span></TableCell>)}
                                                                        {row.timeLogStatus === 'break' && (<TableCell size='small'> <span >{row.timeLogStatus}</span></TableCell>)}
                                                                        {row.timeLogStatus === 'completed' && (<TableCell size='small'> <span className=' activeProject'>{row.timeLogStatus}</span></TableCell>)}
                                                                        <TableCell size='small'>
                                                                            <Stack direction={'row'}>
                                                                                {/* {(row.status === 'invited' || !row.status || row.status === 'Invited') && ( */}
                                                                                <Tooltip title="Edit">
                                                                                    <IconButton onClick={() => handleEditCustomer(row)} >
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                {/* )}
                                {row.status === 'Active' && (
                                  <Tooltip title="Edit">
                                    <IconButton onClick={() => onEdit(row.id)}>
                                      <RemoveRedEyeOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )} */}
                                                                                {/* <Tooltip title="Delete">

                                  <IconButton onClick={() => onDelete(row.id)}  >
                                    <DeleteRoundedIcon />
                                  </IconButton>
                                </Tooltip> */}
                                                                            </Stack>

                                                                        </TableCell>
                                                                    </TableRow>

                                                                ))}
                                                            </>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10]}
                                component="div"
                                count={countRef.current}
                                rowsPerPage={rowsPerPageRef.current}
                                page={pageRef.current}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /></Paper>
                    </Box>
            </Grid>
            {showAnotherComponent && (<UpdateTimeSheet open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} timeView={alignmentRef.current} rowDatas={rowData} setCount={updateSetCount} />)}
        </>
    );
}
export default AdminTimeSheets;