// Issue/WorkItem |    DATE    |     AUTHOR    |   DESCRIPTION OF CHANGE
// * -----------------------------------------------------------------------------
// *    1678     | 24-04-2024 | Nandha Kumar  | Digital transaction View Receipt
// *   1715     | 26-04-2024 | Nandha Kumar  | Digital transaction Receipt design implemenatation


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { getPaymentReceiveDetail } from '../../services/InvoiceServices';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const BlobData: React.FC<any> = ({ open, props, rows, onClose }) => {
  console.log(props?.data);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  // console.log("rows", rows);
// const [invoiceDetails, setInvoiceDetails] = React.useState<any>([]);
const [paymentDetails, setPaymentDetails] = React.useState<any>([]);

// let invoiceData=rows.invoiceDetails;
// console.log(invoiceData[0]?.status);
//  console.log("Invoice Details",invoiceDetails);
const fetchRows = async () => {
  // getUsers(accessToken)
  // .then(res => setUser(res.data))
  const data = await getPaymentReceiveDetail(rows.invoiceDetails[0].id, accessToken).then(response=>
      setPaymentDetails(response.data)

  );
  // setPaymentDetails(data);
  // console.log(data);
      // await setInvoiceDetails(rows.invoiceDetails);
      
      
      
  
};
React.useEffect(() => {

    fetchRows();
}, [open]);


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const closeDialog = () => {
    onClose();
  };
  return (
    <React.Fragment>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => onClose()}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
      // maxWidth={'sm'}
      >
        <DialogTitle id="responsive-dialog-title" className='paymentRptHead'>
          Payment Receipt   -         {   paymentDetails.payMethod}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
          {(paymentDetails.payMethod === "cash" || paymentDetails.payMethod === "cheque") && (
  <img src={props?.data} alt="Invoice Receipt" />
)}
            {   paymentDetails.payMethod === "Digital"&&       
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign={"center"}>
            <CheckCircleOutlineIcon sx={{color:"#0cce6b"}} fontSize='large' />
            <Typography fontWeight={"Bold"} fontSize={25} sx={{color:"#0cce6b"}}>Payment Successful!</Typography>
          </Grid>
          <Grid xs={12}  textAlign={"center"} marginLeft={2}>
          <Divider sx={{ borderColor: '#808080', borderWidth: '1px', borderStyle: 'dashed' }} />
          </Grid>
          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1" sx={{color:"#808080"}}>Customer Name:</Typography>
          </Grid>
          <Grid item xs={3} >
            <Typography  sx={{color:"#808080"}}>{rows.invoiceDetails[0]?.customerName}</Typography>
          </Grid>
          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1"  sx={{color:"#808080"}}>Invoice ID:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography  sx={{color:"#808080"}}>{rows.invoiceDetails[0]?.id}</Typography>
          </Grid>
          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1"  sx={{color:"#808080"}}>Invoice Number:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography  sx={{color:"#808080"}}>{rows.invoiceDetails[0]?.invoiceNo}</Typography>
          </Grid>

          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1"  sx={{color:"#808080"}}>Invoice Amount:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography  sx={{color:"#808080"}} >${rows.invoiceDetails[0]?.invoiceAmount}</Typography>
          </Grid>

          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1"  sx={{color:"#808080"}}>Project ID:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography  sx={{color:"#808080"}}>{rows.invoiceDetails[0]?.projectId}</Typography>
          </Grid>

          <Grid item xs={6} marginLeft={5} >
            <Typography variant="subtitle1"  sx={{color:"#808080"}}>Status:</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography  sx={{color:"#808080"}}>{rows.invoiceDetails[0]?.status}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => onClose()}>
            Ok
          </Button>
          {/* <Button onClick={()=>onClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default BlobData;