import React from "react";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import "gantt-task-react/dist/index.css";
import { Task, ViewMode } from "../components/react-gantt-component/types/public-types";
import { ButtonGroup, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, Menu, MenuItem, Select, Switch, Tooltip, Typography } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CustomTask, exportToExcel, exportToPdf } from "./ReactGantchartDataOptionsToolBar.tsx";
import { initTasks } from "./helper";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { padding } from "@mui/system";
type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
  updateTasks: (newTasks: Task[]) => void;
  selected: any[];
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  updateTasks,
  selected
}) => {
  const [tasks, setTasks] = React.useState<Task[]>(selected);
  console.log(tasks);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const options: any = [{ lable: 'Hour', mode: ViewMode.Hour }, { lable: 'Quarter of Day', mode: ViewMode.QuarterDay }, { lable: 'Half of Day', mode: ViewMode.HalfDay },
  { lable: 'Day', mode: ViewMode.Day }, { lable: 'Week', mode: ViewMode.Week }, { lable: 'Month', mode: ViewMode.Month }]

  const handleExpandAllClick = (tasks: CustomTask[], expand: boolean) => {

    const updatedTasks = tasks.map(task => {

      task.hideChildren = !expand;


      if (task.children) {
        task.children = handleExpandAllClick(task.children, expand);
      }

      return task;
    });


    updateTasks(updatedTasks);


    return updatedTasks;
  };


  const handleCollapseAllClick = (tasks: CustomTask[]) => {
    handleExpandAllClick(tasks, false);
  };

  return (
    <div className="ViewContainer">
      <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" padding="4px">

        <FormControl sx={{ m: 1, minWidth: 300 }} size="small">
          {/* <InputLabel id="demo-simple-select-label">Choose Project</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Roofing"
          >
            <MenuItem value={10}>Roofing</MenuItem>
            <MenuItem value={20}>project 2</MenuItem>
          </Select> */}
        </FormControl>


        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((option: any) => (
            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => { onViewModeChange(option.mode); handleClose() }}>
              {option.lable}
            </MenuItem>
          ))}
        </Menu>


        <Stack direction="row" spacing={2} alignItems="center">
          {/* <ButtonGroup  sx={{ marginLeft: "1px" }} > */}
            {/* <Button  className="sucessButton" startIcon={<KeyboardDoubleArrowDownIcon />}
              onClick={() => handleExpandAllClick(tasks, true)}>
              Expand All
            </Button>

            <Button
            className="primaryTonalButton"
              startIcon={<KeyboardDoubleArrowUpIcon />}
              onClick={() => handleCollapseAllClick(tasks)}
            >
              Collapse All
            </Button> */}
          {/* </ButtonGroup> */}

          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            className="primaryTonalButton"
          >
            View Filter
          </Button>



          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map((option: any) => (
              <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => { onViewModeChange(option.mode); handleClose() }}>
                {option.lable}
              </MenuItem>
            ))}
          </Menu>

          <Tooltip title="Download Pdf Format">
            <IconButton onClick={() => exportToPdf(tasks)} sx={{ color: "red" }}>
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download Excel Format">
            <IconButton onClick={() => exportToExcel(tasks)} sx={{ color: "blue" }}>
              <GetAppIcon />
            </IconButton>
          </Tooltip>

          <FormGroup>
            <FormControlLabel sx={{ color: "green" }} control={<Switch defaultChecked color="success" />} onClick={() => onViewListChange(!isChecked)} label="Show Task List" />
          </FormGroup>
        </Stack>

      </Stack>
    </div>

  );
};
