import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { Divider, TextField } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import { spacing } from '@mui/system';
import './TabThree.scss'


const TabThree: React.FC = () => {
    return (
        <>
            <div className="container">
                <div className="search-input">
                    <TextField
                        placeholder="Search by name"
                        size="small"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                <div className="buttons">
                    <Grid container spacing={2}>
                        <Grid>
                            <Button variant="contained" color="primary">
                                Button 1
                            </Button>
                        </Grid>
                        <Grid >
                            <Button variant="contained" color="primary">
                                Button 2
                            </Button>
                        </Grid>
                        <Grid>
                            <Button variant="contained" color="primary">
                                Button 3
                            </Button>
                        </Grid>
                        <Grid >
                            <Button variant="contained" color="primary">
                                Button 4
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <br /><br />
            <Grid container  >
                <Grid xs={4} md={11}>
                    <Button className='hi' variant="contained" color="error" size="small" >
                        Cancel
                    </Button>
                </Grid>
                <Grid xs={4} md={1}>
                    <Button variant="contained" color="success" size="small">
                        Save
                    </Button>
                </Grid>
            </Grid>

        </>
    );
}


export default TabThree;