import React, { useCallback, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import {  getUserSessionDetails } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { error } from 'console';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import VendorList from './VendorList';
import { importVendors } from '../../services/ImportService';
import CustomDialog from '../../pages/Dialog';

const VendorManagement: React.FC = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const [userSessionDetails, setUserDetails] = useState(getUserSessionDetails(accessToken));
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);

    const [openDialog, setOpenDialog] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [dataa, setFormData] = useState<any>();
    const [open, setOpen] = useState<boolean>(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleDialogOpen = () => {
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleChange = useCallback(
        (event) => {
            const files = event.target.files;
            if (files && files[0]) {
                handleFileChange(files[0]);
                // setFile(files[0]);
            }
        },
        []
    );

    let formData = new FormData();

    const handleFileChange = (file: File) => {
        setLoading(true);
        setFile(file);
        formData.append('file', file);
        console.log(file)
        setFormData(formData);
    };

    const importVendor = async () => {
        const res = await importVendors(dataa, accessToken);
        console.log(res);
        setOpenDialog(false);
        setOpen(true);
        if (res.ok === true) {
            setSuccess(true);

        } else {
            setError(true);
        }
    }

    const closeDialog = () => {
        setSuccess(false);
        setError(false);
        if (success) {
            handleDialogClose();
        }
    }

    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={9}>
                    <Grid item xs={12} className='alignEnd' sx={{ padding: '15px' }}><Button variant="contained" size="large" onClick={handleDialogOpen}>Import Vendor</Button></Grid>
                    <Grid item xs={12}><VendorList /></Grid>
                </Grid>
                {/* <Grid item xs={3}>
                <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && <Grid item xs={12}><GPS /></Grid>}
                    <Grid item xs={12} className='headerTable'>
                        <Stack direction="column" spacing={2}>
                            <InsertChartIcon />&nbsp; Completed Task Feed
                            <CompletedTaskFeed />
                        </Stack>
                    </Grid>
                    </Stack>
                  
                       
                    
                </Grid> */}
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Upload
                        </Typography>
                        <Typography textAlign={'center'}>
                            <form>
                                <input type="file" accept={".csv"} onChange={handleChange} />
                            </form>
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleDialogClose} className="dangerButton">Cancel</Button>
                    <Button
                        onClick={importVendor}
                        autoFocus className="primaryTonalButton">
                        Upload
                    </Button>
                </DialogActions>

            </Dialog>
            <CustomDialog
                open={open}
                onClose={closeDialog}
                success={success}
                error={error}
                Content={success ? "Imported  Successfully" : "Failed To Import"}
            />
        </>
    )
}

export default VendorManagement;