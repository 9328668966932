
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    FormControlLabel, Switch
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import './BillList.scss'
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { getBillList } from '../../../services/BillService';


interface Data {
    id: number;
    vendor: string;
    date: string;
    customer: string;
    billAmount: number;
    openBalance: number;
    status: string;
}

function createData(
    id: number,
    vendor: string,
    date: string,
    customer: string,
    billAmount: number,
    openBalance: number,
    status: string,
): Data {
    return {
        id,
        vendor,
        date,
        customer,
        billAmount,
        openBalance,
        status,
    };
}
const rows = [
    createData(1, 'Cupcake', "09/07/2000", '', 3.7, 67, "Due Later Due in 10 days"),
    createData(2, 'Donut', "09/07/2000", '', 25.0, 51, "Due Later Due in 10 days"),
    createData(3, 'Eclair', "09/07/2000", '', 16.0, 24, "Due Later Due in 10 days"),
    createData(4, 'Frozen yoghurt', "09/07/2000", '', 6.0, 24, "Due Later Due in 10 days"),
    createData(5, 'Gingerbread', "09/07/2000", '', 16.0, 49, "Due Later Due in 10 days"),
    createData(6, 'Honeycomb', "09/07/2000", '', 3.2, 87, "Due Later Due in 10 days"),
    createData(7, 'Ice cream sandwich', "09/07/2000", '', 9.0, 37, "Due Later Due in 10 days"),
    createData(8, 'Jelly Bean', "09/07/2000", '', 0.0, 94, "Due Later Due in 10 days"),
    createData(9, 'KitKat', "09/07/2000", '', 26.0, 65, "Due Later Due in 10 days"),
    createData(10, 'Lollipop', "09/07/2000", '', 0.2, 98, "Due Later Due in 10 days"),
    createData(11, 'Marshmallow', "09/07/2000", '', 0, 81, "Due Later Due in 10 days"),
    createData(12, 'Nougat', "09/07/2000", '', 19.0, 9, "Due Later Due in 10 days"),
    createData(13, 'Oreo', "09/07/2000", '', 18.0, 63, "Due Later Due in 10 days"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'vendor',
        numeric: false,
        disablePadding: true,
        label: 'VENDOR',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'DUE DATE',
    },
    {
        id: 'customer',
        numeric: true,
        disablePadding: false,
        label: 'CUSTOMER',
    },
    {
        id: 'billAmount',
        numeric: true,
        disablePadding: false,
        label: 'BILL AMOUNT',
    },
    {
        id: 'openBalance',
        numeric: true,
        disablePadding: false,
        label: 'OPEN BALANCE',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell 
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >ACTION</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const BillList: React.FC = () => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('vendor');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate();

    const [billList, setBillList] = React.useState([] as any[]);

    React.useEffect(() => {
        const fetData = async () => {
            const response = await getBillList();
            //console.log(response);
            setBillList(response);
        }
        fetData();
    }, [])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    const billForm = (rowData:any) => {
        navigate("/bill", { state: { rowData } });

      };
      const billAddForm=() => {
        navigate("/bill");

      };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const SearchBar = () => (
        <div className='searchBar'>
            <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                {/* <Grid item xs={12}>
          <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={10}></Grid>
            
          </Grid>
        </Grid> */}
                <Grid item xs={2} mt={2}>
                    <Select
                        className='filter'
                        fullWidth
                        value=""
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                    >
                        <MenuItem value="" disabled>
                            Filters
                        </MenuItem>
                        <MenuItem value="1">Option 1</MenuItem>
                        <MenuItem value="2">Option 2</MenuItem>
                        <MenuItem value="3">Option 3</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={3} mt={2}>
                    <TextField
                        className='billFilter'
                        id="outlined-basic"
                        // label="Outlined"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value="Bill date:&nbsp; Last 12 months"
                        sx={{
                            borderRadius: '10px', // Adjust the value to set the desired border radius
                            border: 'none',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    // Add more props as needed
                    />
                </Grid>
                <Grid item xs={1}>  </Grid>
                <Grid item xs={4}>  <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}><Grid item xs={6}></Grid><Grid item xs={6} mt={2}>  <Box>
                    <Button
                        variant="contained" size="large"
                        onClick={billAddForm}
                    >
                        Add Bill
                    </Button>
                </Box>
                </Grid></Grid></Grid>
                <Grid item xs={2} className='createInvoice'>
                    <Grid item xs={12} mt={2}>
                        <Tooltip title="Print">
                            <IconButton>
                                <PrintIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                            <IconButton>
                                <ShareIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            <SearchBar />
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />
                                    <TableBody>
                                        {billList.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow

                                                    hover
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    selected={isItemSelected}
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <CustomCheckbox
                                                            className='check'
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        // className='invoiceRows'
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        {row.vendorName}
                                                    </TableCell>
                                                    <TableCell >{row.dueDate}</TableCell>
                                                    <TableCell >{row.customerName}</TableCell>
                                                    <TableCell >${row.amount}</TableCell>   
                                                    <TableCell ></TableCell>
                                                    <TableCell >
                                                        {row.terms}
                                                    </TableCell>
                                                    <TableCell >
                                                    <div onClick={() => billForm(row)}>
                                                        
                                                        edit/view
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={billList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </>

    );
}


export default BillList;