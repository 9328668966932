import { createContext } from "react";
import { getUserRolePermissions } from "../services/UserService";
import {pagesPermission} from '../data/PagePermission'

export type UserModeContext = {
    userSessionDetails: any,
    setUserDetails: (userSessionDetails: any) => void
  
 }

 export type TaskContextType = {
  isTaskCompleted: boolean;
 handleCompleted: () => void;
};

export const TaskContext = createContext<TaskContextType>({
  isTaskCompleted: false,
  handleCompleted: () => {},
});


export const UserContext = createContext<UserModeContext>({
    userSessionDetails: getUserSessionDetails(),
    setUserDetails: () => { },
  
});

var userSession:any;
export async function getUserSessionDetails(accessToken?: any) {
    var userSessionDetails: any;
    if (accessToken != null && accessToken.length != 0) {
        const response = await getUserRolePermissions(accessToken);
        userSessionDetails = response.data;
        userSession=response.data;
        // console.log(userSession)
        // console.log(response)
    }
    return userSessionDetails;
}
export function isUserHasPermission(permission: string): boolean {
  // console.log(userSession)
  if (userSession) {
    const rolePermissions = userSession?.role?.userRolePermissions;
    // console.log("rolePermissions",rolePermissions);
    // console.log("typeof rolePermission",typeof rolePermissions);
    for (const pagePermissions of pagesPermission) {
      const pagePermissionKeys = Object.keys(pagePermissions);
      if (pagePermissionKeys.includes(permission)) {
        // console.log(`Permission ${permission} exists in pagePermissions with value: ${pagePermissions[permission]}`);
        var userData:any;
        for (userData in rolePermissions) {
          // console.log("rolePermissionsUserdata",rolePermissions[userData]);          
          const userPermission = rolePermissions[userData].permission; // Assuming permissions are comma-separated strings
          // console.log("rolePermissionsdata",userPermission);

          // console.log(pagePermissions[permission]);
          // console.log(pagePermissions[permission].includes(userPermission));
          //for (const perm of userPermission) {
            if (pagePermissions[permission].includes(userPermission)) {
              // console.log(`User has the permission ${userPermission}`);
              // console.log(userPermission.trim())
              return true; // User has the permission
            }
         // }
        }
      } else {
        console.log('Dashboard does not exist in pagesPermission');
      }
    }
  }
  // console.log('User session is undefined or permission not found');
  return false; // User doesn't have the permission or userSession is undefined
}
export async function getUserSession(accessToken?: any) {
  if (accessToken != null && accessToken.length !== 0) {
    const response = await getUserRolePermissions(accessToken);
    return response.data;
  }
  throw new Error('User OID is invalid or empty');
}

type PageDetails = {
  path: string;
  tabPosition: any;
  tabName: String;
};

var previousPageDetails:PageDetails;
export async function setCurrentPageDetails(path:any, tabPosition:any, tabName:any) {
  console.log(path, tabPosition)
  previousPageDetails = {
    path: path,
    tabPosition: tabPosition,
    tabName: tabName
  };
  console.log(previousPageDetails)
}

export function getPreviousPageDetails() {
  return previousPageDetails;
}

// export async function isUser(permission: string, userSession): Promise<boolean> {
//   if (userSession) {
//     const rolePermissions = userSession?.role?.userRolePermissions;
//     for (const pagePermissions of pagesPermission) {
//       const pagePermissionKeys = Object.keys(pagePermissions);
//       if (pagePermissionKeys.includes(permission)) {
//         for (const userData in rolePermissions) {
//           const userPermission = rolePermissions[userData].permission;
//           console.log(pagePermissions[permission].includes(userPermission));
//           if (pagePermissions[permission].includes(userPermission)) {
//             return true; // User has the permission
//           }
//         }
//       }
//     }
//     return false; // User doesn't have the permission
//   } else {
//     throw new Error('User session is undefined');
//   }
// }


//   const contextValue = useMemo(
//     () => ({ userSessionDetails, setUserDetails, hasPermission }),
//     [userSessionDetails, setUserDetails, hasPermission]
//   );

// export type UserModeContext = {
//     userSessionDetails: any,
//     setUserDetails: (userSessionDetails: any) => void
// }

// export const UserContext = createContext("user");
  

// export async function getUserSessionDetails(accessToken?: any, userOID?: string) {
//     var userSessionDetails: any;
//     if (userOID != null && userOID.length != 0) {
//         const response = await getUserRolePermissions(accessToken, userOID);
//         userSessionDetails = response.data;
//     }
//     return userSessionDetails;
// }




