
import { executeDeleteData, executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const uploadContractDoc = async (fileUpload: FormData, accessToken: string) => {
  try {
    const url = `${ConfigUrl.baseURL}/contracts/fileUpload`;
    const response = await executePostData(url, fileUpload, accessToken);
    return response;
  } catch (error) {
    // Handle error
    console.error('Error uploading contract document:', error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

// export const createContract = async (projectId:any,contractData:any, accessToken: string) => {
//   try {
//     const url = `${ConfigUrl.baseURL}/contracts/uploadContract/projectId/${projectId}`;
//     const response = await executePostImage(url, contractData, accessToken);
//     return response;
//   } catch (error) {
//     // Handle error
//     console.error('Error uploading contract document:', error);
//     throw error; // Re-throw the error to be handled by the caller
//   }
// };

export const createContract= async(payload:any,projectId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/contracts/contractUpload/projectId/${projectId}`;
  return await executePostImage(url, payload, accessToken);
}

export const getContractDetails= async(projectId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/contracts/getContractAgreement/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}

export const contractUpload = async(payload:any,accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/contracts/contractUpload`;
  return await executePostImage(url, payload, accessToken);
}

export const getContract= async(accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/contracts/getContract`;
  return await executeGetData(url, accessToken);
}
export const getDynamicContractDetails= async(projectId:any,accessToken:any) =>{
  const url = `${ConfigUrl.baseURL}/contracts/getDynamicContract/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
}