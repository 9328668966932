/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For create bid advertisement
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1531           |  2/4/24    |   Nandha      |  Create Bid advertisement redesign
* 2072          |  28/05/24   |   Vanitha     |  Status of my Project Ui changed 
*
*/ import { Box, Button, Card, CardActions, CardMedia, Grid } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import './CreateBidAdvertisement.scss';
import owens from "../../assets/imgs/owenscorning company.jpg";
import certainteed from "../../assets/imgs/ct-capping-feature.jpg";
import gaf from "../../assets/imgs/Gaf roof new.jpg";
import createbidicon from "../../assets/imgs/Bid-Design.svg";
import { useNavigate } from "react-router-dom";
const CreateBidAdvertisement: React.FC = () => {

    interface CarouselItem {
        id: number;
        image: any;
        content: string;
    }
    let navigate = useNavigate();
    const items: CarouselItem[] = [
        { id: 1, image: owens, content: 'CertainTeed' },
        { id: 2, image: gaf, content: 'Owens Cowing' },
        { id: 3, image: certainteed, content: 'GAF' },
    ];
    const [activeIndex, setActiveIndex] = React.useState(0);
  
    const handleSlideChange = (index: number) => {
        setActiveIndex(index);
    };
    const handleClick = () => {
        navigate("/createbid")
    }
    const images = [
        'https://tse4.mm.bing.net/th?id=OIP.hyu_fMsX5yfbnzUxj2AhUgHaHa&pid=Api&P=0&h=220',
        'https://mergr-image-uploads.s3.amazonaws.com/images/1500687844624592.jpg',
        'https://tse1.mm.bing.net/th?id=OIP.Cl0cuTql8saWRIZ6nykbpAHaFd&pid=Api&P=0&h=220',
    ];

    React.useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 4000); // Change the interval to 7000 milliseconds (7 seconds)
        // Change slide every 3 seconds (adjust as needed)

        return () => {
            clearInterval(interval);
        };
    }, []);
    return (
        <>
            <Grid container  mt={0} sx={{padding:0}} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                <Grid item xs={6}  >
                    <Grid item xs={12} >
                        {/* <Card className="POCard" > */}
                        <Grid item >
                        <Card >
                            <Carousel
                                navButtonsAlwaysVisible

                                index={activeIndex}
                                onChange={(_, index: any) => handleSlideChange(index)}
                                animation="slide"
                                indicators={false}

                            >
                                {items.map((image, index) => (
                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid container position="relative">
                                          
                                                <CardMedia
                                                    component="img"
                                                    className='POCard createBidCourousel'
                                                    image={image.image}
                                                    alt={`slide-${index}`}
                                                />

                                                <CardActions style={{ width: '100%', justifyContent: 'center' }}>
                                                    <Button
                                                        // className="POCard createadvButton"
                                                        size="large"
                                                        onClick={handleClick}
                                                        sx={{ background: "linear-gradient(to right, #3c5bfa, #7f00ffd4)" ,color:"white",width:"100%",justifyContent:"center"}}
                                                        fullWidth
                                                    >
                                                        <img
                                                            src={createbidicon}
                                                            alt="Your Image Alt Text"
                                                            style={{
                                                                width: '22px',
                                                                height: '22px',
                                                                marginRight: '10px',
                                                                color:'#FFFFFF',backgroundColor:'white',
                                                                borderRadius:'50%', 

                                                            }}
                                                        />
                                                        Create Bid
                                                    </Button>
                                                </CardActions>
                                            

                                        </Grid>
                                    </Box>
                                ))}
                            </Carousel>
                            </Card>
                        </Grid>
                        {/* </Card> */}
                    </Grid>

                </Grid>
                <Grid item xs={6} rowSpacing={2} >
                    <Grid item xs={12} >
                        {/* <Card className="POCard" > */}
                        <Grid item >
                        <Card >
                            <Carousel
                                navButtonsAlwaysVisible

                                index={2}
                                onChange={(_, index: any) => handleSlideChange(index)}
                                animation="slide"
                                indicators={false}

                            >
                                {items.map((image, index) => (
                                    <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Grid container position="relative">
                                          
                                                <CardMedia
                                                    component="img"
                                                    className='POCard createBidCourousel'
                                                    image={image.image}
                                                    alt={`slide-${index}`}
                                                />

                                                <CardActions style={{ width: '100%', justifyContent: 'center' }}>
                                                    <Button
                                                        // className="POCard createadvButton"
                                                        size="large"
                                                        onClick={handleClick}
                                                        sx={{ background: "linear-gradient(to right, #3c5bfa, #7f00ffd4)" ,color:"white",width:"100%",justifyContent:"center"}}
                                                        fullWidth
                                                    >
                                                        <img
                                                            src={createbidicon}
                                                            alt="Your Image Alt Text"
                                                            style={{
                                                                width: '22px',
                                                                height: '22px',
                                                                marginRight: '10px',
                                                                color:"white",
                                                                backgroundColor:'white',
                                                                borderRadius:'50%'
                                                            }}
                                                        />
                                                        Create Bid
                                                    </Button>
                                                </CardActions>
                                            

                                            {/* </div> */}
                                            {/* <Grid item xs={12} xl={12}>
                                                <ButtonBase
                                                    sx={{
                                                        display: "block",
                                                        background: "linear-gradient(to right, #3c5bfa, #7f00ffd4)",
                                                        textAlign: "center",
                                                        borderRadius: "8px", // Adjust as needed
                                                        padding: "10px", // Adjust as needed
                                                    }}
                                                    onClick={handleClick}

                                                >
                                                    <img
                                                        src={createbidicon}
                                                        alt="Your Image Alt Text"
                                                        style={{
                                                            width: '22px',
                                                            height: '22px',
                                                            marginRight: '5px',
                                                            verticalAlign: 'middle', // Align the image vertically
                                                        }}
                                                    />
                                                    <span style={{ verticalAlign: 'middle' }}>Create Bid</span>
                                                </ButtonBase>

                                            </Grid> */}

                                            {/* <Grid item xs={6} pl={1} className="imgContent" justifyContent='flex-start'>
                                                 <Typography className="createBidContent">{image.content}</Typography>
                                             </Grid>
                                             <Grid item xs={6} /> */}
                                        </Grid>
                                    </Box>
                                ))}
                            </Carousel>
                            </Card>
                        </Grid>
                        {/* </Card> */}
                    </Grid>

                </Grid>
                {/* <Grid item xs={6}>
                    <Grid item xs={12} >
                        <Card className="POCard">
                            <Grid item p={1}>
                                <Carousel
                                    index={activeIndex}
                                    onChange={(_, index: any) => handleSlideChange(index)}
                                    animation="slide"
                                    indicators={false}

                                >
                                    {items.map((image, index) => (
                                        <Box key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <Grid container position="relative">
                                                <Grid item xs={12}>
                                                  
                                                    <div className="imageContainer" style={{ position: 'relative' }}>
                                                        <img className='POCard createBidCourousel' src={image.image} alt={`slide-${index}`} />

                                                        
                                                        <Box className='ml-3 mr-3' style={{ position: 'absolute', bottom: 20, left: '50%', transform: 'translateX(-50%)' }}>
                                                            <Button
                                                                fullWidth
                                                                className="POCard createadvButton"
                                                                variant="contained"
                                                                size="medium"
                                                                style={{
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}
                                                            >
                                                                Create Bid
                                                            </Button>
                                                        </Box>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} pl={1} className="imgContent" justifyContent='flex-start'>///hide
                                                 <Typography className="createBidContent">{image.content}</Typography>
                                             </Grid>
                                             <Grid item xs={6} />/hide
                                            </Grid>
                                        </Box>
                                    ))}
                                </Carousel>
                            </Grid>
                        </Card>
                    </Grid>
                   
                </Grid> */}
            </Grid>
        </>

    );
};

export default CreateBidAdvertisement;