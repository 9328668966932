/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-apr-2024
* @author      : Karthikeyan.A,Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create customer implementation
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2059           |  27/05/24   |   Vanitha     |  Dialog box implementation for (create,update,)
* 2061           |  27/05/24   |   Vanitha     |  redirect implementation
* 2073           |  28/05/24   |   Vanitha     |  Success Pop up message display implementation
*
*
*/

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Stack } from '@mui/material';
import { getRoles } from '../../services/RoleSevice';
import { updateUser, saveCreateUserAsset, getCustomerDetails, findUserDetails } from '../../services/UserService';
import CustomDialog from '../Dialog';
import { useState } from 'react';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://gracefulmanagement.com/">
        Graceful Management Systems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


const CreateCustomer: React.FC<any> = (props) => {

  const { onClose, open, setRows, rowDatas } = props;
  const navigate = useNavigate();
  const [openDialogEmail, setOpenDialogEmail] = useState<boolean>(false);
  const hasEmailRef = React.useRef<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [noChange, setNoChange] = React.useState(false);
  const [isSubmited, setIsSubmited] = React.useState<any>(false);


  const { accessToken } = useOidcAccessToken();
  const [user, setUser] = React.useState({
    firstname: '',
    lastname: '',
    email: '',
    mobileNo: '',
    roles: 'customer',
    deleted: false,
    eulaAccepted: false,
    active: false,
    gender: null,
    avatar: null,
    birthdate: null,
    middlename: null,
    updatedAt: null,
    registeredDate: null,
    objectId: null,
    alternateMobileNo: null,
    id: null,
    status: 'Invited',

  });

  const rowData = rowDatas;

  const [emailError, setEmailError] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const [updateSuccess, setUpdateSuccess] = React.useState(false);

  React.useEffect(() => {
    if (rowData) {
      setUser({
        firstname: rowData.firstname || '',
        lastname: rowData.lastname || '',
        email: rowData.email || '',
        mobileNo: rowData.mobileNo || '',
        roles: "customer",
        deleted: rowData.deleted || false,
        eulaAccepted: rowData.eulaAccepted || false,
        active: rowData.active || false,
        gender: rowData.gender || null,
        avatar: rowData.avatar || null,
        birthdate: rowData.birthdate || null,
        middlename: rowData.middlename || null,
        updatedAt: rowData.updatedAt || null,
        registeredDate: rowData.registeredDate || null,
        objectId: rowData.objectId || null,
        alternateMobileNo: rowData.alternateMobileNo || null,
        id: rowData.id || null,
        status: rowData.status || ''
      });
    }
  }, [rowData]);

  const handleInputChange = (fieldName: string) => (e: any) => {
    if(fieldName === "mobileNo"){
      if (!isNaN(e.target.value)) {
        setUser((prevUser) => ({
          ...prevUser,
          [fieldName]: e.target.value,
        }));
      }
    }else{
      setUser((prevUser) => ({
        ...prevUser,
        [fieldName]: e.target.value,
      }));
    }
  };

  const formValidated = (data:any) =>{
    var validated = false;
    if (data.firstname !== '' && data.lastname !== '' && data.email !== '' && data.mobileNo !== '' && data.roles !== '') {
      validated = true;
    }
    return validated;
  }

  const closeDialog = () => {

    setSuccess(false);
    setEmailError(false);
    setError(false);
    setUpdateError(false);
    setUpdateSuccess(false);
    setOpenDialogEmail(false);
    if (success) {
      // window.location.href = "/admin";
      onClose();
    }

  }


  const findUserAlreadyExist = async (email: any) => {
    const res = await findUserDetails(email, accessToken);
    console.log(res.data);
    hasEmailRef.current = res.data;
  };


  // Mobile number validation function
  const validateMobileNumber = (mobileNo) => {
    // Check if mobile number consists of exactly 10 digits
    return /^\d{10}$/.test(mobileNo);
  };

  const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

    setNoChange(false)
    navigate("/admin")

  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsSubmited(true);
    setOpenDialog(true);
    event.preventDefault();
    await findUserAlreadyExist(user.email);

    if (hasEmailRef.current === true) {
      setEmailError(true);
    }

    else if (hasEmailRef.current === false) {
      if (rowData) {
        if(formValidated(rowData) && validateMobileNumber(rowData.mobileNo) && validateEmail(rowData.email)){
          const result = await updateUser(rowData.id, user, accessToken);
          if (result.status === 200) {
            setUpdateSuccess(true);

          }
          else {

            setUpdateError(true);
          }
        }

      } else {
        if(formValidated(user) && validateMobileNumber(user.mobileNo) && validateEmail(user.email)){
          const result = await saveCreateUserAsset(user, accessToken);
          if (result.status === 201) {
            setSuccess(true);
          } else {
            setError(true);

          }
        }
      }
    }

  };
  // Email validation function using regular expression
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          fullWidth={true}
          maxWidth={'md'}
          open={open}
          // onClose={handleClose}
          //onBackdropClick={handleBackdropClick}
          disableEscapeKeyDown
          PaperProps={{
            sx: {
              borderRadius: '15px', // Set the border radius to 15px
            },
          }}
        >
          <DialogContent sx={{ backgroundColor: "#f8f7fa", padding: '0px' }} >
            <Typography>
              <Typography className="addEmployee" sx={{ bgcolor: 'primary.main' }}>
                {/* <Button className='primaryTonalButton' startIcon={<ReplyAllIcon/>} onClick={onCancel}>
             <CloseIcon/>
            </Button> */}
                <Stack direction="row" spacing={1}>

                  {/* <Avatar sx={{ bgcolor: 'secondary.main' }}> */}
                  <PersonAddAlt1Icon />
                  {/* </Avatar> */}
                  <Typography component="h1" variant="h5">
                    Invite Customer
                  </Typography>
                </Stack>
              </Typography>
              {/* <CloseIcon  /> */}

            </Typography>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',

              }}
            >
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 0, m: 5 }}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={12} mb={1}>    <Typography component="h1" variant="h6">Who is your  new  customer?</Typography></Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      autoComplete="given-name"
                      name="firstname"
                      required
                      fullWidth
                      id="firstname"
                      label="First Name"
                      autoFocus
                      size='small'
                      value={user.firstname}
                      onChange={handleInputChange('firstname')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      // required
                      fullWidth
                      id="middlename"
                      label="Middle name"
                      name="middlename"
                      size='small'
                      // autoComplete="family-name"
                      value={user.middlename}
                      onChange={handleInputChange('middlename')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      required
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      name="lastName"
                      size='small'

                      // autoComplete="family-name"
                      value={user.lastname}
                      onChange={handleInputChange('lastname')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      size='small'
                      name="email"
                      value={user.email}
                      onChange={handleInputChange('email')}
                      error={user.email !== '' && !validateEmail(user.email) && isSubmited}
                      helperText={user.email !== '' && !validateEmail(user.email) && isSubmited ? 'Invalid email address' : ''}

                    />
                    <FormHelperText>We will email them an invite ,Customer  list  from there ,they will be  able to create bids ,Add assets and manage projects</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      name="mobileNo"
                      label="Mobile No"
                      type="text" // Change type to 'text' to allow non-numeric characters
                      id="mobileNo"
                      size='small'
                      value={user.mobileNo}
                      onChange={handleInputChange('mobileNo')}
                      error={user.mobileNo !== '' && !validateMobileNumber(user.mobileNo)  && isSubmited}
                      helperText={user.mobileNo !== '' && !validateMobileNumber(user.mobileNo)  && isSubmited ? "Invalid mobile number" : null}
                      inputProps={{
                        maxLength: 10,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} mt={1}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Role</InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={user.roles}
                        label="Role"
                        onChange={handleSelectChange('roles')}
                        size='small'
                      >
                        {roles
                          .filter(role => role.name === 'customer') // Filter out 'labour' and 'admin' roles
                          .map(role => (
                            <MenuItem key={role.id} value={role.name}>
                              {role.name}
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={12} sm={6} >
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='DD MMM YYYY'
                            label="Choose Date"
                            // disablePast   
                            // disableHighlightToday   
                            // shouldDisableDate={date => date.day() === 0 || date.day() === 6}            
                            // minDate = {dayjs().add(1, 'day')}
                            slotProps={{ textField: { fullWidth: true, size: "small", error: (user.registeredDate === undefined) } }}
                            value={parsedDate}
                            onChange={(e:any)=>handleDateChange(e)}
                     
                          />
                        </LocalizationProvider> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker', 'DatePicker']}>
        <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
        <DatePicker
          label="Controlled picker"
          value={parsedDate}
          onChange={(e:any)=>handleDateChange(e)}
        />
      </DemoContainer>
    </LocalizationProvider> */}

                  </Grid>
                </Grid>

                <br />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button className="dangerButton" variant="contained" size="medium" onClick={onClose} fullWidth>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} mt={1}>
                    <Button type="submit" variant="contained" fullWidth >
                      Submit
                    </Button>
                  </Grid>
                </Grid>

              </Box>
            </Box>
            <Copyright sx={{ mt: 0, mb: 3 }} />
          </DialogContent>

        </Dialog>
        <CustomDialog
          open={openDialog}
          onClose={closeDialog}
          success={updateSuccess}
          error={updateError}
          Content={updateSuccess ? "Customer Update Successfully" : "Failed To Updated"}
        />
        <CustomDialog
          open={openDialog}
          onClose={closeDialog}
          success={success}
          error={error}
          Content={success ? "Created  Successfully" : "Failed To Create"}
        />

        <CustomDialog
          open={openDialog}
          onClose={closeDialog}
          success={openDialogEmail}
          error={emailError}
          Content={emailError ? " Email already registered " : " Email Registered  Successfully"}
        />

        <Dialog open={noChange} >
          <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>

      </Container>

    </ThemeProvider>
  );
}
export default CreateCustomer;