import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, FormControl, InputLabel, ListSubheader, MenuItem, Select, SelectChangeEvent, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { generateInvoice, getPaymentType, getTerms } from '../../services/InvoiceServices';
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const InvoiceModelBox: React.FC<any> = (props) => {
  const { onClose, projectId, open, getList } = props;
  const [openDialog, setOpenDialog] = React.useState(props.open);
  const [paymentTerm, setPaymentTerm] = React.useState('');
  const [selectInvoice, setSelectInvoice] = React.useState('');
  const [termList, setTermList] = React.useState([] as any);
  const [paymentMethodList, setPaymentMethodList] = React.useState([] as any);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const navigate = useNavigate();
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState({
    message: '',
    security: ''
  });
  const [openSelectBox, setOpenSelectBox] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState('');


  const handleChange = async (event: SelectChangeEvent) => {
    setPaymentTerm(event.target.value);
    let result = await getTerms(projectId, event.target.value, accessToken);
    setTermList(result.data);
    // getTermList();
  };
  const selecthandleChange = (event: SelectChangeEvent) => {

    setSelectInvoice(event.target.value);

  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  // const getTermList = async () => {
  //   let result = await getTerms(projectId, paymentTerm, accessToken);
  //   console.log(result.data);
  //   setTermList(result.data);
  // }
  const handleSelectBox = () => {
    setOpenSelectBox(true);
  }
  const handleClose = () => {
    setOpenDialog(false);
    onClose(false);
    setOpenSelectBox(false);
  };

  const getPaymentMethod = async () => {
    let result = await getTerms(projectId, "project.invoice.paymentType", accessToken);
    setPaymentMethodList(result.data);
  }
  React.useEffect(() => {
    getPaymentMethod();

  }, []);
  const createInvoice = async () => {
    if (projectId != null && paymentTerm != null && selectInvoice != null) {
      // let result = await generateInvoice(projectId, paymentTerm, selectInvoice, accessToken);
      let result = await generateInvoice(projectId, accessToken);
      if (result.status === 200 || result.status === 201) {
        onClose(false);
        setOpenToast(true);
        setToastMessage({ ...toastMessage, message: "Invoice Created", security: "success" });
        getList();

      }
      else {
        setOpenToast(true);
        setToastMessage({ ...toastMessage, message: "Error", security: "error" });
      }
    }
    else {
      setOpenToast(true);
      setToastMessage({ ...toastMessage, message: "Error", security: "error" });
    }
  };
  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  return (
    <React.Fragment>
      <Snackbar open={openToast} autoHideDuration={2000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
        <Alert onClose={handleToastClose} security={toastMessage.security} sx={{ width: '100%' }}>
          {toastMessage.message}
        </Alert>
      </Snackbar>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Generate Invoice
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small-label">Select Payment Terms</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={paymentTerm}
              label="Select Payment Terms"
              onChange={handleChange}
            >
              {/* {paymentMethodList?.map(
                (method:any)=>{
                  return (
                    <MenuItem value={"project.invoice.full"} onClick={handleSelectBox} disabled={method?.enabled}>{method?.name}</MenuItem>
                  )
                }
              )} */}

              <MenuItem value={"project.invoice.full"} onClick={handleSelectBox} disabled={paymentMethodList[0]?.enabled}>{paymentMethodList[0]?.name}</MenuItem>
              <MenuItem value={"project.invoice.terms"} onClick={handleSelectBox} disabled={paymentMethodList[1]?.enabled}>{paymentMethodList[1]?.name}</MenuItem>
            </Select>
          </FormControl>
          {openSelectBox ?
            <FormControl fullWidth size="small" sx={{ mt: 3 }}>
              <InputLabel id="demo-select-small-label">Select Invoice</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={selectInvoice}
                label="Select Invoice"
                onChange={selecthandleChange}
              >
                {termList.map(
                  (term: any) => {
                    return (
                      <MenuItem value={term?.name} disabled={term?.enabled}>{term?.name}</MenuItem>
                    )
                  }
                )}
              </Select>
            </FormControl>
            : null}
          {/* <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
            magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
            ullamcorper nulla non metus auctor fringilla.
          </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={createInvoice}>
            Generate Invoice
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}


export default InvoiceModelBox;