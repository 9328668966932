/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1231     dec-11,2023     xyz buildGetResult function implementation
* 2134           |  04/06/24   |   Vanitha     |  Expences Paid vs due  Card view  integration 
*
*
*/ 
import {PaymentJson} from '../data/paymentjson'
import { executeGetData, executePostData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';

export const getPaymentList= async () => {
const url = PaymentJson;
  return url;
};
export const createPayment = async (paymentDetails: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/paymentReceives/createPayment`;
  return await executePostData(url, paymentDetails, accessToken);
};
export const getPaymentReceive = async (projectId: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/paymentReceives/getPayment/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const makePayment = async (paymentDetails: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/payment/makePayment`;
  return await executePostData(url, paymentDetails, accessToken);
};

export const makeACHPayment = async (paymentDetails: any,service:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/payment/makeAchPayment/service/${service}`;
  return await executePostData(url, paymentDetails, accessToken);
};

export const getInquire = async (projectId: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/payment/inquire/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};
