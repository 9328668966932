import * as React from 'react';
import {
    Box, Grid, Card, CardHeader, CardActions,
    Button, Typography, CardContent, Chip,
    InputLabel, MenuItem, FormControl, ListItem,
    ListItemText, ListItemAvatar, Avatar, SelectChangeEvent,
    Select, List
} from '@mui/material';

import NotificationsIcon from '@mui/icons-material/Notifications';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { Chart } from "react-google-charts";
import { Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BusinessIcon from '@mui/icons-material/Business';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import SettingsIcon from '@mui/icons-material/Settings';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PercentIcon from '@mui/icons-material/Percent';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import ReceiptIcon from '@mui/icons-material/Receipt';

import "./Tenantfinance.scss"
import { color } from '@mui/system';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 0,
        // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));


export const data = [
    ["Task", "Hours per Day"],
    ["Work", 4.5],
    ["Eat", 4],
    ["Commute", 1.5],

];

export const options = {
    // title: "My Daily Activities",
    pieHole: 0.4,
    is3D: false,
    sliceVisibilityThreshold: 0,

    colors: ['#00bdae', 'black', '#357cd2'],
    enableEvents: false,
    legend: {
        position: 'none'
    },




};

export default function Tenantfinance() {

    const [age, setAge] = React.useState('');
    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value);
    };



    return (
        <Box sx={{ flexGrow: 1 }}>

            <Grid container rowSpacing={3} columnSpacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card className='cardheight' >


                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} >

                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                        PROFIT AND LOSS
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6} >

                                    <FormControl sx={{ width: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">Last day</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            // value={age}
                                            label="Last day"
                                            onChange={handleChange}>
                                            <MenuItem value="Last day">Last day</MenuItem>
                                            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                                            <MenuItem value="This month">This month</MenuItem>
                                            <MenuItem value="This Quarter">This Quarter</MenuItem>
                                            <MenuItem value="Last month">Last month</MenuItem>
                                            <MenuItem value="Last Quarter">Last Quarter</MenuItem>
                                            <MenuItem value="Last Year" >Last Year</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 3 }}>
                                        $35,000
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        Net Income for this year <br></br>
                                        <Chip icon={<NotificationsIcon />} label="100% categorized" />
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <Typography variant="h5" component="div" color="#00bdae" sx={{ mb: -0.5, mt: 1 }}>
                                        $40,000
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        Net Income for this year
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <BorderLinearProgress variant="determinate" value={50} sx={{
                                        mt: 2, '& .MuiLinearProgress-bar1Determinate': {
                                            backgroundColor: '#00bdae',
                                        }
                                    }} />
                                </Grid>



                                <Grid item xs={12} sm={6} >
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 1 }}>
                                        $5,000
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        Expenses
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <BorderLinearProgress variant="determinate" value={10} sx={{
                                        mt: 1, '& .MuiLinearProgress-bar1Determinate': {
                                            backgroundColor: 'rgb(0 0 0 / 87%)',
                                        }
                                    }} />
                                </Grid>



                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        See profit and loss report
                                    </Typography>
                                </Grid>


                            </Grid>




                        </CardContent>


                    </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card className='cardheight'  >
                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} md={6}>

                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                        EXPENSES
                                    </Typography>

                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>

                                    <FormControl sx={{ width: "100%" }} size="small">
                                        <InputLabel id="demo-select-small-label">Last day</InputLabel>
                                        <Select
                                            labelId="demo-select-small-label"
                                            id="demo-select-small"
                                            // value={age}
                                            label="Last day"
                                            onChange={handleChange}>
                                            <MenuItem value="Last day">Last day</MenuItem>
                                            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                                            <MenuItem value="This month">This month</MenuItem>
                                            <MenuItem value="This Quarter">This Quarter</MenuItem>
                                            <MenuItem value="Last month">Last month</MenuItem>
                                            <MenuItem value="Last Quarter">Last Quarter</MenuItem>
                                            <MenuItem value="Last Year" >Last Year</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 3 }}>
                                        $5,000
                                    </Typography>
                                    <Typography sx={{ mb: 0.5 }} color="text.secondary">
                                        Total Expense<br></br>

                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={4} >
                                    <Typography variant="h5" component="div" color="#00bdae" sx={{ mb: -0.5, mt: 0 }}>
                                        $2,000
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="#00bdae">
                                        PREXP
                                    </Typography>
                                </Grid>



                                <Grid item xs={12} sm={4} >
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 0 }}>
                                        $2,500
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        JOB COST
                                    </Typography>
                                </Grid>


                                <Grid item xs={12} sm={4} >
                                    <Typography variant="h5" component="div" color='#357cd2' sx={{ mb: -0.5, mt: 0 }}>
                                        $500
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="#357cd2">
                                        OH COSTS
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} >
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        height="200px"
                                        data={data}
                                        options={options}
                                    />
                                </Grid>

                            </Grid>




                        </CardContent>


                    </Card>


                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card className='cardheight' >
                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6} >
                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                        INVOICE
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                        Last 365 days
                                    </Typography>
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 3 }}>
                                        $21,000
                                    </Typography>
                                    <Typography sx={{ mb: 0.5 }} color="text.secondary">
                                        UnPaid<br></br>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} >
                                    <Typography variant="h5" component="div" color="#00bdae" sx={{ mb: -0.5, mt: 0 }}>
                                        $4,300
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="#00bdae">
                                        Overdue
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <BorderLinearProgress variant="determinate" value={60} sx={{
                                        mt: 1, '& .MuiLinearProgress-bar1Determinate': {
                                            backgroundColor: '#00bdae',
                                        }
                                    }} />
                                </Grid>


                                <Grid item xs={12} sm={6} >
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 0 }}>
                                        $11,000
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="text.secondary">
                                        JOB COST
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <BorderLinearProgress variant="determinate" value={45} sx={{
                                        mt: 1, '& .MuiLinearProgress-bar1Determinate': {
                                            backgroundColor: 'rgb(0 0 0 / 87%)',
                                        }
                                    }} />
                                </Grid>


                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h5" component="div" color='#357cd2' sx={{ mb: -0.5, mt: 0 }}>
                                        $1000
                                    </Typography>
                                    <Typography sx={{ mb: 1 }} color="#357cd2">
                                        Not Deposited
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <BorderLinearProgress variant="determinate" value={10} sx={{
                                        mt: 1, '& .MuiLinearProgress-bar1Determinate': {
                                            backgroundColor: '#357cd2',
                                        }
                                    }} />
                                </Grid>

                            </Grid>




                        </CardContent>


                    </Card>


                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card className='cardheight'>


                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} >

                                    <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
                                        BANK ACCOUNTS
                                    </Typography>

                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -0.5, mt: 3 }}>
                                        XXbank
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography color="text.secondary" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        Balance
                                    </Typography>

                                    <Typography color="text.secondary" component="div" sx={{ mt: 1 }}>
                                        In QuickBooks
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'center' }} >
                                    <Typography variant="h5" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        $15,000
                                    </Typography>
                                    <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                                        $15,000
                                    </Typography>
                                </Grid>


                                <Divider />

                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -1.5, mt: 3 }}>
                                        ABCbank
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography color="text.secondary" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        Balance
                                    </Typography>

                                    <Typography color="text.secondary" component="div" sx={{ mt: 1 }}>
                                        In QuickBooks
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h5" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        $15,000
                                    </Typography>
                                    <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                                        $15,000
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h5" component="div" sx={{ mb: -1.5, mt: 3 }}>
                                        CObank
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography color="text.secondary" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        Balance
                                    </Typography>

                                    <Typography color="text.secondary" component="div" sx={{ mt: 1 }}>
                                        In QuickBooks
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                                    <Typography variant="h5" component="div" sx={{ mb: -1.5, mt: 0 }}>
                                        $15,000
                                    </Typography>
                                    <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                                        $15,000
                                    </Typography>
                                </Grid>



                            </Grid>




                        </CardContent>


                    </Card>
                </Grid>




                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card className='cardheight1'>
                        <CardHeader title="Account Tools" />
                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={4} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemAvatar >
                                                <Avatar>
                                                    <PersonIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Customer" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BusinessCenterIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Job" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <RequestPageIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Purchase Orders" secondary="" />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={4} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <MonetizationOnIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Expense" secondary="" />
                                        </ListItem>

                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EventNoteIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Bill" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BusinessIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Vendor" secondary="" />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={4} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <RequestQuoteIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Invoice" secondary="" />
                                        </ListItem>


                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <BrandingWatermarkIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Produts" secondary="" />
                                        </ListItem>


                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <SettingsIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Services" secondary="" />
                                        </ListItem>
                                    </List>
                                </Grid>
                            </Grid>




                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card className='cardheight1'>
                        <CardHeader title="Quick Links" />
                        <CardContent>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EventNoteIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Invoicing" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <EditNoteIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="WIP Report" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <MonetizationOnIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Expense/Bills" secondary="" />
                                        </ListItem>

                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={6} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <SummarizeIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Job Profit Report" secondary="" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <PercentIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Percent Complete" secondary="" />
                                        </ListItem>


                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ReceiptIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Over/Under Billing Report" secondary="" />
                                        </ListItem>


                                    </List>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={4} >
                                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                        {/* <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                <BrandingWatermarkIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Produts" secondary="" />
                                        </ListItem>


                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                <SettingsIcon/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary="Services" secondary="" />
                                        </ListItem>                                      */}
                                    </List>
                                </Grid>
                            </Grid>




                        </CardContent>
                    </Card>
                </Grid>
            </Grid>


        </Box >
    );
}
