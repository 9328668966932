/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 24-jun-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create customer implementation
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  24/06/24   |   Asish     |  Timesheet editor view
*
*
*/
import CloseIcon from '@mui/icons-material/Close';

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useNavigate } from 'react-router-dom';
import { ButtonBase, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Paper, Stack } from '@mui/material';
import { useState } from 'react';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomDialog from '../../../pages/Dialog';
import { LocalizationProvider, DatePicker, TimePicker, renderTimeViewClock, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { count } from 'console';
import dayjs from 'dayjs';
import './TimeSheet.scss'
import { getAllTimeCounts, getAllTimeSheets, getAllTimeSheetsByDate, getUserTimeSheetByDate, getUserTimeSheetCountByDate, getUserTimeSheets, updateApproveRejectStatus, updateTimeStatus } from '../../../services/TimelogService';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import moment from 'moment';
import { getUserSession } from '../../../hooks/UserSession';

dayjs.extend(utc);
dayjs.extend(timezone);
function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://gracefulmanagement.com/">
        Graceful Management Systems
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


const UpdateTimeSheet: React.FC<any> = (props) => {
  const [errorDetails, setErrorDetails]= useState<string | null>(null);
  const ROLES = "customer"
  const { onClose, open, setRows, rowDatas, setCount } = props;
  const navigate = useNavigate();
  const [roles, setRoles] = React.useState<any[]>([]);
  const [hasEmail, setHasEmail] = useState<boolean>(false);
  const [openDialogEmail, setOpenDialogEmail] = useState<boolean>(false);
  const hasEmailRef = React.useRef<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [role, setRole] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const [noChange, setNoChange] = React.useState(false);
  const [MobileNumberValid, setMobileNumberValid] = React.useState(true);
  const [timeSpents, setTimeSpents] = useState<any>();
  const [lastChangeReason, setLastChangeReason] = useState<string>('');

  const [clockIn, setClockIn] = useState<any>(null);
  const [clockOut, setClockOut] = useState<any>(null);

  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [timeLog, setTimeLog] = React.useState({
    taskName: '',
    startTime: '',
    endTime: '',
    changeReason: '',
    timelogId: '',
    oldStatus: '',
    newStatus: 'Awaiting Approval',
    timeSpent: '',
    resourceId: '',
    taskId: ''


  });

  // const rowData = state && state.rowsData;
  const rowData = rowDatas;
  const dateFormatter = (date) => {
    return dayjs(date).utc().format(); // Convert to UTC and format
  };
  React.useEffect(() => {
    console.log(props)
    if (rowData) {
      setTimeLog({
        ...timeLog,
        taskName: props.rowDatas.taskName,
        timelogId: props.rowDatas.timeLogId,
        startTime: props.rowDatas.startTime,
        endTime: props.rowDatas.endTime,
        oldStatus: props.rowDatas.timeLogStatus,
        newStatus: "Awaiting Approval",
        timeSpent: '',
        resourceId: props.rowDatas.resourceId,
        taskId: props.rowDatas.taskId
        // Use computed property name
      });
      console.log(props.rowDatas.stateChangeHistory !== '' || props.rowDatas.stateChangeHistory !== null)
      if (props.rowDatas.stateChangeHistory !== '' || props.rowDatas.stateChangeHistory !== null) {
        const dataArray = JSON?.parse(props?.rowDatas?.stateChangeHistory);
        console.log(dataArray?.length)
        if (dataArray?.length != undefined) {
          const lastChange = dataArray[dataArray?.length - 1];
          setLastChangeReason(lastChange?.changeReason);
          setTimeLog({
            ...timeLog,
            taskName: props.rowDatas.taskName,
            timelogId: props.rowDatas.timeLogId,
            startTime: props.rowDatas.startTime,
            endTime: props.rowDatas.endTime,
            oldStatus: props.rowDatas.timeLogStatus,
            newStatus: "Awaiting Approval",
            timeSpent: '',
            changeReason: lastChange?.changeReason,
            resourceId: props.rowDatas.resourceId,
            taskId: props.rowDatas.taskId

            // Use computed property name
          });
        }

      }
      const startTime = dayjs.utc(props.rowDatas.startTime)
      const endTime = dayjs.utc(props.rowDatas.endTime)
      calculateTimeDifference(startTime, endTime);

      setClockIn(startTime);
      setClockOut(endTime);
    }
    getUserSession(accessToken).then(res => {
      setRole(res.role.name);
      console.log(res.role.name);
    })
  }, [rowData]);

  const [timeDifference, setTimeDifference] = useState('');
  const handleChange = (e: any) => {
    console.log(e.target.name, e.target.value);
    setTimeLog({
      ...timeLog,
      [e.target.name]: e.target.value, // Use computed property name
    });
  };


  const handleClockInChange = (newValue) => {
    console.log(newValue)
    setClockIn(newValue);
    setTimeLog({
      ...timeLog,
      startTime: dateFormatter(newValue)

    })
    calculateTimeDifference(newValue, clockOut);
  };

  const handleClockOutChange = (newValue) => {
    setClockOut(newValue);
    console.log(newValue, clockIn)
    setTimeLog({
      ...timeLog,
      endTime: dateFormatter(newValue)

    })
    calculateTimeDifference(clockIn, newValue);
  };
  const dateFormat = (value: any, isEndOfDay: boolean = false) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${date.getFullYear()}-${month}-${day}`;

    if (isEndOfDay) {
      formattedDate += "T23:59:59.999Z";
    } else {
      formattedDate += "T00:00:00.000Z";
    }

    return formattedDate;
  };
  const calculateTimeDifference = (clockIn, clockOut) => {

    if (clockIn && clockOut) {
      const diff = dayjs(clockOut).diff(dayjs(clockIn), 'minute');
      console.log(diff)
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      console.log(typeof ((moment(dateFormatter(clockOut)).diff(moment(clockIn), 'minutes') / 60).toFixed(2)), (moment(dateFormatter(clockOut)).diff(moment(dateFormatter(clockIn)), 'minutes') / 60).toFixed(2))
      // setTimeSpents((moment(dateFormatter(clockOut)).diff(moment(dateFormatter(clockIn)), 'minutes') / 60).toFixed(2))

      const time = (moment(dateFormatter(clockOut)).diff(moment(dateFormatter(clockIn)), 'minutes') / 60).toFixed(2)
      // Combine hours and minutes in the format hours.minutes
      setTimeDifference(`${hours} hour(s) and ${minutes} minute(s)`);
      // Update the timeLog object with the calculated timeSpent
      // Update the timeLog object with the calculated timeSpent
      setTimeLog(prevTimeLog => ({
        ...prevTimeLog,
        timeSpent: time
      }));

      console.log('Updated timeLog:', timeLog);

    } else {
      setTimeDifference('');
    }


  };

  const ApproveReject = (timeLogId: any, status: any) => {
    console.log(timeLog)
    updateApproveRejectStatus(accessToken, timeLogId, status, timeLog).then((res) => {
      console.log(res)
      if (res.data === 1 && res.ok === true && res.status === 200) {
        setOpenDialog(true);
        setSuccess(true)
      }
      else {
        setOpenDialog(true);
        setError(true)
      }
    })
  }

  const Month = async () => {
    // setLoading(false)
    const localTimezone = dayjs.tz.guess();

    // Start and end of the month in local timezone
    const startOfMonth = dateFormat(dayjs().startOf('month').tz(localTimezone));
    const endOfMonth = dateFormat(dayjs().endOf('month').tz(localTimezone), true);
    console.log("Start of Month:", startOfMonth);
    console.log("End of Month:", endOfMonth);

    if (role === 'admin') {
      const response = await getAllTimeSheetsByDate(accessToken, startOfMonth, endOfMonth, 0, 5);
      if (response.data) {
        // setLoading(false); // Set loading to false when data is fetched
        setRows(response.data);
        const res = await getAllTimeCounts(accessToken, startOfMonth, endOfMonth);
        console.log(res.data)
        props.setCount(res.data);
        // setCount(response.data.length)

      }
    } else {
      const response = await getUserTimeSheetByDate(accessToken, startOfMonth, endOfMonth, 0, 5);
      if (response.data) {
        setRows(response.data);
        const res = await getUserTimeSheetCountByDate(accessToken, startOfMonth, endOfMonth);
        props.setCount(res.data) 
        // setCount(response.data.length)

      }
    }

  };
  const Day = async () => {
    // setLoading(true); // Set loading to false when data is fetched

    // Start of the current day at 12:00 AM local time
    const localTimezone = dayjs.tz.guess();

    // Start and end of the day in local timezone
    const startOfDay = dateFormat(dayjs().startOf('day').tz(localTimezone));
    const endOfDay = dateFormat(dayjs().endOf('day').tz(localTimezone), true);

    if (role === 'admin') {
      const res = await getAllTimeCounts(accessToken, startOfDay, endOfDay);
      props.setCount(res.data)
      const response = await getAllTimeSheetsByDate(accessToken, startOfDay, endOfDay, 0, 5);
      if (response.data) {
        // setLoading(false); // Set loading to false when data is fetched
        setRows(response.data);
        // setCount(response.data.length)

      }
    } else {
      const response = await getUserTimeSheetByDate(accessToken, startOfDay, endOfDay, 0, 5);
      if (response.data) {
        setRows(response.data);
        // setCount(response.data.length)

      }
    }

    console.log(startOfDay, endOfDay);
  };

  const Week = async () => {
    // setLoading(true); // Set loading to false when data is fetched

    // Define your local timezone
    const localTimezone = dayjs.tz.guess();

    // Start of the week (Monday) in local timezone
    const startOfWeek = dateFormat(dayjs().startOf('week').tz(localTimezone));

    // End of the week (Sunday) in local timezone
    const endOfWeek = dateFormat(dayjs().endOf('week').tz(localTimezone), true);


    if (role === 'admin') {
      const res = await getAllTimeCounts(accessToken, startOfWeek, endOfWeek);
      props.setCount(res.data)
      console.log(res)
      const response = await getAllTimeSheetsByDate(accessToken, startOfWeek, endOfWeek, 0, 5);
      if (response.data) {
        // setLoading(false); // Set loading to false when data is fetched
        setRows(response.data);
        // setCount(response.data.length)

      }
    } else {
      const res = await getUserTimeSheetCountByDate(accessToken, startOfWeek, endOfWeek);
      props.setCount(res.data)

      const response = await getUserTimeSheetByDate(accessToken, startOfWeek, endOfWeek, 0, 5);
      if (response.data) {
        setRows(response.data);
        // setCount(response.data.length)

      }
    }
    console.log(startOfWeek, endOfWeek);
  }


  const handleSubmit = () => {
    setSubmitted(true);
    if (role !== 'admin' && !timeLog.changeReason) {
      setErrorDetails('Reason for Change is required for non-admin users.');
      return;
    }
    console.log(timeLog);
    updateTimeStatus(accessToken, timeLog.timelogId, timeLog).then((res) => {
      console.log(res);
      if (res.data === 1 && res.ok === true && res.status === 200) {
        setOpenDialog(true);
        setSuccess(true);
      } else {
        setOpenDialog(true);
        setError(true);
      }
    });
  };


  const handleClose = async () => {
    console.log(props.timeView,props.timeView === 'month',props.timeView === 'day',props.timeView === 'week')
    if (props.timeView === 'day') {
      Day();
      props.onClose();
    }
    else if (props.timeView === 'week') {
      Week();
      props.onClose();

    }
    else if ((props.timeView === 'month')) {
      Month();
      props.onClose();

    }
    else{
      const localTimezone = dayjs.tz.guess();

      // Start and end of the month in local timezone
      const startOfMonth = dateFormat(dayjs().startOf('month').tz(localTimezone));
      const endOfMonth = dateFormat(dayjs().endOf('month').tz(localTimezone), true);

      if (role === 'admin') {
        const response = await getAllTimeSheetsByDate(accessToken, startOfMonth, endOfMonth, 0, 5);
        if (response.data) {
          // setLoading(false); // Set loading to false when data is fetched
          const res = await getAllTimeCounts(accessToken, startOfMonth, endOfMonth);
          console.log(res)
          props.setCount(res.data);      
              setRows(response.data);
          // setCount(response.data.length)
  
        }
      } else {
        const response = await getUserTimeSheetByDate(accessToken, startOfMonth, endOfMonth, 0, 5);
        if (response.data) {
          const res = await getUserTimeSheetCountByDate(accessToken, startOfMonth, endOfMonth);
          props.setCount(res.data) 
              setRows(response.data);
          // setCount(response.data.length)
  
        }
      }
      props.onClose();
    }
  }


  // Email validation function using regular expression
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  // const [open, setOpen] = React.useState(true);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={open}
          // onClose={handleClose}
          //onBackdropClick={handleBackdropClick}
          disableEscapeKeyDown
          PaperProps={{
            sx: {
              borderRadius: '15px', // Set the border radius to 15px
            },
          }}
        >
          <DialogContent sx={{ backgroundColor: "#f8f7fa", padding: '0px' }} >
            <Typography>
              <Box sx={{ position: 'relative', bgcolor: 'primary.main' }}>
                <Stack className="addEmployee" direction="row" spacing={0} alignItems="center">
                  <Typography component="h5" variant="h5">
                    Time Sheet Editor
                  </Typography>
                </Stack>
                <Box sx={{ position: 'absolute', top: 10, right: 10, color: 'red' }}>
                  <CloseIcon style={{ cursor: 'pointer' }} />
                </Box>
              </Box>
            </Typography>

            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                // alignItems: 'center',
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  p: 1,
                  marginTop: 2,
                  margin: 'auto',
                  maxWidth: 500,
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid container spacing={1}>
                  <Grid container pl={1} pt={2}>
                    <Grid container alignItems="center" pl={1} mb={1} >
                      <Grid item xs={3}>
                        <Typography className='timesheetEditLabel'>Type :</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography>
                          <span className='timesheetEdit'>Time In/ Out</span>
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={3} mb={2} mt={1}>
          <Typography className='timesheetEditLabel'>Task Name :</Typography>
        </Grid> */}
                    <Grid item xs={12} mb={2} mt={1}>
                      <TextField
                        id="demo-helper-text-aligned"
                        label="Task Name"
                        name="name"
                        size="small"
                        value={timeLog.taskName}
                        fullWidth
                        InputProps={{
                          style: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={9}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Clock In date time"
                            value={dayjs.utc(timeLog.startTime)}
                            onChange={handleClockInChange}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                size: 'small',
                                InputProps: {
                                  readOnly: role === 'admin',
                                },
                              },
                            }}
                            
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Clock Out date time"
                            value={dayjs.utc(timeLog.endTime)}
                            onChange={handleClockOutChange}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                size: 'small',
                                InputProps: {
                                  readOnly: role === 'admin',
                                },
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} container alignItems="center" justifyContent="center">
                    <Box
                      className="timesheetEditTotal"
                      sx={{
                        border: '1px solid #DADADA',
                        borderRadius: 1,
                        padding: 2,
                        width: '100%',
                      }}
                    >
                      <Typography variant="body2" align="center">
                        {timeDifference ? ` ${timeDifference}` : 'Select both date and time'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item mt={2} mb={2}>
                <TextField
        label="Reason for Change"
        multiline
        rows={3}
        value={timeLog.changeReason}
        name="changeReason"
        onChange={(e) => handleChange(e)}
        fullWidth
        error={submitted && role !== 'admin' && !timeLog.changeReason}
        helperText={submitted && role !== 'admin' && !timeLog.changeReason ? 'This field is required' : ''}
        InputProps={{
          readOnly: role === 'admin',
        }}
      />
                </Grid>
              </Paper>

              <Box component="form" sx={{ mt: 0, m: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button sx={{ mr: 2 }} className="dangerButton sendApprove" variant="contained" size="small" onClick={onClose}>
                  Cancel
                </Button>
                {role === 'admin' ? (

                  <>
                    {props.rowDatas.timeLogStatus === "Awaiting Approval" ? (
                      <>
                        <Button
                          sx={{ mr: 2 }}
                          className="dangerButton mb-2"
                          variant="contained"
                          size="small"
                          onClick={() => ApproveReject(props.rowDatas.timeLogId, "Reject")}
                        >
                          Reject
                        </Button>
                        <Button
                          className="sendApprove mb-2"
                          variant="contained"
                          size="small"
                          onClick={() => ApproveReject(props.rowDatas.timeLogId, "Approve")}
                        >
                          Approve
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          sx={{ mr: 2 }}
                          className=" sendApprove mb-2"
                          variant="contained"
                          size="small"
                          disabled
                          onClick={() => ApproveReject(props.rowDatas.timeLogId, "Reject")}
                        >
                          Reject
                        </Button>
                        <Button
                          className="sendApprove mb-2"
                          variant="contained"
                          size="small"
                          disabled
                          onClick={() => ApproveReject(props.rowDatas.timeLogId, "Approve")}
                        >
                          Approve
                        </Button>
                      </>
                    )}

                  </>

                ) : (
                  <>
                    <Button className="sendApprove mb-2" variant="contained" size="small" onClick={handleSubmit}>
                      Send For Approval
                    </Button>
                  </>
                )}

              </Box>
            </Box>
          </DialogContent>
          <DialogActions>      <Grid container spacing={2} >
            <Grid item xs={12} >
              {/* <Button className="mb-2"type="submit" variant="contained" fullWidth >
                      Send For Approval
                    </Button>
                    <Button className="dangerButton" variant="contained" size="medium" onClick={onClose} fullWidth>
                      Cancel
                    </Button> */}
              {/* </Grid>
                  <Grid item xs={12} mt={1}> */}

            </Grid>
          </Grid>

          </DialogActions>
          {/* <Copyright sx={{ mt: 0, mb: 3,m:2 }} /> */}

        </Dialog>

        <Dialog open={noChange} >
          <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="NoChangeButton" >Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>
        <CustomDialog
          open={openDialog}
          onClose={() => handleClose()}
          success={success}
          error={error}
          Content={success ? "Updated Successfully" : "Not Updated Successfully"}
        />
      </Container>

    </ThemeProvider>
  );
}
export default UpdateTimeSheet;