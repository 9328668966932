import { PagingInfo } from "../queryparams/PagingInfo";
import { BidQueryParam } from "../queryparams/BidQueryParam";
import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";

export const postChatBot = async (message: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/chatApi`;
  return await executePostData(url, message, accessToken);
};

//This api is used for both salesperson and customer to get the bid/project order no based on the userUID.
export const getBidInfoByQueryParam = async (bidQueryParam: BidQueryParam, pagingInfo: PagingInfo, accessToken: any) => {
  //const url = `${ConfigUrl.baseURL}/bids/getBidInfoByQueryParam?PageNo=${pagingInfo.page}&PageSize=${pagingInfo.size}`;
  const url = `${ConfigUrl.baseURL}/bids/getBidInfoByQueryParam?page=${pagingInfo.page}&size=${pagingInfo.size}`;  
  JSON.stringify(bidQueryParam);
  return await executePostData(url, bidQueryParam, accessToken);
}

export const getBidInfoByBidId = async (bidId:any, accessToken: any) => {
  //const url = `${ConfigUrl.baseURL}/bids/getBidInfoByQueryParam?PageNo=${pagingInfo.page}&PageSize=${pagingInfo.size}`;
  const url = `${ConfigUrl.baseURL}/bids/getBidInfoByBidId/bidId/${bidId}`;  
  return await executeGetData(url, accessToken);
}

//export const storeDataToBlob = async (bidId:any, bidContent:any, accessToken:any) => {
//  const url = `${ConfigUrl.baseURL}/chatApi/storeData/bidId/${bidId}/content/${bidContent}`;
//  return await executePutData(url, null, accessToken)
//}
