import { Task } from "../components/react-gantt-component/types/public-types";
export function initTasks() {
  const currentDate = new Date();
  const tasks: Task[] = [
    // {
    //   start: new Date("2023-10-27T09:00:00"),
    //   end: new Date("2023-11-09T14:30:00"),
    //   name: "Roofing",
    //   id: "ProjectSample",
    //   // progress: 25,
    //   type: "project",
    //   hideChildren: false,
    //   progress: 60,
    //   Phase: "project",
    //   dependencies: [""],
    // },

    {
      start: new Date("2023-10-27T09:00:00"),
      end: new Date("2023-10-27T18:30:00"),
      name: "1.Sales",
      id: "1",
      // progress: 45,
      type: "project",
      hideChildren: false,
      // project: "ProjectSample",
      progress: 60,
      Phase: "Sales",
    },
    {
      start: new Date("2023-10-27T09:00:00"),
      end: new Date("2023-10-27T10:00:00"),
      name: " 1.1 Received Project Request",
      id: "2",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
     
    },
    {
      start: new Date("2023-10-27T10:00:00"),
      end: new Date("2023-10-27T10:30:00"),
      name: "1.2 Scheduled Meeting",
      id: "3",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
      dependencies: ["2"],

    },
    {
      start: new Date("2023-10-27T10:30:00"),
      end: new Date("2023-10-27T13:30:00"),
      name: "1.3 Met to review project sie & material types",
      id: "4",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
      dependencies: ["3"],

    },
    {
      start: new Date("2023-10-27T13:30:00"),
      end: new Date("2023-10-27T14:30:00"),
      name: "1.4 Took Property Pictures",
      id: "5",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 20,
      Phase: "Sales",
      dependencies: ["4"],
    },
    {
      start: new Date("2023-10-27T14:30:00"),
      end: new Date("2023-10-27T15:30:00"),
      name: "1.5 Identify haards, staging concerns, & parking needs",
      id: "6",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
      dependencies: ["4"],
    },
    {
      start: new Date("2023-10-27T15:30:00"),
      end: new Date("2023-10-27T16:00:00"),
      name: "1.6 Tenant approved/Confirmed",
      id: "7",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
      dependencies: ["4"],
    },
    {
      start: new Date("2023-10-27T16:00:00"),
      end: new Date("2023-10-27T16:30:00"),
      name: "1.7 Executed",
      id: "8",
      // progress: 45,
      type: "task",
      project: "1",
      progress: 60,
      Phase: "Sales",
      dependencies: ["7"],
    },

    {
      start: new Date("2023-10-27T00:00:00"),
      end: new Date("2023-10-30T23:59:00"),
      name: "2.preConstruction",
      id: "9",
      hideChildren: false,
      type: "project",
      // project: "ProjectSample",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["1"],
    },
    {
      start: new Date("2023-10-27T16:30:00"),
      end: new Date("2023-10-30T19:30:00"),
      name: "2.1 Create BOM(s) - Materials, Subcontractors & Vendors required",
      id: "10",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["1"],
    },
    {
      start: new Date("2023-10-30T19:30:00"),
      end: new Date("2023-10-30T22:30:00"),
      name: "2.2 BOM(s) approved",
      id: "11",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["10"],
    },
    {
      start: new Date("2023-10-30T14:30:00"),
      end: new Date("2023-10-30T15:30:00"),
      name: "2.3 BOMS(s) associated with existing templated and/or added scheduled deliveries",
      id: "12",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["11"],
    },
    {
      start: new Date("2023-10-30T11:30:00"),
      end: new Date("2023-10-30T12:00:00"),
      name: "2.4 Ordered Construction Permit",
      id: "13",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["10"],
    },
    {
      start: new Date("2023-10-30T12:00:00"),
      end: new Date("2023-10-30T12:30:00"),
      name: "2.5 Ordered Parking Permits",
      id: "14",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["10"],
    },
    {
      start: new Date("2023-10-30T12:30:00"),
      end: new Date("2023-10-30T13:00:00"),
      name: "2.6 Ordered Electrical Haard Protection",
      id: "15",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["10"],
    },
    {
      start: new Date("2023-10-30T13:00:00"),
      end: new Date("2023-10-30T13:30:00"),
      name: "2.7 Registered for Manufacturer Warranty",
      id: "16",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["10"],
    },
    {
      start: new Date("2023-10-30T15:30:00"),
      end: new Date("2023-10-31T09:30:00"),
      name: "2.8 Phone Follow Ups with BOM resources to confirming receipt/approvals or counter offers",
      id: "17",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["12"],
    },
    {
      start: new Date("2023-10-30T12:00:00"),
      end: new Date("2023-11-06T12:00:00"),
      name: "2.9 Permit received",
      id: "18",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["13"],
    },
    {
      start: new Date("2023-10-30T15:30:00"),
      end: new Date("2023-10-30T16:30:00"),
      name: "2.10 Confirm Unique Shop Tools are functioning and prep for use",
      id: "19",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["12"],
    },
    {
      start: new Date("2023-10-30T16:30:00"),
      end: new Date("2023-10-30T17:00:00"),
      name: "2.11 Confirm if GC is ready",
      id: "20",
      type: "task",
      project: "9",
      progress: 60,
      Phase: "preConstruction",
      dependencies: ["19"],
    },


  
      {
        start: new Date("2023-11-06T12:00:00"),
        end: new Date("2023-11-09T14:30:00"),
        name: "3.activeConstruction",
        id: "21",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "project",
        hideChildren: false,
        // project: "ProjectSample",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["9"],
      },
      {
        start: new Date("2023-11-06T12:00:00"),
        end: new Date("2023-11-06T13:00:00"),
        name: "3.1 Drive to jobsite",
        id: "22",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["9"],
      },
      {
        start: new Date("2023-11-06T13:00:00"),
        end: new Date("2023-11-06T14:00:00"),
        name: "3.2 Jobsite Meeting",
        id: "23",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["22"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T14:30:00"),
        name: "3.3 Meet w Customer",
        id: "24",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T15:00:00"),
        name: "3.4 Protect Property",
        id: "25",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T16:00:00"),
        name: "3.5 Safety SetUp",
        id: "26",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T16:00:00"),
        name: "3.6 Tool SetUp",
        id: "27",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T14:30:00"),
        name: "3.7 Receive Materials",
        id: "28",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-06T14:30:00"),
        name: "3.8 Stage Materials",
        id: "29",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.9 Receive Bathroom (if applicable)",
        id: "30",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.10 Need Bathroom",
        id: "31",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-08T16:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.12 Notify for removals",
        id: "32",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["31"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.13 Need Machinery",
        id: "33",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-08T16:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.14 Notify for removals",
        id: "34",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["37"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.15 Receive Trash Removal",
        id: "35",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-06T14:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.16 Receive Removal",
        id: "36",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["23"],
      },
      {
        start: new Date("2023-11-08T16:00:00"),
        end: new Date("2023-11-08T16:00:00"),
        name: "3.17 Notify for removal",
        id: "37",
        // progress: 10,
        // dependencies: ["Task 1"],
        type: "task",
        project: "21",
        progress: 60,
        Phase: "activeConstruction",
        dependencies: ["37"],
      },
    
    
    // Use the tasksData as needed in your application.
    
    {
      start: new Date("2023-11-08T14:30:00"),
      end: new Date("2023-11-09T13:30:00"),
      name: "4.postConstruction",
      id: "60",
      progress: 60,
      type: "project",
      hideChildren: false,
      Phase: "PostConstruction",
      // project: "ProjectSample",
      dependencies: ["49"],
    },
    {
      start: new Date("2023-11-08T14:30:00"),
      end: new Date("2023-11-08T15:00:00"),
      name: "4.1 Register warranty",
      id: "61",
      progress: 60,
      type: "task",
      Phase: "PostConstruction",
      project: "60",
      dependencies: ["49"],
    },
    {
      start: new Date("2023-11-09T12:30:00"),
      end: new Date("2023-11-09T13:00:00"),
      name: "4.2 Call customer to confirm satisfaction & market for online reviews",
      id: "62",
      progress: 60,
      type: "task",
      Phase: "PostConstruction",
      project: "60",
      dependencies: ["58"],
    },
    {
      start: new Date("2023-11-09T13:00:00"),
      end: new Date("2023-11-09T13:30:00"),
      name: "4.3 Send Thank you card",
      id: "63",
      progress: 60,
      type: "task",
      Phase: "PostConstruction",
      project: "60",
      dependencies: ["62"],
    },
    {
      start: new Date("2023-11-09T13:00:00"),
      end: new Date("2023-11-09T13:00:00"),
      name: "4.4 Email Final Invoice",
      id: "64",
      progress: 60,
      type: "task",
      Phase: "PostConstruction",
      project: "60",
      dependencies: ["62"],
    },
    
    
    // {
    //   start: new Date("2023-11-10T13:00:00"),
    //   end: new Date("2023-11-10T13:00:00"),
    //   name: "Party Time",
    //   id: "65",
    //   // progress: 0,
    //   // isDisabled: true,
    //   type: "milestone",
    //   project: "ProjectSample",
    //   progress: 60,
    //   Phase: "Release",
    // },
  ];
  return tasks;
}
export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;
  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}