/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 21/06/24 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    customer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2196           |  24/06/24   | Aish       |  time sheet details page   to render the details for admin and employees alternatively basd on roles
*
*
*/
import { Grid } from "@mui/material"
import React from "react";
import { getUserSession } from "../../../hooks/UserSession";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import EmployeeTimeLogs from "../../../pages/EmployeTimeLogs";
import TimeSheet from "./TimeSheet";

const TimeSheetView: React.FC = () => {
    const [role, setRole] = React.useState('');
    const [loading, setLoading] = React.useState(true);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const fetchRoleInform = async () => {
        try {
            const response = await getUserSession(accessToken);

            setRole(response.role.name);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            //console.lerror('Error fetching user session:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };
    React.useEffect(() => {
        fetchRoleInform();
        // getImage();
    }, [])

    return (
        <div className='searchBar'>
              
        <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
        {role==='admin'? <EmployeeTimeLogs/>:<TimeSheet/>      } 
            </Grid>
            </div>
    )}
    export default  TimeSheetView;