/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For finance side payment details
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/

import WarningIcon from '@mui/icons-material/Warning';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Close, Label } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import './PaymentDetails.scss'
import {
    Card,
    Typography,
    CardActions,
    CardHeader,
    IconButton,
    Modal,
    Grid,
    Stack,
    Divider,
    Button,
    TextField,
    Box,
} from "@mui/material";
import React from "react";






function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
function PaymentDetails() {
    // const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        // <div className="main-div"></div>


        <>
            <Grid container justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} className='headerTable'>
                    <Stack direction="row"> Payment Details</Stack>
                </Grid>
                <Grid item xs={12} >
                    <Card className="POCard mb-2 mt-2">

                        <Grid item xs={12} p={1} pb={2} className='headerTable'>
                            <Stack direction="row">Payment Done</Stack>
                        </Grid>
                        <Divider />
                        <CardActions >
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} md={6} >
                                    <Typography className='invoiceDateNo mb-3'>

                                        <Typography className='mb-3'>
                                            Invoice No:&nbsp;<span className="costInformation">&nbsp;004&nbsp;</span>
                                        </Typography>


                                        <Typography variant="body1">
                                            Invoice Date:<span className="date-info">&nbsp;07-31-2021</span>
                                        </Typography>

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography>
                                        <Typography >
                                            <Typography >
                                                <Typography className='mb-3'>
                                                    Cost:&nbsp;<span className="totalCost"    >&nbsp;$17,100.00&nbsp;</span>
                                                </Typography>
                                            </Typography>
                                            <Typography >
                                                <Stack direction='row'>
                                                    <CheckCircleIcon className="datePaid" />&nbsp;Date Paid:<span className="date-info">&nbsp;08-10-2021</span>
                                                </Stack>
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>

                        <Divider />
                        <CardActions >
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} md={6} >
                                    <Typography className='invoiceDateNo mb-3'>

                                        <Typography className='mb-3'>
                                            Invoice No:&nbsp;<span className="costInformation">&nbsp;004&nbsp;</span>
                                        </Typography>


                                        <Typography variant="body1">
                                            Invoice Date:<span className="date-info">&nbsp;07-31-2021</span>
                                        </Typography>

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography>
                                        <Typography >
                                            <Typography >
                                                <Typography className='mb-3'>
                                                    Cost:&nbsp;<span className="totalCost">&nbsp;$17,100.00&nbsp;</span>
                                                </Typography>
                                            </Typography>
                                            <Typography >
                                                <Stack direction='row'>
                                                    <CheckCircleIcon className="datePaid" />&nbsp;Date Paid:<span className="date-info">&nbsp;08-10-2021</span>
                                                </Stack>
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>

                    </Card>

                </Grid>
                <Grid item xs={12}>
                    <Card className="POCard mb-2">
                        <Grid item xs={12} p={1} pb={2} className='headerTable'>
                            <Stack direction="row"> Pending Payment/Upcoming Payment</Stack>
                        </Grid>
                        <Divider />
                        <CardActions >
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} md={6} >
                                    <Typography className='invoiceDateNo mb-3'>

                                        <Typography className='mb-3'>
                                            Invoice No:&nbsp;<span className="costInformation">&nbsp;004&nbsp;</span>
                                        </Typography>


                                        <Typography variant="body1">
                                            Invoice Date:<span className="date-info">&nbsp;07-31-2021</span>
                                        </Typography>

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography>
                                        <Typography >
                                            <Typography >
                                                <Typography className='mb-3'>
                                                    Cost:&nbsp;<span className="pendingPayment"    >&nbsp;$17,100.00&nbsp;</span>
                                                </Typography>
                                            </Typography>
                                            <Typography >
                                                <Stack direction='row'>
                                                    <WarningIcon className='warningIcons' />Date Paid:<Stack className="date-info">&nbsp;08-10-2021</Stack>
                                                </Stack>
                                            </Typography>
                                            <Typography mt={1} mb={1}>
                                                <Button variant='contained' size="small" onClick={handleOpen}>
                                                    <AttachMoneyIcon className='attachMoneyIcon' /> <Stack className='paymentDetails'>Make Payment </Stack>&nbsp;
                                                </Button>
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>
                        <Divider />
                        <CardActions >
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} md={6} >
                                    <Typography className='invoiceDateNo mb-3'>

                                        <Typography className='mb-3'>
                                            Invoice No:&nbsp;<span className="costInformation">&nbsp;004&nbsp;</span>
                                        </Typography>


                                        <Typography variant="body1">
                                            Invoice Date:<span className="date-info">&nbsp;07-31-2021</span>
                                        </Typography>

                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography>
                                        <Typography >
                                            <Typography >
                                                <Typography className='mb-3'>
                                                    Cost:&nbsp;<span className="pendingPayment"    >&nbsp;$17,100.00&nbsp;</span>
                                                </Typography>
                                            </Typography>
                                            <Typography >
                                                <Stack direction='row'>
                                                    <WarningIcon className='warningIcons' />Date Paid:<Stack className="date-info">&nbsp;08-10-2021</Stack>
                                                </Stack>
                                            </Typography>
                                            <Typography mt={1} mb={1}>
                                                <Button variant='contained' size="small" onClick={handleOpen}>
                                                    <AttachMoneyIcon className='attachMoneyIcon' /> <Stack className='paymentDetails'>Make Payment </Stack>&nbsp;
                                                </Button>
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActions>



                    </Card>
                </Grid>
            </Grid>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <Typography style={modalStyle} className="paper">

                    <Box display="flex" alignItems="center" pl={2} mt={1}>
                        <Box flexGrow={1} >
                            <Stack direction="row" >
                                <Typography className='headerTable'>Credit Card Details</Typography>
                            </Stack>
                        </Box>
                        <Box>
                            <IconButton onClick={handleClose}>
                                <Close sx={{ color: "red" }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Grid container spacing={1} p={4} rowSpacing={3}>
                        {/* <PaymentPortal /> */}
                        {/* <Grid item xs={12}>
                            <TextField
                                id="demo-helper-text-aligned"
                                label="Card Owner Name"
                                size="small"
                                fullWidth
                                helperText=""
                                value=''
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction='row'><TextField
                                id="demo-helper-text-aligned"
                                label="Valid Card Number"
                                size="small"
                                fullWidth
                                helperText=""
                                value=''
                            /><label id="cardicon" ><CreditCardIcon className='creditIcon' /></label>
                            </Stack>
                        </Grid>

                        <Grid item xs={8}>
                            <Grid container>
                                <Grid item xs={12}> <label>Expiration Date</label></Grid>
                                <Grid item xs={6}>

                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="MM"
                                        size="small"
                                        fullWidth
                                        helperText=""
                                        value=''
                                        type='number'
                                    />
                                </Grid>
                                <Grid item xs={6} >
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="YY"
                                        size="small"
                                        fullWidth
                                        helperText=""
                                        value=''
                                        type='number'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}> <Grid item xs={12}> <label>CVV ?</label></Grid>
                            <Grid item xs={12}>

                                <TextField
                                    id="demo-helper-text-aligned"
                                    label="MM"
                                    size="small"
                                    fullWidth
                                    helperText=""
                                    value=''
                                    type='number'
                                />
                            </Grid>
                        </Grid>



                        <Typography className="modalContent">
                            <Stack className="card-footer" direction='row'>
                                <Button variant='contained' size='small'>Confirm Payment</Button>
                            </Stack>
                        </Typography> */}


                    </Grid>


                </Typography>

            </Modal>
        </>




    );

}

export default PaymentDetails;