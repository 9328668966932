/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import { executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const getpersonalInfo= async (email:any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/personalInfo/hasEmployeeDetails/email/${email}`;
    return await executeGetData(url, accessToken);
  };
  export const getcustomerInfo= async (email:any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/personalInfo/hasCustomer/email/${email}`;
    return await executeGetData(url, accessToken);
  };


  export const createPersonalInfo = async (personalDetails: any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/personalInfo/createPersonalInfo`;
    return await executePostData(url, personalDetails, accessToken);
  };

  export const getUserDetails= async (userId:any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/personalInfo/hasEmployeeDetails/userId/${userId}`;
    return await executeGetData(url, accessToken);
  };
  export const updateEmployeeDetail= async (personalDetails: any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/personalInfo/updateEmployeeDetails`;
    return await executePutData(url,personalDetails, accessToken);
  };