/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for listing    employee 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useState } from 'react';
import ProjectList from '../../components/projectList/ProjectList';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
// import {  getUserSessionDetails } from '../../hooks/UserSession';
import GPS from '../../components/gps/Gps';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack, Typography } from "@mui/material";
import CompletedTaskFeed from '../../components/completedTaskFeed/CompletedTaskFeed';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { error } from 'console';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Employeetable from './Employeetable';


const EmployeeManagement: React.FC = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    // const [userSessionDetails, setUserDetails] = useState(getUserSessionDetails(accessToken));
    // const [success, setSuccess] = React.useState(false);
    // const [error, setError] = React.useState(false);
    return (
        <>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }} mt={2}>
                <Grid item xs={9}>
                    <Grid item xs={12}><Employeetable /></Grid>
                </Grid>
                {/* <Grid item xs={3}>
                <Stack direction="column" spacing={2}>
                    {isUserHasPermission("GPS") && <Grid item xs={12}><GPS /></Grid>}
                    <Grid item xs={12} className='headerTable'>
                        <Stack direction="column" spacing={2}>
                            <InsertChartIcon />&nbsp; Completed Task Feed
                            <CompletedTaskFeed />
                        </Stack>
                    </Grid>
                    </Stack>
                  
                       
                    
                </Grid> */}
            </Grid>
        </>
    )
}

export default EmployeeManagement;