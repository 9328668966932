/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1415           |  10/04/24   | shiyam Kumar  |  Implement a Dialog Box For Update Detials
* 2218           |  06/06/24   | Vanitha       |  Genenral confoguration no value hasbeen changed implementation
*/

import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SignatureCanvas from 'react-signature-canvas';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Modal,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../Header";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Stack } from "@mui/system";
import "../asset/Asset.scss";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router";
import { tokens } from "../../hooks/theme";
import { useLocation, } from "react-router-dom";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Tenanatconfiguration.scss";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getUserSession } from "../../hooks/UserSession";
import { format } from "date-fns";
import { createSignatureContract, generalConfig, getSignature, getTenantDetails, upadateTenantDetails } from "../../services/TenantService";
import CustomDialog from "../../pages/Dialog";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import moment from "moment";


const containerStyle = {
  width: "100%",
  height: "300px",
};


const libraries: any = ["places"];

const Tenatconfiguration: React.FC = () => {

  const [code, setCode] = useState<any>("");
  const newDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
  const [data, setData] = useState<any>('');
  const [showUpdateButton, setShowUpdateButton] = React.useState<any>(false);
  const [validationDialog, setValidationDialog] = React.useState(false);
  const navigate = useNavigate();

  const [assetData, setAssetData] = React.useState<any>(() => ({
    tenantid: "",
    createdAt: newDate,
    updatedAt: newDate,
    createdBy: data?.firstname || "",
    status: "Provision",
    secondaryContact: "",
    primaryContact: "",
    mobileNo: "",
    alternateMobileNo: "",
    name: '',
    website: '',
    address: "",
    // email:data?.email,
    country: "",
    zipcode: "",
    state: "",
    description: "",
    city: "",
    location: null,
    tenantLogo: '',
    // stories: null,
    email: "",
    alternateEmail: '',
    signature: "",
    scheduleWithAi: "false",
    estimateWithAi:"false"
  }));


  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];


  const [textValue, setTextValue] = useState('');
  const [response, setResponse] = useState<any>();
  const [openSave, setOpenSave] = useState<boolean>(false); // Declare openMatrec state variable
  const [openFailed, setOpenFailed] = useState<boolean>(false); // Declare openMatrec state variable

  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState<File | string>();
  const [image, setImage] = React.useState<any>(null);

  const handleOpenSaveCancel = async () => {
    setOpenSave(false);
    const response = await getTenantDetails(accessToken);
    setResponse(response.data)
    window.location.reload()

  };
  const getFile = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setAssetData({
            ...assetData,
            tenantLogo: reader.result,
            createdBy: data?.firstname,


          })
        }
      };
      reader.onerror = (error) => {
        console.error('Error occurred while reading the file:', error);
      };
    }
    // Do something with the selected file, e.g., upload or process it
    console.log('Selected file:', selectedFile);
  };
  const handleOpenFailed = () => {
    setOpenFailed(false);

  };
  const handleOpenCancel = (setOpenUpdateValue) => {
    setOpenUpdate(setOpenUpdateValue);
    // navigate(page);
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTextValue = event.target.value;
    setTextValue(newTextValue);
    setSaveButtonDisabled(newTextValue.trim() === ''); // Enable the button if text is not empty
    if (event.target.name === "primaryContact") {
      setAssetData({
        ...assetData,
        [event.target.name]: event.target.value,

      });
    }
    else {
      setAssetData({
        ...assetData,
        status: "Provision",
        createdBy: data?.firstname || '',
        createdAt: newDate,
        [event.target.name]: event.target.value,

      });
    }

  };


  const handleProcedureContentChange = (content: string, _, __, editor: any) => {
    // Enable the button if text is not empty
    setAssetData({
      ...assetData,
      description: content,
    })
    setCode(content);
    // setSaveButtonDisabled(editor.getLength() === 1); // Adjust the condition as needed
  };
  // console.log(assetData)

  // const handleUpdateButtonClick = async () => {

  //   setCount(count + 1);
  //   let validated = validation();

  //   if (response.data.address === assetData.address && response.data.description === assetData.description && response.data.country === assetData.country && response.data.tenantLogo === assetData.tenantLogo
  //     && response.data.zipCode === assetData.zipCode && response.data.state === assetData.state && response.data.city === assetData.city && response.data.scheduleWithAi === assetData.scheduleWithAi && file
  //   ) {
  //     setValidationDialog(true);
  //   }
  //   else {
  //     // const signatureResponse = handleSignatureToBlob();
  //     // console.log(signatureResponse)
  //     if (validated === true) {
  //       const response = await upadateTenantDetails(assetData, assetData?.id, accessToken);
  //       try {
   
  //         // Handle save logic here
  //         // console.log('Saving:', code);

  //         console.log('Saving:', response);
  //         setOpenUpdate(true);
  //         if (response.ok) {
  //           setUpdateSuccess(true);
  //         }
  //         else {
  //           setUpdateError(true)
  //         }

  //         // setResponse(response.data)
  //       }
  //       catch {
  //         console.log('err:', response);
  //         setUpdateError(true);
  //       }
  //     }
  //     else {
  //       setValidationDialog(true);
  //     }
  //   }
  // }

  const handleUpdateButtonClick = async () => {
    setCount(count + 1);
    let validated = validation();

    // Check if any value has been changed
    const hasValueChanged =
        response.data.address !== assetData.address ||
        response.data.description !== assetData.description ||
        response.data.country !== assetData.country ||
        response.data.tenantLogo !== assetData.tenantLogo ||
        response.data.zipCode !== assetData.zipCode ||
        response.data.state !== assetData.state ||
        response.data.city !== assetData.city ||
        response.data.scheduleWithAi !== assetData.scheduleWithAi ||
        response.data.estimateWithAi !== assetData.estimateWithAi ||
        file;

    if (!hasValueChanged) {
        // No value has been changed, show a message or perform any other action
        setValidationDialog(true);
        console.log("No value has been changed.");
        return;
    }

    if (validated === true) {
        const response = await upadateTenantDetails(assetData, assetData?.id, accessToken);
        try {
            console.log('Saving:', response);
            setOpenUpdate(true);
            if (response.ok) {
                setUpdateSuccess(true);
            } else {
                setUpdateError(true);
            }
        } catch {
            console.log('err:', response);
            setUpdateError(true);
        }
    } else {
        setValidationDialog(true);
    }
}

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };

  let location = useLocation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
    libraries: libraries
  });

  const [map, setMap] = React.useState(null);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [matRecSignature, setMatRecSignature] = useState<string | null>(null);
  // const [editorHtml, setEditorHtml] = useState('<h2>Contract Signing</h2><p>Sign below:</p>');
  const editorRef = React.useRef<ReactQuill>(null); // Explicitly define the type of editorRef
  const [matrecSign, setMatrecSign] = useState<string>(""); // Declare matrecSign state variable
  const signatureRef = React.useRef<any>(null); // Declare signatureRef with its type
  const [openmatrec, setOpenmatrec] = useState<boolean>(false); // Declare openMatrec state variable
  const [editorHtml, setEditorHtml] = useState('');
  const [file, setFile] = useState<File | any>(null);

  // const [openmatrec, setOpenmatrec] = useState<boolean>(false);
  // const signatureRef = React.useRef<any>(null);
  // const [file, setFile] = useState<File | any>(null);
  // const [matRecSignature, setMatRecSignature] = useState<string | null>(null);
  // const [matrecSign, setMatrecSign] = useState<string>("");

  // const handlematrecOpen = () => {
  //   setOpenmatrec(true);
  // };
  // const handlematrecCancel = () => {
  //   setOpenmatrec(false);
  // };

  // const handlematrecSave = () => {
  //   console.log(signatureRef.current)
  //   if (signatureRef.current) {
  //     // Get a reference to the canvas element
  //     const canvas = signatureRef.current.getCanvas();

  //     // Create a temporary canvas with the desired dimensions
  //     const tempCanvas = document.createElement('canvas');
  //     tempCanvas.width = 150; // Set the new width
  //     tempCanvas.height = 100; // Set the new height

  //     // Draw the original canvas content onto the temporary canvas with the new dimensions
  //     const tempCtx = tempCanvas.getContext('2d');
  //     if (tempCtx) {
  //       tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
  //     }

  //     // Convert the temporary canvas to a data URL
  //     const signatureBase64 = tempCanvas.toDataURL();
  //     const updatedEditorHtml = response + `<img src="${signatureBase64}" alt="Signature" />`;
  //     console.log(updatedEditorHtml)
  //     setEditorHtml(updatedEditorHtml);
  //     setFile(signatureBase64)

  //     // Create a download link for the signature image
  //     const link = document.createElement('a');
  //     link.href = signatureBase64;
  //     // link.download = 'signature.png'; // Set the file name for the downloaded image
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // document.body.removeChild(link);

  //     // Update state with the signature data
  //     setMatrecSign(signatureBase64.replace(/^data:image\/(png|jpeg);base64,/, ""));
  //     setMatRecSignature(signatureBase64);
  //   }
  //   // setOpenmatrec(false);
  // };

  // const [editorHtml, setEditorHtml] = useState<any>('');

  const rowData = location?.state?.tenantDetails;
  const [message, setMessage] = React.useState<any>({
    propertyName: "",
    country: "",
    zipcode: "",
    state: "",
    address: "",
    city: "",
    stories: null,
  });
  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);

  // const [show, setShow] = React.useState(false);

  const fetchRoleInform = async () => {
    try {
      const response = await getUserSession(accessToken);
      setData(response)

    } catch (error) {
      console.error('Error fetching user session:', error);
    }
  };

  const handleSaveButtonClick = async () => {
    setCount(count + 1);
    let validated = validation();
    if (validated === true) {
      // const signatureResponse = handleSignatureToBlob();
      // console.log(signatureResponse)
      const response = await generalConfig(assetData, accessToken);
      try {
        // Handle save logic here
        // console.log('Saving:', code);

        console.log('Saving:', response);
        if (response.ok) {
          setOpenSave(true);
        }
        else {
          setOpenFailed(true)
        }

      }
      catch {
        console.log('err:', textValue);
      }
    }
    else {
      handleClick('Not Inserted');
    }
  };
  const handleCardClick = () => {
    const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
    fileInput.click();
  };
  const componentData = React.useRef<any>();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  // const [selectedFile, setSelectedFile] = React.useState<File | string>();
  const [toastMessage, setToastMessage] = React.useState("");
  // const assetDataObject = location.state?.assetObjectData;
  const inputRef = React.useRef<any>();

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(assetData.location);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onClick = (e: google.maps.MapMouseEvent) => {
    componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };
    let locationObject: any = {};
    const geocoder = new google.maps.Geocoder();
    const fulladdress: any[] = [];
    geocoder.geocode({ location: componentData.current }).then((response) => {
      if (response.results[0]) {
        // eslint-disable-next-line array-callback-return
        response.results[0].address_components.map((address: any) => {
          if (
            address.types.includes("street_number") ||
            address.types.includes("sublocality_level_1") ||
            address.types.includes("sublocality_level_2") ||
            address.types.includes("route") ||
            address.types.includes("landmark") ||
            address.types.includes("premise")
          ) {
            fulladdress.push(address.long_name);
            // setLocation({...location,address:fulladdress.join(",")});
            // Object.assign(location,{address:fulladdress.join(",")});
            locationObject["address"] = fulladdress.join(",");
          } else if (address.types.includes("locality")) {
            // setLocation({...location,city:address.long_name});
            locationObject["city"] = address.long_name;
          } else if (address.types.includes("administrative_area_level_1")) {
            // setLocation({...location,state:address.long_name});
            locationObject["state"] = address.long_name;
          } else if (address.types.includes("country")) {
            // setLocation({...location,country:address.long_name});
            locationObject["country"] = address.long_name;
          } else if (address.types.includes("postal_code")) {
            // setLocation({...location,zipCode:address.long_name});
            locationObject["zipCode"] = address.long_name;
          }
        });
      }
      setAssetData({
        ...assetData,

        zipcode: locationObject?.zipCode || '',
        country: locationObject?.country || '',
        address: locationObject?.address || '',
        city: locationObject?.city || '',
        state: locationObject?.state || '',
        location: Object.values(componentData.current).join(",") || ''
      });

      // Object.assign(location,locationObject);
      // setLocation(locationObject);
    });
  };
  const convertLocation = (locationString: any) => {
    let locationObject: any = {};

    // Check if locationString is a string
    if (typeof locationString === 'string') {
      let st = locationString?.split(",");

      // Check if split operation yielded at least two elements
      if (st.length >= 2) {
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        // console.log(locationObject);

      } else {
        console.error("Invalid location format. Unable to extract latitude and longitude.");
      }
    } else {
      console.error("Invalid location. Input must be a string.");
    }

    return locationObject;
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = (position: any) => {
    // Ensure that assetData is defined before attempting to access or modify its properties
    if (assetData) {
      componentData.current = {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      };
      assetData["location"] = componentData?.current;
      // setAssetData({ ...assetData,  location: componentData.current });
    } else {
      console.log("assetData is not defined");
    }
  };
 

  React.useEffect(() => {

    (async () => {
      const oldresponse = await getTenantDetails(accessToken);
      setResponse(oldresponse);
      console.log(oldresponse);
      if (oldresponse.data !== undefined) {
        setAssetData(oldresponse.data);
        setSelectedFile(oldresponse.data.tenantLogo)
        setShowUpdateButton(true);
      }
    })()
    getLocation();
    fetchRoleInform();
    setAssetData({
      tenantid: "",
      createdAt: newDate,
      updatedAt: newDate,
      createdBy: data?.firstname || "",
      status: "Provision",
      secondaryContact: "",
      primaryContact: "",
      mobileNo: data?.mobileNo || "",
      alternateMobileNo: "",
      name: '',
      website: '',
      address: "",
      // email:data?.email,
      country: "",
      zipcode: "",
      state: "",
      description: code,
      city: "",
      location: "",
      tenantLogo: '',
      // stories: null,
      email: "",
      alternateEmail: '',
    });
    // locationDetails();
    const assetDataObject = location.state?.tenantDetails;
    // setResponse(assetDataObject);
    if (assetDataObject !== undefined) {
      setAssetData(assetDataObject);
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      error => {
        console.error('Error getting current location:', error);
        // Handle errors if necessary
      }
    );
    setCode(rowData?.description);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputchange = (e: any) => {
    const newTextValue = e.target.value;
    setTextValue(newTextValue);
    setSaveButtonDisabled(newTextValue.trim() === '');
    setAssetData({
      ...assetData,
      [e.target.name]: e.target.value,

    });
  };
  const handleChange = (event: any) => {
    console.log(event.target.checked);
    setAssetData({
      ...assetData,
      [event.target.name]: (event.target.checked).toString(),

    });
    console.log(assetData);
  };

  const handleClick = (message: any) => {
    setOpen(true);
    setToastMessage(message);
  };

  const validation = () => {
    let validated: any = true;
    if (assetData?.email === "") {
      message.email = "Enter Your email id ";
      validated = false;
    }
    if (assetData?.primaryContact === "") {
      message.primaryContact = "Enter Primary Contact";
      validated = false;
    }
    if (assetData?.state === "") {
      message.state = "Enter Your State";
      validated = false;
    }
    if (assetData?.city === "") {
      message.city = "Enter Your City";
      validated = false;
    }
    if (assetData?.country === "") {
      message.country = "Enter Your Country";
      validated = false;
    }
    if (assetData?.tenantLogo === "") {
      message.tenantLogo = "Upload TenantLogo";
      validated = false;
    }

    if (assetData?.zipcode === "") {
      message.zipCode = "Enter Your ZipCode";
      validated = false;
    }
    if (assetData?.address === "") {
      message.address = "Enter Your address";
      validated = false;
    }
    if (assetData?.createdAt === "") {
      message.createdAt = "Enter Your Created Date ";
      validated = false;
    }

    if (assetData?.name === "") {
      message.tenantid = "Enter Your Company Name";
      validated = false;
    }
    return validated;
  };


  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    const fulladdress: any[] = [];
    let locationObject: any = {};
    if (place) {
      place.address_components.map((address: any) => {
        if (
          address.types.includes("street_number") ||
          address.types.includes("sublocality_level_1") ||
          address.types.includes("sublocality_level_2") ||
          address.types.includes("route") ||
          address.types.includes("landmark") ||
          address.types.includes("premise")
        ) {
          fulladdress.push(address.long_name);
          locationObject["address"] = fulladdress.join(",");
        } else if (address.types.includes("locality")) {
          locationObject["city"] = address.long_name;
        } else if (address.types.includes("administrative_area_level_1")) {
          locationObject["state"] = address.long_name;
        } else if (address.types.includes("country")) {
          locationObject["country"] = address.long_name;
        } else if (address.types.includes("postal_code")) {
          locationObject["zipCode"] = address.long_name;
        } else {
          locationObject["zipCode"] = "";
          locationObject["address"] = fulladdress.join(",");
        }
      });
      componentData.current = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setAssetData({
        ...assetData,
        createdBy: data?.firstname || '',
        mobileNo: data?.mobileNo || '',
        zipcode: locationObject?.zipCode || '',
        country: locationObject?.country || '',
        address: locationObject?.address || '',
        city: locationObject?.city || '',
        state: locationObject?.state || '',
        location: Object.values(componentData.current).join(",") || ''
      });
    }
  };
  const handlematrecSave = () => {
    console.log(signatureRef.current)
    if (signatureRef.current) {
      // Get a reference to the canvas element
      const canvas = signatureRef.current.getCanvas();

      // Create a temporary canvas with the desired dimensions
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = 150; // Set the new width
      tempCanvas.height = 100; // Set the new height

      // Draw the original canvas content onto the temporary canvas with the new dimensions
      const tempCtx = tempCanvas.getContext('2d');
      if (tempCtx) {
        tempCtx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
      }

      // Convert the temporary canvas to a data URL
      const signatureBase64 = tempCanvas.toDataURL();
      const updatedEditorHtml =  `<img src="${signatureBase64}" alt="Signature" />`;
      console.log(updatedEditorHtml)
      setEditorHtml(updatedEditorHtml);
      setFile(`<img src="${signatureBase64}" alt="Signature" />`)

      // Create a download link for the signature image
      const link = document.createElement('a');
      link.href = signatureBase64;
      // link.download = 'signature.png'; // Set the file name for the downloaded image
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      // Update state with the signature data
      setMatrecSign(signatureBase64.replace(/^data:image\/(png|jpeg);base64,/, ""));
      setMatRecSignature(signatureBase64);
    }

    setOpenmatrec(false);
  };

  const handlematrecCancel = () => {
    setOpenmatrec(false);
  };
  const handlematrecOpen = () => {
    setOpenmatrec(true);
  };

  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedFile = event.target.files?.[0];
  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(selectedFile);
  //     reader.onload = () => {
  //       if (typeof reader.result === 'string') {
  //         setAssetData({
  //           ...assetData,
  //           tenantLogo: reader.result,
  //           createdBy: data?.firstname,

  //         })
  //       }
  //     };
  //     reader.onerror = (error) => {
  //       console.error('Error occurred while reading the file:', error);
  //     };
  //   }
  //   // Do something with the selected file, e.g., upload or process it
  //   console.log('Selected file:', selectedFile);
  // };

  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [noChange, setNoChange] = React.useState(false);
  const [successType, setSuccessType] = React.useState('');

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    setSuccess(false);
    setError(false);
    setNoChange(false)

  }
  const handleCancel = () => {

    navigate('/commondashboard');
  };

  return (
    <><Box sx={{ width: "100%" }}>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        mx="5px"
        p="3px"
        mb="40px"
      >
        <Header title="General configuration" subtitle={""} />
        {/* <Box>
        <FormControlLabel
          name = 'scheduleWithAi'
          checked = {assetData.scheduleWithAi === "true" ? true : assetData.scheduleWithAi === "false" ? false : false}
          // value = {assetData.scheduleWithAi === "true" ? true : false}
          control={<Switch color="primary"/>}
          label="Schedule With AI"
          onChange={(e) => handleChange(e)}
          labelPlacement="start"
        />
    </Box>  */}
    <Box>
        <FormControlLabel
          name = 'estimateWithAi'
          checked = {assetData.estimateWithAi === "true" ? true : assetData.estimateWithAi === "false" ? false : false}
          // value = {assetData.scheduleWithAi === "true" ? true : false}
          control={<Switch color="primary"/>}
          label="Estimate With AI"
          onChange={(e) => handleChange(e)}
          labelPlacement="start"
        />
    </Box> 
      </Box>
      <Grid
        container
        sx={{ p: 0 }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >

        <Grid item xs={12} md={10}>
          <Grid
            container
            sx={{ p: 0 }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {rowData === undefined ? <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Company Name"
                fullWidth
                size="small"
                onChange={handleTextChange}
                name="name"
                helperText={(assetData?.name === '' && count !== 0) ? message?.name : null}
                error={(assetData?.name === '' && count !== 0)}

                value={assetData?.name}
                InputProps={{ readOnly: response !== undefined, disableUnderline: true }} />
            </Grid> : <></>}

            {/* <Grid item xs={12} md={6}>
      <TextField
        required
        id="outlined-required"
        label="Created date"
        fullWidth
        size="small"
        type="date"
        onChange={handleTextChange}
        defaultValue="2023-12-12" // Format: YYYY-MM-DD
        value={assetData?.createdAt}
        name="createdAt"
        helperText={(assetData?.createdAt === '' && count !== 0) ? message?.createdAt : null}
        InputProps={{ readOnly: assetData?.createdAt !== null, disableUnderline: true }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format='DD MMM YYYY'
          label="Created Date"
          slotProps={{ textField: { fullWidth: true, size: "small", error: (assetData?.createdAt !== null || assetData?.createdAt !== undefined) } }}
          value={(assetData?.createdAt !== undefined) ? dayjs(assetData?.createdAt) : (assetData?.createdAt === undefined) ? null : null}
          onChange={(date) => updateDate(date)}

        />
      </LocalizationProvider>
    </Grid> */}
            <Grid item xs={12} md={12}>
              <Typography>Description</Typography>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                value={assetData?.description}
                onChange={handleProcedureContentChange} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box sx={{ marginTop: '80px' }}>
            {/* <FileUpload onFileUpload={onFileUpload}  /> */}

            <Card variant="outlined" className='tenantImageUpload' onClick={handleCardClick} raised sx={{ maxWidth: 200, maxHeight: 160, margin: '0 auto', padding: '0.1em', marginTop: '20px' }}

            >
              <label>
                {typeof selectedFile === "string" ? (
                  <img src={selectedFile} alt="Selected" className='provisioningImage configImg' />
                ) : (
                  <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                    <Typography>
                      <UploadIcon />
                    </Typography>
                    <Typography className="logoText">
                      Click to Upload company logo
                    </Typography>
                  </Stack>
                )}
              </label>
              <input
                type="file"
                id="image-upload-input"
                onChange={(e) => getFile(e)}
                style={{ display: "none" }}
                accept="image/*"
              />

            </Card>
            {/* <FileUpload onFileUpload={onFileUpload}  /> */}
            {/* <Card raised sx={{ maxWidth: 280, maxHeight: 280, margin: '0 auto', padding: '0.1em' }}>

              <CardMedia
                component="div" // Use a div to easily overlay content
                sx={{
                  position: 'relative',
                  padding: '1em',
                  minHeight: '250px',
                }}
              >
             
                {response ? (
                  <img
                    src={` ${assetData.tenantLogo}`}
                    alt=""
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                ) : (
                  <img
                    src={` ${assetData.tenantLogo !== undefined ? assetData.tenantLogo : ""}`}
                    alt=""
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                )} */}

            {/* 

                <input
                  type="file"
                  accept="image/*" // Specify accepted file types if needed
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange} />

                <IconButton aria-label="settings" onClick={handleIconClick} style={{
                  position: 'absolute',
                  top: 5,
                  right: 8,
                  width: '35px', // Set the width of the overlay icon
                  height: '35px', // Set the height of the overlay icon
                  backgroundColor: '#3c5bfa'
                }}>
                  <UploadFileIcon style={{
                    color: 'white'
                  }} />
                </IconButton>
              </CardMedia>
            </Card> */}
          </Box>
        </Grid>
        <Grid
          container
          sx={{ pt: 4, pl: 3, mt: 5 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary Contact Name"
              size="small"
              fullWidth
              required
              defaultValue=""
              value={assetData?.primaryContact}
              helperText={(assetData?.primaryContact === '' && count !== 0) ? message?.primaryContact : null}
              error={(assetData?.primaryContact === '' && count !== 0)}
              name="primaryContact"
              onChange={handleTextChange}
              disabled={response !== undefined}
            // disabled={response.data !== undefined}
            //InputProps={{ readOnly: response !== null || undefined, disableUnderline: true }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary Mobile Number"
              size="small"
              fullWidth
              required
              onChange={handleTextChange}
              name="mobileNo"
              defaultValue=""
              value={assetData?.mobileNo}
              helperText={(assetData?.mobileNo === '' && count !== 0) ? message?.mobileNo : null}
              error={(assetData?.mobileNo === '' && count !== 0)}
              // disabled={response !== null}
              disabled={response !== undefined}
            //InputProps={{ readOnly: response !== null || undefined, disableUnderline: true }} 
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary e-Mail ID"
              size="small"
              fullWidth
              required
              value={assetData?.email}
              onChange={handleTextChange}
              // defaultValue="xxx@yyy.com"
              name="email"
              helperText={(assetData?.email === '' && count !== 0) ? message?.email : null}
              error={(assetData?.email === '' && count !== 0)}
              disabled={response !== undefined}
            // disabled={assetData.email !== ''}
            //InputProps={{ readOnly: response !== null || undefined, disableUnderline: true }} 
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary Contact Name"
              onChange={handleTextChange}
              size="small"
              name="secondaryContact"
              fullWidth
              value={assetData?.secondaryContact} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary Mobile Number"
              onChange={handleTextChange}
              name="alternateMobileNo"
              size="small"
              fullWidth
              value={assetData?.alternateMobileNo} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary e-Mail ID"
              onChange={handleTextChange}
              name="alternateEmail"
              size="small"
              fullWidth
              value={assetData?.alternateEmail} />
          </Grid>

          {/* <Grid item xs={12} md={4} >
                                  <TextField
                                      error={(assetData.propertyName === '' && count !== 0)}
                                      id="demo-helper-text-aligned"
                                      label="Property Name"
                                      name="propertyName"
                                      size="small"
                                      fullWidth
                                      value={assetData.propertyName}
                                      // value={assetDataObject ? assetDataObject.propertyName : assetData.propertyName}
                                      required
                                      helperText={(assetData.propertyName === '' && count !== 0) ? message.propertyName : null}
                                      onChange={(e) => inputchange(e)}
                                  />
                              </Grid> */}

          <Grid item xs={12} md={4}>
            {isLoaded && (
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <TextField
                  error={assetData?.address === "" && count !== 0}
                  id="Street address"
                  label="Street address"

                  name="address"
                  size="small"
                  fullWidth
                  value={assetData?.address}
                  // value={assetDataObject ? assetDataObject.address : assetData.address}
                  required
                  helperText={(assetData?.address === '' && count !== 0) ? message?.address : null}

                  // helperText={message.address}
                  onChange={(e) => inputchange(e)} />
              </StandaloneSearchBox>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.city === "" && count !== 0}
              id="demo-helper-text-aligned"
              label="City"
              size="small"
              fullWidth
              required
              name="city"
              value={assetData?.city}
              // value={assetDataObject ? assetDataObject.city : assetData.city}
              helperText={assetData?.city === "" && count !== 0 ? message.city : null}
              onChange={(e) => inputchange(e)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.state === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="State"
              size="small"
              fullWidth
              name="state"
              required
              value={assetData?.state}
              // value={assetDataObject ? assetDataObject.state : assetData.state}
              onChange={(e) => inputchange(e)}
              helperText={assetData?.state === "" && count !== 0 ? message?.state : null} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.country === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="Country"
              size="small"
              fullWidth
              value={assetData?.country}
              // value={assetDataObject ? assetDataObject.country : assetData.country}
              helperText={assetData?.country === "" && count !== 0 ? message?.country : null}
              name="country"
              required
              onChange={(e) => inputchange(e)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.zipcode === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="Zip Code"
              size="small"
              name="zipCode"
              fullWidth
              value={assetData?.zipcode}
              // value={assetDataObject ? assetDataObject.zipCode : assetData.zipCode}
              required
              helperText={assetData?.zipcode === "" && count !== 0 ? message.zipcode : null}
              onChange={(e) => inputchange(e)} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Website Address"
              size="small"
              fullWidth
              name="website"
              onChange={handleTextChange}
              value={assetData?.website} />
          </Grid>


          <Grid item xs={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Card sx={{ minWidth: "100%", p: 2 }}>
                <Stack direction={"row"}>
                  <Typography>
                    <MapOutlinedIcon fontSize="small" />
                  </Typography>
                  <Typography>Choose from MAP</Typography>
                </Stack>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={convertLocation(assetData.location)}
                    zoom={30}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={onClick}
                  >
                    <MarkerF
                      position={convertLocation(assetData.location)}
                    >
                    </MarkerF>

                    <></>

                  </GoogleMap>
                ) : <></>}
              </Card>
            </Box>
          </Grid>
        
         
        </Grid>
        <Grid container spacing={1} ></Grid>
        <Grid container justifyContent="flex-end">
          <Box m={2} className="poFooter">
            <Button
              sx={{ marginRight: "32px" }}
              variant="contained"
              className={`ErrorTonalButton`}
              onClick={handleCancel}
            >
              Cancel
            </Button>

            {showUpdateButton ? (
              <Button
                className="button"
                variant="contained"
                size="medium"
                onClick={() => handleUpdateButtonClick()}
              >
                Update
              </Button>
            ) : (
              <Button
                className="button"
                variant="contained"
                size="medium"
                onClick={handleSaveButtonClick}
                disabled={isSaveButtonDisabled}

              >
                Save
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openSave}
        onClose={handleOpenSaveCancel}

      >
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent>
            <Typography textAlign={'center'}><DoneIcon sx={{ fontSize: "80px", color: "#0ea347", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Added Successfully
            </Typography>
            {/* <Typography textAlign={'center'}>
        You won't be able to revert this!
    </Typography> */}
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOpenSaveCancel} className="successButton">Ok</Button>
          {/* <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
        Yes, Delete it!
    </Button> */}
        </DialogActions>

      </Dialog>

      <Dialog
        open={openFailed}
        onClose={handleOpenFailed}

      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent>
            <Typography textAlign={'center'}><ErrorOutlineIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Error Occured
            </Typography>
            {/* <Typography textAlign={'center'}>
        You won't be able to revert this!
    </Typography> */}
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOpenFailed} className="successButton">Ok</Button>
          {/* <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
        Yes, Delete it!
    </Button> */}
        </DialogActions>

      </Dialog>

      {/* <Dialog
        open={validationDialog}
        onClose={() => setValidationDialog(false)}
      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent>
            <Typography textAlign={'center'}><ErrorOutlineIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              No Changes To Update
            </Typography> */}
      {/* <Typography textAlign={'center'}>
        You won't be able to revert this!
    </Typography> */}
      {/* </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={() => setValidationDialog(false)} className="successButton">Ok</Button> */}
      {/* <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
        Yes, Delete it!
    </Button> */}
      {/* </DialogActions>

      </Dialog> */}

      <Dialog open={validationDialog} >
        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
            {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="NoChangeButton" onClick={() => setValidationDialog(false)}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <CustomDialog
        open={openUpdate}
        onClose={() => handleOpenCancel(false)}
        success={updateSuccess}
        error={updateError}
        Content={updateSuccess ? "Updated successfully" : "Error could not updated"}
      />



    </Box>

    </>
  );
}

export default Tenatconfiguration;