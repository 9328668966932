
import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";



export const saveAsset = async (asset: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/assets/addasset`;
  return await executePostData(url, asset, accessToken);
};

export const getAsset = async(customerUid:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/assets/getAssetByCustomerUid/customerUid/${customerUid}`;
  return await executeGetData(url, accessToken);
}

export const deleteAssetDocument = async(assetId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/assetDocument/deleteAssetDocumentById/assetId/${assetId}`;
  return await executeDeleteData(url, accessToken);
}

export const deleteAsset = async(assetId:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/assets/delete/id/${assetId}`;
  return await executeDeleteData(url, accessToken);
}

export const editAsset = async(id:any, payload:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/assets/editAssetDetails/id/${id}`;
  return await executePutData(url, payload, accessToken);
}
export const checkAsset = async(accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/assets/getAsset/checkAssetIsEmpty`;
    return await executeGetData(url,accessToken);
  }