/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 4-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Grid, InputBase,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import { getBOMList } from '../../services/BomService';
import { useTheme } from '@mui/system';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import SearchIcon from "@mui/icons-material/Search";


interface Data {
    id: number;
    taskid: number;
    taskname: string;
    start: string;
    end: string;
    duration: number,
    total: number
}

function createData(
    id: number,
    taskid: number,
    taskname: string,
    start: string,
    end: string,
    duration: number,
    total: number
): Data {
    return {
        id,
        taskid,
        taskname,
        start,
        end,
        duration,
        total
    };
}

const rows = [
    createData(1, 0, '', '', '', 0, 0),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'taskid',
        numeric: false,
        disablePadding: true,
        label: 'Material Id',
    },
    {
        id: 'taskname',
        numeric: true,
        disablePadding: false,
        label: 'Material Name',
    },
    {
        id: 'start',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'end',
        numeric: true,
        disablePadding: false,
        label: 'Cost',
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell 
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {/* <TableCell className='tablerows '>ACTION</TableCell> */}
                </TableRow>
            </TableHead>
        </>
    );
}

const BOMList: React.FC<any> = (props) => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('taskid');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [searchParams, setSearchParams] = React.useState<any>(null);
    const navigate = useNavigate();
    const theme = useTheme();

    const [bomList, setBOMList] = React.useState([] as any[]);


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: theme.palette.primary.main, // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const fetchData = async (searchParams:any) => {
        const response = await getBOMList(props?.projectId, searchParams, page, rowsPerPage, accessToken);
       // console.log(response);
        setBOMList(response.data);
        console.log(response.data);
    }

    const inputchange = async (e: any) => {
        var value = e.target.value === "" ? null : e.target.value;
        if(e.target.value.length >= 3){
          setSearchParams(value);
          fetchData(value);
        }
        if(e.target.value.length === 0){
          setSearchParams(value);
          fetchData(value);
        }
      };

    React.useEffect(() => {
        fetchData(null);
    }, [])


    return (

        <>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={10} sm={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                        <Grid container mt={1}>
                            <Grid item xs={3} p={3}> 
                                <Paper
                                    variant="outlined"
                                    component="form"
                                    className='searchField'
                                    sx={{ p: "2px 4px", display: "flex", alignItems: "center"}}
                                >
                                    <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search by name"
                                    inputProps={{ "aria-label": "search google maps" }}
                                    onChange={(e) => inputchange(e)}
                                    />
                                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                    <SearchIcon />
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows.length}
                                    />

                                    <TableBody>
                                        {bomList.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                // row.bom.map((data: any) => {
                                                //     return (
                                                        <TableRow
                                                            hover
                                                            onClick={(event) => handleClick(event, row.id)}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.id}
                                                            selected={isItemSelected}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell padding="checkbox">
                                                                <CustomCheckbox
                                                                    className='check'
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                               
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="none"
                                                            >
                                                                {row?.materialId}
                                                            </TableCell>
                                                            <TableCell >{row?.name}</TableCell>
                                                            <TableCell >{row?.unit}</TableCell>
                                                            <TableCell >{row?.unitCost}</TableCell>
                                                            <TableCell >{row?.quantity}</TableCell>
                                                            <TableCell >{row?.totalCost}</TableCell>
                                                        </TableRow>
                                                    )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={bomList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>

                    </Box>
                </Grid>
            </Grid>
        </>

    );
}

export default BOMList;