import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData, executePostData, executePutData } from "./ApiBase";


export const clockIn = async (data: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/clockTime/addTimeLog`;
  return await executePostData(url, data, accessToken);
};


export const breakIn = async (timeLogId: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/breakTime/timeLogId/${timeLogId}`;
  return await executePostData(url, null, accessToken);
};

export const getTimelogById = async (id: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/scheduleId/${id}`;
  return await executeGetData(url,accessToken);
};
export const getTimeLogDetailsByProjectId = async (projectId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/getTimeLogDetails/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};
export const getAdminTimeLogDetailsByProjectId = async (projectId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/getAllTimeLogDetails/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};
export const getUserTimeSheets = async (accessToken: any,startTime:any,endTime:any, page: any, size: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/timesheet/startTime/${startTime}/endTime/${endTime}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};
export const getAllTimeSheets = async (accessToken: any,startTime:any,endTime:any, page: any, size: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheet/startTime/${startTime}/endTime/${endTime}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};
export const searchAllTimeSheetByName = async (accessToken: any,startTime:any,endTime:any, page: any, size: any,name:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheet/startTime/${startTime}/endTime/${endTime}/name/${name}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};
export const getAllTimeSheetsByDate = async (accessToken: any,startTime:any,endTime:any, page: any, size: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheetByDate/startTime/${startTime}/endTime/${endTime}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getAllTimeCounts = async (accessToken: any,startTime:any,endTime:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheetCount/startTime/${startTime}/endTime/${endTime}`;
  return await executeGetData(url, accessToken);
};
export const updateTimeStatus = async (accessToken: any,timeLogId:any,payload:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/updateTimeLogStatus/timeLogId/${timeLogId}`;
  return await executePutData(url, payload,accessToken);
};
export const updateApproveRejectStatus = async (accessToken: any,timeLogId:any,status:any,payload:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/updateApproveRejectStatus/timeLogId/${timeLogId}/status/${status}`;
  return await executePutData(url, payload,accessToken);
};

export const getUserTimeSheetCount = async (accessToken: any,startTime:any,endTime:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/userTimesheetCount/startTime/${startTime}/endTime/${endTime}`;
  return await executeGetData(url, accessToken);
};

export const getTimeSheetCountByDate = async (accessToken: any,startTime:any,endTime:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheetCountByDate/startTime/${startTime}/endTime/${endTime}`;
  return await executeGetData(url, accessToken);
};
export const searchAllTimeSheetByDate = async (accessToken: any,startTime:any,endTime:any, page: any, size: any,name:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/adminTimesheetByDate/startTime/${startTime}/endTime/${endTime}/name/${name}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};
export const getUserTimeSheetByDate = async (accessToken: any,startTime:any,endTime:any, page: any, size: any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/timesheetByDate/startTime/${startTime}/endTime/${endTime}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getUserTimeSheetCountByDate = async (accessToken: any,startTime:any,endTime:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/employeeTimesheetCountByDate/startTime/${startTime}/endTime/${endTime}`;
  return await executeGetData(url, accessToken);
};

export const searchUserTimeSheetByDate = async (accessToken: any,startTime:any,endTime:any, page: any, size: any,name:any) => {
  const url = `${ConfigUrl.baseURL}/timelogs/getUserTimesheetByDate/startTime/${startTime}/endTime/${endTime}/name/${name}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};