/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Form For Add And Edit Template
* 1557           |  24/04/24   | shiyam kumar  |  Edit,Delete Issue Fix
* 1791           |  04/05/24   | Vanitha       |  Dialog box implementation for no value has been change 
* 1793           |  04/05/24   | Vanitha       |  Dialog box implementation for Deleted successfuly
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
* 1898           | 13-05-2024  | Vanitha       | Update Template implementation
* 1903           | 14-05-2024  | Vanitha       | Add below and edit below edit implementation and resource and esitimated hours fild mandatory fild
* 1936           | 14-05-2024  | Vanitha       | Esitmation hours nagative value not accept implementation
* 2031           | 24-05-2024  | Vanitha       | Esitmation hours mandatory field implementation
* 2032           | 24-05-2024  | Vanitha       | Task Name  mandatory field implementation and Update implementation
* 2124           | 18-06-2024  | Vanitha       | change the wbs generate implementation
* 2124           | 21-06-2024  | Vanitha       | change the wbs generate , id generate and esitmate field display implementation
* 
*/


import { Modal, Grid, Typography, IconButton, FormControl, InputLabel, Select, MenuItem, TextField, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, OutlinedInput, Chip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import genericTemplate from '../../../../assets/generic template.json';
import { addTemplate, getTemplates, updateTradeTemplate } from "../../../../services/TradeTemplateService";
import { usePapaParse } from 'react-papaparse';
import { getTrades } from "../../../../services/TradeConfigurationService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import CustomDialog from "../../../../pages/Dialog";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from "react-router-dom";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';




const TemplateForm: React.FC<any> = (props) => {

    const templateModelStyle = {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'hidden',
    };

    const { jsonToCSV } = usePapaParse();
    const { readString } = usePapaParse();

    let initialTemplate = Object.assign([], genericTemplate);
    const [addedRows, setAddedRows] = React.useState<any>([]);
    const [taskTemplate, setTaskTemplate] = React.useState<any>(initialTemplate);
    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [materialNames, setMaterialNames] = React.useState<any>([]);
    const [validated, setValidated] = React.useState<any>(false);
    const [selectedTrade, setSelectedTrade] = React.useState<any>();
    const [selectedMaterial, setSelectedMaterial] = React.useState<any>();
    const [selectedManufacturer, setSelectedManufacturer] = React.useState<any>();
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);
    const [templateList, setTemplateList] = React.useState<any>([]);
    const taskTypes = ["standard", "confirmation", "nonExecuted", "execution", "dynamicDuration", "dynamicDuration-distance", "fixedDuration", "dynamicDuration-area", "dynamicDuration-parent"]
    const taskPriorities = ["critical", "insignificant"]
    const templateType = ["local", "with SubContractor"]
    const resources = ["sales", "admin", "labour", "foreman", "contractorSupervisor", "shopSupervisor", "vendor", "gms"]
    const timelines = ["workinghours", "24hours"]
    const [templateData, setTemplateData] = React.useState<any>({
        tradeId: props?.selectedTemplate?.trade?.id,
        templateType: props?.selectedTemplate?.templateType,
        description: props?.selectedTemplate?.description,
        tasks: props?.selectedTemplate?.tasks
    });
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [updateSuccess, setUpdateSuccess] = React.useState(false);
    const [updateError, setUpdateError] = React.useState(false);
    const [addSuccess, setAddSuccess] = React.useState(false);
    const [addError, setAddError] = React.useState(false);
    const [noChange, setNoChange] = React.useState(false);
    const [hasChanges, setHasChanges] = React.useState(false);

    const [validatedFields, setValidatedFields] = React.useState<boolean[]>(Array(taskTemplate.length).fill(false));
    const [openDialogTrade, setOpenDialogTrade] = React.useState<boolean>(false);

    // const location = useLocation();
    let navigate = useNavigate();
    const closeTemplate = () => {
        setValidated(false);
        setTaskTemplate(initialTemplate);
        setSelectedTrade(undefined);
        setSelectedMaterial(undefined);
        setSelectedManufacturer(undefined);
        setTemplateData(undefined);
        props.function();
      
        if (props?.selectedTemplate === undefined) {
            window.location.reload();
        }
        
        // navigate('/tenantconfiguration');
    }

    const handleTradeSelect = (event: any) => {
        setSelectedTrade(event.target.value);
        let materials: any = [];
        var array = tradeData.filter(data => data.name === event.target.value);
        array.map((data) => {
            materials.push(data.materialType)
        })
        setMaterialNames(Array.from(new Set(materials)));
    }


    const handleMaterialSelect = (event: any) => {
        setSelectedMaterial(event.target.value);
        var array = tradeData.filter(data => data.name === selectedTrade && data.materialType === event.target.value);
        setTemplateData({ ...templateData, tradeId: array[0].id });

    }
    const addRow = (row: any, index: any, where: any) => {
        if (!row || !row.wbs) {
            console.error("Row or WBS is undefined");
            return;
        }

        let temp = [...taskTemplate];
        let element = {
            additionalData: "",
            costCode: "",
            dependencies: undefined,
            estimatedDays: '',
            estimatedHours: undefined,
            id: row.id,
            phase: row.phase,
            resources: undefined,
            taskName: undefined,
            taskPriority: undefined,
            taskType: undefined,
            timeLine: undefined,
            tools: "",
            wbs: ""
        };

        let wbsParts = row.wbs.split(".");
        let lastPart = parseInt(wbsParts[wbsParts.length - 1]);

        if (isNaN(lastPart)) {
            console.error("Invalid WBS format");
            return;
        }

        if (where === "below") {
            lastPart++;
            wbsParts[wbsParts.length - 1] = String(lastPart);
            element.wbs = wbsParts.join(".");
            element.id = parseInt(row.id) + 1;

            temp.splice(index + 1, 0, element);

            for (let i = index + 2; i < temp.length; i++) {
                let currentWbsParts = temp[i].wbs.split(".");
                let parentWbs = wbsParts.slice(0, -1).join(".");
                if (temp[i].wbs.startsWith(parentWbs)) {
                    currentWbsParts[wbsParts.length - 1] = String(parseInt(currentWbsParts[wbsParts.length - 1]) + 1);
                    temp[i].wbs = currentWbsParts.join(".");
                }
                temp[i].id = parseInt(temp[i].id) + 1;
            }
        } else {
            element.id = row.id;
            wbsParts[wbsParts.length - 1] = String(lastPart);
            element.wbs = wbsParts.join(".");

            temp.splice(index, 0, element);

            for (let i = index + 1; i < temp.length; i++) {
                let currentWbsParts = temp[i].wbs.split(".");
                let parentWbs = wbsParts.slice(0, -1).join(".");
                if (temp[i].wbs.startsWith(parentWbs)) {
                    currentWbsParts[wbsParts.length - 1] = String(parseInt(currentWbsParts[wbsParts.length - 1]) + 1);
                    temp[i].wbs = currentWbsParts.join(".");
                }
                temp[i].id = parseInt(temp[i].id) + 1;
            }
        }

        setTaskTemplate(temp);
    };



    const getDependencies = (inedx: any) => {
        var deps: any = [];
        var tasks = taskTemplate;
        for (var i = 0; i < taskTemplate.length; i++) {
            if (i < inedx) {
                if (tasks[i].wbs.split(".").length > 2) {
                    deps.push(tasks[i].id);
                }
            }
        }
        return deps;
    }

    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    const handleDeleteRow = (rowId: any) => {
        setTaskTemplate(prevTaskTemplate => {
            const updatedTaskTemplate = prevTaskTemplate
                .filter(row => row.id !== rowId)
                .map((row, index) => {
                    row.id = index + 1; // Reassign ids to be sequential
                    const wbsParts = row.wbs.split(".");
                    wbsParts[wbsParts.length - 1] = String(index + 1);
                    row.wbs = wbsParts.join(".");
                    return row;
                });

            // Also update the addedRows state if it's used to track newly added rows
            const updatedAddedRows = addedRows.filter(id => id !== rowId);

            setAddedRows(updatedAddedRows);

            return updatedTaskTemplate;
        });
    };


    const handleDelete = (resourceIndex: any, index: any) => {
        let newArray = [...taskTemplate];
        var resourceList = newArray[index].resources.split(";");
        var selectedResource = resourceList[resourceIndex];
        resourceList = resourceList.filter(item => item !== selectedResource);
        if (resourceList.length === 0) {
            newArray[index].resources = undefined;
        } else {
            newArray[index].resources = resourceList.join(";");
        }
        setTaskTemplate(newArray);
    }


    const handleEstimatedHoursChange = (event: any, index: any) => {
        const { name, value } = event.target;
        setHasChanges(true);
        if (Number(value) < 0) {
            setValidatedFields(prev => {
                const newValidationState = [...prev];
                newValidationState[index] = true;
                return newValidationState;
            });
            return;
        }

        let newArray = [...taskTemplate];
        newArray[index][event.target.name] = value;

        if (value.trim() === '') {
            setValidatedFields(prev => {
                const newValidationState = [...prev];
                newValidationState[index] = true;
                return newValidationState;
            });
        } else {
            setValidatedFields(prev => {
                const newValidationState = [...prev];
                newValidationState[index] = false;
                return newValidationState;
            });
        }
        setTaskTemplate(newArray);
    };


    const handletaskChange = (event: any, index: any) => {

        const { name, value } = event.target;
        setHasChanges(true);
        let tempArray = [...taskTemplate];
        if (value.trim() === '') {

            setValidatedFields(prev => {
                const newValidationState = [...prev];
                newValidationState[index] = true;
                return newValidationState;
            });

            delete tempArray[index][name];
        } else {

            tempArray[index][name] = value;

            setValidatedFields(prev => {
                const newValidationState = [...prev];
                newValidationState[index] = false;
                return newValidationState;
            });
        }
        setTaskTemplate(tempArray);
    };





    const handleResourceChange = (event: any, index: any) => {
        let tempArray = [...taskTemplate];
        setHasChanges(true);
        if (tempArray[index][event.target.name] !== undefined) {
            if (tempArray[index][event.target.name] !== "") {
                tempArray[index][event.target.name] = tempArray[index][event.target.name] + ";" + event.target.value;
            } else {
                tempArray[index][event.target.name] = event.target.value.join(";");
            }
        } else {
            tempArray[index][event.target.name] = event.target.value.join(";");
        }
        setTaskTemplate(tempArray);
    }

    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        setTradeData(result.data);
        result.data.map((data) => {
            tradenames.push(data.name)
        })
        setTradeNames(Array.from(new Set(tradenames)));
        const response = await getTemplates(accessToken);
        setTemplateList(response.data);
    }


    const validateRows = () => {
        for (let index = 0; index < addedRows.length; index++) {
            const row = taskTemplate[addedRows[index]];
            if (
                row.dependencies === undefined ||
                row.estimatedHours === undefined ||
                row.resources === undefined ||
                row.taskName === undefined ||
                row.taskPriority === undefined ||
                row.taskType === undefined ||
                row.timeLine === undefined
            ) {
                return false;
            }
        }
        return true;
    };


    const storeTemplate = async () => {

        if (selectedTrade !== undefined && selectedMaterial !== undefined && templateData?.templateType !== undefined && validateRows()) {
            setOpenAddDialog(true);
            setValidated(false);
            const results = jsonToCSV(taskTemplate);
            Object.assign(templateData, { tasks: results })
                const res = await addTemplate(templateData, accessToken);
                if (res.status === 201 || res.status === 200) {
                    setAddSuccess(true);
                } else {
                setAddError(true);
            }
        } else {
            setValidated(true);
        }
    }

   
    const updateTemplate = async () => {
        setOpenUpdateDialog(true);
        if (templateData?.templateType !== undefined && validateRows()) {
            setValidated(false);
            for (let index = 0; index < taskTemplate.length; index++) {
                const row = taskTemplate[index];
                if (
                    row.dependencies === undefined ||
                    row.estimatedHours === undefined ||
                    row.resources === undefined ||
                    row.taskName === undefined ||
                    row.taskPriority === undefined ||
                    row.taskType === undefined ||
                    row.timeLine === undefined
                ) {
                    setValidated(true);
                    return;
                }
            }

            const results = jsonToCSV(taskTemplate);
            Object.assign(templateData, { tasks: results });
            const res = await updateTradeTemplate(props?.selectedTemplate?.id, templateData, accessToken);
            if (res.status === 200 || res.status === 201) {
                setUpdateSuccess(true);
            } else {
                setNoChange(true);
            }
        } else {
            setUpdateError(true);
            setValidated(true);
        }
    }

    const closeDialog = () => {
        closeTemplate();
        setOpenAddDialog(false);
        setAddSuccess(false);
        setAddError(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);

    }

    const closeErrorDialog = () => {
        setOpenAddDialog(false);
        setAddSuccess(false);
        setAddError(false);
        setOpenUpdateDialog(false);
        setUpdateSuccess(false);
        setUpdateError(false);
    }

    React.useEffect(() => {
        getTradeList();
        setTemplateData({
            tradeId: props?.selectedTemplate?.trade?.id,
            templateType: props?.selectedTemplate?.templateType,
            description: props?.selectedTemplate?.description,
            tasks: props?.selectedTemplate?.tasks
        });
        readString(props.selectedTemplate?.tasks, {
            header: true,
            worker: true,
            complete: (results: any) => {
                var data = results.data;
                setTaskTemplate(data);
            },
        });
        console.log(taskTemplate);
        // setTaskTemplate(initialTemplate);
    }, []);

    return (
        <><Modal
            open={props.open}
            onClose={closeTemplate}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={templateModelStyle}>
                <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={6} p={0}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" p={1}>
                           {props?.selectedTemplate?.description || "Generic Template"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} p={0}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={closeTemplate}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{ pt: 4, pl: 0, mt: 5 }}
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12}>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                            <Grid item xs={8}>
                                <Grid container spacing={{ xs: 2, md: 3 }}>
                                    <Grid item xs={4}>
                                        {props.selectedTemplate?.trade?.name === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedTrade === undefined && validated}>
                                                <InputLabel id="demo-select-small-label">Select Trade</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='taskType'
                                                    label="Select Trade"
                                                    value={selectedTrade}
                                                    onChange={handleTradeSelect}
                                                >
                                                    {tradeNames.map((trade: any) => (
                                                        <MenuItem value={trade}>{trade}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='taskName' size="small" value={props.selectedTemplate?.trade?.name} disabled />}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {props.selectedTemplate?.trade?.materialType === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedMaterial === undefined && validated}>
                                                <InputLabel id="demo-select-small-label">Select MaterialType</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='taskType'
                                                    label="Select MaterialType"
                                                    value={selectedMaterial}
                                                    onChange={handleMaterialSelect}
                                                >
                                                    {materialNames.map((name: any) => (
                                                        <MenuItem value={name}>{name}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='taskName' size="small" value={props.selectedTemplate?.trade?.materialType} disabled />}
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        {props.selectedTemplate?.trade?.manufacturer === undefined ?
                                            <FormControl fullWidth size="small" required error={selectedManufacturer === undefined && validated}>
                                                <InputLabel id="demo-select-small-label">Select Manufacturer</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    name='taskType'
                                                    label="Select Manufacturer"
                                                    value={selectedManufacturer}
                                                    onChange={handleManufacturerSelect}
                                                >
                                                    {manufacturerNames.map((name: any) => (
                                                        <MenuItem value={name}>{name}</MenuItem>))}
                                                </Select>
                                            </FormControl> :
                                            <TextField fullWidth name='taskName' size="small" value={props.selectedTemplate?.trade?.manufacturer} disabled />}
                                    </Grid> */}
                                    <Grid item xs={4}>
                                        <FormControl fullWidth size="small" required error={(templateData?.templateType === undefined && props?.selectedTemplate?.templateType === undefined) && validated}>
                                            <InputLabel id="demo-select-small-label">Select Type</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name='templateType'
                                                label="Select Type"
                                                value={templateData?.templateType !== undefined ? templateData?.templateType : props.selectedTemplate?.templateType}
                                                onChange={(e) => 
                                                    {
                                                setTemplateData({ ...templateData, templateType: e.target.value }); 
                                                setHasChanges(true);}
                                            }
                                            >
                                                {templateType.map((type: any) => (
                                                    <MenuItem value={type}>{type}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description"
                                    size='small'
                                    value={templateData?.description !== undefined ? templateData?.description : props.selectedTemplate?.description}
                                    onChange={(e) =>{
                                         setTemplateData({ ...templateData, description: e.target.value });
                                         setHasChanges(true);
                                    }}
                                    multiline
                                    fullWidth
                                    rows={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Paper sx={{ width: '100%' }}>
                        <TableContainer sx={{ maxHeight: 300 }}>
                            <Table size="small" stickyHeader aria-label="sticky table">
                                <TableHead sx={{
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    "& .MuiTableCell-root": { backgroundColor: "#c2cbf8" },
                                }}>
                                    <TableRow>
                                        <TableCell align="center">Id</TableCell>
                                        <TableCell align="center">Wbs</TableCell>
                                        <TableCell align="center">TaskName</TableCell>
                                        <TableCell align="center">TaskType</TableCell>
                                        <TableCell align="center">TaskPriority</TableCell>
                                        <TableCell align="center">Dependencies</TableCell>
                                        <TableCell align="center">Phase</TableCell>
                                        <TableCell align="center">Timeline</TableCell>
                                        <TableCell align="center">EstimatedHours</TableCell>
                                        <TableCell align="center">Resources</TableCell>
                                        <TableCell align="center">Action</TableCell>
                                        {/* <TableCell align="center">Tools</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflow: "scroll", maxHeight: 200 }}>
                                    {taskTemplate.map((row: any, index: any) => (
                                        <TableRow key={row.id}>
                                            <TableCell align="center">{row.id}</TableCell>
                                            <TableCell align="center">{row.wbs}</TableCell>
                                            <TableCell align="center">
                                                <TextField
                                                    fullWidth
                                                    id="outlined-multiline-static"
                                                    defaultValue=""
                                                    name="taskName"
                                                    size="small"
                                                    value={row.taskName !== undefined ? row.taskName : ''}
                                                    error={row.taskName === undefined && validated}
                                                    helperText={validatedFields[index] ? "" : ""}
                                                    required
                                                    onChange={(e: any) => handletaskChange(e, index)}
                                                />
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl fullWidth size="small" error={row.taskType === undefined && validated}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="taskType"
                                                        value={row.taskType}
                                                        onChange={(e: any) => handletaskChange(e, index)}
                                                    >
                                                        {taskTypes.map((type) => (
                                                            <MenuItem key={type} value={type}>{type}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormControl fullWidth size="small" error={row.taskPriority === undefined && validated}>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        name="taskPriority"
                                                        value={row.taskPriority}
                                                        onChange={(e: any) => handletaskChange(e, index)}
                                                    >
                                                        {taskPriorities.map((priority) => (
                                                            <MenuItem key={priority} value={priority}>{priority}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </TableCell>
                                            <TableCell align="center">
                                                {(row.dependencies === undefined || addedRows.includes(index)) ? (
                                                    <FormControl fullWidth size="small" error={row.dependencies === undefined && validated}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="dependencies"
                                                            value={row.dependencies}
                                                            onChange={(e: any) => handletaskChange(e, index)}
                                                        >
                                                            {getDependencies(index).map((priority) => (
                                                                <MenuItem key={priority} value={priority}>{priority}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : row.dependencies}
                                            </TableCell>
                                            <TableCell align="center">{row.phase}</TableCell>
                                            <TableCell align="center">
                                                {(row.timeLine === undefined || addedRows.includes(index)) ? (
                                                    <FormControl fullWidth size="small" error={row.timeLine === undefined && validated}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            name="timeLine"
                                                            value={row.timeLine}
                                                            onChange={(e: any) => handletaskChange(e, index)}
                                                        >
                                                            {timelines.map((time) => (
                                                                <MenuItem key={time} value={time}>{time}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                ) : row.timeLine}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(row.estimatedHours !== undefined) ? (
                                                    <TextField
                                                        fullWidth
                                                        name="estimatedHours"
                                                        size="small"
                                                        type="number"
                                                        error={row.estimatedHours === undefined && validated} // Set error based on validation state
                                                        helperText={validatedFields[index] ? "" : ""}
                                                        value={row.estimatedHours !== undefined ? row.estimatedHours : ""}
                                                        required
                                                        onChange={(e: any) => handleEstimatedHoursChange(e, index)}
                                                    />
                                                ) : (
                                                    <TextField
                                                    fullWidth
                                                    name="estimatedHours"
                                                    size="small"
                                                    type="number"
                                                    error={row.estimatedHours === undefined && validated} // Set error based on validation state
                                                    helperText={validatedFields[index] ? "" : ""}
                                                    value={row.estimatedHours !== undefined ? row.estimatedHours : ""}
                                                    required
                                                    onChange={(e: any) => handleEstimatedHoursChange(e, index)}
                                                />
                                                )}
                                            </TableCell>
                                            <TableCell align="center" size="small">
                                                {row.resources?.length !== 0 && (
                                                    <FormControl fullWidth size="small" error={row.resources === undefined && validated}>
                                                        <Select
                                                            labelId="demo-multiple-chip-label"
                                                            id="demo-multiple-chip"
                                                            name="resources"
                                                            multiple
                                                            value={row.resources !== undefined ? row.resources.split(";") : []}
                                                            onChange={(e) => handleResourceChange(e, index)}
                                                            input={<OutlinedInput id="select-multiple-chip" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', gap: 0.5, textOverflow: "ellipsis", zIndex: 1 }}>
                                                                    {selected.map((value, i) => (
                                                                        (i < 2) ? <Chip key={value} label={value}
                                                                            clickable
                                                                            deleteIcon={<CancelIcon
                                                                                onMouseDown={(event) => event.stopPropagation()} />}
                                                                            onDelete={(event) => { handleDelete(i, index); }} /> : (i === 2) ? <Chip key={value} label={"..."} /> : <></>
                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        width: 250,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {resources.map((name) => (
                                                                <MenuItem key={name} value={name}>
                                                                    {name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {(row.wbs !== undefined ? row.wbs.split(".").length > 2 : false) && (
                                                    <Stack direction="row" spacing={1}>
                                                        <Button onClick={() => addRow(row, index, "above")}>Add Above</Button>
                                                        <Button onClick={() => addRow(row, index, "below")}>Add Below</Button>
                                                        <IconButton className="erroriconButton" aria-label="delete" onClick={() => handleDeleteRow(row?.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Stack>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Paper>
                    <Grid item xs={12}>
                        <Box m={2} className="poFooter">
                            {props.selectedTemplate === undefined ? <Button
                                className="button"
                                variant="contained"
                                fullWidth
                                size="small"
                                onClick={storeTemplate}
                            >
                                Save
                            </Button> :
                            (hasChanges ?
                                <Button
                                    className="button"
                                    variant="contained"
                                    fullWidth
                                    size="small"
                                    onClick={updateTemplate}
                                >
                                    Update
                                </Button>
                                :
                                <Button
                                className="button"
                                variant="contained"
                                fullWidth
                                size="small"
                                disabled
                            >
                                Update
                            </Button>
                            )
                                
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
            <CustomDialog
                open={openAddDialog}
                onClose={addSuccess ? closeDialog : closeErrorDialog}
                success={addSuccess}
                error={addError}
                Content={addSuccess ? "Template Added Successfully" : "Failed To Add"} />
            <CustomDialog
                open={openUpdateDialog}
                onClose={updateSuccess ? closeDialog : closeErrorDialog}
                success={updateSuccess}
                error={updateError}
                Content={updateSuccess ? "Template Updated Successfully" : "Failed To Update"} />


            <Dialog open={noChange}>
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            No Value has been Changed
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>

        </>

    );
}

export default TemplateForm;