/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Apr-2024
* @author      : Shiyam kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : BomTemplateService
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1622           |  16/04/24   | shiyam Kumar  |  Service Layer For Bom Template Settings
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
*/

import { executeDeleteData, executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";



export const getBomTemplates = async(accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/bomTemplate`;
  return await executeGetData(url, accessToken);
}
  
export const addBomTemplate = async(tradeTemplateId:any, manufacturer:any, formdata:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/import/bomTemplates/tradeTemplateId/${tradeTemplateId}/manufacturer/${manufacturer}`;
  return await executePostImage(url, formdata, accessToken);
}

export const deleteBoms = async(ids:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/bomTemplate/bomIds/${ids}`;
  return await executeDeleteData(url, accessToken);
}

export const updateBoms = async(updatedRows:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/bomTemplate/update`;
  return await executePutData(url, updatedRows, accessToken);
}