/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 9-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1728           |  29/4/24    |   Vanitha      |  Document table ui improvements related changes
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Grid, Box, Tooltip, Button, MenuItem,
    Select, IconButton, TablePagination, Dialog, DialogActions, DialogContent, Typography
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
    CheckBox,
} from '@mui/icons-material';
import { getDocumentListByProjectId } from '../../services/DocumentService';
import { useEffect, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getContractDetails } from '../../services/ContractSigningService';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { getReceipt } from '../../services/DeliverySlipService';
import Editor from '../ReactQuil/tableModule';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: string,
    fat: string,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Agreement', 'Project Contract Agreement', '25/10/2023', 0, 0),
    createData('PO screenshots', 'Purchase Orders of Project', '1/11/2023', 0, 0),
];



const DocumentList: React.FC<any> = (props: any) => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [data, setData] = useState<any>(); // Declare openMatrec state variable
    const [response, setResponse] = useState<any>(); // Declare openMatrec state variable
    const [openConfirmationFailed, setOpenConfirmationFailed] = useState<boolean>(false); // Declare openMatrec state variable
    const handleOpenConfirmationFailed = () => {
        setOpenConfirmationFailed(false);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const navigate = useNavigate();
    const [data1, setData1] = useState<any>();

    const formatDate = (dateString: string): any => {
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const getContract = async (projectId: any, type: any) => {
        try {
            // Fetch the image data as a Blob
            const response = await getContractDetails(projectId, accessToken);
            console.log(response)
            setResponse(response.data);
            setOpenConfirmationFailed(true);

        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    }

    const getSlip = async (projectId: any, receiptId: any, type: any) => {
        try {
            const res = await getReceipt(projectId, receiptId, accessToken);
            console.log(res);
            // setResponse(res.data)
            setResponse(res.data)
            setOpenConfirmationFailed(true);
        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    }

    const listContract = () => {
        navigate("/contract", {
            state: { response } // Pass projectDTO as state to the /contract route
        });
    }
    const handleCancelConfirmation = () => {
        setOpenConfirmationFailed(false);

        //window.location.reload();
    };

    useEffect(() => {
        const handleGetDocumentByProjectId = async () => {
            try {
                const response = await getDocumentListByProjectId(props?.projectId, accessToken);
                console.log(response.data);
                setData(response.data)
                const data = await fetch(response.data);
                console.log(data)
            } catch (error) {
                console.error('Error fetching user session:', error);
            }
        };
        handleGetDocumentByProjectId();

    }, []);

    const SearchBar = () => (
        <div className='searchBar'>
            <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                {/* <Grid item xs={12}>
              <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                <Grid item xs={10}></Grid>
                
              </Grid>
            </Grid> */}
                <Grid item xs={3} mt={3}>
                    <Select
                        fullWidth
                        value=""
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                    >
                        <MenuItem value="" disabled>
                            Batch Actions
                        </MenuItem>
                        <MenuItem value="1">Option 1</MenuItem>
                        <MenuItem value="2">Option 2</MenuItem>
                        <MenuItem value="3">Option 3</MenuItem>
                    </Select>
                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2} className='createInvoice'>
                    <Grid item xs={12} mt={2}>
                        <Tooltip title="Print">
                            <IconButton>
                                <PrintIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                            <IconButton>
                                <ShareIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            {/* <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={10} sm={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            <SearchBar />
                            <TableContainer>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow cl>
                                            <StyledTableCell className='invoiceRows'>File Name</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Description</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Date</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Download</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Delete</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((document) => (
                                            <StyledTableRow key={document?.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {document?.documentType}
                                                </StyledTableCell>
                                                <StyledTableCell className='invoiceRows'>{document?.description}</StyledTableCell>
                                                <StyledTableCell className='invoiceRows'>{formatDate(document?.createdAt)}</StyledTableCell>
                                                <StyledTableCell className='invoiceRows'><Button onClick={() => getContract(document?.projectId)}>View</Button></StyledTableCell>
                                                <StyledTableCell className='invoiceRows'><DeleteIcon /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </Paper>
                    </Box>
                </Grid >
            </Grid>  */}
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow className='tableHead'>

                                    <TableCell>File Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Download</TableCell>
                                    <TableCell>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((document) => (
                                    <TableRow key={document?.id}>
                                        <TableCell>{document?.documentType}</TableCell>
                                        <TableCell>{document?.description}</TableCell>
                                        <TableCell>{formatDate(document?.createdAt)}</TableCell>
                                        <TableCell>
                                            {document?.documentType === "Contract" ?
                                                <Button onClick={() => getContract(document?.projectId, document?.documentType)}>View</Button> :
                                                <Button onClick={() => getSlip(document.projectId, document?.receiptId, document.documentType)}>View</Button>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            <Dialog
                open={openConfirmationFailed}
                onClose={handleOpenConfirmationFailed}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Contract
                        </Typography>
                        {/* <img src={response} alt='' height="100px" /> */}
                        {/* <ReactQuill
                            theme="snow"
                            value={response}
                            //   onChange={handleChange}
                            modules={modules}
                            readOnly={true}
                        /> */}
                        <Editor
                            value={response}
                            readOnly={true} onChange={function (content: string): void {
                                throw new Error('Function not implemented.');
                            } }
                        //   onChange={handleEditorChange} // Pass the callback to the Editor component
                        // onChange={function (content: string): void {
                        //   throw new Error('Function not implemented.');
                        // } }        // onChange={handleEditorChange}
                        />
                    </DialogContent>
                </Box>
                <DialogActions  sx={{ justifyContent: "end",marginRight:"3px" }}>
                    <Button onClick={handleCancelConfirmation} className="dangerButton">Ok</Button>
                    {/* <Button onClick={() => onDeleteAsset(assetId)} autoFocus className="primaryTonalButton">
                        Yes, Delete it!
                    </Button> */}
                </DialogActions>

            </Dialog>
        </>
    );
}

export default DocumentList;