
/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 29-May-2024
* @author      : Priyankaa
* @since       : 0.0.1
* Requirement# :
* Purpose      : Tenant Mail Configuration Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData, executePostData, executePutData } from "./ApiBase";

export const addMailData = async (payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/addMailData`;
    return await executePostData(url, payload, accessToken);
}

export const getMailDetails = async (accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/getMailDetails`;
    return await executeGetData(url, accessToken);
}


export const getMailData = async (key:any,accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/value/${key}`;
    return await executeGetData(url, accessToken);
}


export const editMailData = async(payload:any, accessToken:any) =>
    {
      const url = `${ConfigUrl.baseURL}/tenantConfigurations/editMailDetails`;
      return await executePutData(url, payload, accessToken);
}

export const addMetrics = async (payload: any, accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/addMetrics`;
    return await executePostData(url, payload, accessToken);
}

export const getMetricsData = async (key:any,accessToken: any) => {
    const url = `${ConfigUrl.baseURL}/tenantConfigurations/value/${key}`;
    return await executeGetData(url, accessToken);
}

export const updateTenantConfigData = async(payload:any, accessToken:any) =>
    {
      const url = `${ConfigUrl.baseURL}/tenantConfigurations/updateTenantConfig`;
      return await executePutData(url, payload, accessToken);
}