/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 11-Dec-2023
* @author      : Karthikeyan.A
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |      AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1231           | dec-11,2023|                 xyz buildGetResult function implementation
* 2096           |  29/05/24   |   Vanitha     |  Current Activity Card view ui design
* 2133           |  31/05/24   |   Vanitha     |  Current Activity Card view ui integration
* 2134           |  04/06/24   |   Vanitha     |  Expences Paid vs due  Card view ui integration 
* 2134           |  06/06/24   | Vanitha       |  data binding for expences paid vs due

*
* 
*
*/


import { Grid, Box, Card, CardContent, Typography, Stack, TextField, Select, MenuItem } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimeField } from '@mui/x-date-pickers';
import { useState, useEffect } from 'react';
import { getTaskByCustomerIdAndStatus } from '../../services/TaskService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { Chart } from "react-google-charts";
import { getProjectBycustomerUId } from '../../services/ProjectService';
import { getPaymentReceive } from '../../services/PaymentService';

const initialData = [
  ['Status', 'Percentage', { role: 'style' }, { role: 'tooltip', p: { html: true } }],
  ['Paid', 0, 'color:#258039', '<div style="padding:5px"><b>Paid:</b> $0</div>'],
  ['Due', 0, 'color: #D33333', '<div style="padding:5px"><b>Due:</b> $0</div>'],
];

const options1 = {
  title: 'Payment Status',
  subtitle: 'Paid vs Due',
  bars: 'horizontal',
  legend: { position: 'none' },
  tooltip: { isHtml: true },
};

export default function Projectactivity() {
  const [tasks, setTasks] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedJobNo, setSelectedJobNo] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const [data1, setData1] = useState(initialData);

  const fetchCurrentTask = async () => {
    try {
      const response = await getTaskByCustomerIdAndStatus(accessTokenPayload.oid, 'active', accessToken);
      console.log("current task", response.data);
      setTasks(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // const fetchProjectByCustomer = async () => {
  //   const response = await getProjectBycustomerUId(accessTokenPayload.oid, page, rowsPerPage, accessToken);
  //   setProjects(response.data);
  //   console.log('response', response.data);

  //   if (response.data.length > 0) {
  //     const firstProjectId = response.data[0].id;
  //     fetchPaymentReceive(firstProjectId);
  //   }
  // };

  const fetchProjectByCustomer = async () => {
    try {
      const response = await getProjectBycustomerUId(accessTokenPayload.oid, page, rowsPerPage, accessToken);
    // console.log('response', response);
  
      if (response && response.data) {
        setProjects(response.data);
        console.log('response.data', response.data);
  
        if (response.data.length > 0) {
          const firstProjectId = response.data[0].id;
          fetchPaymentReceive(firstProjectId);
        } else {
          console.log('No projects found');
        }
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  
  const fetchPaymentReceive = async (projectId: any) => {
    const result = await getPaymentReceive(projectId, accessToken);
    console.log('payment', result.data);

    const paidAmount = result?.data?.paidAmount;
    const dueAmount = result?.data?.balanceAmount;
    const totalAmount = paidAmount + dueAmount;
    const paidPercentage = (paidAmount / totalAmount) * 100;
    const duePercentage = (dueAmount / totalAmount) * 100;

    setData1([
      ['Status', 'Percentage', { role: 'style' }, { role: 'tooltip', p: { html: true } }],
      ['Paid', paidPercentage, 'color:#258039', `<div style="padding:5px"><b>Paid:</b> $${paidAmount}</div>`],
      ['Due', duePercentage, 'color: #D33333', `<div style="padding:5px"><b>Due:</b> $${dueAmount}</div>`],
    ]);
  };

  const handleSelectChange = (event) => {
    const selectedJobNo = event.target.value;
    setSelectedJobNo(selectedJobNo);

    const selectedProject = projects.find((project) => project.id === selectedJobNo);

    if (selectedProject) {
      setSelectedProject(`${selectedProject.trade} ${selectedProject.materialType} ${selectedProject.manufacturer}`);
      setSelectedAmount(selectedProject.totalCost);
      fetchPaymentReceive(selectedJobNo);
    }
  };

  useEffect(() => {
    fetchCurrentTask();
    fetchProjectByCustomer();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Card className='POCard mb-2'>
            <Typography variant="h5" component="div" sx={{ padding: '2vh' }}>
              Current Activity
            </Typography>
            <CardContent style={{ height: '500px', overflow: 'auto' }}>
              {tasks?.length > 0 ? tasks.map((task) => (
                <Card key={task.id} sx={{ overflowY: 'auto', marginBottom: '2vh' }}>
                  <Box sx={{ background: "linear-gradient(to right, #3c5bfa, #7f00ffd4)", color: "white", width: "100%" }}>
                    <Stack direction='row' gap={1} sx={{ alignItems: 'center', justifyContent: 'space-between', padding: '1vh' }}>
                      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'start' }}>
                        <Typography>{task.projectName}</Typography>
                      </Grid>
                      <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Typography>#{task.projectDTO.jobNo}</Typography>
                      </Grid>
                    </Stack>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'start', flexDirection: 'column', marginLeft: '2vh', margin: '2vh', border: 'none' }}>
                    <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                      <TextField
                        fullWidth
                        label="Task Name"
                        sx={{ marginBottom: '1vh', border: 'none' }}
                        id="standard-read-only-input"
                        variant="standard"
                        value={task.taskName || ''}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                      />
                      <TextField
                        fullWidth
                        id="standard-read-only-input"
                        variant="standard"
                        label="Person Assigned"
                        value={task.assignedTo || ''}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        sx={{ marginBottom: '1vh' }}
                      />
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction='column' spacing={2} sx={{ marginBottom: '1vh' }}>
                          <DateTimeField
                            fullWidth
                            id="standard-read-only-input"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            label="Start Date Time"
                            value={dayjs(task.startTime)}
                          />
                          <DateTimeField
                            fullWidth
                            id="standard-read-only-input"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            variant="standard"
                            label="Estimate End Date Time"
                            value={dayjs(task.endTime)}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </Box>
                </Card>
              )) : (
                <Typography>No tasks available</Typography>
              )}
            </CardContent>
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card className='POCard'>
            <Box sx={{ background: "linear-gradient(to right, #3c5bfa, #7f00ffd4)", color: "white", width: "100%" }}>
              <Stack direction='row' gap={1} sx={{ alignItems: 'center', justifyContent: 'space-between', padding: '1vh' }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'start' }}>
                  <Typography variant="h5" component="div" sx={{ padding: '2vh' }}>
                    Expense Paid vs Due
                  </Typography>
                </Grid >
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Select
                    value={selectedJobNo}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{ marginLeft: 2, color: 'white' }}
                  >
                    <MenuItem value="" disabled>Select Order Number</MenuItem>
                    {projects && projects.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.jobNo}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Stack>
            </Box>
            <CardContent>
              {selectedJobNo ? (
                <Card>
                  <Box sx={{ width: "100%", padding: '2vh' }}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Typography variant="h6">
                        {selectedProject} <br />
                        Total Amount: {selectedAmount}
                      </Typography>
                    </Grid>
                  </Box>
                  <Grid>
                    <Typography variant="h6">
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Chart
                      chartType="BarChart"
                      data={data1}
                      options={options1}
                      width={"100%"}
                      height={""}
                    />
                  </Grid>
                </Card>) : (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="h6">
                    Select Order Number
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
