export const PaymentJson = [
    {    
		"paymentNumber": 4123445,
        "invoiceDate": "12-08-2023",
		"dueDate": "12-09-2023",
		"customerName": 'Priyanka',
        "type":'Invoice',
		"customerEmail": 'priyanka2000@gmail.com',
        "companyPhoneNumber":'89893450784',
        'companyEmail':'gracefulmanagement@gms.com',
		"customerAddress": "SoftTeam Solutions Pvt Ltd Information Technology & Services Chennai, Tamil Nadu ",
		"terms": "Net 30days",
		"address": "20 Moores Road Malvern Pennsylvania CertainTeed Corp Country United States of America 19355",
        "companyName": "Certain Teed", 
        "state":'Paid',
		"amount": 77.4,
		"itemDetails": [{
            "id":1,
            "product": "OC VentSure 15inchesx4' Strip",
            "description": "",
            "quantity": 3,
            "rate": 19,
            "project": "GMS"
        },
        {
            "id":2,
            "product": "Bundle-33 LF",
            "description": "",
            "quantity": 2,
            "rate": 10.2,
            "project": "GMS"
        }],
        "message": "Needed within November 15 "  
    },
    {    
		"paymentNumber": 4123446,
        "invoiceDate": "12-08-2023",
		"dueDate": "12-07-2023",
		"customerName": 'Asish',
        "type":'Bill',
		"customerEmail": 'asishsaji2000@gmail.com',
        "companyPhoneNumber":'89893450784',
        "vendorName": 'ABC SUPPLIER',
        'companyEmail':'gracefulmanagement@gms.com',
		"customerAddress": "SoftTeam Solutions Pvt Ltd Information Technology & Services Chennai, Tamil Nadu ",
		"terms": "Net 10 Days",
		"address": "20 Moores Road Malvern Pennsylvania CertainTeed Corp Country United States of America 19355",
        "companyName": "Certain Teed", 
        "state":'UnPaid',
		"amount": 77.4,
		"itemDetails": [{
            "id":1,
            "product": "OC VentSure 15inchesx4' Strip",
            "description": "",
            "quantity": 3,
            "rate": 19,
            "project": "GMS"
        },
        {
            "id":2,
            "product": "Bundle-33 LF",
            "description": "",
            "quantity": 2,
            "rate": 10.2,
            "project": "GMS"
        }],
        "message": "Needed within November 15 "  
    },
    {    
		"paymentNumber": 4123447,
        "invoiceDate": "12-08-2023",
		"dueDate": "12-08-2023",
		"customerName": 'Shyam',
        "type":'Bill',
		"customerEmail": 'shyam2000@gmail.com',
        "companyPhoneNumber":'89893450784',
        'companyEmail':'gracefulmanagement@gms.com',
        "vendorName": 'ABC SUPPLIER',
		"customerAddress": "SoftTeam Solutions Pvt Ltd Information Technology & Services Chennai, Tamil Nadu ",
		"terms": "Net 20 days",
		"address": "20 Moores Road Malvern Pennsylvania CertainTeed Corp Country United States of America 19355",
        "companyName": "Certain Teed", 
		"amount": 77.4,
        "state":'Paid',
		"itemDetails": [{
            "id":1,
            "product": "OC VentSure 15inchesx4' Strip",
            "description": "",
            "quantity": 3,
            "rate": 19,
            "project": "GMS"
        },
        {
            "id":2,
            "product": "Bundle-33 LF",
            "description": "",
            
            "quantity": 2,
            "rate": 10.2,
            "project": "GMS"
        }],
        "message": "Needed within November 15 "  
    },
];
