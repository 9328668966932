import { Task, Task2,ViewMode } from "./react-gantt-component/types/public-types";
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as XLSX from 'xlsx';
import { Margins } from "pdfmake/interfaces";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

export interface CustomTask extends Task {
	Progress?: number;
	dependencies?: string[];
	children?: CustomTask[];
	parent?: CustomTask;
    Duration?: string ;
    parentId?:string;
    parentTask?: string;
  }
  // export interface CustomTask2 extends Task2 {
  //   Progress?: number;
  //   dependencies?: string[];
  //   children?: CustomTask[];
  //   parent?: CustomTask;
  //     Duration?: string ;
  //   }
    
  type Margins1 = [number, number, number, number];

interface Style {
  fontSize: number;
  bold: boolean;
  margin?: Margins;
}

interface StyleDictionary {
  [key: string]: Style;
}

interface TDocumentDefinitions {
  content: any[];
  styles?: StyleDictionary;
}

// const sequenceTask = (tasks:any, subtask:any) => {
//   tasks.map((task:any)=>{
//       if(task.tasks.length > 0){
//         subtask.push(task)
//         sequenceTask(task.tasks, subtask)
//       }else{
//         subtask.push(task)
//       }
//   })
//   return subtask
// }
// export const initTasks = (ganttData:any): CustomTask[] => {
//   const tasks= jsonGanttData.flatMap((project:any) => {
//     console.log(new Date(project.startDate.$date.replace("Z", "")))
//     const projectTask: CustomTask = {
//       type: 'project',
//       id: project.TaskID,
//       name: project.trade,
//       start: new Date(project.startDate.$date.replace("Z", "")),
//       end: new Date(project.endDate.$date.replace("Z", "")),
//       progress: project.Progress ,
//       hideChildren: false,
//       dependencies: ('dependencies' in project ? project.Predecessor : []) as string[],
//       children: []
//     };
//     // const sequencedTasks = sequenceTask(project.tasks,[])
//     const subtasks= project.tasks.map((subtask:any) => ({
//       type: 'task',
//       id: subtask.taskId,
//       name: subtask.taskName,
//       start: new Date(subtask.startDate.$date.replace("Z", "")),
//       end: new Date(subtask.endDate.$date.replace("Z", "") ?? ''),
//       progress: subtask.Progress,
//       hideChildren: false,
//       duration: subtask.estimatedHours, // add duration property
//       dependencies: ('dependencies' in subtask ? [subtask.dependencies] : []) as string[],
//       project: project.TaskID,
//       children: []
//     }))
    
    

//     // add subtasks to project task
//     //projectTask.children = subtasks;
    
//     // const childtask: CustomTask[] = [{
//     //   type: "milestone",
//     //   id: "Task 5",
//     //   name: "1.5.1 MileStone (KT)",
//     //   start: new Date(project.startDate.$date.replace("Z", "")),
//     //   end: new Date(project.endDate.$date.replace("Z", "")),
//     //   progress: 100,
//     //   dependencies: [subtasks[0].id],
//     //   project: project.TaskID,
//     //   children: []
//     // }]
//     // subtasks[0].children = childtask;

//     return [projectTask,...subtasks];
//   });
//  // console.log(tasks)
//   return tasks;
// };
export const exportToPdf = (tasks: CustomTask[]) => {
  console.log(tasks);
    const docDefinition: TDocumentDefinitions = {
      content: [
        { text: 'Task List', style: 'header' },
        {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*'],
            body: [
              ['ID', 'Name', 'Start', 'End'],
              // , 'Progress'
              ...tasks.map((task) => [
                task.id,
                task.name,
                task.start.toLocaleDateString(),
                task.end.toLocaleDateString(),
                // task.progress,
              ]),
            ],
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10] as Margins1,
        },
      },
    };
  
    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.getBlob((blob:any) => {
      saveAs(blob, 'TaskList.pdf');
    });
  };
  // Function to export tasks to Excel
  export const exportToExcel = (tasks:CustomTask[]) => {
    const worksheet = XLSX.utils.json_to_sheet(tasks, {
      header: ['id', 'name', 'start', 'end', 'progress'],
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Task List');
    const excelFile = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  
    const buffer = new ArrayBuffer(excelFile.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < excelFile.length; i++) {
      view[i] = excelFile.charCodeAt(i) & 0xff;
    }
  
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      'TaskList.xlsx'
    );
  };
export const getStartEndDateForProject = (
	tasks: Task[],
	projectId: string
): [Date, Date] => {
	const projectTasks = tasks.filter((t) => t.project === projectId);
	let start = projectTasks[0].start;
	let end = projectTasks[0].end;

	for (let i = 0; i < projectTasks.length; i++) {
		const task = projectTasks[i];
		if (start.getTime() > task.start.getTime()) {
			start = task.start;
		}
		if (end.getTime() < task.end.getTime()) {
			end = task.end;
		}
	}
	return [start, end];
};

export default XLSX;

// export const initTasks2 = (ganttData:any): CustomTask2[] => {
//   const tasks2: CustomTask2[] = jsonGanttData2.flatMap((project:any) => {
//     console.log(new Date(project.startDate.$date.replace("Z", "")))
//     const projectTask: CustomTask2= {
//       type: 'project',
//       id: project.TaskID,
//       name: project.trade,
//       start: new Date(project.startDate.$date.replace("Z", "")),
//       end: new Date(project.endDate.$date.replace("Z", "")),
//       progress: project.Progress ? parseInt(project.Progress) : 0,
//       hideChildren: false,
//       dependencies: ('Predecessor' in project ? project.Predecessor : []) as string[],
//       children: []
//     };
//     // const sequencedTasks = sequenceTask(project.tasks,[])
//     const subtasks: CustomTask2[] = project.tasks.map((subtask:any) => ({
//       type: 'task',
//       id: subtask.taskId,
//       name: subtask.taskName,
//       start: new Date(subtask.startDate.$date.replace("Z", "")),
//       end: new Date(subtask.endDate.$date.replace("Z", "") ?? ''),
//       progress: 0,
//       hideChildren: false,
//       duration: subtask.estimatedHours, // add duration property
//       dependencies: ('dependencies' in subtask ? [subtask.dependencies] : []) as string[],
//       project: project.TaskID,
//       children: []
//     }))

//     // add subtasks to project task
//     projectTask.children = subtasks;
    
//     const childtask: CustomTask2[] = [{
//       type: "milestone",
//       id: "Task 5",
//       name: "1.5.1 MileStone (KT)",
//       start: new Date(project.startDate.$date.replace("Z", "")),
//       end: new Date(project.endDate.$date.replace("Z", "")),
//       progress: 100,
//       dependencies: [subtasks[0].id],
//       project: project.TaskID,
//       children: []
//     }]
//     subtasks[0].children = childtask;

//     return [projectTask,...subtasks];
//   });
//   console.log(tasks2)
//   return tasks2;
// };