import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    Popover, Typography, InputAdornment,
    Stack,
    Chip,
    Card,
    FormControl,
    OutlinedInput,
    InputLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    ListItem,
    Backdrop,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { getTaskList, getTasksByProjectId, reassignTeamMember } from '../../services/TaskService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from "@mui/icons-material/Search";
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider, DatePicker, DateTimePicker, TimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CloseIcon from '@mui/icons-material/Close';
import { taskReschedule } from '../../services/ProjectService';
import moment from 'moment';
import gmslogo from "../../assets/logo.gif";
import CustomDialog from '../../pages/Dialog';
import { getProjectMembers } from '../../services/ProjectMembersService';
import { getResourceByDateRange } from '../../services/ResourceService';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { getUserSession, UserContext } from '../../hooks/UserSession';
import { useContext } from 'react';


interface Data {
    id: number;
    taskid: number;
    taskname: string;
    status: string;
    start: string;
    end: string;
    duration: number;
    resource: string;
}

function createData(
    id: number,
    taskid: number,
    taskname: string,
    status: string,
    start: string,
    end: string,
    duration: number,
    resource: string,
): Data {
    return {
        id,
        taskid,
        taskname,
        status,
        start,
        end,
        duration,
        resource
    };
}
const rows = [
    createData(1, 0, '', '', '', '', 0, ''),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'taskid',
        numeric: false,
        disablePadding: true,
        label: 'Task Id',
    },
    {
        id: 'taskname',
        numeric: true,
        disablePadding: false,
        label: 'Task Name',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'start',
        numeric: true,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'end',
        numeric: true,
        disablePadding: false,
        label: 'End Date',
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        label: 'Duration',
    },
    {
        id: 'resource',
        numeric: true,
        disablePadding: false,
        label: 'Resource',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    role: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, role} =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        {/* <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        /> */}
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    {role === "admin" && <TableCell >action</TableCell>}
                    {/* <TableCell className='tablerows '>ACTION</TableCell> */}
                </TableRow>
            </TableHead>
        </>
    );
}

const TaskList: React.FC<any> = (props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('taskid');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [taskList, setTaskList] = React.useState([] as any[]);
    const [memberList, setMemberList] = React.useState<any>([] as any[]);
    const [resourceList, setResourceList] = React.useState<any>([] as any[]);
    const [deletedIndex, setDeletedIndex] = React.useState<any>([]);
    const [updatedData, setUpdatedData] = React.useState<any>({
        'projectId': props.projectId,
        'projectMembers': [],
        'tasks': []
    });
    const [warning, setWarning] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [teamMemberData, setTeamMemberData] = React.useState<any>();
    const [memberIndex, setMemberIndex] = React.useState<any>();
    const taskStatus = ['active', 'scheduled'];
    const [editedTask, setEditedTask] = React.useState<any>();
    // console.log("editedTask",editedTask)
    const [isEndDate, setIsEndDate] = React.useState<any>(false);
    const [isChanged, setIsChanged] = React.useState<any>(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [deleteWarning, setDeleteWarning] = React.useState(false);
    const[startTime,setStartTime]=React.useState<any>();
    const [role, setRole] = React.useState<any>();
     const { userSessionDetails } = useContext(UserContext);
    const admin = "admin";
    // console.log("startTime",startTime)
    const[endTime,setEndTime]=React.useState<any>();
    // console.log("endTime",endTime)

    const showWarning = (data: any, memberListIndex: any) => {
        setDeleteWarning(true);
        setTeamMemberData(data);
        setMemberIndex(memberListIndex);
    }
    const removeResource = () => {
        let memberListArray = [...memberList];
        memberListArray.splice(memberIndex, 1);
        setMemberList(memberListArray);
        (updatedData.projectMembers).push(teamMemberData);
        let taskListData = [...taskList];
        taskListData.map((dataList, taskIndex) => {
            let arr = taskList[taskIndex].resource;
            let assignees = taskList[taskIndex].assignees;
            let number = arr.findIndex(obj => obj.id === teamMemberData.resourceId)
            if (dataList?.status === "scheduled") {
                if (number >= 0) {
                    assignees.splice(assignees.indexOf(arr[number].resourceUid), 1);
                    arr.splice(number, 1);
                    deletedIndex.push(taskIndex);
                }
            }
        }
        )
        setTaskList(taskListData);
        onCloseDialog();
    }

    const getProjectMembersByProjectId = async () => {
        let result = await getProjectMembers(props?.projectId, accessToken);
        setMemberList(result?.data);
    }
    const handleChange = (event: any, index: any) => {
        deletedIndex.splice(deletedIndex.indexOf(index), 1);
        const {
            target: { value },
        } = event;

        let newArr = [...taskList];

        let difference = [];

        if (value.length > newArr[index]["resource"].length) {
            difference = value.filter((element: any) => !newArr[index]["resource"].includes(element));
        } else {
            difference = newArr[index]["resource"].filter((element: any) => !value.includes(element));
        }
        const taskIndex = (updatedData.tasks).findIndex((value: any) => value.id === newArr[index]['id']);

        let task = Object.assign({}, newArr[index]);

        if (taskIndex >= 0) {
            if ((updatedData.tasks)[taskIndex]["resource"].includes(difference[0]) || task['assignees'].includes(difference[0]["resourceUid"])) {
                (updatedData.tasks)[taskIndex]["resource"].splice((updatedData.tasks)[taskIndex]["resource"].indexOf(difference), 1);
                task['assignees'].splice(task['assignees'].indexOf(difference[0]["resourceUid"]), 1);
                if ((updatedData.tasks)[taskIndex]["resource"].length === 0) {
                    (updatedData.tasks).splice(taskIndex, 1);
                }
            } else {
                (updatedData.tasks)[taskIndex]["resource"].push(difference[0]);
            }
        } else {
            if (difference.length !== 0 && !task['assignees'].includes(difference[0]["resourceUid"])) {
                task["resource"] = difference;
                task['assignees'].push(difference[0]["resourceUid"]);
                (updatedData.tasks).push(task);
            } else {
                task["assignees"].splice(task["assignees"].indexOf(difference[0]["resourceUid"]), 1);
            }
        }

        newArr[index]["resource"] = value;

        setTaskList(newArr);
    };
    const handleOpen = async (e: any, index: any, row: any) => {
        let result = await getResourceByDateRange(row.startTime, row.endTime, accessToken);
        setResourceList(result.data);
    }
    const onCloseDialog = () => {
        setDeleteWarning(false);
    }
    const fetData = async () => {
        const response = await getTasksByProjectId(props.projectId, accessToken);
        setTaskList(response?.data);
    }
    React.useEffect(() => {
        fetData();
        getProjectMembersByProjectId();
        // getUserSession(accessToken).then(res => {
            setRole(userSessionDetails?.role.name);
        // });
    }, [props.project])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const reschedule = async () => {
        setWarning(false);
        setIsLoading(true);
        const wbs = (editedTask.wbs).split(".")
        // console.log(wbs);
        wbs.pop();
        let result;
        if (isEndDate === true) {
            result = await taskReschedule(editedTask.project, editedTask.taskId, wbs.join("."), editedTask.startTime, editedTask.endTime, accessToken);
        }
        else {
            result = await taskReschedule(editedTask.project, editedTask.taskId, wbs.join("."), editedTask.startTime, null, accessToken);
        }
        if (result?.status === 200 || result?.status === 201) {
            setIsLoading(false);
            fetData();
            setWarning(false);
            setOpenDialog(true);
            setSuccess(true);
        } else {
            setWarning(false);
            setIsLoading(false);
            setOpenDialog(true);
            setError(true);
        }
    }

    const closeAlter = () =>{
        setWarning(false);
    }

    const handleLoadingClose = () =>{
        setIsLoading(false);
    }

    const closeDialog = () =>{
        setSuccess(false);
        setError(false);
        setOpenDialog(false);
    }



    const handleRowChange = async (event:any, row:any, field:any, index:any) =>{
        if(new Date(row[field].replace(/Z/g, '')).toString() !== (event.$d).toString()){
            setIsChanged(true);
        } else {
            setIsChanged(false);
        }
        // var task = Object.assign('', row);
        var task = { ...row };
        // task[field] = dateFormatter(event.$d);
      
        task[field] = dayjs(event.$d).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        if(field === 'startTime'){
            setStartTime(dayjs(event.$d))
        }
        else{
            setEndTime(task[field]);
        }

        let Arr = [...taskList];
        let estimatedHours = 0;

        if(field === 'endTime'){
            // setStartTime(dayjs.utc(row.startTime).format('YYYY-MM-DD HH:mm:ss[Z]'))
        // const  rowStartTime  = dayjs.utc(row.startTime).format('YYYY-MM-DDTHH:mm:ss[Z]');
        const rowStartTime = dayjs((row?.startTime)?.replace(/Z/g, ''));
        //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
        estimatedHours = calculateWorkingHours(rowStartTime, dayjs(event.$d));
        }else{
            const rowEndTime = dayjs((row?.endTime)?.replace(/Z/g, ''));
        //const estimatedHours =dayjs(event.$d).diff(startTime === undefined ? rowStartTime : startTime, 'hour', true); 
        estimatedHours = calculateWorkingHours(dayjs(event.$d), rowEndTime);
        }

            Arr[index]["estimatedHours"] = estimatedHours.toFixed(2);
           console.log( Arr[index]["estimatedHours"]);
            setTaskList(Arr);
            setIsEndDate(true);
        setEditedTask(task);

    }


// dayjs.extend(isSameOrAfter);
// dayjs.extend(isSameOrBefore);

const WORK_START_HOUR = 9; // 9 AM
const WORK_END_HOUR = 17; // 5 PM

const calculateWorkingHours = (startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
  let start = startDate;
  let end = endDate;

  if (start.isAfter(end)) return 0;

  let totalWorkingHours = 0;

  while (start.isBefore(end)) {
    if (start.day() !== 0 && start.day() !== 6) { // Check if it's a weekday
      const startOfWorkday = start.hour(WORK_START_HOUR).minute(0).second(0);
      const endOfWorkday = start.hour(WORK_END_HOUR).minute(0).second(0);

      if ((start.isSame(startOfWorkday) || start.isAfter(startOfWorkday)) && (start.isSame(endOfWorkday) || start.isBefore(endOfWorkday))) {
        totalWorkingHours += Math.min(endOfWorkday.diff(start, 'hour', true), end.diff(start, 'hour', true));
      } else if (start.isBefore(startOfWorkday)) {
        totalWorkingHours += Math.min(endOfWorkday.diff(startOfWorkday, 'hour', true), end.diff(startOfWorkday, 'hour', true));
      }
    }
    start = start.add(1, 'day').startOf('day');
  }

  return totalWorkingHours;
};



    const shouldDisableTime: any = (
        value,
        view,
        row
    ) => {
        if(row != undefined){
            return (view === 'minutes' && value.minute() <= dayjs((row?.startTime)?.replace(/Z/g, '')).minute() && value.hour() === dayjs((row?.startTime)?.replace(/Z/g, '')).hour()
                && value.date() === dayjs((row?.startTime)?.replace(/Z/g, '')).date() && value.month() === dayjs((row?.startTime)?.replace(/Z/g, '')).month() && value.year() === dayjs((row?.startTime)?.replace(/Z/g, '')).year())
                || (value.hour() >= WORK_END_HOUR && value.minute() !== 0) || value.hour() > WORK_END_HOUR || value.hour() < WORK_START_HOUR
        }else{
            return (value.hour() >= WORK_END_HOUR && value.minute() !== 0) || value.hour() > WORK_END_HOUR || value.hour() < WORK_START_HOUR
        }
    };

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const reassignResource = async () => {
        if (deletedIndex.length === 0) {
            let result = await reassignTeamMember(updatedData, accessToken);
            if ((result?.status === 200 || result?.status === 201) && result?.data !== null) {
                setOpenDialogs(true);
                setCreateSuccess(true);
                fetData();
                getProjectMembersByProjectId();
                setUpdatedData({...updatedData, projectMembers : [], tasks : []});
            }
            else {
                setCreateError(true);
            }
        }

    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );

    const [selectedRowId, setSelectedRowId] = React.useState<any>();

    const handleSave = (id) => {
        setSelectedRowId(undefined);
        setWarning(true);
    };

    const handleclose = () =>{
        setSelectedRowId(undefined);
    };

    const handleClickEdit = (id) => {
        setSelectedRowId(id);
    };


    const SearchBar = () => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const handleClicks = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <div className='searchBar'>
                <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                    {/* <Grid item xs={2} mt={2}>
                        <Select
                            className='filter'
                            fullWidth
                            value=""
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            size='small'
                        >
                            <MenuItem value="" disabled>
                                Batch actions
                            </MenuItem>
                            <MenuItem value="1">Option 1</MenuItem>
                            <MenuItem value="2">Option 2</MenuItem>
                            <MenuItem value="3">Option 3</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={3} mt={2}>

                    </Grid>
                    <Grid item xs={1}>  </Grid>
                    <Grid item xs={4}>  </Grid>
                    <Grid item xs={2} className='createInvoice'>
                        <Grid item xs={12} mt={2}>
                            <Tooltip title="Print">
                                <IconButton>
                                    <PrintIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Share">
                                <IconButton>
                                    <ShareIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Settings">
                                <IconButton>
                                    <SettingsIcon fontSize="medium" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid> */}
                    <Grid item xs={6} mt={2}>
                        <TextField
                            label="Search "
                            size='small'
                            name="materialName"
                            // onChange={(e) => handleTextChange(e)}
                            // value={value}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <br />
                        <br />
                    </Grid>
                </Grid>
            </div>
        )
    };
    return (
        <><Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={handleLoadingClose}
        >
            <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
        </Backdrop> <>
                <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                        <Grid item xs={12} p={2}>
                            <Card sx={{ p: 2 }}>
                                <Grid item xs={12} p={2}>
                                    <Stack direction={'row'} justifyContent="space-between" marginBottom={2}>
                                        <Typography sx={{ fontSize: '18px', fontWeight: 500, paddingBottom: 2 }}>Project Members</Typography>
                                        {/* {deletedIndex.length === 0 ? null : */}
                                        {role === admin && <Button variant='contained' disabled={updatedData.tasks.length === 0} onClick={reassignResource}>Update</Button>}
                                        {/* } */}
                                    </Stack>
                                    {role === admin ?
                                    <Stack direction={'row'} spacing={1} flexWrap="wrap" useFlexGap>
                                        {memberList?.map((data: any, index: any) =>
                                            <Chip label={data?.name} variant="outlined"
                                                onDelete={() => showWarning(data, index)}
                                                disabled={data?.isComplete}
                                            />
                                        )
                                        }
                                    </Stack> :
                                    <Stack direction={'row'} spacing={1} flexWrap="wrap" useFlexGap>
                                    {memberList?.map((data: any, index: any) =>
                                        <Chip label={data?.name} variant="outlined"/>
                                    )
                                    }
                                </Stack>}
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ width: '100%', }}>

                                {/* <SearchBar /> */}

                                <TableContainer >
                                    <Table
                                        sx={{ minWidth: 750, border: 'none', }}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                            role={role}
                                        />
                                        <TableBody>
                                            {taskList?.map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (

                                                    <TableRow

                                                    // hover
                                                    // onClick={(event) => handleClick(event, row.id)}
                                                    // role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    // tabIndex={-1}
                                                    // key={row.id}
                                                    // selected={isItemSelected}
                                                    // sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row?.taskId}
                                                        </TableCell>
                                                        <TableCell >{row?.taskName}</TableCell>
                                                        <TableCell>{row?.status}</TableCell>
                                                        <TableCell>
                                                            {row?.status === "completed" ? dayjs((row?.startTime)?.replace(/Z/g, '')).format('DD MMM YYYY h:mm A')
                                                                :
                                                                (selectedRowId === row?.id) ? <FormControl>

                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DateTimePicker
                                                                            // format='DD MMM YYYY'
                                                                            label="start Date"
                                                                            slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                            sx={{ maxWidth: "220px" }}
                                                                            shouldDisableTime={(value, view) => shouldDisableTime(value, view, undefined)}
                                                                            shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                                                            disablePast
                                                                            closeOnSelect={false}
                                                                            name="startTime"
                                                                            //disabled={ids.includes(index) ? true : false}
                                                                            value={dayjs((row?.startTime)?.replace(/Z/g, ''))}
                                                                            onChange={(e) => handleRowChange(e, row, "startTime", index)} />
                                                                    </LocalizationProvider>
                                                                </FormControl> : dayjs((row?.startTime)?.replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.status === "completed" ? dayjs((row?.endTime)?.replace(/Z/g, '')).format('DD MMM YYYY h:mm A')
                                                                :
                                                                (selectedRowId === row?.id) ? <FormControl>

                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker
                                                                        // format='DD MMM YYYY'
                                                                        label="end Date"
                                                                        slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                                        sx={{ maxWidth: "220px" }}
                                                                        minDateTime={dayjs((row?.startTime)?.replace(/Z/g, ''))}
                                                                        shouldDisableTime={(value, view) => shouldDisableTime(value, view, row)}
                                                                        shouldDisableDate={date => date.day() === 0 || date.day() === 6}
                                                                        closeOnSelect={false}
                                                                        //disablePast
                                                                        name="endTime"
                                                                        //disabled={ids.includes(index) ? true : false}
                                                                        value={dayjs((row?.endTime)?.replace(/Z/g, ''))}
                                                                        onChange={(e) => handleRowChange(e, row, "endTime", index)} />
                                                                </LocalizationProvider>
                                                            </FormControl> : dayjs((row?.endTime)?.replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}
                                                    </TableCell>
                                                    <TableCell>{row?.estimatedHours}</TableCell>
                                                    
                                                    <TableCell>
                                                        {(row?.wbs).split('.').length === 2 ? null :
                                                            <FormControl fullWidth size='small'>
                                                                <InputLabel id="demo-multiple-chip-label">Resource</InputLabel>
                                                                <Select
                                                                    labelId="demo-multiple-chip-label"
                                                                    id="demo-multiple-chip"
                                                                    multiple
                                                                    size='small'
                                                                    name='resourceName'
                                                                    value={row?.resource}
                                                                    disabled={(row?.status === "scheduled" && role === admin && props?.project?.status !== "On Hold") ? false : true}
                                                                    error={deletedIndex.includes(index) ? true : false}
                                                                    onChange={(e) => handleChange(e, index)}
                                                                    // onClick={click}
                                                                    onOpen={(e) => handleOpen(e, index, row)}
                                                                    input={<OutlinedInput id="select-multiple-chip" label="Resource" />}
                                                                    renderValue={(selected) => (
                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                            {selected.map((value) => (
                                                                                <Chip key={value.name} label={value.name} />
                                                                            ))}
                                                                        </Box>
                                                                    )}
                                                                >
                                                                    {row?.resource?.map((name) => (
                                                                        <MenuItem
                                                                            key={name?.name}
                                                                            value={name}
                                                                        >
                                                                            <Checkbox checked={row?.resource?.indexOf(name) > -1} />
                                                                            {name?.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                    {resourceList?.map((name) => (
                                                                        <MenuItem
                                                                            key={name?.name}
                                                                            value={name}
                                                                        >
                                                                            <Checkbox checked={row?.resource?.indexOf(name) > -1} />
                                                                            {name?.name}({name?.designation})
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                        }

                                                        {/* } */}
                                                    </TableCell>
                                                    {role === admin && <TableCell align="center">
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'center',
                                                                '& > *': {
                                                                    m: 1,
                                                                },
                                                            }}
                                                        >
                                                            {(row?.status !== "completed" && row?.status !== "active") && <Stack direction="row" spacing={2}>
                                                                <div>
                                                                    {row?.wbs.split(".").length > 2 && (selectedRowId === row.id ? (
                                                                        isChanged ?
                                                                            <Tooltip title="save" arrow>
                                                                                <IconButton size="small" onClick={() => handleSave(row.id)}>
                                                                                    <SaveIcon />
                                                                                </IconButton>
                                                                            </Tooltip> :
                                                                            <Tooltip title="save" arrow>
                                                                                <IconButton size="small" onClick={() => handleclose()}>
                                                                                    <CloseIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                    ) : (
                                                                        <Tooltip title="Edit" arrow>
                                                                            <IconButton disabled={props?.project?.status === "On Hold"} size="small" onClick={() => handleClickEdit(row.id)}>
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ))}
                                                                </div>
                                                            </Stack>}
                                                        </Box>
                                                    </TableCell>}
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Dialog open={deleteWarning} >
                                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                                    <Box paddingLeft={8} paddingRight={8}>
                                        <Typography textAlign={'center'}>
                                            <ReportProblemOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                                        {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                                        </Box>
                                    </DialogTitle>
                                    <Box paddingLeft={8} paddingRight={8}>
                                        <DialogContent >
                                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Do you want to remove this resource?</DialogContentText>
                                        </DialogContent>
                                        <DialogActions style={{ justifyContent: "center" }}>
                                            <Button variant='contained' className='success' onClick={removeResource}>Yes</Button>
                                            <Button variant='contained' className="error" onClick={onCloseDialog}>No</Button>
                                        </DialogActions>
                                    </Box>
                                </Dialog>
                                <CustomDialog
                                    open={openDialogs}
                                    onClose={() => setOpenDialogs(false)}
                                    success={createSuccess}
                                    error={createError}
                                    Content={createSuccess ? "Resource Assigned" : "Error Couldnot Assigned"} />

                                {/* <TablePagination
                                    sx={{ border: 'none', }}
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={taskList?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}


                            </Box>
                        </Grid>
                    </Paper>
                </Grid>

                <Dialog open={warning}>
                    <DialogTitle className='warning'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <WarningAmberIcon sx={{ fontSize: "50px", color: "white" }} />
                            </Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'}>
                                Warning
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent>
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>Remaining tasks will reschedule</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button onClick={() => closeAlter()} variant='contained' sx={{ backgroundColor: "#df3333ed" }}>Close</Button>
                            <Button variant='contained' color="primary" onClick={reschedule}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

                <CustomDialog
                    open={openDialog}
                    onClose={closeDialog}
                    success={success}
                    error={error}
                    Content={success ? "Project Rescheduled Successfully" : "Failed To Reschedule"} />
            </></>

    );
}

export default TaskList;