import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import Grid from '@mui/material/Unstable_Grid2';


const StepperIcon: React.FC = () => {


  const steps = [
    {
      label: 'Setting',
      icon: <SettingsIcon />,
    },
    {
      label: 'Add People',
      icon: <GroupAddIcon />,
    },
    {
      label: 'Watched',
      icon: <VideoLabelIcon />,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>

      <Grid container>
        <Grid md={12}>      
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={() => step.icon}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid md={1}>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="contained"
            color="secondary"
          >
            Back
          </Button>
        </Grid>
        <Grid md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={activeStep === 2}
          >
            Next
          </Button>
        </Grid>
      </Grid>



    </div>
  );
}
export default StepperIcon;