import { Card, Grid, Typography, CardContent } from '@mui/material'
import { Stack } from '@mui/system';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import cloudy from "../../assets/imgs/cloudy.png";
import sun from "../../assets/imgs/sun.png";
import weatherbg from "../../assets/imgs/weatherbg.jpg";
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import { getWeather } from '../../services/WeatherService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import moment from 'moment';
import { getMyTask } from '../../services/TaskService';



const Weathecard: React.FC = () => {

    // const coordinates="12.942005597437527,80.20628929138184";
    // const projectId="1143";
    // const DateFormat = (date: any) => {
    //     const month = date.getMonth().toString();
    //     return date.getFullYear() + "-" + (month.length == 1 ? "0" + (date.getMonth() + 1).toString() : date.getMonth() + 1) + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate()) + "T" + (date.getHours()) + ":00:00.000";
    // }

    

    const { accessToken } = useOidcAccessToken();
    const [weatherList, setWeatherList] = useState([] as any[]);
    const[coordinatess,setCoordinates]=useState<any>();
    const[projectIds,setProjectId]=useState<any>();
    const[city,setCity]=useState<any>();
    


    useEffect(() => {
        const fetchTask = async () => {
            const response = await getMyTask(accessToken);
            // console.log(response);
            if (response?.data?.length !== 0  && (response.status === 200 || response.status === 201)) {
                setCoordinates(response?.data[0]?.location  )    ;           
                setProjectId(response?.data[0]?.projectId  )    ;    
              
                const customerAsset = JSON.parse( response?.data[0]?.customerAsset);
// console.log(customerAsset);
               
                setCity(customerAsset?.city);
            }}
        const fetData = async () => {
            if (coordinatess !== undefined && projectIds !== undefined) {
                console.log(coordinatess, projectIds);
                const res = await getWeather(coordinatess, moment().format("yyyy-MM-DDTHH:mm:ss"), 12, projectIds, accessToken);
            
                console.log(res)
                if (res.data !== undefined || null) {
                    setWeatherList(res.data)
                }
                //console.log(dayjs(res.data[0].date).format("h A"));
                console.log(moment().format("yyyy-MM-DDTHH:mm:ss"));
            }
        }
        fetchTask();
        fetData();
    }, [coordinatess,projectIds])



    return (
        <>
            <Card sx={{
                backgroundImage: `url(${weatherbg})`,
                backgroundRepeat: 'no-repeat',

                backgroundSize: 'cover'
            }}>
                <Grid container >
                    <Grid item md={6} padding={2}>
                        <Typography variant='h4' sx={{ color: "white" }} >{city}</Typography>
                    </Grid>
                    <Grid item md={6} textAlign={"end"} sx={{ color: "white" }}>
                        <Typography padding={2}> {dayjs().format("DD MMM YYYY ")}{dayjs().format("HH:mm A")}</Typography>
                    </Grid>
                </Grid>
                {/* <Divider /> */}
                <CardContent>
                    <Grid container  >
                        <Grid item md={6}>
                            <Stack direction={"row"}>
                                <img src={cloudy} width={50} height={40} />
                                <Typography variant='h2' sx={{ color: "white" }}>
                                    &nbsp;{weatherList[0]?.temperature}&deg;C
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} textAlign={"center"}>
                            <Typography sx={{ color: "white" }}>{weatherList[0]?.iconPhrase}</Typography>
                        </Grid>
                       
                    </Grid>
                    <Grid container spacing={2}  >
                        <Grid item md={12}></Grid>
                        <Grid item md={12}>
                            <Typography sx={{ color: "white", }} fontWeight={600}>Hourly WeatherCast</Typography>
                        </Grid>
                        <Grid item md={12}></Grid>
                    </Grid>
                    <Grid container spacing={2} padding={1} >
                        {weatherList.map((wl: any) => {
                            
                            return (
                                <Grid item xs={4}  lg={4} xl={2.4}>
                                    <Card sx={{
                                        minWidth: "60px", minHeight: "110px", textAlign: "center", backgroundColor: "transparent", '&:hover': {
                                            backgroundColor: 'lightgray', // Change this to your desired hover color
                                        },
                                    }}  >
                                        <Stack direction={"column"} spacing={1} >
                                            <Typography fontWeight={500} sx={{ color: "white" }}>{moment.utc(wl.date).format('h A')}</Typography>

                                            <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                                <img src={sun} width={35} height={30} />
                                                <Typography fontWeight={500} sx={{ color: "white" }}>
                                                    &nbsp;{wl.temperature} &deg;{wl.temperatureUnit}
                                                </Typography>
                                            </Stack>
                                            <Stack direction={"row"}>
                                                < WaterDropIcon sx={{ height: '15px', color: 'white', marginTop: "2px" }} />
                                                <Typography sx={{ color: "white" }} variant='h6'>{wl.rain}%</Typography>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Grid>
                            )
                        })}
                        {/* <Grid item >
                            <Card sx={{
                                minWidth: "50px", minHeight: "110px", textAlign: "center", backgroundColor: "transparent", '&:hover': {
                                    backgroundColor: 'lightgray', // Change this to your desired hover color
                                },
                            }}  >
                                <Stack direction={"column"} spacing={1} >
                                    <Typography fontWeight={500} sx={{ color: "white" }}>12 Pm</Typography>
                                    <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                        <img src={sun} width={35} height={30} />
                                        <Typography fontWeight={500} sx={{ color: "white" }}>
                                            &nbsp;31&deg;C
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        < WaterDropIcon sx={{ height: '15px', color: 'white', marginTop: "2px" }} />
                                        <Typography sx={{ color: "white" }} variant='h6'>0%</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item >
                            <Card sx={{
                                minWidth: "50px", minHeight: "110px", textAlign: "center", backgroundColor: "transparent", '&:hover': {
                                    backgroundColor: 'lightwhite', // Change this to your desired hover color
                                },
                            }}  >
                                <Stack direction={"column"} spacing={1} >
                                    <Typography fontWeight={500} sx={{ color: "white" }}>1 Pm</Typography>
                                    <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                        <img src={sun} width={35} height={30} />
                                        <Typography fontWeight={500} sx={{ color: "white" }}>
                                            &nbsp;31&deg;C
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        < WaterDropIcon sx={{ height: '15px', color: 'white', marginTop: "2px" }} />
                                        <Typography sx={{ color: "white" }} variant='h6'>0%</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item >
                            <Card sx={{
                                minWidth: "50px", minHeight: "110px", textAlign: "center", backgroundColor: "transparent", '&:hover': {
                                    backgroundColor: 'lightgray', // Change this to your desired hover color
                                },
                            }}  >
                                <Stack direction={"column"} spacing={1} >
                                    <Typography fontWeight={500} sx={{ color: "white" }}>2 Pm</Typography>
                                    <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                        <img src={sun} width={35} height={30} />
                                        <Typography fontWeight={500} sx={{ color: "white" }}>
                                            &nbsp;31&deg;C
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        < WaterDropIcon sx={{ height: '15px', color: 'white', marginTop: "2px" }} />
                                        <Typography sx={{ color: "white" }} variant='h6'>0%</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid>
                        <Grid item >
                            <Card sx={{
                                minWidth: "50px", minHeight: "110px", textAlign: "center", backgroundColor: "transparent", '&:hover': {
                                    backgroundColor: 'lightgray', // Change this to your desired hover color
                                },
                            }}  >
                                <Stack direction={"column"} spacing={1} >
                                    <Typography fontWeight={500} sx={{ color: "white" }}>3 Pm</Typography>
                                    <Stack direction={"column"} sx={{ alignItems: "center" }}>
                                        <img src={sun} width={35} height={30} />
                                        <Typography fontWeight={500} sx={{ color: "white" }}>
                                            &nbsp;31&deg;C
                                        </Typography>
                                    </Stack>
                                    <Stack direction={"row"}>
                                        < WaterDropIcon sx={{ height: '15px', color: 'white', marginTop: "2px" }} />
                                        <Typography sx={{ color: "white" }} variant='h6'>0%</Typography>
                                    </Stack>
                                </Stack>
                            </Card>
                        </Grid> */}

                    </Grid>

                </CardContent>



            </Card>
        </>
    )
}

export default Weathecard

