/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for adding employee
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* #1783            4/05/2024                        Created ui design for  uploading profile picture in personal information
  #1785             4/05/2024                        integration of profile picture in ui
* 1817           |  06/05/24   | Vanitha  |  Overlaop map changed 
  1872           |  09/05/24   | Vanitha  |  After Update redirect the sold/production implement 
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AddIcon from '@mui/icons-material/Add';

import { Button, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import Stack from '@mui/material/Stack';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { GoogleMap, useJsApiLoader, MarkerF, StandaloneSearchBox } from '@react-google-maps/api';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';

import { useOidc, useOidcAccessToken } from "@axa-fr/react-oidc";
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import "../Employment.scss";
import { getPreviousPageDetails, getUserSession } from '../../../../hooks/UserSession';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { createPersonalInfo, updateEmployeeDetail } from '../../../../services/EmployeeService';
import { useState } from 'react';
import CustomDialog from '../../../Dialog';
import './PersonalInfo.scss'

const libraries: any = ['places'];

const containerStyle = {
    width: '100%',
    height: '300px'
};

const Personalinfo: React.FC<any> = (props: any) => {

    // console.log(props);

    let location = useLocation()
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    })
    const [iconVisible, setIconVisible] = useState(true);

    const [map, setMap] = React.useState(null);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [role, setRole] = React.useState<any>([] as any[]);
    const [open, setOpen] = React.useState(false);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [noChange, setNoChange] = React.useState(false);
    const [birthDateError, setBirthDateError] = React.useState(false);
    const [MobileNumberValid, setMobileNumberValid] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [add, setAdd] = React.useState<any>(false);

    const [selectedFile, setSelectedFile] = React.useState<File | string>();
    const [image, setImage] = React.useState<any>(null);
    const [defined, setDefined] = React.useState<any>({
        name: '',
        country: '',
        zipCode: '',
        state: '',
        address: '',
        city: '',
        gender: '',
        email: '',
        mobileNo: '',
        firstname: '',
        lastname: '',
        birthdate: null,


    });
    const [message, setMessage] = React.useState<any>({
        name: '',
        country: '',
        zipCode: '',
        state: '',
        address: '',
        city: '',
        gender: '',
        email: '',
        mobileNo: '',
        firstname: '',
        lastname: '',
        birthdate: null,


    });


    const [assetData, setAssetData] = React.useState<any>({
        firstname: '',
        lastname: '',
        mobileNo: '',
        alternateMobileNo: '' || undefined,
        email: '',
        birthdate: null,
        zipCode: '',
        location: "",
        country: "",
        state: "",
        city: "",
        address: "",

        // status: "Active",


        active: true,
        gender: null || undefined,
        // middlename: null,
        userId: '',
        status: 'Registered' || undefined || null,
        logo: ''
    });

    // console.log(props.rows)

    // const assetDataObject = location.state?.assetObjectData;
    // console.log(location.state.response)
    const inputRef = React.useRef<any>();
    const [successType, setSuccessType] = React.useState('');
    const navigate = useNavigate();
    const { login, logout, isAuthenticated } = useOidc();

    const handleAlertSaveClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (role !== 'admin') {
            console.log(!add)
            if(add){
                setOpenDialogs(false);
                window.location.reload();

            }
            else{
                setOpenDialogs(false)
                navigate('/commondashboard')
            }
       


        }
        else {
                  setSuccess(false);

            setOpenDialogs(false);
            // setSuccess(true)
            // navigate('/tenantconfiguration')

        }
        // setSuccess(false);
        // setError(false);
        // setNoChange(false)
    }

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (role !== 'admin') {
            // setSuccess(true)
            // setError(false);

            // setOpenDialogs(false)
            // window.location.reload();
            navigate('/soldproduction')

        }
//         else if(role === "customer"){
// navigate("/salesestimate")
//         }
        else {
            // setOpenDialogs(false)
            // setSuccess(true)
            // window.location.reload();
            navigate('/commondashboard')

        }
        // setSuccess(false);
        // setError(false);
        // setNoChange(false)
    }

    const componentData = React.useRef<any>()
    React.useEffect(() => {
        //getLocation();
        if (props.rows != null) {
            setAssetData(props.rows);

        }
        else {
            getUserSession(accessToken)
                .then(res => {
                    console.log(res)
                    setDefined(res);
                    setAssetData({
                        ...assetData,
                        firstname: res.firstname,
                        lastname: res.lastname,
                        birthdate:res.birthdate,
                        mobileNo: res.mobileNo,
                        gender: res.gender,
                        email: res.email,
                        alternateMobileNo: res.alternateMobileNossss,
                        // logo:res.avatar


                    });
                    if(res.avatar){
                        setSelectedFile(res.avatar);

                    }
                })
        }
        if (props?.rows?.logo != null) {
            setSelectedFile(props?.rows?.logo);
        }
        getUserSession(accessToken)
            .then(res => {
                console.log(res)
                setRole(res.role.name)
            });
        //    setHasEmail(response);
    }, []);

    const createPersonalInfoData = (personalDetails: any) => {
        createPersonalInfo(personalDetails, accessToken);

    }
    const convertLocation = (locationString: any) => {
        if (!locationString) {
            return null; // or handle the case when locationString is undefined
        }

        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    }

    const onclose = () => {
        if (role !== 'admin') {
            if(add){
                setOpenDialogs(false);
                window.location.reload();

            }
            else{
                setOpenDialogs(false)
                navigate('/commondashboard')
            }
       

        }
        else {
            setOpenDialogs(false)
            // navigate('/admin')

        }
        // setSuccess(false);
        // setError(false);
        // setNoChange(false)
    }

    const getFile = (event: any) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));
        setImage(file);
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setAssetData({
                        ...assetData,
                        logo: reader.result,


                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        console.log('Selected file:', selectedFile);
    };
    const handleCardClick = () => {
        const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
        fileInput.click();
    };
    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(componentData.current);
        map.fitBounds(bounds);
        setMap(map)
    }, [])
    const onCancel = () => {
        const previousPageDetails = getPreviousPageDetails();
        navigate(previousPageDetails.path,
            { state: { selectedTab: previousPageDetails.tabPosition } }
        );
    }
    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    const validation = () => {
        let validated: any = true;

        if (assetData?.state === '') {
            // setMessage({...message,state:'Enter Your State'});
            message.state = 'Enter Your State';
            validated = false;

        }
        if (assetData?.city === '') {
            // setMessage({...message,city:'Enter Your City'});
            message.city = 'Enter Your City';
            validated = false;

        }
        if (assetData?.country === '') {
            //setMessage({...message,country:'Enter Your Country'});
            message.country = 'Enter Your Country';
            validated = false;

        }

        if (assetData?.zipCode === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.zipCode = 'Enter Your ZipCode';
            validated = false;

        }
        if (assetData?.address === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.address = 'Enter Your address';
            validated = false;

        }
        if (assetData?.gender === null) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.gender = 'Select Your gender';
            validated = false;

        }
        if (assetData?.birthdate === null) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.birthdate = 'Enter your  date of birth';
            validated = false;

        }
        if (assetData?.firstname === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.firstname = 'Enter your  first name';
            validated = false;

        }
        if (assetData?.lastname === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.lastname = 'Enter your last name';
            validated = false;

        }
        if (assetData?.email === '') {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.email = 'Enter your email';
            validated = false;

        }

        if (assetData?.mobileNo === '' || !MobileNumberValid) {
            // setMessage({...message,stories:'Enter Your Stories'});
            message.mobileNo = 'Enter your mobile no';
            validated = false;

        }
        return validated;

    }
    const handleSave=(e:any)=>{
        setAdd(true);
        handleSubmit(e);
    }
    const handleSubmit = async (e: any) => {
        setAdd(true);

        //setAssetData({ ...assetData, customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        // Object.assign(assetData,{customerUid: role === "customer" ? accessTokenPayload.oid : customerUid });
        if(assetData?.birthdate === null){
            setBirthDateError(true);
        }
        else{
            setBirthDateError(false);
        }
        //console.log(assetData);
        setCount(count + 1);
        let formData = new FormData();
        // formData.append("file", image);
        let validated = validation();

        if (validated === true) {
            setAdd(true);

            let result = await createPersonalInfo(assetData, accessToken);
            // setSuccess(true);  
            // let uploadResult = await saveAssetDocument(formData, result.data, accessToken);
            if (result.ok === true) {
                // handleClick('Inserted Successfully');
                setSuccessType('save');
                // setOpenDialogs(true);
                // setCreateSuccess(true);

                // setSuccessType('update');
                setSuccess(true);
                // setSuccess(true);
                // setTimeout(() => {
                //     onNextPage();
                // }, 2000);
            }
            else {
                // handleClick('Not Inserted');
                setCreateError(true);
                // setError(true);
            }
        }
        // else{
        //     handleClick('Not Inserted');
        // }
    };
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }
    const updateHandleSubmit = async (userId: any) => {

        setCount(count + 1);
        if(assetData?.birthdate === null){
            setBirthDateError(true);
        }
           else{
            setBirthDateError(false);
        }
        console.log(assetData)
        let validated = validation();
        let formData = new FormData();
        // formData.append("file", image);
        if (props.rows.firstname === assetData?.firstname && props.rows.lastname === assetData?.lastname && props.rows.address === assetData?.address && props.rows.country === assetData?.country && props.rows.gender === assetData?.gender
            && props.rows.zipCode === assetData?.zipCode && props.rows.state === assetData?.state && props.rows.city === assetData?.city
            && props.rows.mobileNo === assetData?.mobileNo && props.rows.alternateMobileNo === assetData?.alternateMobileNo && props.rows.alternateMobileNo === assetData?.alternateMobileNo
            && props.rows.birthdate === dayjs(assetData?.birthdate).format('YYYY-MM-DD') && props.rows.logo === assetData?.logo
        ) {
            // handleClick('No valued Changed');
            setNoChange(true);

        }
        else {
            if (validated === true) {
                setAdd(false);
                if (typeof (assetData?.location) != "string") {
                    let data = assetData;
                    data.location = Object.values(assetData?.location).join(",")
                    setAssetData(data);
                }
                let result = await updateEmployeeDetail(assetData, accessToken);
                // let uploadResult = await editAssetDocument(formData, assetId, accessToken);
                if (result.status === 200 || result.status === 201) {
                    // handleClick('Updated Successfully');
                    setSuccessType('update');
                    setSuccess(true);
                    // setTimeout(() => {
                    //     onNextPage();
                    // }, 2000);
                }
                else {
                    // handleClick('Not Updated');

                    setError(true);
                }
            }

        }
    };
    // const onClick = (e: google.maps.MapMouseEvent) => {
    //     componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() }
    //     let locationObject: any = {}
    //     const geocoder = new google.maps.Geocoder();
    //     const fulladdress: any[] = [];
    //     geocoder
    //         .geocode({ location: componentData.current })
    //         .then((response) => {
    //             if (response.results[0]) {
    //                 // eslint-disable-next-line array-callback-return
    //                 response.results[0].address_components.map((address: any) => {
    //                     if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
    //                         fulladdress.push(address.long_name);
    //                         // setLocation({...location,address:fulladdress.join(",")});
    //                         // Object.assign(location,{address:fulladdress.join(",")});
    //                         locationObject['address'] = fulladdress.join(",");
    //                     }
    //                     else if (address.types.includes("locality")) {
    //                         // setLocation({...location,city:address.long_name});
    //                         locationObject['city'] = address.long_name;
    //                     }
    //                     else if (address.types.includes("administrative_area_level_1")) {
    //                         // setLocation({...location,state:address.long_name});
    //                         locationObject['state'] = address.long_name
    //                     }
    //                     else if (address.types.includes("country")) {
    //                         // setLocation({...location,country:address.long_name});
    //                         locationObject['country'] = address.long_name
    //                     }
    //                     else if (address.types.includes("postal_code")) {
    //                         // setLocation({...location,zipCode:address.long_name});
    //                         locationObject['zipCode'] = address.long_name
    //                     }
    //                 })
    //             }
    //             setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
    //             // Object.assign(location,locationObject);
    //             // setLocation(locationObject);
    //         })
    // }

    const onClick = (e: google.maps.MapMouseEvent) => {
        componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: componentData.current })
            .then((response) => {
                if (response.results[0]) {
                    const locationObject: any = {};

                    response.results[0].address_components.forEach((component) => {
                        const { long_name, types } = component;

                        if (types.includes("street_number") || types.includes("sublocality_level_1") || types.includes("sublocality_level_2") || types.includes("route") || types.includes("plus_code") || types.includes("landmark") || types.includes("premise")) {

                            locationObject.address = long_name + (locationObject.address ? ', ' + locationObject.address : '');

                        }
                        else if (types.includes("locality")) {
                            locationObject.city = long_name;
                        } else if (types.includes("administrative_area_level_1")) {
                            locationObject.state = long_name;
                        } else if (types.includes("country")) {
                            locationObject.country = long_name;
                        } else if (types.includes("postal_code")) {
                            locationObject.zipCode = long_name;
                        }
                    });
                    locationObject.location = Object.values(componentData.current).join(",");
                    setAssetData({ ...assetData, ...locationObject });
                }
            });
    }
    const fileInputRef = React.useRef<HTMLInputElement | null>(null);

    const handleIconClick = () => {
        setIconVisible(false);

        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            const reader = new FileReader();
            reader.readAsDataURL(selectedFile);
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    setAssetData({
                        ...assetData,
                        logo: reader.result,

                    })
                }
            };
            reader.onerror = (error) => {
                console.error('Error occurred while reading the file:', error);
            };
        }
        // Do something with the selected file, e.g., upload or process it
        console.log('Selected file:', selectedFile);
    };
    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                componentData.current = { lat: position.coords.latitude, lng: position.coords.longitude };
            });
        } else {
            // //console.log("Geolocation is not supported by this browser.");
        }
    }
    const validateMobileNumber = (mobileNo) => {
        // Check if mobile number consists of exactly 10 digits
        return /^\d{10}$/.test(mobileNo);
    };
    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces();
        const fulladdress: any[] = [];
        let locationObject: any = {}
        if (place) {
            place.address_components.map((address: any) => {
                if (address.types.includes("street_number") || address.types.includes("sublocality_level_1") || address.types.includes("sublocality_level_2") || address.types.includes("route") || address.types.includes("landmark") || address.types.includes("premise")) {
                    fulladdress.push(address.long_name);
                    // setLocation({...location,address:fulladdress.join(",")});
                    // Object.assign(location,{address:fulladdress.join(",")});
                    locationObject['address'] = fulladdress.join(",");
                }
                else if (address.types.includes("locality")) {
                    // setLocation({...location,city:address.long_name});
                    locationObject['city'] = address.long_name;
                }
                else if (address.types.includes("administrative_area_level_1")) {
                    // setLocation({...location,state:address.long_name});
                    locationObject['state'] = address.long_name;
                }
                else if (address.types.includes("country")) {
                    // setLocation({...location,country:address.lon;g_name});
                    locationObject['country'] = address.long_name;
                }
                else if (address.types.includes("postal_code")) {
                    // setLocation({...location,zipCode:address.long_name});
                    locationObject['zipCode'] = address.long_name;
                }
                else {
                    locationObject['zipCode'] = '';
                    locationObject['address'] = fulladdress.join(",");

                }
            })

            componentData.current = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
            setAssetData({ ...assetData, zipCode: locationObject.zipCode, country: locationObject.country, address: locationObject.address, city: locationObject.city, state: locationObject.state, location: Object.values(componentData.current).join(",") });
        }
    }
    const handleDateChange = (event: any) => {
     
        const formattedDate = dayjs(event.$d).format('YYYY-MM-DD');
        setAssetData({
            ...assetData,
            birthdate: formattedDate
        });
    }
    const inputchange = (e: any) => {
        const { name, value } = e.target;
        console.log(name);
        setAssetData({
            ...assetData,
            [name]: value
        });

        if (name === 'mobileNo') {
            const isValidMobileNumber = validateMobileNumber(value);
            setMobileNumberValid(isValidMobileNumber);
        }
    };



    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
        const trimmedValue = inputValue.slice(0, 5); // Trim the value to 5 characters
        // Update the state or perform any other action with the trimmed value
        // For example, you can set it to state using setState or use it to perform validation
        //console.log(trimmedValue);
    };



    const [Title, setTitle] = React.useState('');

    const HandletitleChange = (event: SelectChangeEvent) => {
        setTitle(event.target.value as string);
    };

    return (


        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >


                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>

                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}

                    >


                        {/* <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-simple-select-label" >Title</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={Title}
                                    label="Title"
                                    onChange={HandletitleChange}
                                >
                                    <MenuItem value={10}>Title 1</MenuItem>
                                    <MenuItem value={20}>Title 2</MenuItem>
                                    <MenuItem value={30}>Title 3</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid> */}
                        <Grid item xs={12} md={12} justifyContent={'center'}>    <Box
                            className="myDiv"
                        >
                            <Grid xs={12} md={12} className='personalInfo'>
                            {assetData.role === 'admin' ? (
          <Typography className='personalInfo' variant="h3" component="h2" align='left' sx={{ mx: 1, py: 1 }} style={{ color: 'white' }}>
            Admin Personal Info
          </Typography>
        
      ) : (
          <Typography className='personalInfo' variant="h3" component="h2" align='left' sx={{ mx: 1, py: 1 }} style={{ color: 'white' }}>
            Personal Info
          </Typography>
        
      )}
                            </Grid>
                            <Card variant="outlined" className='tenantImageUpload' onClick={handleCardClick} raised sx={{ maxWidth: 160, maxHeight: 150, margin: '0 auto', padding: '0.1em', borderRadius: '50% !important' }}

>
    <label>
        {typeof selectedFile === "string" ? (
            <img src={selectedFile} alt="Selected" className='provisioningImage configCusImg' />
        ) : (
            <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                <Typography>
                    <AddIcon style={{
                                                color: 'black', width: '35px',
                                                height: '45px',
                                            }}/>
                </Typography>
                <Typography className="logoCusText">
                    Upload profile
                </Typography>
            </Stack>
        )}
    </label>
    <input
        type="file"
        id="image-upload-input"
        onChange={(e) => getFile(e)}
        style={{ display: "none" }}
        accept="image/*"
    />

</Card>
                            {/* <FileUpload onFileUpload={onFileUpload}  /> */}
                            {/* <Card raised sx={{ maxWidth: 146, maxHeight: 141, margin: '0 auto', padding: '0px', borderRadius: '50%' }}>
                                <CardMedia onClick={handleIconClick}
                                    component="div" // Use a div to easily overlay content
                                    sx={{
                                        position: 'relative',
                                        height: '140px',
                                        overflow: 'hidden', // Hide any overflow from the image
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                             
                                    {props.rows ? (
                                        <img
                                            src={`${assetData.logo}`}
                                            alt=""
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    ) : (
                                        <img
                                            src={`${assetData.logo === undefined ? assetData.logo : assetData.logo}`}
                                            alt=""
                                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                        />
                                    )}

                              
                                    {iconVisible && (
                                        <IconButton
                                            aria-label="settings"

                                            sx={{
                                                position: 'absolute',
                                                //   backgroundColor: '#3c5bfa',
                                                width: '85px',
                                                height: '85px',
                                            }}
                                        >
                                            <AddIcon style={{
                                                color: 'black', width: '45px',
                                                height: '45px',
                                            }} />
                                        </IconButton>
                                    )}

                         
                                    <input
                                        type="file"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </CardMedia>
                            </Card> */}
                        </Box></Grid>

                        <Grid item xs={12} md={6}  >
                            <TextField
                                id="outlined-multiline-static"
                                label="First name*"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={assetData?.firstname}
                                name="firstname"
                                onChange={inputchange}
                                required
                                helperText={(assetData?.firstname === '' && count !== 0) ? message.firstname : null}
                                error={(assetData?.firstname === '' && count !== 0)}


                            />
                        </Grid>


                        {/* <Grid item xs={12} md={2}>
                            <TextField
                                id="outlined-multiline-static"
                                label="M.I."
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}


                        <Grid item xs={12} md={6} >
                            <Grid item xs={12}>
                                <TextField
                                    error={(assetData?.lastname === '' && count !== 0)}
                                    id="outlined-multiline-static"
                                    label="Last name"
                                    size="small"
                                    fullWidth
                                    defaultValue=""
                                    value={assetData?.lastname}
                                    name="lastname"
                                    onChange={inputchange}
                                    helperText={(assetData?.lastname === '' && count !== 0) ? message.lastname : null}

                                />
                            </Grid>

                        </Grid>

                        {/* 
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Preferred first name"
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}


                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Email"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={assetData?.email}
                                name="email"
                                onChange={inputchange}
                                helperText={(assetData?.email === '' && count !== 0) ? message.email : null}
                                error={(assetData?.email === '' && count !== 0)}
                                InputProps={{ readOnly: true }} // Make the TextField read-only

                            />
                        </Grid>
                        {/* 
                        <Grid item xs={4} md={2}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Ext"
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}

                        {/* <Grid item xs={8} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Home phone number"
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}

                        {/* <Grid item xs={4} md={2}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Ext"
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}

                        {/* <Grid item xs={8} md={4}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Work phone number"
                                size="small"
                                fullWidth
                                defaultValue=""

                                name="primaryContact"
                                onChange={handleInputChange}

                            />
                        </Grid> */}



                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Primary Mobile Phone No *"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={assetData?.mobileNo}
                                name="mobileNo"
                                onChange={inputchange}
                                error={!MobileNumberValid}
                                helperText={!MobileNumberValid ? "Invalid mobile number" : null}
                                inputProps={{
                                    maxLength: 10,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="SecondaryMobile Phone No"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={assetData?.alternateMobileNo}
                                name="alternateMobileNo"
                                onChange={inputchange}
                                helperText={assetData?.alternateMobileNo !== '' && !validateMobileNumber(assetData?.alternateMobileNo) ? 'Invalid mobile number' : ''}
                                inputProps={{
                                    maxLength: 10,
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']} >
                                    <DatePicker label="Birth Date" name='birthdate' onChange={(e: any) => handleDateChange(e)} value={assetData && assetData.birthdate ? dayjs(assetData.birthdate) : null} slotProps={{
                                        textField: {
                                            fullWidth: true,
                                            size: 'small',
                                            error: birthDateError
                                        }
                                    }} />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6}>

                            <FormControl fullWidth required error={(assetData?.gender === null && count !== 0)}>
                                <InputLabel id="demo-simple-select-label" >
                                    Gender
                                </InputLabel>
                                <Select
                                    error={(assetData?.gender === null && count !== 0)}

                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={assetData?.gender || ''}
                                    label="Gender"
                                    name="gender"
                                    onChange={(e: any) => inputchange(e)}
                                    size='small'
                                >
                                    <MenuItem value={"male"}>Male </MenuItem>
                                    <MenuItem value={"female"}>Female</MenuItem>
                                </Select>
                                {assetData?.gender === null && count !== 0 && (
                                    <FormHelperText style={{ color: 'red' }}>{message.gender}</FormHelperText>
                                )}
                            </FormControl>


                        </Grid>

                        {/* 
                        <Grid item xs={12} md={5} sx={{ mb: -3 }}>
                            <FormControlLabel
                                control={<Android12Switch defaultChecked />}
                                label="Employment self setup"
                            />
                        </Grid>
                        <Grid item xs={12} md={7}></Grid>
                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>
                                Turn this on if you want this Employment to enter their personal tax, and banking info in Workforce.
                            </Typography>
                        </Grid> */}


                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>
                                Personal Address
                            </Typography>
                        </Grid>


                        <Grid item xs={6}>
                            {isLoaded
                                &&
                                <StandaloneSearchBox
                                    onLoad={ref => inputRef.current = ref}
                                    onPlacesChanged={handlePlaceChanged}
                                >
                                    <TextField
                                        error={(assetData?.address === '' && count !== 0)}
                                        id="Street address"
                                        label="Street address"
                                        name="address"
                                        size="small"
                                        fullWidth
                                        value={assetData?.address}
                                        // value={assetDataObject ? assetDataObject.address : assetData?.address}
                                        InputLabelProps={{ shrink: !!assetData.address }}
                                        required
                                        // helperText={(assetData?.address === '' && count !== 0) ? message.address : null}
                                        // helperText={message.address}
                                        helperText={(assetData.address === '' && count !== 0) ? message.address : null}

                                        onChange={(e) => inputchange(e)}
                                    />
                                </StandaloneSearchBox>}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={(assetData?.city === '' && count !== 0)}
                                id="demo-helper-text-aligned"
                                label="City"
                                size="small"
                                fullWidth
                                required
                                name="city"
                                value={assetData?.city}
                                InputLabelProps={{ shrink: !!assetData.city }}

                                // value={assetDataObject ? assetDataObject.city : assetData?.city}
                                helperText={(assetData?.city === '' && count !== 0) ? message.city : null}
                                onChange={(e) => inputchange(e)}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={(assetData?.state === '' && count !== 0)}
                                id="demo-helper-text-aligned-no-helper"
                                label="State"
                                size="small"
                                fullWidth
                                name="state"
                                required
                                value={assetData?.state}
                                InputLabelProps={{ shrink: !!assetData.state }}

                                // value={assetDataObject ? assetDataObject.state : assetData?.state}
                                onChange={(e) => inputchange(e)}
                                helperText={(assetData?.state === '' && count !== 0) ? message.state : null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={(assetData?.country === '' && count !== 0)}
                                id="demo-helper-text-aligned-no-helper"
                                label="Country"
                                size="small"
                                fullWidth
                                value={assetData?.country}
                                InputLabelProps={{ shrink: !!assetData.country }}

                                // value={assetDataObject ? assetDataObject.country : assetData?.country}
                                helperText={(assetData?.country === '' && count !== 0) ? message.country : null}
                                name="country"
                                required
                                onChange={(e) => inputchange(e)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={(assetData?.zipCode === '' && count !== 0)}
                                id="demo-helper-text-aligned-no-helper"
                                label="Zip Code"
                                size="small"
                                name="zipCode"
                                fullWidth
                                value={assetData?.zipCode}
                                InputLabelProps={{ shrink: !!assetData.zipCode }}

                                // value={assetDataObject ? assetDataObject.zipCode : assetData?.zipCode}
                                required
                                helperText={(assetData?.zipCode === '' && count !== 0) ? message.zipCode : null}
                                onChange={(e) => inputchange(e)}
                            />
                        </Grid>


                        <Grid item xs={12}>
                            <Stack direction={'row'}>
                                <Typography><MapOutlinedIcon fontSize='small' /></Typography>
                                <Typography>Choose from MAP</Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            {isLoaded ? (
                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={convertLocation(assetData?.location)}
                                    zoom={30}
                                    onLoad={onLoad}
                                    onUnmount={onUnmount}
                                    onClick={onClick}
                                >
                                    <MarkerF
                                        position={convertLocation(assetData?.location)}
                                    >
                                    </MarkerF>

                                    <></>

                                </GoogleMap>
                            ) : <></>}
                        </Grid>



                        {/* <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Social Security number *"
                                size="small"
                                fullWidth
                                defaultValue=""
                                placeholder='XXX-XX-XXXX'
                                name="primaryContact"
                                onChange={handleInputChange}
                            />
                        </Grid> */}
                        {/* 
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Confirm Social Security number *"
                                size="small"
                                fullWidth
                                defaultValue=""
                                placeholder='XXX-XX-XXXX'
                                name="primaryContact"
                                onChange={handleInputChange}
                            />
                        </Grid> */}

                        <Grid container spacing={2} justifyContent="flex-end" mt={2}>

                            {props.rows !== null && props.rows !== undefined ? (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={updateHandleSubmit}>
                                        Update
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button className='dangerButton' variant="contained" size="medium" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                        {/* 
                        <Grid item xs={12} md={12}>
                            <Link href="#">What if they only have an ITIN?</Link>
                        </Grid> */}

                    </Grid>
                </Card>

            </Grid>
            <Dialog open={noChange} >
                <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                    </DialogActions>
                </Box>
            </Dialog>
            <Dialog open={success} >
                <DialogTitle className='success'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Success
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>   {successType === 'save' ? 'User Details added Successfully' : 'User Details Updated Successfully'}</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        {successType === 'save' ? <Button variant='contained' className="successButton" onClick={handleAlertSaveClose}>Ok</Button> : <Button variant='contained' className="successButton" onClick={handleAlertClose}>Ok</Button>}
                    </DialogActions>
                </Box>
            </Dialog>

            <Dialog open={error} >
                <DialogTitle className='error'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Error
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent >
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>{successType === 'error' ? 'Error Couldnot  Inserted' : 'Error Couldnot  create'}</DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleAlertClose}>Close</Button>
                    </DialogActions>
                </Box>

            </Dialog>

            <CustomDialog
                open={openDialogs}
                onClose={() => onclose()}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Created Successfully" : " Error Couldnot create"} />


        </Box >


    )
};

export default Personalinfo;
