export const BillJson =
    [{
        "billNumber": 4123445,
        "billDate": "12-08-2023",
        "dueDate": "12-08-2023",
        "vendorName": 'ABC SUPPLIER',
        "vendorEmail": 'asishsaji2000@gmail.com',
        "customerName":"Asish P Saji",
        "companyPhoneNumber":'89893450784',
        'companyEmail':'gracefulmanagement@gms.com',
        "vendorAddress": "SoftTeam Solutions Pvt Ltd Information Technology & Services Chennai, Tamil Nadu ",
        "terms": "Net 30days",
        "address": "20 Moores Road Malvern Pennsylvania CertainTeed Corp Country United States of America 19355",
        "companyName": "Certain Teed",
        "amount": 5667,
        "itemDetails": [{
            "id": 1,
            "product": "OC VentSure 15inchesx4' Strip",
            "description": "",
            "quantity": 3,
            "rate": 19,
            "project": "GMS"
        },
        {
            "id": 2,
            "product": "Bundle-33 LF",
            "description": "",
            "quantity": 2,
            "rate": 10.2,
            "project": "GMS"
        }],
        "message": "Needed within November 15 "
    }]