import React from 'react';
import Header from '../../components/Header';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Fade from '@mui/material/Fade';
import { green } from '@mui/material/colors';

const Progress: React.FC = () => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const [loading, setLoading] = React.useState(false);


    const handleClickLoading = () => {
        setLoading((prevLoading) => !prevLoading);
    };

    const [success, setSuccess] = React.useState(false);
    const [loadings, setLoadings] = React.useState(false);
    const timer = React.useRef<number>();

    const buttonSx = {
        ...(success && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const handleButtonClicks = () => {
        if (!loadings) {
            setSuccess(false);
            setLoadings(true);
            timer.current = window.setTimeout(() => {
                setSuccess(true);
                setLoadings(false);
            }, 2000);
        }
    };

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    return (
        <div>
            <Header title="Progress components Templates" subtitle={null} />
            <Card>
                <CardContent>
                    <h4>Circular Indeterminate Bar</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />

            <Card>
                <CardContent>
                    <h4>Circle Disable Shrink </h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress disableShrink />;
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Card>
                <CardContent>
                    <h4>Circle colors</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress color='success' />
                            <h5>success</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress color='secondary' />
                            <h5>secondary</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress color='inherit' />
                            <h5>inherit</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress color='primary' />
                            <h5>primary</h5>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <br />
            <Card>
                <CardContent>
                    <h4>Circular Determinate Bar</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" color='inherit' value={25} />
                            <h5>25%</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" color='inherit' value={50} />
                            <h5>50%</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" color='inherit' value={75} />
                            <h5>75%</h5>
                        </Grid>
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" color='inherit' value={99} />
                            <h5>99%</h5>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <br />
            <Card>
                <CardContent>
                    <h4>Circle Is progressing without label</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" value={progress} />
                            <h5>0 to 100%</h5>
                        </Grid>


                    </Grid>
                </CardContent>
            </Card>

            <br />
            <Card>
                <CardContent>
                    <h4>Circle Is progressing with label</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={3} >
                            <CircularProgress variant="determinate" value={progress} />
                            <h4>{progress}%</h4>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


            <br />
            <Card>
                <CardContent>
                    <h4>Linear  Indeterminate Bar</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={5} >
                            <LinearProgress />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>


            <br />
            <Card>
                <CardContent>
                    <h4>Linear  Color Bar</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={5} >
                            <LinearProgress color="secondary" />
                        </Grid>
                        <Grid xs={4} md={5} >
                            <LinearProgress color="success" />
                        </Grid>
                        <Grid xs={4} md={5} >
                            <LinearProgress color="inherit" />
                        </Grid>
                        <Grid xs={4} md={5} >
                            <LinearProgress />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Card>
                <CardContent>
                    <h4>Linear  Determinate Bar</h4>
                    <Grid container spacing={4} >
                        <Grid xs={4} md={5} >
                            <LinearProgress variant="determinate" value={progress} />
                            <h4>{progress}%</h4>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Card>
                <CardContent>
                    <h4>Appearance Bar</h4>
                    <Grid container spacing={4} >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ height: 40 }}>
                                <Fade
                                    in={loading}
                                    style={{
                                        transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress color='success' />
                                </Fade>
                            </Box>
                            <Button onClick={handleClickLoading} sx={{ m: 2 }}>
                                {loading ? 'Stop Loading' : 'Start Loading'}
                            </Button>
                        </Box>

                    </Grid>
                </CardContent>
            </Card>

            <br />
            <Card>
                <CardContent>
                    <h4>Form Bar</h4>
                    <Grid container spacing={4} >
                        <Box sx={{ m: 1, position: 'relative' }}>
                            <Button
                                variant="contained"
                                sx={buttonSx}
                                disabled={loadings}
                                onClick={handleButtonClicks}
                            >
                                Accept terms
                            </Button>
                            {loadings && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: green[500],
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    )
}

export default Progress
