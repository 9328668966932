/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 22-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create customer implementation
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2131           |  24/06/24   |    Asish     |  Timesheet for admin view
* 2321           |  04/07/24   | Shiyam Kumar |  Removed Unwanted Console and Blocked weekends in date picker
*
*/

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomDialog from '../../../pages/Dialog';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getUserSession } from '../../../hooks/UserSession';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './BookLeave.scss';


import MuiDateRangePickerDay from '@mui/lab/DateRangePickerDay';
import { getResourceId, } from '../../../services/ResourceService';
import { getdisabledDatesByResourceId, getEmployeeLeaves, leaveBook } from '../../../services/ProjectService';
import { parseISO, differenceInCalendarDays, differenceInBusinessDays } from 'date-fns';
import gmslogo from "../../../assets/logo.gif";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ theme, isHighlighting, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ...(isHighlighting && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover, &:focus': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    ...(isStartOfHighlighting && {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
    ...(isEndOfHighlighting && {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  }),
);

dayjs.extend(utc);
dayjs.extend(timezone);


// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();


const BookLeave: React.FC<any> = (props) => {
  const { open, rowDatas } = props;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [role, setRole] = useState('');
  const [resourceId, setResourceId] = useState();
  const [startDate, setStartDate] = useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);

  const [endDate, setEndDate] = useState<any>();
  const [rows, setRows] = React.useState<any>([]);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [noChange, setNoChange] = React.useState(false);
  const [value, setValue] = React.useState();
  const [endValue, setEndValue] = React.useState();
  const blockedDates = React.useRef<any>({ disableDates: [] });

  const { accessToken } = useOidcAccessToken();

  // const rowData = state && state.rowsData;
  const rowData = rowDatas;

  React.useEffect(() => {
    getResourceId(accessToken).then(res => {
      setResourceId(res.data);
      getBlockedDates(res.data);
    })
    getUserSession(accessToken).then(res => {
      setRole(res.role.name);
    })
    getEmployeeLeaves("leave", accessToken).then((res) => {
      setRows(res.data)
    })
    
  }, [rowData]);

  const getBlockedDates = async (resourceId:any) =>{
    const result = await getdisabledDatesByResourceId(resourceId, accessToken);
        if (result.status == 200) {
          if (result.data.length != 0 && result.data != '') {
              result.data.map((dateString:any) => {
                  if (!blockedDates.current.disableDates.includes(dayjs(dateString).format("YYYY-MM-DD"))) {
                      blockedDates.current.disableDates.push(dayjs(dateString).format("YYYY-MM-DD"))
                  }
              })

          }
        }
  }

  const dateFormat = (value: any, isEndOfDay: boolean = false) => {
    const date = new Date(value);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let formattedDate = `${date.getFullYear()}-${month}-${day}`;
    if (isEndOfDay) {
      formattedDate += "T23:59Z";
    } else {
      formattedDate += "T00:00Z";
    }

    return formattedDate;
  };

  const handleDateChange = (newValue, name) => {
    const eventLikeObject = {
      target: {
        name,
        value: newValue,
      },
    };

    if (eventLikeObject.target.name === "startDate") {
      setStartDate((dateFormat(newValue)));
      setStartDateError(false); // Clear error state on valid input

    }
    else {
      let enndDate = dateFormat(newValue, true);
      setEndDate(enndDate);
      setEndDateError(false); // Clear error state on valid input
    }
  };

  const calculateDaysBetween = (startDate: string, endDate: string): number => {
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    const daysDifference = differenceInBusinessDays(end, start);
    // If the difference is 0, return 1 (consider the same date as 1 day)
    return daysDifference === 0 ? 1 : daysDifference;
  };

  const handleBookNow = () => {
    setIsLoading(true);
    let valid = true;
    if (!startDate) {
      setStartDateError(true);
      valid = false;
    }
    if (!endDate) {
      setEndDateError(true);
      valid = false;
    }
    if (!valid) return;
    setIsLoading(true);
    leaveBook(resourceId, startDate, endDate, accessToken).then((res) => {
      if (res.status === 200 || res.status === 201 || res.ok === true) {
        setIsLoading(false);
        setOpenDialog(true);
        setSuccess(true);
      } else {
        setIsLoading(false);
        setOpenDialog(true);
        setError(true);
      }
    });
  };
  const handleClose = async () => {
    props.onClose();
  }
  const dateFormatterDetails = (value: any) => {
    const date = new Date(value);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const formattedDate = date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
    const formattedTime = (date.getHours() < 10 ? '0' : '') + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ":" + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    return formattedDate + " " + formattedTime;
  }

  const handleLoadingClose = () =>{
    setIsLoading(false);
  }

  const isDateDisabled = (date: Dayjs) => {
    if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
        return true;
    }
    return date.day() === 6 || date.day() === 0;
  };

  // Email validation function using regular expression
  // const [open, setOpen] = React.useState(true);
  return (<>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Dialog
          fullWidth={true}
          maxWidth={'sm'}
          open={open}
          // onClose={handleClose}
          //onBackdropClick={handleBackdropClick}
          disableEscapeKeyDown
          PaperProps={{
            sx: {
              borderRadius: '15px', // Set the border radius to 15px
            },
          }}
        >
           <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
            onClick={handleLoadingClose}
        >
            {/* <CircularProgress color="inherit" /> */}
            <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
            {/* <Typography className="loading" variant='h4' color="blue" >Loading . . .</Typography> */}
        </Backdrop>
          <DialogTitle mb={2}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} >
                <Typography className='cartheader'>Book Leave</Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box display="flex" justifyContent="center" mt={2}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      value={value}
                      name="startDate"
                      disablePast
                      onChange={(e) => {
                        handleDateChange(e, "startDate");
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                          error: startDateError, // Error state
                          helperText: startDateError ? "Start Date is required" : "", // Helper text
                        }
                      }}
                      shouldDisableDate={isDateDisabled}
                    // renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                  {startDate !== undefined && endDate !== undefined ? (
                    <div>Number of  days  Taken :{calculateDaysBetween(startDate, endDate)}</div>
                  ) : (<div>To</div>)}
                </Grid>
                <Grid item xs={12} md={5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="End Date"
                      name="endDate"
                      value={endValue}
                      disabled={startDate === undefined}
                      disablePast
                      onChange={(e) => {
                        handleDateChange(e, "endDate");
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: 'small',
                          error: endDateError, // Error state
                          helperText: endDateError ? "Start Date is required" : "", // Helper text
                        }
                      }}
                      minDate={dayjs((startDate)?.replace(/Z/g, ''))}
                      shouldDisableDate={isDateDisabled}
                    // renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
            <Box mt={4}>
              <><Typography>Your Leave History</Typography></>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow className='tableHead'>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  {rows?.length === 0 ? <Typography textAlign={'center'}>Employee Leave are empty</Typography> :
                    <TableBody>
                      {rows?.map((row, index) => {
                        return (
                          <TableRow>
                            <TableCell>    <Stack className='timeSheetStart'>{dateFormatterDetails(row?.startDate.replace(/Z/g, ''))}</Stack></TableCell>
                            <TableCell><Stack className='timeSheetEnd'>{dateFormatterDetails(row?.endDate.replace(/Z/g, ''))}</Stack></TableCell>
                            <TableCell>{row.status}</TableCell>

                          </TableRow>
                        )
                      })}
                      {/* Add more TableRow components as needed */}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
            </Box>
          </DialogContent>
          <DialogActions className='leaveContent'>
            <Button onClick={() => handleClose()} color="error">Close</Button>
            <Button disabled={isLoading} onClick={() => handleBookNow()} color="primary">Book now</Button>
            {/* <CustomDialog
                        open={openDialogs}
                        onClose={() => handleClose()}
                        success={createSuccess}
                        error={createError}
                        Content={createSuccess ? "Booked Successfully" : " Error Booked Created "} /> */}
          </DialogActions>
          {/* <Copyright sx={{ mt: 0, mb: 3,m:2 }} /> */}
        </Dialog>
        <Dialog open={noChange} >
          <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
            <Box paddingLeft={8} paddingRight={8}>
              <Typography textAlign={'center'}>
                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              {/* <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Success
                            </Typography> */}
            </Box>
          </DialogTitle>
          <Box paddingLeft={8} paddingRight={8}>
            <DialogContent >
              <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button variant='contained' className="NoChangeButton" >Ok</Button>
            </DialogActions>
          </Box>
        </Dialog>
        <CustomDialog
          open={openDialog}
          onClose={() => handleClose()}
          success={success}
          error={error}
          Content={success ? "Booked Successfully" : "Error Occured"}
        />
      </Container>
    </ThemeProvider>
    </>
  );
}
export default BookLeave;