import Box from '@mui/material/Box';
import { Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import Header from '../components/Header';

import React from 'react';
import AddBill from '../components/Bill/billForm/AddBill';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function BillForm() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    //   const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    //   };

    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="10px 0"
            >
                <Header title="Bills" subtitle="" />
            </Box>
            <AddBill />
        </>
    );
}