/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      :For CUSTOMER FORM VIEW
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Alert, Button, Card, CardHeader, Checkbox, Divider, Drawer, FormHelperText, Grid, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, Snackbar, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonIcon from '@mui/icons-material/Person';
import "./CustomerForm.scss"
import BadgeIcon from '@mui/icons-material/Badge';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { Title } from '@mui/icons-material';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import FeedIcon from '@mui/icons-material/Feed';
import { useState } from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Header from '../../Header';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { saveCustomer } from '../../../services/BidService';



const FileUploaderContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '150px',
    border: '2px dashed #ccc',
    borderRadius: '8px',
});

const FileUploadIcon = styled(CloudUploadIcon)({
    fontSize: '4em',
});
const CustomerForm: React.FC = () => {

    const navigate = useNavigate();
    const onPODetails = () => {
        navigate("/polayout"); // Navigate to the "/task" route
    };
    const customerForm = () => {
        navigate("/customerregistration"); // Navigate to the "/task" route
    };


    //   const handleChangePage = (event: unknown, newPage: number) => {
    //     setPage(newPage);
    //   };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const [selectedValue, setSelectedValue] = useState('');

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedValue(event.target.value as string);
    };
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };


    const [cleared, setCleared] = React.useState<boolean>(false);

    const [customerData, setCustomerData] = React.useState<any>({
        // customerUuid:'e43fcc67-5b56-4bba-b07f-d717413f8239',
        firstName:'',
        middleName:'',
        lastName:'',
        email:'',
        mobileNo:'',
        alternateMobile:'',
        accountNo:'',
        status:'active',
        active:true,
        deleted:false,
        createdAt:new Date(),
        updatedAt:new Date(),
        registeredDate:new Date(), 
    });
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [count, setCount] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [message, setMessage] = React.useState<any>({
        firstName: '',
        accountNo:''
    });

    const inputchange = (e: any) => {
       // console.log(e.target.value);
        setCustomerData({
            ...customerData,
            [e.target.name]: e.target.value
        });
    };

    const onNextPage = () =>
    {
        navigate('/createbid');
    }

    const handleClick = (message: any) => {
        setOpen(true);
        setToastMessage(message);
    };


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const validation = () => {
        let validated: any = true;
        if (customerData.firstName === '') {
            message.firstName = 'Enter First Name';
            validated = false;
        }
        if (customerData.accountNo === '') {
            message.accountNo = 'Enter Account Number';
            validated = false;
        }
        return validated;
    }

    const handleSubmit = async (e: any) => {
        setCount(count + 1);
        let validated = validation();
        if (validated === true) {
            let result = await saveCustomer(customerData, accessToken);
            if (result.status === 201) {
                handleClick('Inserted Successfully');
                setTimeout(() => {
                    onNextPage();
                }, 2000);
            }
            else {
                handleClick('Not Inserted');
            }
        }         
    };

    React.useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);
            return () => clearTimeout(timeout);
        }
        return () => { };
        
    }, [cleared]);

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
                m="10px 0"
            >
                <Header title="CUSTOMER REGISTRATION" subtitle="Welcome to your Customer Registration Page" />
                <Box>
                    {/* <Button
            className='poButton'
            variant="contained" size="medium"
            onClick={customerForm}
          >
            <PersonAddIcon className='personIcon' />&nbsp; New Customer
          </Button> */}
                </Box>
            </Box>
            <Snackbar open={open} autoHideDuration={1000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
                <Alert onClose={handleClose} security='success' sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 3 }}>
                <Grid item xs={12} sm={10}>
                    {/* <Card className='POCard'> */}
                    {/* <CardHeader className="customerFormHead"
                        title={
                            <div className='customerTitle'>Customer
                            </div>
                        }
                    /> */}
                    {/* <Divider className='hr' /> */}
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined">
                            <CardHeader
                                title={
                                    <div className='customerSubHead'>
                                        <BadgeIcon className='badgeIcon' sx={{ paddingTop: '5px' }} />&nbsp; Name and Contact
                                    </div>
                                }
                            />
                            <Grid container spacing={2} pb={5} pl={5} pr={5}>
                                {/* <Grid item xs={2}>
                                    <TextField
                                        className='textfield'
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Title"
                                        size="small"
                                        fullWidth

                                    />
                                </Grid> */}
                                <Grid item xs={4}>

                                    <TextField
                                        // helperText=" "
                                        id="demo-helper-text-aligned-no-helper"
                                        label="First Name"
                                        size="small"
                                        name='firstName'
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                        helperText={(customerData.firstName === '' && count !== 0) ? message.firstName : null}
                                        error={(customerData.firstName === '' && count !== 0)}
                                    />
                                </Grid>
                                <Grid item xs={4}>

                                    <TextField
                                        // helperText=" "
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Middle Name"
                                        size="small"
                                        name = "middleName"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                    />
                                </Grid>
                                <Grid item xs={4}>

                                    <TextField
                                        // helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Last Name"
                                        size="small"
                                        name = "lastName"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                    />
                                </Grid>
                                {/* <Grid item xs={2}>

                                    <TextField
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Suffix "
                                        size="small"
                                        fullWidth
                                    />
                                </Grid> */}
                                {/* <Grid item xs={6}>

                                    <TextField

                                        id="demo-helper-text-aligned"
                                        label="Company Name"
                                        size="small"
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid> */}
                                {/* <Grid item xs={6}>

                                    <Select
                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            Customer Display Name
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select>
                                    <FormHelperText>Helper text goes here</FormHelperText>

                                </Grid> */}
                                <Grid item xs={6}>

                                    <TextField

                                        id="demo-helper-text-aligned"
                                        label="Email"
                                        size="small"
                                        name = "email"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                    // helperText="You can use letters, numbers & periods"
                                    />
                                </Grid>
                                <Grid item xs={6}>

                                    <TextField

                                        id="demo-helper-text-aligned-no-helper"
                                        label="Mobile Number"
                                        size="small"
                                        name="mobileNo"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                    // helperText='Use 10 digit numbers '

                                    />
                                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}

                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Alternate Mobile Number"
                                        size="small"
                                        name="alternateMobile"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                    // helperText=' Use 10 digit numbers '

                                    />
                                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Account Number"
                                        size="small"
                                        name="accountNo"
                                        fullWidth
                                        onChange={(e) => inputchange(e)}
                                        helperText={(customerData.accountNo === '' && count !== 0) ? message.accountNo : null}
                                        error={(customerData.accountNo === '' && count !== 0)}

                                    />
                                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Other"
                                        size="small"
                                        fullWidth
                                        helperText=' '
                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        helperText=""
                                        id="demo-helper-text-aligned"
                                        label="Website"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        helperText=" "
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Name to print on Checks"
                                        size="small"
                                        fullWidth
                                    />
                                </Grid> */}
                            </Grid>
                        </Card>
                        {/* <Card className='POCard' variant="outlined">
                            <CardHeader
                                title={
                                    <div className='customerSubHead'>
                                        <MyLocationIcon className='badgeIcon' sx={{ paddingTop: '5px' }} />&nbsp; Addresses
                                    </div>
                                }
                            />
                            <Grid container spacing={2} p={2} pl={5} pr={5} pt={2}>
                                <Grid item xs={12}><div className='billingAddress'>Billing Address</div></Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="Street Address1"
                                        size="small"
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Street Address2"
                                        size="small"
                                        fullWidth
                                        helperText=''

                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="City "
                                        size="small"
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="State"
                                        size="small"
                                        fullWidth
                                        helperText='  '
                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField

                                        id="demo-helper-text-aligned-no-helper"
                                        label="Zip Code"
                                        size="small"
                                        fullWidth
                                        helperText='  '

                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Country"
                                        size="small"
                                        fullWidth
                                        helperText=' '
                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>

                                </Grid>
                                <Grid item xs={12}>
                                    <a
                                        className="anchor"
                                        href="https://example.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', }} // Custom styles for the anchor tag
                                    >
                                        Preview Address
                                    </a>
                                    <FormHelperText>Helper text goes here</FormHelperText>

                                </Grid>
                                <Grid item xs={12} pl={0} mt={2}> <div className='shippingAddress'>Shipping Address</div></Grid>
                                <Grid item xs={12} pl={0}>   <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /><span>Same as Billing Address</span></Grid>
                            </Grid>
                        </Card> */}
                        {/* <Card className='POCard' variant="outlined">
                            <CardHeader
                                title={
                                    <div className='customerSubHead'>
                                        <NoteAltIcon className='badgeIcon' sx={{ paddingTop: '5px' }} />&nbsp; Notes and Attachments
                                    </div>
                                }
                            />
                            <Grid container spacing={2} p={2} pl={5} pr={5}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="address-input"
                                        label="Notes"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid>
                                <Grid item xs={12} pb={3}><FileUploaderContainer>
                                    <Box
                                        sx={{
                                            flexGrow: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                       
                                       <div > <div><a href="#" id="link"><input type="file" id="file" />Add Attachment</a></div>
                                        <div>Max File size :20MB</div>
                                        </div>
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>

                                    </Box>
                                </FileUploaderContainer></Grid>
                            </Grid>
                        </Card> */}
                        {/* <Card className='POCard' variant="outlined">
                            <CardHeader
                                title={
                                    <div className='customerSubHead'>
                                        <CreditCardIcon className='badgeIcon' sx={{ paddingTop: '5px' }} />&nbsp; Payments
                                    </div>
                                }
                            />
                            <Grid container spacing={2} p={2} pl={5} pr={5} pb={4}>

                                <Grid item xs={6}>
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="Primary Payment Method"
                                        size="small"
                                        fullWidth
                                        helperText=""
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            Terms
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select>
                                    <FormHelperText>Helper text goes here</FormHelperText>
                                </Grid>
                                <Grid item xs={6}>
                                    <label><small>Sales Form Delivery Options</small></label>
                                    <Select

                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            Use Company Default
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={6}>
                                    <label><small>Language to use when you send invoice</small></label>
                                    <Select

                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                    >
                                        <MenuItem value="" disabled>
                                            English
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Card> */}
                        {/* <Card className='POCard' variant="outlined">
                            <CardHeader
                                title={
                                    <div className='customerSubHead'>
                                        <FeedIcon className='badgeIcon' sx={{ paddingTop: '5px' }} />&nbsp; Additional Info
                                    </div>
                                }
                            />
                            <Grid container spacing={2} p={2} pl={5} pr={5} pb={4}>
                                <Grid item xs={12}><div className='billingAddress'>Taxes</div></Grid>
                                <Grid item xs={12} p={0}>   <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                /><span>This Customer is Tax exempt</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Select

                                        fullWidth
                                        value=""
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size='small'
                                        onChange={(handleSelectChange) => { }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select Tax Rate
                                        </MenuItem>
                                        <MenuItem value="1">Option 1</MenuItem>
                                        <MenuItem value="2">Option 2</MenuItem>
                                        <MenuItem value="3">Option 3</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={12} mt={2}><div className='billingAddress'>Opening Balance</div></Grid>
                                <Grid item xs={6} mt={1}>
                                    <TextField
                                        id="demo-helper-text-aligned-no-helper"
                                        label="Opening Balance"
                                        size="small"
                                        fullWidth
                                        helperText=' '
                                    />
                                    <FormHelperText>Helper text goes here</FormHelperText>

                                </Grid>
                                <Grid item xs={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="As oF" slotProps={{ textField: { fullWidth: true, size: 'small' } }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <FormHelperText>Helper text goes here</FormHelperText>

                                </Grid>

                            </Grid>
                        </Card> */}
                    </Box>

                    {/* <Divider className='hr'></Divider> */}
                    <Box m={2} className="poFooter">


                        <Button className="button" variant="contained" size="medium" onClick={handleSubmit}>Save</Button>
                        <Button variant="contained" size="medium" onClick={onPODetails}>Cancel</Button>



                    </Box>
                    {/* </Card> */}
                </Grid>

            </Grid>
        </>
    );
}

export default CustomerForm;