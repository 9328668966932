import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow,
    Paper, Checkbox,
    Grid, useMediaQuery, Collapse, Typography,
    Stack
} from '@mui/material';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { getExpenses, getInvoiceReceives, getWipReport } from '../../services/JobLedgerService';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';




interface Data {
    id: number;
    industry: string;
    costCode: string;
    unit: string;
    actualCost: number;
    estCost: number;
    variance: number;
}

function createData(
    id: number,
    industry: string,
    costCode: string,
    unit: string,
    actualCost: number,
    estCost: number,
    variance: number,
): Data {
    return {
        id,
        industry,
        costCode,
        unit,
        actualCost,
        estCost,
        variance,
    };
}

const rows = [
    createData(1, 'Cupcake', '305', '3.7', 0.00, 0.00, 0.00),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'industry',
        numeric: false,
        disablePadding: true,
        label: 'Industry',
    },
    {
        id: 'costCode',
        numeric: true,
        disablePadding: false,
        label: 'Cost Code',
    },
    {
        id: 'unit',
        numeric: true,
        disablePadding: false,
        label: 'Unit',
    },
    {
        id: 'actualCost',
        numeric: true,
        disablePadding: false,
        label: 'Actual Cost',
    },
    {
        id: 'estCost',
        numeric: true,
        disablePadding: false,
        label: 'Est Cost/Rev',
    },
    {
        id: 'variance',
        numeric: true,
        disablePadding: false,
        label: 'Variance',
    }
];



interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    return (
        <TableHead >
            <TableRow className='tableHead'>
                {/* <TableCell padding="checkbox">
                </TableCell> */}
                {/* {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))} */}
                <TableCell>Job Name</TableCell>
                <TableCell>Contract Price</TableCell>
                <TableCell>Approved CO Price</TableCell>
                <TableCell>Total Contract</TableCell>
                <TableCell>Total Billed</TableCell>
                <TableCell>Estimated Costs</TableCell>
                <TableCell>Total Actual Costs</TableCell>
                <TableCell>% complete </TableCell>
                <TableCell>Earned Revenue</TableCell>
                <TableCell>(Over) Under Billings</TableCell>
                <TableCell>Remaining Billings</TableCell>
            </TableRow>
        </TableHead>
    );
}

const WipReport: React.FC<any> = (props) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('industry');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [data, setData] = React.useState<any>({});
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [invoiceData, setInvoiceData] = React.useState<any>([] as any[]);
    const [expenseList, setExpenseList] = React.useState<any>([] as any[]);


    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


    const getWipReportByProjectId = async () => {
        let result = await getWipReport(props.projectId, accessToken);
        console.log(result);
        // if(result.data.length !== 0){
        setData(result.data);
        // }
    }


    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));

    const handleInvoiceClick = (e: any) => {
        setOpen(false);
        setShow(!show);
        if (invoiceData.length === 0) {
            getInvoice();
        }
    }
    const handleExpenseList = (e: any) => {
        setShow(false);
        setOpen(!open);
        if (expenseList.length === 0) {
            getPaymentExpenseList();
        }
    }

    const getInvoice = async () => {
        let result = await getInvoiceReceives(props.projectId, accessToken);
        if ((result.data)?.length !== 0) {
            setInvoiceData(result.data);
        }
        console.log(result.data);
    }

    const getPaymentExpenseList = async () => {
        let result = await getExpenses(props.projectId, accessToken);
        if ((result.data)?.length !== 0) {
            setExpenseList(result.data);
        }
    }

    // function getPrice(data:any){
    //     if(data < 0) {
    //     return '('+data *+")";
    //     }
    //     else return data.UnitPrice
    // }

    React.useEffect(() => {
        getWipReportByProjectId();
    }, [])



    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item md={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='POCard' sx={{ width: '100%', mb: 2 }}>
                            <TableContainer sx={{ marginTop: 3 }}>
                                <Table
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={rows?.length}
                                    />
                                    <TableBody>
                                        {!data ?
                                            <TableRow>
                                                <TableCell colSpan={11}>
                                                    <Typography align="center">No Data</Typography>
                                                </TableCell>
                                            </TableRow> :
                                            <><TableRow>
                                                <>
                                                    <TableCell>{data?.jobName}</TableCell>
                                                    <TableCell>$ {data?.contractPrice}</TableCell>
                                                    <TableCell>$ {data?.approvedCoPrice}</TableCell>
                                                    <TableCell>$ {data?.totalContract}</TableCell>
                                                    <TableCell sx={{ cursor: 'pointer' }} onClick={(e) => handleInvoiceClick(e)}>
                                                        {/* {data?.totalBilled} */}
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography sx={{ fontSize: '13px' }}>$</Typography>
                                                            <Typography sx={{ fontSize: '13px' }}> {data?.totalBilled}</Typography>
                                                            {show === false?
                                                                <ArrowDropDownIcon fontSize='small' />
                                                                :
                                                                <ArrowDropUpIcon fontSize='small' />
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>$ {data?.estimatedCost}</TableCell>
                                                    <TableCell sx={{ cursor: 'pointer' }} onClick={(e) => handleExpenseList(e)}>
                                                        {/* $ {data?.totalActualCost} */}
                                                        <Stack direction="row" alignItems="center">
                                                            <Typography sx={{ fontSize: '13px' }}>$</Typography>
                                                            <Typography sx={{ fontSize: '13px' }}> {data?.totalActualCost}</Typography>
                                                            {open === false?
                                                                <ArrowDropDownIcon fontSize='small' />
                                                                :
                                                                <ArrowDropUpIcon fontSize='small' />
                                                            }
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell>{(data?.percentageComplete)?.toFixed(1)} %</TableCell>
                                                    <TableCell>$ {(data?.earnedRevenue)?.toFixed(2)}</TableCell>
                                                    <TableCell>$ {(data?.underBillings)?.toFixed(2)}</TableCell>
                                                    <TableCell>$ {data?.remainingBillings}</TableCell>
                                                </>
                                            </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Table size="small" aria-label="purchases"
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Serial No</TableCell>
                                                                            <TableCell>Payment Type</TableCell>
                                                                            <TableCell>Paid Date</TableCell>
                                                                            <TableCell>Amount</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {expenseList?.map((item, index) =>
                                                                            <TableRow>

                                                                                <>
                                                                                    <TableCell>{index + 1}</TableCell>
                                                                                    <TableCell>{item?.referenceType}</TableCell>
                                                                                    <TableCell>{dayjs(item?.paidDate).format('DD MMM YYYY')}</TableCell>
                                                                                    <TableCell>{item?.paidAmount}</TableCell>
                                                                                </>

                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                                                        <Collapse in={show} timeout="auto" unmountOnExit>
                                                            <Box sx={{ margin: 1 }}>
                                                                <Table size="small" aria-label="purchases"
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Serial No</TableCell>
                                                                            <TableCell>Invoice No</TableCell>
                                                                            <TableCell>Paid Date</TableCell>
                                                                            <TableCell>Amount</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {invoiceData?.map((item, index) =>
                                                                            <TableRow>

                                                                                <>
                                                                                    <TableCell>{index + 1}</TableCell>
                                                                                    <TableCell>{item?.invoiceNo}</TableCell>
                                                                                    <TableCell>{dayjs(item?.paidDate).format('DD MMM YYYY')}</TableCell>
                                                                                    <TableCell>{item?.paidAmount}</TableCell>
                                                                                </>

                                                                            </TableRow>
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        }
                                    </TableBody>

                                </Table>
                            </TableContainer>
                            {/* <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={rows?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            /> */}
                        </Paper>
                        {/* <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            /> */}
                    </Box>
                </Grid>
            </Grid>
        </>

    );
}

export default WipReport;