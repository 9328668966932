/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Tenant service
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/
import { executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";
// import { ConfigUrl } from "./BaseApi";
import { ConfigUrl } from "../auth/OidcConfig";

export const generalConfig = async (payload: any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tenants/generalConfiguration`;
  return await executePostData(url,payload, accessToken);
};
export const getTenantDetails = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tenants/getTenantDetails`;
  return await executeGetData(url, accessToken);
};
export const getVersionDetails = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/version/getVersions`; 
  return await executeGetData(url, accessToken);
};


export const upadateTenantDetails = async (payload:any,id:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/tenants/updateGeneralConfig/id/${id}`;
  return await executePutData(url,payload, accessToken);
};
export const createSignatureContract = async (payload: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/tenants/signatureUpload`; // Corrected URL
  return await executePostImage(url, payload, accessToken);
};
  
export const getSignature= async(accessToken:any) =>{
    const url = `${ConfigUrl.baseURL}/tenants/getSignature`;
    return await executeGetData(url, accessToken);
}

export const getTenantReport = async (accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/projects/getTenantProjectReport`;
  return await executeGetData(url, accessToken);
}