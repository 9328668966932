import React, { useContext } from 'react';
import {  Grid } from '@mui/material';
import { useNavigate } from "react-router";
import SalesProjectManagement from '../SalesProjectManagement';
import CustomerProjectManagement from '../CustomerProjectManagement';
import { UserContext } from '../../hooks/UserSession';



const SalesEstimate: React.FC = () => {
  let navigate = useNavigate();
 

  const { userSessionDetails, setUserDetails } = useContext(UserContext);

  return (<>
    <Grid container spacing={2} >
      <Grid item xs={12} justifyContent="center" className="projectRendering">
        {/* {loading ? (
          
          <CircularProgress className="loaderCircularBar"/>
        ) : (
          
          <> */}
        {userSessionDetails?.role?.name === 'customer' ? (
          <CustomerProjectManagement />
        ) : (
          <SalesProjectManagement />
        )}
        {/* </>
        )} */}
      </Grid>
    </Grid>
  </>)
}

export default SalesEstimate;