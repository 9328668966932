/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 3-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Material Management Service
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |     AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 2174           |  14/06/24   |   Vanitha    | Material Management Pagination Implementation

*
*/
import { executeDeleteData, executeGetData, executePostData, executePutData } from "./ApiBase";
import { ConfigUrl } from "../auth/OidcConfig";


export const searchMaterials = async (accessToken: any, materials: any, materialName:any, trade:any, grade:any, page: any, size:any) => {
  const url = `${ConfigUrl.baseURL}/materials/search/materialName/${materialName}/trade/${trade}/grade/${grade}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
}
export const getMaterialsCount = async(accessToken: any, materialName:any, trade:any, grade:any) => {
  const url = `${ConfigUrl.baseURL}/materials/count/${materialName}/trade/${trade}/grade/${grade}`;
  return await executeGetData(url, accessToken);
}

export const getMaterialsTags = async(accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/materials/tag`;
  return await executeGetData(url, accessToken);
}



export const editMaterial = async (payload: any, accessToken: any) => {
  console.log(payload);
  const url = `${ConfigUrl.baseURL}/materials/updateMaterialCost`;
  return await executePutData(url, payload, accessToken);
}

export const getAllTrade = async (accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getalltrades`;
  return await executeGetData(url, accessToken);
}

export const getManufacturer = async (trade: any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/trades/getManufacturers/trade/${trade}`;
  return await executeGetData(url, accessToken);
}
export const updateMaterialCost = async (accessToken: any, materials: any) => {
  const url = `${ConfigUrl.baseURL}/materialcost/updateMaterialCost`;
  return await executePostData(url, materials, accessToken);
}

export const getMaterialsCost = async (selectedDate: any, vendorMaterialMapId:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/materialcost/getMaterialCostByDateAndMapId/selectedDate/${selectedDate}/vendorMaterialMapId/${vendorMaterialMapId}`;
  return await executeGetData(url, accessToken);
}