/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 01-Dec-2024
* @author      : Ananth Kumar L
* @since       : 0.0.1
* Requirement# :
* Purpose      : For Chat UI
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './Chat.scss'
import { Box, Button, ButtonGroup, Chip, Fab, Paper, Stack, TextField, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { getBidInfoByQueryParam, postChatBot } from "../../services/ChatBotService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
// import image from "../../assets/images/minilogo-static.png";
import image from "../../assets/images/google-chat-logo-512x512.png";
import { MessagesInfo, BidInfo, ChatOptions } from "../../viewmodel/Chat";
import { defaultMessage } from "../../data/ChatQuestions";
import { UserContext } from "../../hooks/UserSession";
import { PagingInfo } from "../../queryparams/PagingInfo";
import { BidQueryParam } from "../../queryparams/BidQueryParam";
import { tokens, useGlobalContext } from "../../hooks/theme";
import { data } from "../../data/bidsOutTotal";
import dotted from "../../assets/image-processing20200320-1564--unscreen.gif";
import chatlogo from "../../assets/chat.png";

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




interface Props {
    userResponse: string;
    botResponse: {
        purpose: string;
        message: string;
        options?: string[];
        sender: string;
    };
    sendUserResponse: string;
    options: ChatOptions[];
    optionClick: (ChatOptions) => void;
}


const ChatCard: React.FC<Props> = (props) => {
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const [expanded, setExpanded] = React.useState(false);
    const [messages, setMessages] = useState<MessagesInfo[]>([]);
    // const dummyRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    const [step, setStep] = useState<number>(0);
    //const [sendUserResponse, setSendUserResponse] = useState<string>("");
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    // const [botResponse, setBotResponse] = useState<any>({
    //     purpose: "",
    //     message: "",
    //     sender: "GMS",
    //     // options:["Why did my bid cost changed"]
    // });
    const [userResponse, setUserResponse] = useState<string>("");
    const [options, setOptions] = useState<ChatOptions[]>([]);
    const [bidInfos, setBidInfos] = useState<any[]>();
    const { theme, setCopyy } = useGlobalContext();
    const colors = tokens(theme.palette.mode);


    const setMessageResponse = async (userResp: any) => {
        const tempArray: MessagesInfo[] = messages;
        tempArray.push({
            message: userResp,
            sender: "You",
            timestamp: getTimeStamp(),
        });
        console.log("setMessageResponse", tempArray);
        setMessages(tempArray);
    }

    const setBotResponse = (botMsg: any) => {
        let tempArrayTest = messages;
        tempArrayTest.push({
            message: botMsg,
            sender: "GMS",
            timestamp: getTimeStamp(),
        });
        setMessages(tempArrayTest);

        // setTimeout(() => {
        //     let temp2 = [...tempArray];
        //     temp2.push({
        //         message: "Hi",
        //         sender: "GMS",
        //         timestamp: getTimeStamp(),
        //     });
        //     setMessages(temp2);
        // }, 1000);
    }

    const getBidIds = (bidInfos: any) => {
        var bidIds: ChatOptions[] = [];
        if (bidInfos != undefined && bidInfos != null && bidInfos.length > 0) {
            for (var i = 0; i < bidInfos.length; i++) {
                //bidIds[i] = bidInfos[i].id;  
                //bidIds[i] = {option:bidInfos[i]?.id?.toString(),type:"BidId"};            
                bidIds.push({ option: bidInfos[i]?.id?.toString(), type: "BidId" });
            }
        }
        return bidIds;
    }

    // const onOptionClick = (args: any) => {
    //     let selectedBidInfo :any;
    //     if(bidInfos != null && bidInfos.length > 0){
    //        bidInfos.forEach(bid=>{
    //         if(bid.id == args){
    //             selectedBidInfo = bid;
    //         }
    //        });
    //     }
    //     props.optionClick(selectedBidInfo);
    // }

    useEffect(() => {
        if (messages.length === 0) {
            let defaultMsg: MessagesInfo[] = [];
            if (userSessionDetails) {

                var bidQueryParams: BidQueryParam = {};
                var pagingInfo: PagingInfo = {};
                if (userSessionDetails.role && userSessionDetails.role != null && userSessionDetails.role.name == "customer") {
                    pagingInfo.page = 0;
                    pagingInfo.size = 5;
                    bidQueryParams.customerUid = accessTokenPayload.oid;
                    bidQueryParams.status = "Incart";
                };

                const getBidInfo = async () => {
                    // addToCart(bidData.id, "Incart", accessToken).then(res => {
                    //     if (res.status === 200) {
                    //         setSuccess(true);
                    //         // let blobData = generateChatData(res.data);
                    //         // storeDataToBlob(blobData, blobData, accessToken).then(result => {
                    //         //   if (result.status === 200) {
                    //         //     let x = result.data;
                    //         //   }
                    //         // });
                    //         setTimeout(() => { window.location.reload(); }, 1000);
                    //     }
                    //     else {
                    //         setError(true);
                    //     }
                    // }
                    // );

                    await getBidInfoByQueryParam(bidQueryParams, pagingInfo, accessToken).then(res => {
                        if (res.status === 200) {
                            //const bidInfos = res.data;
                            setBidInfos(res.data);
                            //to prepare chat options with type
                            let bidIds: ChatOptions[];
                            bidIds = getBidIds(res.data);
                            //bidIds = ["B5752"];
                            defaultMsg = [defaultMessage(userSessionDetails.firstname)];
                            setMessages(defaultMsg);
                            setOptions(bidIds);

                        }
                        else {
                            //var x = false;
                        }
                    });


                }
                getBidInfo();
            }
            // setMessages([
            //     {
            //         purpose: "introduction",
            //         message: "Hello! Greetings from Gms! How can I help you?",
            //         // options:["Why did my Bid scheduled Changed"],
            //         sender: "Gms",
            //         timestamp: getTimeStamp(),
            //     },
            // ]);
            // var bidIds = ["B0005"];
            // setOptions(bidIds);
        } else {
            let tempArray = [...messages];
            tempArray.push({
                message: props.sendUserResponse,
                sender: "You",
                timestamp: getTimeStamp(),
            });
            setMessages(tempArray);
            setOptions(props.options);
            setTimeout(() => {
                let temp2 = [...tempArray];
                temp2.push({ ...props.botResponse, timestamp: getTimeStamp() });
                setMessages(temp2);
            }, 1000);
        }
    }, [props.sendUserResponse, props.botResponse]);

    // useEffect(() => {
    //     if (messages.length === 0) {
    //         let defaultMsg: MessagesInfo[] = [];
    //         if (userSessionDetails) {

    //             var bidQueryParams: BidQueryParam = {};
    //             var pagingInfo: PagingInfo = {};
    //             if (userSessionDetails.role && userSessionDetails.role != null && userSessionDetails.role.name == "customer") {
    //                 pagingInfo.page = 0;
    //                 pagingInfo.size = 5;
    //                 bidQueryParams.customerUid = accessTokenPayload.oid;
    //                 bidQueryParams.status = "Incart";
    //             };

    //             const getBidInfo = async () => {
    //                 const response = await getBidInfoByQueryParam(bidQueryParams, pagingInfo, accessToken);
    //                 const bidInfos = await response.data;
    //                 var bidIds = getBidIds(bidInfos);
    //                 bidIds = ["B0005"];
    //                 defaultMsg = [defaultMessage(userSessionDetails.firstname)];
    //                 setMessages(defaultMsg);
    //                 setOptions(bidIds);
    //             }
    //             getBidInfo();

    //             //defaultMsg[0].options = bidIds;
    //         }

    //     }

    // }, [messages]);


    // useEffect(() => {
    //     if (
    //         dummyRef &&
    //         dummyRef.current &&
    //         bodyRef &&
    //         bodyRef.current
    //     ) {
    //         bodyRef.current.scrollTo({
    //             top: dummyRef.current.offsetTop,
    //             behavior: "smooth",
    //         });
    //     }
    // }, [messages]);

    const getTimeStamp = (): string => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${period}`;
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const setNextStep = async (userResp: any) => {
        await setMessageResponse(userResp);
        //await setBotResponse("XYZ");
        let data = {
            "question": userResp,
            "context": "bid"
        }
        // let botMessage = await postChatBot(data, accessToken);
        // if (botMessage.ok) {
        //     //setMessageResponse();
        //     //setStep(prevState => prevState + 1);
        //     //setSendUserResponse(response);
        //     // let res = NextSteps(step, response, responseMessage.current);           

        //     //setUserResponse("");
        //     //setUserResponse("");
        // }
    };

    const optionClick = (userResp: any) => {
        if (userResp) {
            //  setUserResponse(userResp);
            // setNextStep(userResp);
            const tempArray = messages;
            tempArray.push({
                purpose: "",
                message: userResp,
                sender: "You",
                timestamp: getTimeStamp(),
            });
            console.log("setMessageResponse", tempArray);
            setMessages(tempArray);
        }
    };
   
const dummyRef = useRef<HTMLDivElement>(null);


useEffect(() => {
    dummyRef.current?.scrollIntoView({ behavior: 'smooth' });
}, [messages]); //

    return (
        <Card sx={{ maxWidth: 345 }} className="chatcard-container" style={{ position: 'fixed', bottom: 15, right: 95, zIndex: 1050 }}>
            <CardHeader className="chat-header" style={{ display: 'flex', alignItems: 'center', }}
                avatar={<Box component='img' src={chatlogo} sx={{ width: "30px", height: "30px" }} />}
                title="GMS AI Chat"
                titleTypographyProps={{ variant: 'h3', color: 'white' }} >
            </CardHeader>
            <CardContent className='chat-msg-container' sx={{ maxHeight: 450, minHeight: 450 }} >
                {messages.map((chat, index) => (
                    <>
                        {chat.sender === 'GMS' ?
                            <div key={index}>
                                <span style={{ fontSize: "14px", color: "grey", marginRight: "auto", marginLeft: "12px", }}>
                                    {chat.sender}
                                </span>
                                <div className={`message ${chat.sender}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    {chat.message}
                                    {/* <img src={dotted} alt="GIF" height={"40px"} width={"50px"}/> */}
                                </div>
                                <span className="timestamp" style={{ fontSize: "11px", color: "grey", marginRight: "auto", marginLeft: "3px" }}>
                                    {chat.timestamp}
                                </span>
                                {/* <div ref={dummyRef} className="dummy-div"></div> */}
                            </div>
                            :
                            <div key={index}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    spacing={2}
                                >
                                    {/* <span style={{ fontSize: "14px", color: "orange" }} className={`${chat.sender} title`}>
                                    {chat.sender}
                                </span> */}
                                    <div style={{ fontSize: "14px", color: "#232323" }} >
                                        {chat.sender}
                                    </div>
                                    <div className={`message ${chat.sender}`} style={{ display: 'flex', alignItems: 'center' }}>
                                        {chat.message}
                                    </div>
                                    {/* <div className={`message ${chat.sender}`} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className="dot-flashing"></div>
                                    </div> */}
                                    <span className="timestamp" style={{ fontSize: "11px", color: "grey", marginTop: "0px" }}>
                                        {chat.timestamp}&#10004;&#10004;
                                    </span>
                                </Stack>

                                {/* <div ref={dummyRef} className="dummy-div"></div> */}
                            </div>
                        }
                    </>
                ))}
                  <div ref={dummyRef} className="dummy-div"></div>     
            </CardContent>
            <CardActions sx={{ marginBottom: "10px" }}>
                {/* <Stack direction="column" spacing={1} style={{ width: '100%' }}> */}
                    {options != undefined && options != null && options.length > 0 ? (
                        <Stack className="options" direction="row" spacing={1} flexWrap="wrap">
                            
                            <Card sx={{padding:"5px"}}>
                           
                            <ButtonGroup
                                 
                                 aria-label="Vertical button group"
                                 variant="outlined"
                             
                                
                            >
                                <Button>
                                    <TouchAppIcon />
                                </Button>
                                {options.map((option, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => props.optionClick(option)}
                                        color="primary"
                                    >
                                        {option.option}
                                    </Button>
                                ))}
                            </ButtonGroup>
                            </Card>

                            {/* <Chip label={<TouchAppIcon />} color="primary" className="MuiChip MuiChipSpace" /> */}
                        </Stack>
                    ) : null}
                    {/* <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                        <TextField
                            fullWidth
                            className="custom-textfield"
                            placeholder="Type a message.."
                            variant="outlined"
                            // value={userResponse}
                            onChange={(e) => setUserResponse(e.target.value)}
                            disabled
                        />
                        <Fab color="primary" aria-label="add" className="chat-fabicon" onClick={() => setNextStep(userResponse)} sx={{height:"50px"}}>
                            <SendIcon />
                        </Fab>
                    </Stack> */}
                {/* </Stack> */}
            </CardActions>
        </Card >
    );
}

export default ChatCard;

