/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For sales projectmanagement dashboard (sales/Estimate)
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1505   25/3/24   Priyankaa   RBAC for salesestimate page
*
*/

import { Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { options } from "../data/ForecastChartData"
import LineChart from "../components/lineChart/LineChart";
import { forecastChartData } from "../data/ForecastChartData";
import UpcomingVerification from "../components/upcomingVerification/UpcomingVerification";
import { UserContext,  isUserHasPermission } from "../hooks/UserSession";
import { useContext, useState } from "react";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import Salescard from "../components/Salescard/Salescard";
import CompletedTaskFeed from "../components/completedTaskFeed/CompletedTaskFeed";
import GPS from "../components/gps/Gps";
import React from "react";


const SalesProjectManagement: React.FC = () => {
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const [verificationData, setVerificationData] = useState({
    status: undefined as string | undefined,
    taskName: undefined as string | undefined,
    bidId: undefined as any,
  });

  // const [weatherData, setWeatherData] = useState({
  //   coordinates: undefined as string | undefined,
  //   projectId: undefined as any,
  // });
  const [coordinates, setCoordinates] = React.useState<any>();
  const [projectId, setProjectId] = React.useState<any>();

  const handleData = (coordinates: any, projectId: any) => {
    // Handle data received from GPS component

    setCoordinates(coordinates);
    setProjectId(projectId);
    console.log('Location received from GPS:', coordinates);
    console.log('ProjectId id received from GPS:', projectId)
  };

  const handleDataFromChild = (status: string | undefined, taskName: string | undefined, bidId: any) => {
    // Handle data received from GPS component
    console.log('Status received from GPS:', status);
    console.log('TaskName received from GPS:', taskName);
    console.log('Bid id received from GPS:', bidId)
    setVerificationData({ status, taskName, bidId });

  };

  const { userSessionDetails, setUserDetails } = useContext(UserContext);
  console.log(userSessionDetails);

  return (
    <>
      <UserContext.Provider value={{ userSessionDetails, setUserDetails }}>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
          <Grid item xs={12} md={9} sm={12}>
            {/* <Grid item xs={12} mb={2} className='headerTable'>
      <Stack direction="row">
        <InsertChartIcon />&nbsp; Monthly recap report
      </Stack>
    </Grid>
    <MonthlyRecapReport />
    <BidStatus /> */}
            {userSessionDetails?.role?.name === "sales" &&
              <UpcomingVerification status={verificationData.status} taskName={verificationData.taskName} bidId={verificationData.bidId} />
            }
            <Grid container rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
              {/* Conditionally render UpcomingVerification based on user permission */}
              {isUserHasPermission("UpcomingVerification") && (
                <Grid item xs={12} md={12} sm={12} mt={1}>
                  <Salescard coordinates={coordinates} projectId={projectId} />
                </Grid>
              )}
              {/* <Grid item xs={12} md={4} sm={12}>
                <Grid item xs={12} mt={1}>
                  <Card className="POCard" sx={{ minHeight: "350px" }}>
                    <CardContent>
                      <Typography className="headerTable">
                        John's forecast chart
                      </Typography>
                      <LineChart tradeTypeName={forecastChartData} width={"100%"} height={"280px"} options={options} />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} sm={12}>
            <Grid item xs={12} mb={2} className='headerTable'>
              <GPS onDataReady={handleDataFromChild} onData={handleData} />
            </Grid>
            <Grid item xs={12} mb={2} className='headerTable '>
              <Stack direction="row">
                <InsertChartIcon />&nbsp; Completed Task Feed
              </Stack>
            </Grid>

            <CompletedTaskFeed />
            {/* <ClosingForeCastRatio /> */}
          </Grid>

        </Grid>
      </UserContext.Provider>
    </>

  );
};

export default SalesProjectManagement;