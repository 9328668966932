
/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Jan-2023
* @author      : Asish.Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : Document Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData, executePostData, executePostImage, executePutData } from "./ApiBase";


export const getDocumentListByProjectId= async (projectId:any,accessToken:any) => {
    const url = `${ConfigUrl.baseURL}/documents/getDocuments/projectId/${projectId}`;
    return await executeGetData(url, accessToken);
  };
