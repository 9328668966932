/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For customer projectmanagement dashboard (sales/Estimate)
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/

import { Card, Grid } from "@mui/material";
import PaymentDetails from "../paymentDetails/PaymentDetails";


const Finance: React.FC = () => {

    return (
        <>

            <Grid container justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={10} sm={12}>
                <PaymentDetails/>
                </Grid>
            </Grid>
        </>

    );
};

export default Finance;