/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1557           |  10/04/24   | shiyam Kumar  |  Template Add, Edit and Delete Implementation
* 1622           |  16/04/24   | shiyam kumar  |  Make as Componant Bom Setting 
* 1557           |  24/04/24   | shiyam kumar  |  Edit,Delete Issue Fix
* 1819           |  06/05/24   | Vanitha       |  All fields in new trade select trade field have a mandatory warning
* 1782           | 09-05-2024  | Shiyam Kumar  | MAde Changes For Trade Domain Change
* 2175           | 14-06-2024  |   Vanitha     | Remove search bar implementation
*/

import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from "@mui/material/Grid";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Bomtemplate from '../bomtemplate/Bomtemplate'
import Tradecontract from './tradecontract/Tradecontract'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import "../Tenanatconfiguration.scss";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import GanttDialog from '../../../components/ganttchart/components/GanttDialog';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MenuItem from '@mui/material/MenuItem';
import BOMFileupload from './addedittemplate/Fileupload/BOMFileupload'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { addTrade, deleteTrade, getTrades } from '../../../services/TradeConfigurationService';
import ConfirmationDialog from './ConfirmationDialog';
import { deleteTemplate, getTemplates } from '../../../services/TradeTemplateService';
import TemplateForm from './templateSettings/templateForm';
import TradeForm from './TradeForm';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CustomDialog from '../../../pages/Dialog';


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Tradeconfiguration() {
    const [value, setValue] = React.useState(0);
    // const [selectedValue, setSelectedValue] = React.useState('All');
    const [open, setOpen] = React.useState(false);
    const [showTemplate, setShowTemplate] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handlegantClose = () => setgantOpen(false);
    const [selectedTradeData, setSelectedTradeData] = React.useState<any>();
    const [selectedTemplate, setSelectedTemplate] = React.useState<any>();
    const [tradeNames, setTradeNames] = React.useState<any>([]);
    const [tradeData, setTradeData] = React.useState<any>([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDeletDialog, setOpenDeleteDialog] = React.useState(false);
    const [tradeId, setTradeId] = React.useState<any>();
    const [tradeTemplateId, setTradeTemplateId] = React.useState<any>();
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [data, setData] = React.useState<any>({
        name: undefined,
        manufacturer: undefined,
        materialType: undefined,
        description: undefined,
        code: undefined
    });
    const [templateList, setTemplateList] = React.useState<any>([]);

    const handleDialogClose = () => {
        setOpenDialog(false);
        setOpenDeleteDialog(false);
    };


    const tabhandleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChange = (event: any) => {
        // getTradeList();
        setData({ ...data, [event.target.name]: event.target.value });
    };

    const showGenericTemplate = () => {
        setShowTemplate(true);
    }

    const editTemplate = (template: any) => {
        setSelectedTemplate(template);
        setShowTemplate(true);
    }

    const [tasks, setTasks] = React.useState([]);
    const [gantopen, setgantOpen] = React.useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();


    const getTradeList = async () => {
        let tradenames: any = [];
        let result = await getTrades(accessToken);
        setTradeData(result.data);
        result?.data?.map((data) => {
            tradenames.push(data?.name)
        })
        setTradeNames(Array.from(new Set(tradenames)));
        const response = await getTemplates(accessToken);
        setTemplateList(response.data);
    }

    const handleDialogOpen = (id: any) => {
        setTradeId(id);
        setOpenDialog(true);
    }

    const onDeleteTrade = async () => {
        setOpenDialog(false);
        if (tradeId !== null) {
            let result = await deleteTrade(tradeId, accessToken);
            console.log(result);
            if (result.status === 200 || result.status === 201 || result.status === 204) {
                var res = await getTrades(accessToken);
                setTradeData(res.data);
                setSuccess(true);
            }
            else {
            }
        }
        else {
        }
    }

    const closeTemplate = async () => {
        setShowTemplate(false);
        setSelectedTemplate(undefined);
        const response = await getTemplates(accessToken);
        setTemplateList(response.data);
    }

    const closeTradeForm = async () => {
        setOpen(false);
        setSelectedTradeData(undefined);
        let result = await getTrades(accessToken);
        setTradeData(result.data);
    }

    const handleEditTrade = (row: any) => {
        setSelectedTradeData(row);
        setOpen(true);
    }

    const handleDelete = (id: any) => {
        setOpenDeleteDialog(true);
        setTradeTemplateId(id);
    }

    const handleTemplateDelete = async () => {
        const res = await deleteTemplate(tradeTemplateId, accessToken);
        if (res.status === 200 || res.status === 201 || res.status === 204) {
            const response = await getTemplates(accessToken);
            setTemplateList(response.data);

        }
        else {
            console.log("error");
        }
        setSuccess(true);
        setOpenDeleteDialog(false);

    }

    React.useEffect(() => {
        getTradeList();
        var newArr: any = ["0"];
        newArr = newArr.filter(item => item !== "0");
    }, []);

    const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setSuccess(false);
        setError(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid xs={12} md={8}>
                    <Typography variant="h3" component="h2" align='left' sx={{ mx: 2 }}>
                        {/* {selectedValue} */}
                        Trade Configuration
                    </Typography>
                </Grid>
                {/* <Grid xs={12} md={4}>
                    <FormControl sx={{ m: 0, width: '100%' }} size="small">
                        <InputLabel id="demo-select-small-label">Select Trade</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={selectedValue}
                            label="Select Trade"
                            onChange={handleChange}
                        >
                            <MenuItem value="All">
                                All
                            </MenuItem>
                            {tradeNames?.map((trade, index) => (
                                <MenuItem key={index} value={trade}>
                                    {trade}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid xs={12} md={12}>
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <Tabs
                            value={value}
                            onChange={tabhandleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab sx={{ textTransform: "none" }} label="Add/Edit Trade" />
                            <Tab sx={{ textTransform: "none" }} label="Template Settings" />
                            <Tab sx={{ textTransform: "none" }} label="Contract Settings" />
                            <Tab sx={{ textTransform: "none" }} label="BOM Settings" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                        <Box>
                            <Grid
                                container
                                sx={{ p: 0 }}
                                spacing={{ xs: 2, md: 3 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                                <Grid item xs={12} md={8}>
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <Box sx={{ float: "right", mb: 3 }}>
                                        <Button variant="contained" onClick={handleOpen}>
                                            Add New Trade
                                        </Button>
                                    </Box>
                                </Grid>
                                {open && <TradeForm open={open} function={closeTradeForm} selected={selectedTradeData} />}
                            </Grid>

                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table" >
                                    <TableHead sx={{}}>
                                        <TableRow className="table">
                                            <TableCell align="center">Trade</TableCell>
                                            <TableCell align="center">Cost Code</TableCell>
                                            <TableCell align="center">Description</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tradeData?.map((row) => (
                                            <TableRow
                                                key={row.Id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="trade" align="center"  >
                                                    {row?.name}
                                                </TableCell>
                                                <TableCell align="center"  >{row?.costCodeName}</TableCell>
                                                <TableCell align="center"  >{row?.description}</TableCell>
                                                {/* <TableCell align="center" >{row.material}</TableCell> */}
                                                <TableCell align="center" >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            '& > *': {
                                                                m: 1,
                                                            },
                                                        }}
                                                    >
                                                        <Stack direction="row" spacing={1}>
                                                            <Tooltip title="Edit" arrow>
                                                                <IconButton className='prmaryiconButton' aria-label="delete" onClick={() => handleEditTrade(row)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" arrow>
                                                                <IconButton className='erroriconButton' aria-label="delete" onClick={() => handleDialogOpen(row?.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </CustomTabPanel>
                    <Dialog
                        open={openDialog}
                        onClose={handleDialogClose}
                    >
                        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                            <DialogContent >
                                <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                                <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                    Are you sure?
                                </Typography>
                                <Typography textAlign={'center'}>
                                    You won't be able to revert this!
                                </Typography>
                            </DialogContent>
                        </Box>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                            <Button onClick={() => onDeleteTrade()} autoFocus className="primaryTonalButton">
                                Yes, Delete it!
                            </Button>
                        </DialogActions>
                    </Dialog>



                    <Dialog
                        open={openDeletDialog}
                        onClose={handleDialogClose}
                    >
                        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                            <DialogContent >
                                <Typography textAlign={'center'}><ErrorOutlineRoundedIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                                <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                                    Are you sure?
                                </Typography>
                                <Typography textAlign={'center'}>
                                    You won't be able to revert this!
                                </Typography>
                            </DialogContent>
                        </Box>
                        <DialogActions sx={{ justifyContent: "center" }}>
                            <Button onClick={handleDialogClose} className="dangerButton">No, Cancel!</Button>
                            <Button onClick={() => handleTemplateDelete()} autoFocus className="primaryTonalButton">
                                Yes, Delete it!
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* <ConfirmationDialog  open={openDialog}  onClose={() => setOpenDialog(false)}/> */}
                    <CustomTabPanel value={value} index={1}>
                        <Box>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="a dense table" >
                                    <TableHead sx={{}} className="table">
                                        <TableRow>
                                            <TableCell align="center">Sr.No</TableCell>
                                            <TableCell align="center">Trade</TableCell>
                                            <TableCell align="center">Description</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="trade" align="center">{0}</TableCell>
                                        <TableCell align="center" >{"Generic Template"}</TableCell>
                                        <TableCell align="center" >{"Generic Template"}</TableCell>
                                        <TableCell align="center" >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    '& > *': {
                                                        m: 1,
                                                    },
                                                }}
                                            >
                                                <Stack direction="row" spacing={1}>
                                                    <Tooltip title="Add Template" arrow>
                                                        <IconButton aria-label="Add Template" onClick={showGenericTemplate}>
                                                            <PostAddIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    {templateList?.map((value, index) => (
                                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="trade" align="center">{index + 1}</TableCell>
                                            <TableCell align="center" >{value.trade.name + "-" + value.trade.materialType}</TableCell>
                                            <TableCell align="center" >{value.description}</TableCell>
                                            <TableCell align="center" >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        '& > *': {
                                                            m: 1,
                                                        },
                                                    }}
                                                >
                                                    <Stack direction="row" spacing={1}>
                                                        <Tooltip title="Edit" arrow>
                                                            <IconButton aria-label="Edit" onClick={() => editTemplate(value)}>
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete" arrow>
                                                            <IconButton aria-label="Delete" onClick={() => handleDelete(value.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </Table>
                            </TableContainer>
                            <GanttDialog open={gantopen} selected={tasks} function={handlegantClose} />
                            <ConfirmationDialog open={confirmDialog} onClose={() => setConfirmDialog(false)} />
                            {showTemplate && <TemplateForm open={showTemplate} function={closeTemplate} selectedTemplate={selectedTemplate} />}

                        </Box>
                        {/* <Addedittemplate /> */}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Tradecontract />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <Box>
                            <Bomtemplate />
                        </Box>
                    </CustomTabPanel>

                </Grid>
            </Grid>

            <CustomDialog
                open={success}
                onClose={() => setSuccess(false)}
                success={success}
                error={error}
                Content={success ? "Trade has been deleted successfully" : "Error could not deleted "}
            />

        </Box>
    );
}
