/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Task Selection page Form Create Bid
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1937           |  15/05/24   | Vanitha       |  Mandatory implementation in Property name
* 1953           |  16/05/24   | Vanitha       |  City,State,Country fields are binded
* 2222           |  06/06/24   | Vanitha       |   Add Customer redirect page implementation changed
* 
*/

import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Button, Card, FormControl, FormControlLabel, Grid, InputLabel, Link, MenuItem, Paper, Box, RadioGroup, Select, TextField, Typography, createFilterOptions, useTheme, Tooltip } from '@mui/material';
import { tokens } from '../../../hooks/theme';
import SelectionCard from './SelectionCard';
import { getAssets, getAssetsByAssetUid, getTrades, getUsers } from '../../../services/BidService';
import SearchBox from '../../SearchBox';
import { useNavigate } from 'react-router-dom';
import "../CreateBid.scss";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import "../selectorComponent/TaskSelector.scss";
import RoofingIcon from '@mui/icons-material/Roofing';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import { getUserSession } from '../../../hooks/UserSession';
import CreateCustomer from '../../../pages/Admin templates/CreateCustomer';
import { getCustomerDetails, getCustomers } from '../../../services/UserService';

interface UserOptionType {
    inputValue?: string;
    userName: string;
    mobileNo?: number;
    status: string;
    displayName: string;
}

const filter = createFilterOptions<any>();

const TaskSelector: React.FC<any> = (data) => {

    const [bidData, setBidData] = useState(data.data);
    const componentData = useRef<any>();
    const [customerList, setCustomerList] = useState<any>();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [count, setCount] = useState(0);
    const [role, setRole] = React.useState<any>([] as any[]);
    const [userdata, setUserData] = React.useState<any>([] as any[]);
    console.log(userdata);
    const [completed, setCompleted] = React.useState<any | null>(data.completed);
    const [loading, setLoading] = useState(true);

    const [properties, setProperties] = useState<any>([]);
    const [selectedData, setSelectedData] = useState({
        customerUid: "",
        assetUid: "",
        trade: ""
    });
    // console.log(selectedData.customerUid);
    const selectedAsset = useRef();
    const [selctionCardProps, setSelectionCardprops] = useState({
        title: "Select a Trade to Create an Estimate",
        type: "trade",
        data: [],
        selectedValue: bidData.trade,
        suggestion: false,
        returnValue: "name",
        displayValue: "name"
    });

    const [value, setValue] = React.useState<any | null>(data.selectedCustomer);
   
    const [user, setUser] = React.useState<any>([] as any[])
    const [showAnotherComponent, setShowAnotherComponent] = useState(false);
    const [rows, setRows] = React.useState<any>([]);
    const [rowData, setRowData] = useState();
    const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
   const ROLES ="customer";



    const closeConfirmation = () => {
        setShowAnotherComponent(false);
      }

    const navigate = useNavigate()
    //  console.log(user);
    const addCustomer = () => {
        // navigate("/customerregistration", {
        // })
        setShowAnotherComponent(true);
    }
    const onAddAssetPage = () => {
        navigate("/asset", {
            state: {
                action: "addProperty",
                customerUid: selectedData.customerUid
            }
        });
    }

    const userList: readonly UserOptionType[] = user
//     const activeUsers = userList.filter(user => user.status === "Active");

// // Print displayNames of active users
// activeUsers.forEach(user => {
//     console.log(user.displayName);
// });

    //     useEffect(() => {
    //         getUsers(accessToken).then(res => setUser(res.data));
    //         getUserSession(accessToken).then(res => { setUserData(res); setRole(res.role.name); console.log(res) });

    // // fetData();
    // const customerUid = data.data.customerUid || userdata.objectId;
    // getAssets(accessToken, customerUid).then(assetsResponse => {
    //     setProperties(assetsResponse.data);
    //     console.log(assetsResponse);
    // });

    //         getTrades(accessToken).then(res => {
    //             setSelectionCardprops({ ...selctionCardProps, data: res.data });
    //             console.log(res.data)
    //             console.log(data.data);

    //             const customerUid = data.data.customerUid || userdata.objectId;
    //             getAssets(accessToken, customerUid).then(assetsResponse => {
    //                 setProperties(assetsResponse.data);
    //                 console.log(assetsResponse);
    //             });

    //         });

    //         console.log(bidData);
    //         setSelectedData({
    //             customerUid: bidData.customerUid || userdata.objectId,
    //             assetUid: bidData.assetUid,
    //             trade: bidData.trade
    //         })
    //         componentData.current = {
    //             customerUid: bidData.customerUid || userdata.objectId,
    //             assetUid: bidData.assetUid,
    //             trade: bidData.trade
    //         };
    //         if (data.count !== 0) {
    //             setFormValidator(true);
    //             setCount(count + 1);
    //         }
    //         getUsers(accessToken).then((res: any) => setCustomerList(res.data))

    //     }, [data.count])

    //new use effect for customer 

    useEffect(() => {

        getUserSession(accessToken)
        .then(res => {
            setUserData(res);
            setRole(res.role.name);
            setLoading(false)

            Object.assign(componentData.current, { customerUid: res.objectId });
            getAssets(accessToken, res.objectId)
                .then(assetsResponse => {
                    setProperties(assetsResponse.data);
                    // console.log(assetsResponse);
                })
                .catch(error => console.error("Error fetching assets data:", error));
        })
        .catch(error => console.error("Error fetching user session data:", error));

        // getUsers(accessToken)
        //     .then(res => setUser(res.data))
        //     .catch(error => console.error("Error fetching user data:", error));


        const customerUid = data.data.customerUid || userdata.objectId;

        getcustomer();


        getTrades(accessToken)
            .then(res => {

                setSelectionCardprops({ ...selctionCardProps, data: res.data });
                // console.log(res.data);

                const customerUid = data.data.customerUid || userdata.objectId;
                // getAssets(accessToken, customerUid)
                //     .then(assetsResponse => {
                //         setProperties(assetsResponse.data);
                //         // console.log(assetsResponse);
                //     })
                //     .catch(error => console.error("Error fetching assets data:", error));
            })
            .catch(error => console.error("Error fetching trades data:", error));

        // console.log(bidData);


        setSelectedData({
            customerUid: bidData.customerUid || userdata.objectId,
            assetUid: bidData.assetUid,
            trade: bidData.trade
        });
        componentData.current = {
            customerUid: bidData.customerUid || userdata.objectId,
            assetUid: bidData.assetUid,
            trade: bidData.trade
        };


        if (data.count !== 0) {
            setCompleted(false);
            // setCount(count + 1);
        }


        getUsers(accessToken)
            .then((res: any) => setCustomerList(res.data))
            .catch(error => console.error("Error fetching customer list:", error));
    }, []);




    // useEffect(() => {
    //     getUsers(accessToken)
    //         .then(res => setUser(res.data))
    //         .catch(error => console.error("Error fetching user data:", error));

    //     getUserSession(accessToken)
    //         .then(res => {
    //             setUserData(res);
    //             setRole(res.role.name);
    //             Object.assign(componentData.current, { customerUid: res.objectId });
    //             getAssets(accessToken, res.objectId)
    //                 .then(assetsResponse => {
    //                     setProperties(assetsResponse.data);
    //                 })
    //                 .catch(error => console.error("Error fetching assets data:", error));
    //         })
    //         .catch(error => console.error("Error fetching user session data:", error));

    //     getTrades(accessToken)
    //         .then(res => {
    //             setSelectionCardprops({ ...selctionCardProps, data: res.data });
    //         })
    //         .catch(error => console.error("Error fetching trades data:", error));

    //     setSelectedData({
    //         customerUid: bidData.customerUid || userdata.objectId,
    //         assetUid: bidData.assetUid,
    //         trade: bidData.trade
    //     });
    //     componentData.current = {
    //         customerUid: bidData.customerUid || userdata.objectId,
    //         assetUid: bidData.assetUid,
    //         trade: bidData.trade
    //     };

    //     if (!selectedData.customerUid || !selectedData.assetUid || !selectedData.trade) {
    //         setFormValidator(true); // Set formValidator to true to indicate missing required fields
    //     } else {
    //         setFormValidator(false); // Set formValidator to false if all required fields are filled
    //     }

    //     getUsers(accessToken)
    //         .then((res: any) => setCustomerList(res.data))
    //         .catch(error => console.error("Error fetching customer list:", error));
    // }, []);

    // [accessToken, bidData, count, data.count, selctionCardProps, userdata.objectId, data.data.customerUid]
    //end 
    // console.log(role.objectId)
    const validator = () => {
        const exist = Object.values(componentData.current).includes(undefined);
        // console.log(exist);
        if (exist) {
            data.function({ data: componentData.current, validated: false });
        }
        else {
            data.function({ data: componentData.current, validated: true, customerName: value, SelectedAsset: selectedAsset.current });
        }
        Object.entries(componentData.current).map((value) => {
            if (value[1] === undefined) {
                setCompleted(true);
                data.function({ data: componentData.current, validated: false });
            }
        })
    }



    const onselect = async (e: any) => {
        // console.log(e)
        if (e.target.name === "assetUid") {
            await getAssetsByAssetUid(accessToken, e.target.value).then((res: any) => {
                Object.assign(componentData.current, {
                    measurement: res.data.area,
                    stories: res.data.stories, location: res.data.location, zipcode: res.data.zipCode, state: res.data.state, city: res.data.city
                })
            })
            setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
            Object.assign(componentData.current, { [e.target.name]: e.target.value });
            validator()
        }
        else {
            setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
            Object.assign(componentData.current, { [e.target.name]: e.target.value });
            validator()
        }
    };


    const selectedTrade = async (e: any) => {
        Object.assign(selectedData, { [e.target.name]: e.target.title });
        Object.assign(componentData.current, { [e.target.name]: e.target.title });
        validator()
    }

    const getcustomer =async()=>{
        const result = await getCustomers(accessToken);
        setUser(result.data);
        console.log("user",result.data);
    }


    const warranties = [{
        project: "Replace roof",
        warranties: "Expiring in 6 Months"
    }, {
        project: "Replace side",
        warranties: "Expiring in 6 Months"
    }]

    //const customrs = [{ name: "john" }, { name: "tommy" }]

    //const properties = [{ name: "john house", assetUid: "123456" }]


    return (
        <>
         
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card sx={{ height: "100%" }} variant="outlined" className='createBidFormCard'>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)">Would you like to create a job for one of your customers?</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9} md={6} lg={9} xl={10}>
                                        {/* <FormControl variant="outlined" fullWidth size="small">
        <InputLabel id="demo-select-small-label">Customers</InputLabel>
        <Select
            error={selectedData.customer === undefined && formValidator === true}
            value={selectedData.customer}
            label="Customers"
            onChange={(e) => onselect(e)}
            name='customer'
        >
            <MenuItem value="">
                <em>None</em>
            </MenuItem>
            {customrs.map((data, i) =>
                <MenuItem value={data.name}>{data.name}</MenuItem>
            )}
        </Select>
    </FormControl> */}
                                        {role !== 'customer' ?

                                            (<Autocomplete
                                                ListboxProps={{ style: { maxHeight: 150 } }}
                                                size='small'
                                                value={value}
                                                // value ={role.objectId === user.customerUuid ? 
                                                //     user.displayName  
                                                //        : 
                                                //        null}
                                                onChange={(event, newValue: any) => {
                                                    // console.log(newValue)
                                                    if (newValue && newValue.inputValue) {
                                                        // Create a new value from the user input
                                                        //setSelectedData({...selectedData,customer:newValue.email});
                                                        //Object.assign(selectedData,{customer:newValue.email});
                                                        setSelectedData({ ...selectedData, customerUid: newValue.objectId  });
                                                        Object.assign(componentData.current, { customerUid: newValue. objectId});
                                                        addCustomer();
                                                        setValue(newValue.displayName);
                                                    } else if (newValue === null) {
                                                          setValue(newValue);
                                                        // getAssets(accessToken, newValue.customerUuid).then(res => { setProperties(null); console.log(res) });
                                                    } else {
                                                        //setSelectedData({...selectedData,customer:newValue.email});
                                                        //Object.assign(selectedData,{customer:newValue.email});
                                                        setValue(newValue.displayName);
                                                        setSelectedData({ ...selectedData, customerUid: newValue.objectId });
                                                        getAssets(accessToken, newValue.objectId || userdata.objectId)
                                                            .then(res => {
                                                                setProperties(res.data);
                                                                // console.log(res);
                                                            });

                                                        Object.assign(componentData.current, { customerUid: newValue.objectId });
                                                        validator();
                                                        // setValue(newValue.displayName);
                                                    }
                                                }}
                                                filterOptions={(options, params) => {
                                                    const filtered = filter(options, params);
                                                    // console.log(filtered);
                                                    const { inputValue } = params;
                                                    // Suggest the creation of a new value
                                                    const isExisting = options.some((option) => inputValue === option.displayName);
                                                    if (inputValue !== '' && !isExisting) {
                                                        filtered.push({
                                                            inputValue,
                                                            displayName: `Add Customer`,
                                                        });

                                                    }

                                                    return filtered;
                                                }}
                                                selectOnFocus
                                                // clearOnBlur
                                                handleHomeEndKeys
                                                id="free-solo-with-text-demo"


                                                options={role !== 'customer' ? (userList.length > 0? userList : []) : [userdata.objectId

                                                ]}


                                                getOptionLabel={(option) => {
                                                    console.log(option)
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Add "xxx" option created dynamically
                                                    if (option.inputValue) {
                                                        return option.inputValue;
                                                    }
                                                    // Regular option
                                                    return option.displayName;
                                                }}
                                                renderOption={(props, option) => <li {...props}>{option.displayName}</li>}

                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField {...params} label="Customer Name" error={selectedData.customerUid === undefined && completed === true} />
                                                )} />) : (

                                                // setSelectedData({ ...selectedData, customerUid: userdata.objectId }),
                                                <TextField
                                                    id="standard-read-only-input"
                                                    fullWidth
                                                    size='small'
                                                    // className='textfield'
                                                    disabled={true}
                                                    label="Customer Name"
                                                    defaultValue={loading?"Loading...": userdata?.firstname + " " + userdata?.lastname}

                                                    variant="outlined" />
                                            )}

                                    </Grid>
                                    <Grid item xs={12}>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)">Select the property that needs work.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={9} md={6} lg={9} xl={10}>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <InputLabel id="demo-select-small" required error={!selectedData.assetUid === undefined && completed === true}>Property Name</InputLabel>
                                            <Tooltip
                                                title={role !== "customer" ?
                                                    (!value ? "Choose any customer name first" : "Add Property name") :
                                                    (!userdata ? "Choose any customer name first" : "Add Property name")}
                                                arrow
                                            >
                                                <Select
                                                    error={selectedData.assetUid === undefined && completed}
                                                    required
                                                    labelId="demo-select-small-label"
                                                    id="demo-select-small"
                                                    value={selectedData.assetUid}
                                                    label="Property Name"
                                                    onChange={(e) => onselect(e)}
                                                    name='assetUid'
                                                    disabled={role !== "customer" ? !value : !userdata}

                                                >
                                                    {properties && properties?.length > 0 ?
                                                        properties.map((data: any) => (
                                                            <MenuItem key={data.assetUid} value={data.assetUid}>{data.name}</MenuItem>
                                                        )) :
                                                        <MenuItem disabled>{properties}</MenuItem>}

                                                </Select>
                                            </Tooltip>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>

                                        {/* <Link  href='/asset' underline="none" color="inherit">{'Property Not Found? Add New Property'}</Link> */}
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography color="grey">Property Not Found?</Typography>
                                            <Link
                                                component='button'
                                                underline="none"
                                                key="1"
                                                color="inherit"
                                                id=''
                                                onClick={() => onAddAssetPage()}
                                                sx={{
                                                    "&:hover": {
                                                        color: colors.primary[500] + ' !important',
                                                        background: 'none !important',
                                                    },
                                                }}
                                                className='formSubTitle'
                                                disabled={role !== "customer" ? !value : !userdata}
                                            >
                                                <Tooltip
                                                    title={role !== "customer" ?
                                                        (!value ? "Choose any customer name first" : "Add your Property") :
                                                        (!userdata ? "Choose any customer name first" : "Add your Property")}
                                                    arrow
                                                >

                                                    <Link>
                                                        Add New Property
                                                    </Link>
                                                </Tooltip>
                                            </Link>

                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Card className='recommendationCard createBidFormCard' variant="outlined" sx={{ height: "100%" }}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)">Recommendations(Based off your Warranties)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            {warranties.map((data: any) => <Grid item xs={12} sm={12} md={4} lg={6} xl={4}>
                                                <Box className='formButton'>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={data.project === "Replace roof" ? <RoofingIcon /> : <HouseSidingIcon />}
                                                        className={`primaryTonalButton`}
                                                        fullWidth
                                                    >
                                                        {data.project}
                                                    </Button>
                                                </Box>
                                                <Typography variant='h6' className='warrantyDuration' color={colors.primary[500]}>{data.warranties}</Typography>
                                            </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <SelectionCard data={selctionCardProps} count={count} function={selectedTrade} />
                </Grid>
            </Grid>
            {showAnotherComponent && (<CreateCustomer open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} rowDatas={rowData} />)}
</>

    );
}

export default TaskSelector;