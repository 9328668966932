/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Asish P saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : Invoice Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import {getPODetails}  from '../data/PODetails'
import {vendorDetails}  from '../data/VendorDetails'
import { executeGetData, executePostData, executePutData } from './ApiBase';
import { ConfigUrl } from '../auth/OidcConfig';

export const getPoCount = async(projectId:any, searchParams:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/purchaseOrder/getPurchaseOrderCount/projectId/${projectId}/searchParams/${searchParams}`;
  return await executeGetData(url, accessToken);
}

export const getPoDetails = async(projectId:any, searchParams:any, page:any, size:any, accessToken:any) =>
{
  const url = `${ConfigUrl.baseURL}/purchaseOrder/getPurchaseOrderDetails/projectId/${projectId}/searchParams/${searchParams}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
}

export const getVendorDetails = async () => {
  const url = vendorDetails;
  return url;
};
export const createPO = async (paymentDetails: any, projectId:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/purchaseOrder/createPO/projectId/${projectId}`;
  return await executePostData(url, paymentDetails, accessToken);
};

export const getPOItems = async (purchaseId: any, projectId:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/purchaseOrder/getPO/projectId/${projectId}/purchaseId/${purchaseId}`;
  return await executeGetData(url, accessToken);
};
export const getPOByPurchaseId = async (projectId:any, purchaseId: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/purchaseOrder/getPOByPurchaseId/purchaseId/${purchaseId}/projectId/${projectId}`;
  return await executeGetData(url, accessToken);
};

export const approveOrRejectPo = async (tenantId:any, purchaseId: any, status:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/purchaseOrder/approve/${tenantId}/${purchaseId}/status/${status}`;
  return await executeGetData(url, accessToken);
};

export const getPOAndSlip = async (projectId:any, purchaseId: any, receiptId: any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/purchaseOrder/comparePOAndSlip/projectId/${projectId}/purchaseId/${purchaseId}/receiptId/${receiptId}`;
  return await executeGetData(url, accessToken);
};

export const updateTaxInfoAndTotal = async(payload:any, accessToken:any) =>
  {
    const url = `${ConfigUrl.baseURL}/purchaseOrder/updateTaxInfoAndTotal`;
    return await executePutData(url, payload, accessToken);
  }

  export const sendPoToVendor = async(projectId:any, purchaseId:any, status:any, accessToken:any) =>
    {
      const url = `${ConfigUrl.baseURL}/purchaseOrder/sendPo/projectId/${projectId}/purchaseId/${purchaseId}/status/${status}`;
      return await executePutData(url, null, accessToken);
    }