import { vendorDetails } from "../data/VendorDetails";
import { bidStatusData } from "../data/bidStatusData";
import { BillJson } from "../data/billjson";
import { executePost } from "./Api";




export const getVendor = async () => {
  const url = vendorDetails;
  return url;
}

export const getBillList = async () => {
  // const url = `${Config.baseURL}/subjects?page=0&size=10&sortDir=ASC&sort=firsNumber`;
  // return await executeGet(url, accessToken);
  return BillJson;
};

export const getBidStatus= async()=>{
return bidStatusData;
}