/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      :Asish P Saji
* @since       : 0.0.1
* Requirement# :
* Purpose      : For Payment Types
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/

import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, Button, Grid, Typography, IconButton, List, ListItemText, ListItemButton, ListItemIcon, TextField, styled, Box, DialogContent, DialogActions, InputAdornment } from '@mui/material';
import { Stack } from '@mui/system';
import './PaymentTypes.scss';
import CloseIcon from '@mui/icons-material/Close';
import PaidIcon from '@mui/icons-material/Paid';
import { useGlobalContext, tokens } from '../../hooks/theme';
import { createPayment, makeACHPayment, makePayment } from '../../services/PaymentService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { TaskContext, getPreviousPageDetails, getUserSession } from '../../hooks/UserSession';
import { format } from 'date-fns';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { useNavigate } from 'react-router-dom';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import dayjs from 'dayjs';
import PaymentCancelDialog from './paymentCancelDialog';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CancelOutlined } from '@mui/icons-material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';



const PaymentTypes: React.FC<any> = (props) => {

    const [openmatrec, setOpenmatrec] = useState<boolean>(false); // Declare openMatrec state variable
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false); // Declare openMatrec state variable
    const [openConfirmationFailed, setOpenConfirmationFailed] = useState<boolean>(false); // Declare openMatrec state variable
    const navigate = useNavigate();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [callCount, setCallCount] = React.useState(0);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [hide, setHide] = useState(false);
    const [icon, setIcon] = useState(false);
    const [iconShow, setIconShow] = useState(false);
    const [iconhide, setIconHide] = useState(false);
    const [iconShows, setIconShows] = useState(false);
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const[validatePayNumber,setValidatePayNumber]= useState(false);
    const [show, setShow] = useState(false);
    const { isTaskCompleted, handleCompleted } = React.useContext(TaskContext);

    const handlematrecCancel = () => {
        // setOpenmatrec(false);
        setIsDialogOpen(true);

    };

    const handleCancelConfirmation = () => {
        setOpenConfirmation(false);
        setIsDialogOpen(false);
        navigate("/invoiceView");
        //window.location.reload();
    };

    const handleOpenConfirmationFailed = () => {
        setOpenConfirmationFailed(false);
    };
    const handlematrecOpen = () => {
        setOpenmatrec(true);
    };
    const FileUploaderContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        minHeight: '120px',
        border: '2px dashed #ccc',
        borderRadius: '8px',
    });

    const [role, setRole] = useState('');
    const [user, setUser] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [file, setFile] = useState<any>();
    const [showCheckOptions, setShowCheckOptions] = useState(false);
    const [voucherError, setVoucherError] = React.useState(false);
    const [manualPayType, setManualPayType] = useState<string>(props?.props?.billingAddress);
    const [response, setResponse] = useState<any>();
    const newDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
    let receipt;
    // setManualPayType(props?.props?.billingAddress)
    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    // const [manualData, setManualData] = React.useState({
    //     status: 'Paid',
    //     userUid: accessTokenPayload.oid,
    //     invoiceNo: props?.props?.invoiceNo,
    //     jobNo: props?.props?.jobNo,
    //     payType: '', // Initialize payType as an empty string
    //     payMethod: '', // Initialize payMethod as an empty string
    //     payNumber: '',
    //     totalAmount: props?.props?.total,
    //     description: props?.props?.description,
    //     dueDate: props?.props?.dueDate,
    //     deadLineDate: props?.props?.dueDate,
    //     projectId: props.projectId,
    //     // unitCost: props?.props?.rate,
    //     createdAt: newDate,
    //     version: 1,
    //     transactionType: 'in',
    //     // receipt: file,
    //     billingAddress: manualPayType


    // });
    //console.log(props)
    const [manualData, setManualData] = React.useState({
        status: 'Paid',
        invoiceNo: props?.props?.invoiceNo,
        jobNo: props?.props?.jobNo,
        payType: '', // Initialize payType as an empty string
        payMethod: '', // Initialize payMethod as an empty string
        payNumber: '',
        amount: props?.props?.total,
        description: props?.props?.description,
        projectName: props?.props?.projectName,
        statement: props?.props?.statement,
        receivedAmount: props?.props?.total,
        projectId: props.projectId,
        createdAt: newDate,
        // receipt: file,
        billingAddress: manualPayType,
        invoiceId: props?.invoiceId,
        invoiceAmount: props?.props?.total,

    });
    const [message, setMessage] = React.useState<any>({
        payNumber: '',

    });

    const handlePayment = (paymentMethod) => {
        setSelectedPayment(paymentMethod);
        // Add any other logic you want to perform when a payment method is selected
    };

    const handlePaymentSelectedClick = (paymentType) => {
        console.log(paymentType)
        if (paymentType === 'digital') {
            setShow(true)
        }
        setSelectedPayment(paymentType);

        setManualData((prevData) => ({
            ...prevData,
            payType: paymentType === 'cash' || paymentType === 'cheque' ? 'manual' : 'digital',
            payMethod: paymentType === 'cash' ? 'cash' : 'cheque', // Update payMethod based on paymentType

        }));
        setShowCheckOptions(paymentType === 'cash' || paymentType === 'cheque');

        if (paymentType === 'cheque' || paymentType === 'cash') {

            fetchRows();
        }
    }
    const handlePaymentClick = (paymentType) => {
        // setSelectedPayment(paymentType);
        console.log(paymentType)
        if (paymentType === 'digital') {
            setShow(true);
        }
        // Update payType and payMethod based on selectedPayment

        setManualData((prevData) => ({
            ...prevData,
            payType: paymentType === 'cash' || paymentType === 'cheque' ? 'manual' : 'digital',
            payMethod: paymentType === 'cash' ? 'cash' : 'cheque',

        }));
        setShowCheckOptions(paymentType === 'cash' || paymentType === 'cheque');
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);


        // Call handlePaymentClick with the corresponding paymentType based on the selected index
        switch (index) {
            case 0:
                handlePaymentClick('digital'); // Replace 'manual' with the actual paymentType for index 0
                break;
            case 1:
                handlePaymentClick('manual'); // Replace 'manual' with the actual paymentType for index 0
                break;
            case 2:
                handlePaymentClick('financing');
                break;
            // Add cases for other indices as needed
            default:
                break;
        }
    };
    const handleListItemClickManual = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);

        // Call handlePaymentClick with the corresponding paymentType based on the selected index
        switch (index) {
            case 0:
                handlePaymentClick('cash'); // Replace 'manual' with the actual paymentType for index 0
                break;
            case 1:
                handlePaymentClick('cheque'); // Replace 'manual' with the actual paymentType for index 0
                break;

            // Add cases for other indices as needed
            default:
                break;
        }
    };


    const handleGoBack = () => {
        setSelectedPayment(null);
        setShowCheckOptions(false); // Reset the check options when going back
        setCallCount(0);
    };

    const { theme } = useGlobalContext();
    // drag state
    // ref


    // Define the onFileUpload function
    // const onFileUpload = (file: File) => {
    //     const reader = new FileReader();

    //     // Define a callback function to handle the file reading completion
    //     reader.onload = (event) => {
    //         if (event.target?.result) {
    //             // Access the result property of the FileReader object to obtain the file data
    //             const fileData = event.target.result;
    //             setManualData((prevData) => ({
    //                 ...prevData,
    //                 receipt: event?.target?.result,
    //                 invoiceNo: props?.props?.invoiceNo,
    //                 deadLineDate: props?.props?.dueDate,
    //                 dueDate: props.props.dueDate,
    //                 description: props?.props?.description,
    //                 totalAmount: props?.props?.total,
    //                 billingAddress: props?.props?.billingAddress


    //             }));
    //             // You can now use fileData as an object or process it further
    //              //console.log('File data:', fileData);

    //             receipt = fileData;
    //             setFile(receipt);
    //              //console.log(receipt)

    //         }
    //     };

    //     // Start reading the file as text
    //     reader.readAsText(file);
    //     //  //console.log(typeof reader.readAsText(file))
    // };

    const fetchRoleInform = async () => {
        try {
            const response = await getUserSession(accessToken);
            //console.log(response.role.name);
            setUser(response);
            setRole(response?.role?.name);
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error('Error fetching user session:', error);
            setLoading(false); // Set loading to false in case of error
        }
    };

    useEffect(() => {
        setManualPayType(props?.props?.billingAddress || '');
        fetchRoleInform();
        
    }, [props?.props?.billingAddress]);

    const inputchange = (e: any) => {
        //console.log(e, selectedPayment);
        if (e.target.name === 'billingAddress') {
            setManualPayType(e.target.value)
        }

        setManualData({
            ...manualData,
            [e.target.name]: e.target.value,
            invoiceNo: props?.props?.invoiceNo,
            invoiceAmount: props?.props?.total,
            // deadLineDate: props?.props?.dueDate,
            // dueDate: props.props.dueDate,
            description: props?.props?.description,
            amount: props?.props?.total,
            billingAddress: manualPayType,

        });
      
    };
    const fetchRows = async () => {
    setCallCount(callCount + 1);
        let validated = await validation();
        //console.log(manualData)
        if (validated === true) {
            console.log("manualData")
            const data = await createPayment(manualData, accessToken);
            console.log(data);
            if (data.ok === true) {
                setOpenConfirmation(true);
               setCallCount(0);
                console.log("ok")
            }
            else {
                setOpenConfirmationFailed(true);
                setCallCount(0);
           
               

            }

        }

      
        //   setRows(data.data);
    };


    const validation = () => {
        let validated: any = true;
    //  console.log(manualData.payNumber);
        if (manualData.payNumber === '' ) {
            // setMessage({...message,propertyName:'Enter Property Name'});
            // console.log("validate false")
            setValidatePayNumber(true);
            message.payNumber = 'Enter Pay Number';
            validated = false;

        }
        else{
        setValidatePayNumber(false);
        }
        //     if(selectedIndex === 0){
        //     if (manualData.payNumber === '') {
        //         // setMessage({...message,propertyName:'Enter Property Name'});
        //         message.payNumber = 'Enter Pay Number';
        //         validated = false;
        //         setVoucherError(true);

        //     }
        //     else{
        //         setVoucherError(false);
        //     }
        // }

        return validated;
    }
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };
    const colors = tokens(theme.palette.mode);

    const [Data, setData] = useState<any>({
        cardNumber: "",
        expiry: "",
        cvv: "",
        name: "",
        amount: "350.00",
        currency: "USD",
        paymentGateway: "fiserv"
    });
    console.log("data",Data)


    const [achData, setAchData] = useState<any>({
        account: "9036412947515678",
        accttype: "ECHK",
        amount: "1000",
        ecomind: "E",
        capture: "y",
        name: "CC Test",
        paymentGateway: "fiserv"
    });

    const [formErrors, setFormErrors] = useState({
        cardNumber: "",
        expiry: "",
        cvv: "",
    });
    console.log(formErrors);


    const validateField = (name, value) => {
        let error = "";

        if (name === "cardNumber") {
            if (value === "") {
                error = "Enter your Card Number";
            } else if (!validateCardNumber(value)) {
                error = "Card Number must have 16 digits";
            }
        }

        if (name === "expiry") {
            if (value === "") {
                error = "Enter your expiry";
            } else if (!validateExpiry(value)) {
                error = "Enter a valid expiry date";
            }
        }

        if (name === "cvv") {
            if (value === "") {
                error = "Enter your CVV";
            }
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };
    const handlechange = (e: any) => {

        console.log(e.target.name, e.target.value)
        if (e.target.name === "cardNumber") {
            if ((e.target.value).startsWith(3)) {
                // setIcon(true);

                setIcon(true);
                setIconShows(false);
                setIconHide(false);
                setIconShow(false);
            }
            else if ((e.target.value).startsWith(4)) {
                setIcon(false);
                setIconShows(false);
                setIconHide(false);
                setIconShow(true);
            }
            else if ((e.target.value).startsWith(5)) {
                // setIconShows(true);

                setIcon(false);
                setIconShows(true);
                setIconHide(false);
                setIconShow(false);
            }
            else if ((e.target.value).startsWith(6)) {
                // setIconHide(true);

                setIcon(false);
                setIconShows(false);
                setIconHide(true);
                setIconShow(false);
            }
        }
        const value = e.target.value.trim();
        setData({
            ...Data,
            [e.target.name]: value
        });
        validateField(e.target.name, value);
    };

    const validateCardNumber = (cardNumber: string) => {
        console.log(cardNumber.replace(/ /g, ''))
        const cardNo = /^\d{16}$/;
        return cardNo.test(cardNumber.replace(/ /g, ''));
    }

    const validateExpiry = (expiry: string) => {
        console.log(expiry.replace(/\//g, ""))
        const exp = /^\d{4}$/;
        return exp.test(expiry.replace(/\//g, ""));
    }


    // const validateCVV = (cvv: string) => {
    //     console.log(cvv)
    //     const cardNo = /^[0-9]{4}/;
    //     return cardNo.test(cvv);
    // }

    const [paymentData, setPaymentData] = useState<any>({});

    const handleSubmit = async (e: any) => {
        //e.preventDefault();

        let userData: any;
        let userachData: any;
        let service = props?.props?.poItems?.[0]?.service;
        if (role === "customer") {

            userData = {
                cardNumber: Data.cardNumber,
                expiry: Data.expiry.replace(/\//g, ""),
                cvv: Data.cvv,
                amount: props?.props?.invoicedAmount,
                currency: "USD",
                name: "Uat Test",
                paymentGateway: "fiserv",
                projectId: props?.projectId,
                invoiceId: props?.invoiceId
            };
        } else {

            userachData = {
                account: (props?.props?.routingNo + "/" + props?.props?.accountNo),
                accttype: "ECHK",
                amount: props?.props?.purchaseAmount,
                ecomind: "E",
                capture: "y",
                name: "CC Test",
                paymentGateway: "fiserv",
                projectId: props?.projectId,
                invoiceId: props?.receiptId,
               
            };
          
        }


        let errors = {
            cardNumber: "",
            expiry: "",
            cvv: "",
        };
        let hasErrors = false;
        if (Data.cardNumber === "") {
            errors.cardNumber = "Enter your Card Number";
            hasErrors = true;
        }
         else if (!validateCardNumber(Data.cardNumber)) {
            errors.cardNumber = "Card Number must have 16 digits";
            hasErrors = true;
        }

        if (Data.expiry === "") {
            errors.expiry = "Enter your expiry";
            hasErrors = true;
        }

        if (Data.expiry !== "" && !validateExpiry(Data.expiry)) {
            errors.expiry = "enter Valid expiry date";
            hasErrors = true;
        }

        if (Data.cvv === "") {
            errors.cvv = "Enter your CVV";
            hasErrors = true;
        }

        // if (Data.cvv !== "" && !validateCVV(Data.cvv)) {
        //     errors.cvv = "CVV must have 3 letters";
        // }

        if (errors.cardNumber || errors.expiry || errors.cvv) {
            setFormErrors(errors);
        }

        // else {
            //console.log(userData)
            // if(!hasErrors){
            //     setFormErrors({
            //         cardNumber: "",
            //         expiry: "",
            //         cvv: "",
            //     });
                let res: any;
                if (role === "customer" && hasErrors === false) {
                    res = await makePayment(userData, accessToken);
                    console.log(res.data);
                    setResponse(res.data.responseMessage);
                } else {
                    console.log(userachData);
                    res = await makeACHPayment(userachData, service,accessToken);
                    console.log(res.data);
                    setResponse(res?.data?.responseMessage);
                }
                if(res.data !== undefined){
                    if (res.data?.responseMessage === "Approved" || res.data?.responseMessage === "Approval") {
                        setOpenConfirmation(true);
                        handleCompleted();
                        setHide(true);
                        setPaymentData(res.data);
                    } else {
                        setHide(true);
                        setPaymentData(res.data);
                        setOpenConfirmationFailed(true);
                    }
                }
            // }

        // }
    };


    const handleClick = () => {
        setHide(false);
        setOpenConfirmation(false);
        setOpenmatrec(false);
        navigate("/soldproduction");

    };

    const handleRetry = () => {
        setHide(false);
        setOpenConfirmationFailed(false);

    };

    const handleOthers = () => {
        setHide(false);
        setOpenConfirmationFailed(false);
        setSelectedPayment(null);
        setShow(false);
    };

    const handleBack = () => {
        const previousPageDetails = getPreviousPageDetails();
        navigate(previousPageDetails.path, { state: { rowData: props?.projectId, user, selectedTab: previousPageDetails.tabPosition, tabName: previousPageDetails.tabName } });
    }

    const checkVendorPaymentTerms = (payTerms:any, deliveryDate:any) =>{
        if(payTerms !== undefined && deliveryDate !== undefined){
            if(dayjs(dayjs().format('YYYY-MM-DD')).isAfter(dayjs(dayjs(deliveryDate).add(parseInt(payTerms), 'day').format('YYYY-MM-DD'))) || dayjs(dayjs().format('YYYY-MM-DD')).isSame(dayjs(dayjs(deliveryDate).add(parseInt(payTerms), 'day').format('YYYY-MM-DD')))){
                return true;
            }else{
                return false;
            }
        }
    }

    return (
        <>
        <Grid container spacing={2} direction={'column'}>
        <Grid item >
                <Button variant="contained" onClick={handleBack} fullWidth sx={{ backgroundColor: '#455bff' }}>
                <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                </Button>
                {/* <Button>Make Payment</Button> */}
            </Grid>
            {(role === "customer" || checkVendorPaymentTerms(props.props.paymentTerm, props.props.deliveryDate) ) &&
            <Grid item className="otpVerifyBtn"  >
                <Button variant="contained" onClick={handlematrecOpen} fullWidth sx={{ backgroundColor: '#56CA00' }}>
                    <MonetizationOnOutlinedIcon sx={{ fontSize: '19px !important' }} />&nbsp;Proceed To Payment
                </Button>
                {/* <Button>Make Payment</Button> */}
            </Grid>}
            </Grid>
            <Dialog open={openmatrec} onClose={handlematrecCancel} maxWidth="xs" className='makePaymentDailog'
                PaperProps={{
                    style: {
                        borderRadius: 10,
                        height: "auto"
                    },
                }}>

                <Grid className='dialogOtp   POCard paymentBox' >
                    <Grid item className='makePaymentHead' sx={{ background: colors.gradient[2] }} >
                        <IconButton className='closeIcon'> <CloseIcon sx={{ color: 'white' }} onClick={handlematrecCancel} /></IconButton>
                        <Stack direction="row" className="cash p-2"  >
                            <Typography className='otpImg pb-0 paidIcon ml-2'>
                                <PaidIcon className='paidIcon' /></Typography>

                            <DialogTitle className=' p-0 m-2 mb-3'><Stack className="paymentHead">Make Payment</Stack>
                                <Stack direction="row" className="centerDetails"><small>Invoice No&nbsp;:&nbsp;</small><span className='customerName pl-1 pr-1'>&nbsp;{props?.props?.invoiceNo}&nbsp;</span>
                                    <small className='closeIcon  currentDate mt-5' >{dayjs(newDate).format('DD MMM YYYY HH:mm')} </small></Stack>
                                {/* <Stack direction="row" className="centerDetails"></Stack> */}

                            </DialogTitle>
                        </Stack>
                    </Grid>

                    {/* <Grid> 
                         <Typography variant="body2" color="textSecondary" pl={2} mb={1} mt={2}>Order No:&nbsp; <span style={{ color: '#3c5bfa' }}>#1223345</span></Typography> 
                        { <Typography variant="body2" color="textSecondary" pl={2} pr={2} mt={2}>Billing Address:&nbsp; <span style={{ color: '#3c5bfa' }}>{props?.props?.tenantAddress}</span></Typography> 
                    </Grid> */}
                    {openConfirmationFailed && <> <DialogTitle className='failed'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <CancelOutlined sx={{ fontSize: "50px", color: "white", strokeWidth: 1 }} /></Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                Payment Failed
                            </Typography>
                            <Typography textAlign={'center'} color='white'>
                                {response}
                            </Typography>
                        </Box>
                    </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <Typography textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                                    {/* John Doe */}
                                </Typography>
                                <Typography textAlign={'center'} fontSize={'16px'} fontWeight={'500'} marginBottom={2}>
                                    $ {props?.props?.invoicedAmount !== undefined ? props?.props?.invoicedAmount : props?.props?.purchaseAmount }
                                </Typography>
                                <Typography textAlign={'center'}>
                                    Transaction Id: {paymentData?.retrievalReference}
                                </Typography>
                                <Typography textAlign={'center'}>
                                    {dayjs().format("DD MMM YYYY")}
                                </Typography>
                            </DialogContent>
                        </Box>
                        <DialogActions>
                            <Button className='dangerButton' onClick={handleRetry}>Try Again</Button>
                            <Button autoFocus className='primaryTonalButton' onClick={handleOthers}>
                                Choose different payment method
                            </Button>
                        </DialogActions>
                    </>}

                    {openConfirmation && <>  <DialogTitle className='success'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <CheckCircleOutlineIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                Payment Successful
                            </Typography>
                        </Box>
                    </DialogTitle>
                        <Box paddingLeft={8} paddingRight={8}>
                            <DialogContent >
                                <Typography textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                                    {/* John Doe */}
                                </Typography>
                                <Typography textAlign={'center'} fontSize={'16px'} fontWeight={'500'} marginBottom={2}>
                                    $ {(manualData.payType === 'digital' && props?.props?.projectStatus === 'Payment') ? paymentData?.amount : props?.props?.invoicedAmount}
                                    {(manualData.payType === 'digital' && props?.props?.projectStatus === 'Project Active') ? paymentData?.amount : props?.props?.purchaseAmount}
                                
                                </Typography>
                                <Typography textAlign={'center'}>
                                    Transaction Id: {paymentData?.retrievalReference}  {manualData?.payNumber}
                                </Typography>
                                <Typography textAlign={'center'}>
                                    {dayjs().format("DD MMM YYYY")}
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: "center" }}>
                                <Button className="successButton" onClick={handleClick}>Ok</Button>
                            </DialogActions>
                        </Box> </>}

                    {!openConfirmationFailed && !openConfirmation && <DialogContent className='contentDetails'>
                        <Grid mt={3} m={1}>
                            {/* <Typography mb={1} className='orderDetails'>Order Details:</Typography> */}
                            {/* <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow style={{ backgroundColor: 'rgb(237 237 237)' }}>
                                        <Hidden smDown>
                                            <TableCell className='tablerows tableTop' size='small'>Line Item</TableCell>
                                        </Hidden>
                                        <TableCell className='tablerows tableTop' size='small'>Quantity </TableCell>
                                        <TableCell className='tablerows tableTop' size='small'>Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <Hidden smDown>
                                            <TableCell className='tableRows tableDetails ' size='small'>Data 1</TableCell>
                                        </Hidden>
                                        <TableCell className='tableRows tableDetails' size='small'>Data 2</TableCell>
                                        <TableCell className='tableRows tableDetails' size='small'>Data 3</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <Hidden smDown>
                                            <TableCell className='tableRows tableDetails' size='small'>Data 1</TableCell>
                                        </Hidden>
                                        <TableCell className='tableRows tableDetails' size='small'>Data 2</TableCell>
                                        <TableCell className='tableRows tableDetails' size='small'>Data 3</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>

                        </TableContainer> */}
                            {/* <Grid container className='itemDetails'>
                                <Grid item xs={12} md={9} mt={1} >  <Typography variant="body2"  ><span style={{ fontSize: '14px', color: 'black' }}>#1223345</span></Typography></Grid>

                                <Grid item xs={12} md={9} className='itemQuantity' style={{ fontSize: '14px', color: 'black' }}>{props?.props?.description}</Grid>   <Grid item xs={12} md={3} className='dollarAmount'><span className='dollar'>$</span>{props?.props?.rate}</Grid>
                                <Grid item xs={12} md={6} mb={1} className='tableDetails'>3 Quantity</Grid> <Grid item xs={12} md={3}></Grid><Grid item xs={12} md={3}></Grid>
                            </Grid>
                            <Divider />
                            <Typography className='lineItemCost' mt={1} justifyContent="space-between"><Stack direction="row" className="filePay" mr={1}><small>Total:</small>&nbsp;<span className='dollar dollarAmount'>$</span>{props?.props?.total}</Stack></Typography> */}
                            <Grid container >
                                <Grid item xs={12} md={12} mt={1} >        <Typography variant="body1">
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            error={(manualData.description === '')}
                                            id="demo-helper-text-aligned"
                                            label="Project Name"
                                            size="small"
                                            name='projectName'
                                            fullWidth
                                            onChange={(e) => inputchange(e)}
                                            value={props?.props?.projectName}
                                            disabled
                                            aria-readonly
                                        // inputProps={{ readOnly: true }}
                                        // helperText={manualData.description ? 'Project name' : 'Enter your name of the project'}
                                        />

                                    </Grid>          </Typography></Grid>

                                <Grid item xs={12} md={12} className='itemQuantity' style={{ fontSize: '14px', color: 'black' }}>       <Typography variant="body1">
                                    <Grid item xs={12} mb={2}>
                                        <TextField
                                            error={(props?.props?.paymentTerm === '')}
                                            id="demo-helper-text-aligned"
                                            label="Invoice type"
                                            size="small"
                                            name='invoiceType'
                                            fullWidth
                                            onChange={(e) => inputchange(e)}
                                            value={props?.props?.paymentTerm}
                                            disabled
                                            aria-readonly
                                        // inputProps={{ readOnly: true }}
                                        // helperText={props.props.paymentTerm ? ' Invoice type' : 'Enter your Invoice type'}
                                        />
                                    </Grid>          </Typography></Grid>
                                <Grid item xs={12} md={12} mb={1} className='tableDetails'>       <Typography variant="body1">
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            error={manualData.billingAddress === ''}
                                            id="demo-helper-text-aligned"
                                            label="Billing Address"
                                            multiline   // Set multiline to true for TextArea
                                            rows={2}    // Specify the number of rows for the TextArea
                                            fullWidth
                                            name='billingAddress'
                                            onChange={(e: any) => inputchange(e)}
                                            value={manualPayType}
                                            disabled
                                        //helperText={manualData.billingAddress ? '' : 'Enter your Billing Address'}
                                        />
                                    </Grid>
                                </Typography>
                                </Grid>
                            </Grid>
                            {selectedPayment === 'manual' && <Typography>How would you like to make the payment?</Typography>}
                            {selectedPayment === 'digital' && props?.props?.projectStatus === 'Payment' && <Typography mt={2}>Add New Card</Typography>}
                            {selectedPayment === 'digital' && props?.props?.projectStatus === 'Project Active' && <Typography mt={2}>Add Vendor Account Details</Typography>}
                            <Grid container spacing={0} mt={2} mb={3} justifyContent="center">
                                {!selectedPayment && (
                                    <>
                                        <Grid item xs={12} md={12} className='listDetails'><List
                                            sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '0px !important', paddingBottom: '0px !important' }}
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                        //   subheader={
                                        //     <ListSubheader component="div" id="nested-list-subheader">
                                        //       Nested List Items
                                        //     </ListSubheader>
                                        //   }
                                        >
                                            <ListItemButton className={`itemPay ${selectedIndex === 0 ? 'selected' : ''}`} selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)} sx={{ background: selectedIndex === 0 ? '#ccc' : 'inherit' }}>
                                                <ListItemIcon>
                                                    {/* <img
                                                        src="https://www.freeiconspng.com/uploads/payment-icon-credit-payment-icon-0.png"
                                                        alt="Your Image Alt Text"
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            marginRight: '5px',
                                                        }}
                                                    /> */}
                                                    <CreditScoreIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Digital Payments" secondary="Card,ACH,Bank Transfer.." />
                                            </ListItemButton>
                                            { props?.props?.projectStatus !== 'Project Active' && 
                                            <ListItemButton className={`itemPay ${selectedIndex === 1 ? 'selected' : ''}`} selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1)} sx={{ background: selectedIndex === 1 ? '#ccc' : 'inherit' }}>
                                                <ListItemIcon>
                                                    {/* <img
                                                        src="https://www.freeiconspng.com/uploads/cash-payment-icon-5.png"
                                                        alt="Your Image Alt Text"
                                                        style={{
                                                            width: '35px',
                                                            height: '35px',
                                                            marginRight: '5px',
                                                        }}
                                                    /> */}
                                                    <CurrencyExchangeIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Manual Payments" secondary="Cash,Cheque..." />
                                            </ListItemButton>
}
{ props?.props?.projectStatus !== 'Project Active' && 
                                            <ListItemButton className={`itemPay ${selectedIndex === 3 ? 'selected' : ''}`} selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)} sx={{ background: selectedIndex === 2 ? '#ccc' : 'inherit' }}>
                                                <ListItemIcon>
                                                    {/* <img
                                                        src="http://cdn.onlinewebfonts.com/svg/img_463498.png"
                                                        alt="Your Image Alt Text"
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            marginRight: '5px',
                                                        }}
                                                    /> */}
                                                    <AccountBalanceIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Financing" />
                                            </ListItemButton>
}

                                        </List>
                                        </Grid>
                                    </>
                                )}
                                {selectedPayment === 'manual' && (
                                    <Grid item xs={12} md={12} className='listDetails'>
                                        <List
                                            sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '0px !important', paddingBottom: '0px !important' }}
                                            component="nav"
                                            aria-labelledby="nested-list-subheader"
                                        //   subheader={
                                        //     <ListSubheader component="div" id="nested-list-subheader">
                                        //       Nested List Items
                                        //     </ListSubheader>
                                        //   }
                                        >
                                            <ListItemButton className={`itemPay ${selectedIndex === 0 ? 'selected' : ''}`} onClick={(event) => handleListItemClickManual(event, 0)} selected={selectedIndex === 0} sx={{ background: selectedIndex === 0 ? '#f0f0f0' : 'inherit', }}
                                            >
                                                <ListItemIcon>
                                                    {/* <img
                                                    src="https://cdn0.iconfinder.com/data/icons/mobile-shopping/50/17-1024.png"
                                                    alt="Your Image Alt Text"
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        marginRight: '5px',
                                                    }}
                                                /> */}
                                                    <MoneyIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Cash" />
                                            </ListItemButton>
                                            <ListItemButton className={`itemPay ${selectedIndex === 1 ? 'selected' : ''}`} onClick={(event) => handleListItemClickManual(event, 1)} selected={selectedIndex === 1} sx={{ background: selectedIndex === 1 ? '#ccc' : 'inherit' }}>
                                                <ListItemIcon>
                                                    <img
                                                        src="https://w7.pngwing.com/pngs/600/401/png-transparent-payment-invoice-computer-icons-bank-account-payment-angle-text-service.png"
                                                        alt="Your Image Alt Text"
                                                        style={{
                                                            width: '30px',
                                                            height: '30px',
                                                            marginRight: '5px',
                                                        }}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText primary="Cheque" />
                                            </ListItemButton>
                                        </List>
                                        <Typography className='rightButtons'>
                                            <Button onClick={handleGoBack}>Go Back</Button>
                                        </Typography>
                                    </Grid>
                                )}
                                {selectedPayment === 'digital' && props?.props?.projectStatus === 'Payment' && (
                                    <Grid container rowSpacing={3} columnSpacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs={8} pr={2}>
                                                    {/* <Typography variant="body1">
                                            Details after clicking Digital Payment button
                                        </Typography>
                                        
                                        <Button onClick={handleGoBack}>Go Back</Button> */}
                                                    <TextField
                                                        error={!!formErrors.cardNumber}
                                                        id="demo-helper-text-aligned"
                                                        label="Card Number"
                                                        size="small"
                                                        fullWidth
                                                        name='cardNumber'
                                                        type="tel"
                                                        placeholder='XXXX XXXX XXXX XXXX'
                                                        onChange={(e: any) => handlechange(e)}
                                                        // onKeyDown={(event:any) => { 
                                                        //     if(event.key == 8 || event.key == 46) {
                                                        //         event.target.value.trim();
                                                        //     }                                                          
                                                        // }}
                                                        value={Data.cardNumber.replace(/[^0-9]/g, "").replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')}
                                                        helperText={formErrors.cardNumber}
                                                        inputProps={{ maxLength: 20 }}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {icon && <img src={require("../../assets/amex.png")} height="27px" width="27px" />}
                                                                    {iconShow && <img src={require("../../assets/visa.jpg")} height="27px" width="27px" />}
                                                                    {iconShows && <img src={require("../../assets/mastercard.png")} height="27px" width="27px" />}
                                                                    {iconhide && <img src={require("../../assets/Discover.png")} height="27px" width="27px" />}
                                                                </InputAdornment>
                                                            ),

                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        error={!!formErrors.expiry}
                                                        id="demo-helper-text-aligned"
                                                        label="Expiry"
                                                        size="small"
                                                        fullWidth
                                                        name='expiry'
                                                        placeholder='MM/YY'
                                                        inputProps={{ maxLength: 5 }}
                                                        onChange={(e: any) => handlechange(e)}
                                                        value={Data.expiry.replace(/[^0-9]/g, "").replace(/^(\d{2})(\d{1})/, '$1/$2')}
                                                        helperText={formErrors.expiry}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs={8} pr={2}>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Card Holder Name"
                                                        size="small"
                                                        fullWidth
                                                        name='name'
                                                        onChange={(e: any) => handlechange(e)}
                                                        value={Data.name.replace(/[^A-Za-z]/g, "")}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField
                                                        error={!!formErrors.cvv}
                                                        id="demo-helper"
                                                        label="CVV"
                                                        size="small"
                                                        fullWidth
                                                        name='cvv'
                                                        placeholder='XXX'
                                                        onChange={(e: any) => handlechange(e)}
                                                        value={Data.cvv.replace(/[^0-9]/g, "")}
                                                        helperText={formErrors.cvv}
                                                        inputProps={{ maxLength: 3 }}
                                                    />
                                                </Grid>
                                                {/* <Button onClick={handleSubmit}>Pay Now</Button> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                )}
                                {selectedPayment === 'digital' && props?.props?.projectStatus === 'Project Active' && (
                                    <Grid container rowSpacing={3} columnSpacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs={6} pr={2}>

                                                    <TextField
                                                        //error={!!formErrors.routingNumber}
                                                        id="demo-helper-text-aligned"
                                                        label="Routing No."
                                                        size="small"
                                                        fullWidth
                                                        name='routingNumber'
                                                        type="tel"
                                                        placeholder='XXXXXXXXX'
                                                        onChange={(e: any) => handlechange(e)}
                                                        //value={achData.routingNumber.replace(/[^0-9]/g, "")}
                                                        value={props?.props?.routingNo}
                                                        //helperText={formErrors.routingNumber}
                                                        inputProps={{ maxLength: 9 }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        //error={!!formErrors.accountNo}
                                                        id="demo-helper-text-aligned"
                                                        label="Account Number"
                                                        size="small"
                                                        fullWidth
                                                        name='accountNo'
                                                        placeholder='XXXX XXXX XXXX XXXX'
                                                        inputProps={{ maxLength: 16 }}
                                                        onChange={(e: any) => handlechange(e)}
                                                        //value={achData.account.replace(/[^0-9]/g, "")}
                                                        value={props?.props?.accountNo}
                                                    //helperText={formErrors.accountNo}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container >
                                                <Grid item xs={8} pr={2}>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Account Holder Name"
                                                        size="small"
                                                        fullWidth
                                                        name='holdername'
                                                        onChange={(e: any) => handlechange(e)}
                                                        //value={achData.holdername.replace(/[^A-Za-z]/g, "")}
                                                        value={props?.props?.vendorName}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                {selectedPayment === 'financing' && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            Details after clicking Financing button
                                        </Typography>
                                        <Button onClick={handleGoBack}>Go Back</Button>

                                        {/* Add any other details you want to display */}
                                    </Grid>
                                )}
                                {selectedPayment === 'cash' && selectedIndex === 0 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1" mb={2}>
                                            <Grid item xs={12}>  <TextField
                                                error={validatePayNumber  && callCount >=2}

                                                id="demo-helper-text-aligned"
                                                label="Voucher Number"
                                                size="small"
                                                fullWidth
                                                name='payNumber'
                                                onChange={(e) => inputchange(e)}
                                                helperText={manualData.payNumber ? 'Please enter a valid Voucher Number' : 'Enter your Voucher Number'}

                                            // value={row.email}
                                            // disabled
                                            // inputProps={{ readOnly: true }}
                                            // helperText="You can use letters, numbers & periods"
                                            /></Grid>          </Typography>

                                        {/* Add any other details you want to display */}
                                        <Typography className='rightButtons'>
                                            <Button onClick={handleGoBack}>Go Back</Button>
                                            {/* <Button onClick={fetchRows}>Submit</Button> */}
                                        </Typography>

                                    </Grid>
                                )}
                                {selectedPayment === 'cheque' && selectedIndex === 1 && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            <Grid item xs={12} mb={2}>
                                                <TextField
                                                    error={validatePayNumber  && callCount >=2}

                                                    id="demo-helper-text-aligned"
                                                    label="Cheque Number"
                                                    size="small"
                                                    name='payNumber'
                                                    fullWidth

                                                    onChange={(e) => inputchange(e)}
                                                    value={manualData.payNumber}
                                                    // disabled
                                                    // inputProps={{ readOnly: true }}
                                                    helperText={manualData.payNumber ? 'Please enter a valid cheque number' : 'Enter your cheque number'}
                                                />
                                            </Grid>          </Typography>
                                        {/* <Box
                                            sx={{
                                                flexGrow: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <div className='filePay'>
                                                <div ><UploadFileIcon/></div>
                                                <div><a href="#" id="link"><input type="file" id="file" />Upload Receipt</a></div>
                                                <div>Max File size :20MB</div>
                                            </div>
                                        </Box> */}
                                        {/* <FileUpload onFileUpload={onFileUpload} /> */}
                                        {/* <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                                        <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
                                        <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                                            <div>
                                                <div ><UploadFileIcon /></div>

                                                <p>Drag and drop your file here or</p>
                                                <Button className="upload-button" onClick={onButtonClick}>Upload Receipt</Button>
                                            </div>
                                        </label>
                                        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                                    </form> */}
                                        {/* Add any other details you want to display */}
                                        <Typography className='rightButtons'>
                                            <Button onClick={handleGoBack}>Go Back</Button>
                                            {/* <Button onClick={fetchRows}>Submit</Button> */}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>

                    </DialogContent >}
                    {/* otpcodeTitle */}



                    {!hide && <DialogActions sx={{ background: colors.gradient[2] }} >

                        <Grid container justifyContent="space-between" alignItems="center" p={1}>
                            {!openConfirmationFailed && !openConfirmation && <Typography className='lineItemCost' mt={1}>
                                <Stack direction="row" className="totalamtinvc" mr={1}>
                                    Total Amount&nbsp;:&nbsp;<span className='totalcost'>${props?.props?.invoicedAmount}{props?.props?.purchaseAmount}</span>
                                </Stack>
                            </Typography>}

                            {!selectedPayment && selectedIndex === 1 && <Button onClick={() => handlePaymentSelectedClick("manual")} variant="contained" size="small" className='complete'>
                                <PriceCheckIcon />&nbsp;Next
                            </Button>}

                            {!selectedPayment && selectedIndex === 0 && <Button onClick={() => handlePaymentSelectedClick("digital")} variant="contained" size="small" className='complete'>
                                <PriceCheckIcon />&nbsp;Next
                            </Button>}

                            {selectedPayment && selectedIndex === 0 && show && <Button onClick={handleSubmit} variant="contained" size="small" className='complete'>
                                <PriceCheckIcon />&nbsp;Pay Now
                            </Button>}

                            {selectedPayment && selectedIndex === 0 && !show  && props?.props?.projectStatus !== 'Project Active' &&<Button onClick={() => handlePaymentSelectedClick("cash")} variant="contained" size="small" className='complete'>
                                <PriceCheckIcon />&nbsp;Complete Payment
                            </Button>}

                            {selectedPayment && selectedIndex === 1 && !show && props?.props?.projectStatus !== 'Project Active' && <Button onClick={() => handlePaymentSelectedClick("cheque")} variant="contained" size="small" className='completePayment'>
                               &nbsp;Complete Payment
                            </Button>}

                        </Grid>
                    </DialogActions >
                    }
                </Grid >
            </Dialog >


            {/* <Dialog
                open={openConfirmation}
                onClose={handleCancelConfirmation}

            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}><DoneIcon sx={{ fontSize: "80px", color: "#0ea347", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Payment SuccessFul
                        </Typography>
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleCancelConfirmation} className="successButton">Ok</Button>
                </DialogActions>

            </Dialog> */}

            {/* <Dialog
                open={openConfirmationFailed}
                onClose={handleOpenConfirmationFailed}

            > 
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}><ErrorOutlineIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Payment Failed
                        </Typography>
                        
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleCancelConfirmation} className="dangerButton">Ok</Button>
                    
                </DialogActions>

                </Dialog> */}



            {isDialogOpen && <PaymentCancelDialog open={isDialogOpen} onClose={closeDialog} />}



        </>
    );
};
export default PaymentTypes;


