import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Stack, useTheme } from '@mui/system';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { updateBidStatus } from '../../services/ProjectService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import "./OtpGeneratorDialog.scss"
import { getProjectConfirmationByprojectId } from '../../services/ProjectConfirmation';
import Password from '@mui/icons-material/Password';
// import Draggable from 'react-draggable';

// function PaperComponent(props: PaperProps) {
//   return (
//     <Draggable
//       handle="#draggable-dialog-title"
//       cancel={'[class*="MuiDialogContent-root"]'}
//     >
//       <Paper {...props} />
//     </Draggable>
//   );
// }

const OtpGeneratorDialog: React.FC<any> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [response, setResponse] = useState<any>([]);
  const { accessToken, } = useOidcAccessToken();
  const [showOTPButton, setShowOTPButton] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const[count,setcount]=React.useState<number>(1);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  console.log(props)
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  // console.log(props.props)
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchProjectConfirmation = async (projectConfirmationId: number) => {
    try {
      const response = await getProjectConfirmationByprojectId(projectConfirmationId, accessToken);
      console.log(response);
      // setProjectConfirmations(response.data);
      setResponse(response.data.code)
      if (response.data !== null) {
        setShowOTPButton(true);
      }

      // setOpen(true);


    } catch (error: any) {
      setError(error.message);
      // setOpen(true);
      setResponse('Error showing otp')

    } finally {
      setLoading(false);
    }
  };
  const handleShowOTP = () => {
    setOpen(true);
  };
  const handleUpdateStatus = async (projectId: number) => {
    setcount(count + 1);
    setIsButtonDisabled(true); 
    try {
      if(count === 1 ){
      const response = await updateBidStatus(projectId, props.props.createdBy, "Salesperson Approval", accessToken);
      console.log(response);

      setOpen(true);
      setResponse(response.data);
      console.log(response)
      fetchProjectConfirmation(props.props.projectId);
      // Check if response and response.data are defined before accessing code
      if (response) {

        console.log(response);
      } else {
        console.error('Invalid response structure:', response);
      }
    } }
    catch (error: any) {
      setOpen(true);
      setResponse('Error generating otp');
      console.error('Error updating code:', error.message);
    }
  };
  useEffect(() => {

    // fetchData();
    fetchProjectConfirmation(props.props.projectId);

  }, []);
  return (
    <React.Fragment>
  <Grid >
  {!showOTPButton && count === 1 ? (
    <Button 
      variant="outlined" 
      size='small'  
      className='primarySolid' 
      disabled={isButtonDisabled}
      onClick={() => handleUpdateStatus(props.props.projectId)}>
      Generate OTP
    </Button>
  ) : (
    <Button 
      variant="outlined" 
      size='small'  
      className='SucessSolids' 
      onClick={() => { 
        fetchProjectConfirmation(props.props.projectId); 
        handleShowOTP(); 
      }}>
      Show OTP
    </Button>
  )}
</Grid>

    
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
      >

        <DialogTitle id="responsive-dialog-title" alignContent='center' className='otpGeneraterHead' sx={{ backgroundColor: "#3c5bfa" }}>
          <Typography className="otpHead ">
            <Stack className='otpGeneraterHead' justifyContent='center' alignItems='center'  direction="row"style={{ color: 'white' }}>
              <Password className='passwordIcon' />
              <span className='projectConfirm'>&nbsp;&nbsp;Project Confirmation</span>
            </Stack>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ marginTop: "10px" }}>
          <Grid container>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}> <DialogContentText mb={1}> <Typography><span className='confirmNames'> Order No&nbsp;:</span></Typography></DialogContentText>
          </Grid>
          <Grid item xs={4}><DialogContentText mb={1}> <Typography> <span className='jobNo'>&nbsp;#<i>{props.props.jobNo}</i></span></Typography></DialogContentText></Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}></Grid>
            <Grid item xs={4}> <DialogContentText mb={1}> <Typography><span className='confirmNames'> Customer Name&nbsp;:</span></Typography></DialogContentText>
          </Grid>
          <Grid item xs={4}><DialogContentText mb={1}> <Typography> <span className='jobNo'>&nbsp;#<i>{props.props.createdBy}</i></span></Typography></DialogContentText></Grid>
          <Grid item xs={2}></Grid>
          </Grid>
          <DialogContentText className='otpGeneraterHead ' justifyContent='center'>
            
            <Typography className="otpHead " justifyContent='center'>
              <Stack mb={1} className="otpHead">Please share the below mentioned one time password with customer to approve the project</Stack>
              <Stack direction='row' className='otpNumber ' justifyContent='center'>   {response}</Stack>
              {/* <Stack direction='row' m={1} className="otporderNo " justifyContent='center'>  Order No&nbsp;: <span >&nbsp;#<i>{props.props.jobNo}</i></span></Stack> */}

            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center", marginBottom: '12px' }}>

          <Button onClick={handleClose} variant='contained' sx={{ backgroundColor: "#3c5bfa" }} autoFocus >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export default OtpGeneratorDialog;