
import axios, { AxiosRequestConfig } from "axios";
import {useOidcAccessToken } from "@axa-fr/react-oidc";



interface IApiResponse {
  map(arg0: (item: {
      [x: string]: any;
      quantity: any;
    referenceNo(referenceNo: any): { primaryKey: any; bodyRegion: any; bodyPart: any; observationType: any; code: any; };
    primaryKey: any; //process response
    //process response
    bodyRegion: any; bodyPart: any; observationType: any; code: any;
  }) => { primaryKey: any; bodyRegion: any; bodyPart: any; observationType: any; code: any; }): unknown;
  data: any;
  status: number;
  message: string;
  ok: boolean;
}




export const execute = async (
  method: any,
  url: any,
  payload: any,
  accessToken: any
) => {
  const result = {} as IApiResponse;
  result.ok = false;



  try {

    const requestOptions: AxiosRequestConfig = {
      method: method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },

      data: payload != null ? JSON.stringify(payload) : null,
    };

    //call api
    const response = await axios(url, requestOptions);
    
    //process response
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      result.data = response.data;
      result.ok = true;
    } else {
      console.log(
        `An error has occured: ${response.status} - ${response.statusText}`
      );
      result.message = response.statusText;
    }
    result.status = response.status;
  } catch (error) {
    result.status = 400;
    if (error instanceof Error) {
      result.message = error.message;
    } else {
      result.message = "An error has occured";
    }
  }
  return result;
};


export const executeGetData = async (url: any, accessToken:any) => {
  return await execute('GET', url, null, accessToken);
}

export const executePostData = async (url: any, payload: any, accessToken:any) => {
  return await execute("POST", url, payload, accessToken);
};
// export const executePutData = async (url: string, accessToken: string) => {
//   try {
//     const response = await axios.put(url, null, {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     return response.data;
//   } catch (error) {
//     // Handle error, e.g., log or throw a custom error
//     throw new Error('Failed to execute PUT request');
//   }
// };



export const executePostImage = async (url: any, formData: any, accessToken:any) => {
  return await executeImage("POST", url, formData, accessToken);
};

export const executePutImage = async (url: any, formData: any, accessToken:any) => {
  return await executeImage("PUT", url, formData, accessToken);
};


export const executeImage = async (
  method: any,
  url: any,
  formData: any,
  accessToken: any
) => {
  const result = {} as IApiResponse;
  result.ok = false;



  try {

    const requestOptions: AxiosRequestConfig = {
      method: method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        "type": "formData"
      },

      data: formData ? formData : null,
    };

    //call api
    const response = await axios(url, requestOptions);
    console.log(response.status)
    //process response
    if (response.status === 200 || response.status === 201 || response.status === 204) {
      console.log(response.data)
      result.data = response.data;
      result.ok = true;
    } else {
      console.log(
        `An error has occured: ${response.status} - ${response.statusText}`
      );
      result.message = response.statusText;
    }
    result.status = response.status;
  } catch (error) {
    result.status = 400;
    if (error instanceof Error) {
      result.message = error.message;
    } else {
      result.message = "An error has occured";
    }
  }
  return result;
};

export const executePutData = async (url: any, payload: any, accessToken:any) => {
  return await execute('PUT', url, payload, accessToken);
}

export const executeDeleteData = async (url: any, accessToken:any) => {
  return await execute('DELETE', url, null, accessToken);
}

