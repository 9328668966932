import React, { useState } from 'react';
import SplitPane, { Pane } from 'split-pane-react-fixed';
import 'split-pane-react-fixed/esm/themes/default.css';

const Sample = () => {
    const [sizes, setSizes] = useState([100, '30%', 'auto']);
  return (
    <div style={{ height: 500 }}>
      <SplitPane
        split='vertical'
        sizes={sizes}
        onChange={setSizes}
      >
        <Pane minSize={50} maxSize='50%'>
          <div style={{backgroundColor:"blue"}}>
            pane1
          </div>
        </Pane>
        <div style={{backgroundColor:"black"}}>
          pane2
        </div>
      
      </SplitPane>
    </div>
  )
}

export default Sample;