/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 3-Nov-2023
* @author      : vijayakumar.s
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Service
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/

import { useEffect, useState } from "react";
import { LineChart } from "./fineTuneSelector/LineChart"


export const options = {
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      enabled: true,
      displayColors: false,
    },
    title: {
      display: false,
      text: "Users Gained between 2016-2020"
    },
    legend: {
      display: true
    },
    datalabels: {
      display: false 
    }
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Month',
        font: {
          size: 15,
        }
      }
    },
    y: {
      // min: 10,
      title: {
        display: true,
        text: 'Cost/SQ',
        font: {
          size: 15,
        }
      },
      beginAtZero: false,
    }
  }
}


const MultiDimensionalChart: React.FC<any> = (props) => {


  console.log(props.params);
  let month: any = []
  let gardeA: any = []
  let gardeB: any = []
  let gardeC: any = []
  // apiResponse[0].trend.forEach((item) => sales.push(item.costPerUnit));
  // apiResponse[0].trend.forEach((item) => month.push([item.month]));
  for (let i in props.params[0]?.trend) {
    month.push(props.params[0]?.trend[i].month)
    gardeA.push(props.params[0]?.trend[i].costPerunit)
  }

  props.params[1]?.trend.forEach((item: { costPerunit: any; }) => gardeB.push(item.costPerunit));
  props.params[2]?.trend.forEach((item: { costPerunit: any; }) => gardeC.push(item.costPerunit));

  const grades = {
    labels: month,
    datasets: [{
      label: 'Grade A',
      backgroundColor: [
        'rgba(0, 82, 189, 0.8)'
      ],
      borderColor: [
        'rgba(0, 82, 189, 0.8)'
      ],
      data: gardeA,
      borderWidth: 3
    },
    {
      label: 'Grade B',
      backgroundColor: [
        'rgb(255, 99, 132)',
      ],
      borderColor: [
        'rgb(255, 99, 132)',
      ],
      borderWidth: 3,
      data: gardeB,

    },
    {
      label: 'Grade C',
      backgroundColor: [
        'rgb(255, 205, 86)'
      ],
      borderColor: [
        'rgb(255, 205, 86)'
      ],
      borderWidth: 3,
      data: gardeC,
    }
    ]
  };

  return (
    <LineChart data={grades} options={options} />
  )

}

export default MultiDimensionalChart