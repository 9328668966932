/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : shiyam kumar
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1103           |  18-04-2024    |   shiyamkumar     | Bid Chat Cost Issue Fix
* 
*/


import React, { useState } from 'react';
import ChatIcon from '@mui/icons-material/Chat';
import './FloatChatbot.scss';
import { Box, Fab, SxProps, Typography, Zoom, useTheme } from '@mui/material';
import ChatCard from './Chat';
import { green } from '@mui/material/colors';
import { getBidInfoByBidId, postChatBot } from '../../services/ChatBotService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { ChatOptions } from '../../viewmodel/Chat';
import { StringBuilder } from 'typescript-string-operations';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const fabGreenStyle = {
  color: 'common.white',
  bgcolor: green[500],
  '&:hover': {
    bgcolor: green[600],
  },
};


const FloatChatbot = () => {
  const theme = useTheme();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const { accessToken, accessTokenPayload } = useOidcAccessToken();

  const [userResponse, setUserResponse] = useState<string>("");
  const [options, setOptions] = useState<ChatOptions[]>([]);
  const [step, setStep] = useState<number>(0);
  const [botResponse, setBotResponse] = useState<any>({
    purpose: "",
    message: "",
    sender: "Gms",
    // options:["Why did my bid cost changed"]
  });
  const [sendUserResponse, setSendUserResponse] = useState<string>("");
  const [selectedBid, setSelectedBid] = useState<string>("");

  const buildString = (data: any) => {
    if (data) {
      var builder = new StringBuilder();
      builder.append("BidId: " + data.id + "\n");
      builder.append("Trade: " + data.trade + "\n");
      builder.append("Cost: " + "$" + data.totalCost + "\n");
      builder.append("Status: " + data.status + "\n");
      var dataString = builder.toString();
      return dataString;
    }

  }

  const prepareQuestion = (selectedOption: any) => {
    var questionDTO = {
      "question": "",
      "context": "bid"
    };
    if (selectedOption) {
      questionDTO.question = "why bid cost changed for " + selectedBid + "?";
    }
    return questionDTO;
  }

  const setNextStep = async (response: ChatOptions) => {
    if (response && response.option) {
      let questionDTO = {
        "question": userResponse,
        "context": "bid"
      }
      let message: any;
      if (response.type == "BidId") 
      {
        setSelectedBid(response.option);
        let responseData = await getBidInfoByBidId(response.option, accessToken);
        let dataString = buildString(responseData.data);
        message = dataString;
        var chatOptions: ChatOptions[] = [
          { option: "Why bid cost changed?", type: "Question" },
          { option: "Why bid schedule changed?", type: "Question" },
        ]
        setOptions(chatOptions);
      } 
      else
      {
        questionDTO = prepareQuestion(questionDTO);
        let responseData = await postChatBot(questionDTO, accessToken);
        message = responseData.data;
      }

      setStep(prevState => prevState + 1);

      setSendUserResponse(response.option);
      //let res = NextSteps(step, response, responseMessage.current);
      setBotResponse({ ...botResponse, message: message.toString(), sender: "GMS" });
      //setUserResponse("");
    }
  };

  const optionClick = (userResp: ChatOptions) => {
    //let option = userResp.option;
    //console.log(option);
    if (userResp) {
      setNextStep(userResp);
    }
  };

  const toggleChatbot = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };

  const [value, setValue] = React.useState(0);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: 'primary' as 'primary',
      sx: fabStyle as SxProps,
      icon: <ChatIcon />,
      label: 'Add',
    }
  ];

  return (
    // <div>
    //   <IconButton color="primary" aria-label="open chatbot" onClick={toggleChatbot} style={{ position: 'fixed', bottom: 0, right: 30 }}>
    //     {isChatbotOpen ? (
    //       <Tooltip title="Cancel"  >
    //         <CancelIcon style={{ width: "50px", height: "50px", color: "red" }} />
    //       </Tooltip>
    //     ) : (
    //       <Tooltip title="Open Chat" placement="bottom" arrow>
    //         <ChatIcon style={{ width: "50px", height: "50px" }} />
    //       </Tooltip>
    //     )}
    //   </IconButton>

    //   {isChatbotOpen &&
    //      <Chatbot/>       
    //   }
    // </div>

    // <Box
    //   sx={{
    //     bgcolor: 'background.paper',
    //     width: 'auto',
    //     position: 'relative',
    //     minHeight: 200,
    //   }}
    // >
    // <Box sx={{ height: '89vh', transform: 'translateZ(0px)', flexGrow: 1 }}>

    <>

      {fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          {isChatbotOpen ? (
            <Fab sx={fab.sx} size="medium" aria-label={fab.label} color={fab.color} onClick={toggleChatbot} style={{ position: 'fixed' }}>
              {fab.icon}
            </Fab>
          ) : (
            <Fab sx={fab.sx} size="medium" aria-label={fab.label} color={fab.color} onClick={toggleChatbot} style={{ position: 'fixed' }}>
              {fab.icon}
            </Fab>
          )}
        </Zoom>
      ))}

      {isChatbotOpen &&
        <ChatCard
          userResponse={userResponse}
          botResponse={botResponse}
          sendUserResponse={sendUserResponse}
          options={options}
          optionClick={optionClick}
        ></ChatCard>
      }
    </>
  );
};

export default FloatChatbot;
