/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |    DATE    |   AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*  1175          | 27-03-2024 | shiyamkumar |  Salesperson appoinment Calendar Blocked dates Issue Fixed
*
*
*/
import * as React from 'react';
import {
    DialogContent, DialogActions, Button,
    DialogTitle, Dialog, Box, Typography, IconButton, Stack, DialogContentText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import "../cart/CartDialog.scss"
import dayjs, { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { isSameDay } from "date-fns";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useContext, useEffect, useRef, useState } from 'react';
import { getdisabledDatesByStartDateAndResourceType } from '../../services/ProjectService';
import SalesBookingCheckout from '../salesBookingConfirmation/SalesBookingCheckout';
import './SalesmanCalendar.scss'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { UserContext, getUserSession } from '../../hooks/UserSession';


const SalesmanCalendar: React.FC<any> = (props) => {

    // const startDateValidator = (date:any) =>{
    //     let nextavailabledate:any;
    //     let availableDate = dayjs().add(1, 'day');
    //     if(date.isBefore(availableDate)){
    //         return dateValidator(findNextAvailableDate(availableDate));
    //     }else{
    //         return findNextAvailableDate(date);
    //     }
    // } 

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [data, setData] = useState<any>([]);
    const [availableDates, setAvailableDates] = useState<any>([]);
    const [selectedDate, setSelectedDate] = useState<any>(props.props?.startdate);
    const [showAnotherComponent, setShowAnotherComponent] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const componentData = useRef<any>();
    const [user, setUser] = React.useState<any>();
    // console.log(user);
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    // console.log(userSessionDetails?.role?.name)


    const startDate = dayjs().add(1, 'day'); // Initialize startDate as the current date plus one day
    const maxRecommendedStartDate = dayjs(props.props?.maxRecomendedStartdate);
    const jobStartdate = dayjs(props.props?.startdate);
    const diffTime = Math.abs(maxRecommendedStartDate.diff(jobStartdate));
    const diffDays = Math.ceil(diffTime / (24 * 60 * 60 * 1000));
    const formattedDates: Dayjs[] = [];
    const [disabledDates, setDisabledDates] = useState<any>([]);
    const blockedDates = useRef<any>({disableDates:[]});
    const [isLoading, setIsLoading] = useState(true);

    for (let i = 0; i <= diffDays; i++) {
        const newDate = startDate.add(i, 'day');
        formattedDates.push(newDate);
    }

const dateValidator = (date:Date) =>{
        
    if(new Date(date).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0)){
        const nextdate = new Date(new Date(date).setDate(new Date(date).getDate() + 1));
        if(nextdate.getDay() == 0){
            return new Date(new Date(date).setDate(new Date(date).getDate() + 1));
        }
        if(nextdate.getDay() == 6){
            return new Date(new Date(date).setDate(new Date(date).getDate() + 2));
        }
        else{
            return nextdate;
        }
    }
    else{
        if(date.getDay() == 0){
            return new Date(new Date(date).setDate(new Date(date).getDate() + 1));
        }
        if(date.getDay() == 6){
            return new Date(new Date(date).setDate(new Date(date).getDate() + 2));
        }
        else{
            return date;
        }
    }
}

let exeactDate:any;

const findNextAvailableDate = (date:any, disabledDates:any) =>{
    if(disabledDates.includes(dayjs(date).format("YYYY-MM-DD"))){
        findNextAvailableDate(dateValidator(new Date(new Date(date).setDate(new Date(date).getDate() + 1))), disabledDates);
    }else{
        exeactDate = date;
    }
    return exeactDate;
}

const startDateValidator = (date:Date) =>{
    let nextavailabledate:any;
    let availableDate = new Date(new Date().setDate(new Date().getDate() + 1));
    if(new Date(date).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0)){
        return dateValidator(findNextAvailableDate(availableDate, blockedDates.current.disableDates));
    }else{
        return dateValidator(findNextAvailableDate(date, blockedDates.current.disableDates));
    }
} 

    const isDateDisablesAvailable = (date: Dayjs) => {
        const formattedDate = date.format("YYYY-MM-DD");

        // Check if the date is in the disabledDates array
        if (disabledDates.includes(formattedDate)) {
            return true;
        }
        // Check if the date is a Saturday (6) or Sunday (0)
        return date.day() === 6 || date.day() === 0;
    };
    const filteredDates: Dayjs[] = formattedDates.filter(date => !isDateDisablesAvailable(date));

    const highlightedDays: Array<Dayjs> = filteredDates

    const CustomDay: React.FC<PickersDayProps<Dayjs>> = (props) => {

        const matchedStyles = highlightedDays.reduce((a, v) => {

            const date = props.day.toDate();
            return isSameDay(date, v.toDate()) && (!blockedDates.current.disableDates.includes(props.day.format("YYYY-MM-DD")))
                ? {
                    // backgroundColor: 'green',
                    // borderTopRightRadius: '0%',
                    // borderBottomRightRadius: '0%',
                    // borderTopLeftRadius: '0%',
                    // borderBottomLeftRadius: '0%',
                    // margin: '0',
                    // color: 'white'
                    backgroundColor: 'green',
                    borderTopRightRadius: '0%',
                    borderBottomRightRadius: '0%',
                    borderTopLeftRadius: '0%',
                    borderBottomLeftRadius: '0%',
                    margin: '0',
                    color: 'white',
                    width: '13% !important',
                    "&:hover": {
                        background: "#47a947"
                      }
                }
                : a;
        }, {});

        return <PickersDay {...props} sx={{ ...matchedStyles }} />;
       
    };
    // const { onClose, open } = props;
    // const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17'));
    const handleDateChange = (newDate: any) => {
        if (newDate) {
            setSelectedDate(new Date(newDate));
            // setShowAnotherComponent(true);

            const isHighlighted = filteredDates.some((highlightedDate) =>
                isSameDay(newDate.toDate(), highlightedDate.toDate())
            );

            if (!isHighlighted) {
                setShowAlert(true);
                setShowAnotherComponent(false);
            }
        }
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
        setShowAnotherComponent(true);
    };
    const isDateDisabled = (date: Dayjs) => {
        const today = dayjs();  // Get the current date
        // Disable dates up to and including today

        if(date.isSame(date.startOf('month'))){
            handleMonthChange(date)
        }
        if (date.isBefore(today, 'day') || date.isSame(today, 'day')) {
            return true;
        }
        // Additional disabling logic (if needed)
        if (blockedDates.current.disableDates.includes(date.format("YYYY-MM-DD"))) {
            return true;
        }
        return date.day() === 6 || date.day() === 0;
    };
 

    const handleMonthChange = async (newMonth) => {
        // Automatically select the first day of the new month
        const firstDayOfNextMonth = newMonth.startOf('month');
        const formattedDate = firstDayOfNextMonth.format('YYYY-MM-DDTHH:mm:ss[Z]');
        const result = await getdisabledDatesByStartDateAndResourceType(formattedDate, componentData.current?.resourceType, accessToken)
            if(result.status == 200){
                if(result.status == 200){
                    var dates = blockedDates.current.disableDates;
                    if(result.data.length != 0 && result.data != ''){
                    result.data.map(dateString => {
                        if(!blockedDates.current.disableDates.includes(dayjs(dateString).format("YYYY-MM-DD"))){
                            blockedDates.current.disableDates.push(dayjs(dateString).format("YYYY-MM-DD"))
                        }
                    })
                    setDisabledDates(dates)
                    //blockedDates.current.dis = dates
                    }
                    }
            }
        //setSelectedDate(firstDayOfNextMonth);
    };
    useEffect(() => {
        // getUserSession(accessToken)
        // .then(res => {
        //   setUser(res);
        //   console.log(res);
        // })
        setUser(userSessionDetails);
        if(props.props != undefined){
        componentData.current = {
            startDate: props?.props?.startdate,
            resourceType: "sales",

        };
        const fetchData = async () => {
          try {
            const result = await getdisabledDatesByStartDateAndResourceType(dayjs(props?.props?.startdate).add(1,"day").startOf('month').format('YYYY-MM-DDTHH:mm:ss[Z]'), componentData.current.resourceType, accessToken);
            if(result.status == 200){
                if(result.status == 200){
                    var dates =  result.data.length != 0
                    ? result.data.map(dateString => dayjs(dateString).format("YYYY-MM-DD"))
                    : []
                    setDisabledDates(dates)
                    blockedDates.current.disableDates = dates
                    setSelectedDate(startDateValidator(new Date(props?.props?.startdate)));
                }
                }
          } catch (error) {
            //console.error(error);
          }
          finally {
            setIsLoading(false);
          }
        };
        fetchData();
    }
      }, [props.props?.startdate]);
    // const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    //     // console.log(id, projectdata)

    //      projectdata.map((project: any, index: any) => {
    //          if (project.projectId === id) {
    //              getTask(id);
    //          }
    //      })

    //      // const selectedIndex = selected.indexOf(id);
    //      // let newSelected: readonly number[] = [];

    //      // if (selectedIndex === -1) {
    //      //     newSelected = newSelected.concat(selected, id);
    //      // } else if (selectedIndex === 0) {
    //      //     newSelected = newSelected.concat(selected.slice(1));
    //      // } else if (selectedIndex === selected.length - 1) {
    //      //     newSelected = newSelected.concat(selected.slice(0, -1));
    //      // } else if (selectedIndex > 0) {
    //      //     newSelected = newSelected.concat(
    //      //         selected.slice(0, selectedIndex),
    //      //         selected.slice(selectedIndex + 1),
    //      //     );
    //      // }
    //      setSelected(id);
    //  };

    //  const getTask = async (id: any) => {
    //      const response = await getProjectById(id);
    //     // console.log(response.data);
    //      setSelectedproject(response.data)
    //  }

    const onClose = () => {
        setSelectedDate(startDateValidator(new Date(props?.props?.startdate)));
        setShowAnotherComponent(false)
        props.handleClose();
    }

    const closeDateConfirmation =() =>{
        setSelectedDate(startDateValidator(new Date(props?.props?.startdate)));
        setShowAnotherComponent(false);
    }

    const closeAlter = () =>{
        setSelectedDate(startDateValidator(new Date(props?.props?.startdate)));
        setShowAlert(false);
    }

    if (isLoading) {
        return <div></div>;
      }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={props.open}
            // onClose={()=>onClose()}
            
        >
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >
                        <Typography className='cartheader'>Book an Associate Appointment</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={()=>onClose()}>
                            <CloseIcon sx={{ color: "red" }} />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                            <DateCalendar value={value} onChange={(newValue) => setValue(newValue)} />
                        </DemoItem>
                    </DemoContainer>
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar

                        // sx={{
                        //   '.MuiPickersDay-root': {
                        //     color: 'blue',


                        //     backgroundColor: 'grey',
                        //   },
                        // }}
                        slots={{ day: CustomDay }}//date reange
                        shouldDisableDate={isDateDisabled}//disable properties     
                        disableHighlightToday               
                        defaultValue={dayjs(startDateValidator(new Date(props.props?.startdate)))}//default date is setted
                        value={selectedDate == undefined ? dayjs(startDateValidator(new Date(props.props?.startdate))) : dayjs(startDateValidator(new Date(selectedDate)))}
                        onChange={handleDateChange}
                        onMonthChange={handleMonthChange}


                    />
                </LocalizationProvider>
                {/* onClose={handleCloseAlert} */}
                <Dialog open={showAlert}
                >
                   <DialogTitle className='warning'>
            <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                               Warning
                            </Typography>
                        </Box>
                        </DialogTitle>
                     <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent style={{marginTop:"10px"}}>
                    {/* <DialogTitle className='warningMessage'>Warning!</DialogTitle> */}
                        <DialogContentText sx={{  fontWeight: "500", fontSize: "18px" }}>
                            Selected date <span className='warningDate'>{dayjs(selectedDate).format("DD MMM YYYY")}</span> will change your actual construction date .
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{  justifyContent: "center" }}>
                    <Button onClick={()=>closeAlter()} variant='contained' sx={{backgroundColor:"#df3333ed"}}>Cancel</Button>
                        <Button onClick={handleCloseAlert}  variant='contained' color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                    </Box>
                </Dialog>
                {showAnotherComponent && (
                    <SalesBookingCheckout open={showAnotherComponent} bid={props.props} user={user} selectedDate={dayjs(selectedDate).format("DD MMM YYYY")} onClose={() => closeDateConfirmation()} />
                )}
            </DialogContent>
            
            <DialogActions>
                <Button onClick={()=>onClose()} color="error">Close</Button>
                <Button onClick={handleCloseAlert} color="primary">Book now</Button>
            </DialogActions>
        </Dialog>


    );
}


export default SalesmanCalendar;





