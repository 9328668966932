import React from "react";
import { useLocation } from "react-router-dom";

const ShowNavBar = ({ children }: any ) => {
    const [show, setShow] = React.useState(false);

    const location = useLocation();

    React.useEffect(() => {
        if (location.pathname === '/') 
        {
            setShow(false);
        }
        else {
            setShow(true);
        }        
    }, [location]);

    return (
        <div>{show && children}
        </div>
    )
};

export default ShowNavBar;