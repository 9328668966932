import { TabContext, TabList, TabPanel } from '@mui/lab';
import {  Tab } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import LeaveList from '../components/TimeLog/bookLeave/LeaveList';
import AdminTimeSheets from '../components/TimeLog/timeSheet/AdminTimeSheet';



const EmployeeTimeLogs : React.FC = () => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box sx={{ width: '100%', typography: 'body1',marginLeft:"0px" }}>
      <TabContext value={value} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
          
            <Tab label="Employers Time Sheet View" value="1" />
            <Tab label="Employers Leave Information" value="2" />
            {/* <Tab label="Employers Time Sheet View by Date" value="3" /> */}

            {/* <Tab label="Vendor Management" value="2" />
            <Tab label="Customer Management" value="3" />
            <Tab label="Tool Management" value="4" />
            <Tab label="Material Management" value="5" />
            <Tab label="Resource Management" value="6" /> */}
            {/* <Tab label="Employee Personal Info" value="4" />
            <Tab label="Employement details" value="5" />
            <Tab label="Employement details" value="6" /> */}


          </TabList>
        </Box>
        <TabPanel value="1"> <AdminTimeSheets /></TabPanel>
        <TabPanel value="2"> <LeaveList /></TabPanel>
        {/* <TabPanel value="3"><DateAdminTimeSheets/></TabPanel> */}
        {/* <TabPanel value="2"><VendorManagement/></TabPanel>
        <TabPanel value="3"><CustomerManagement/></TabPanel>
        <TabPanel value="4"><ToolList/></TabPanel>
        <TabPanel value="5"><MaterialList/></TabPanel>
        <TabPanel value="6"><ResourceList/></TabPanel> */}
        {/* <TabPanel value="4"><Personalinfo/></TabPanel> */}
        {/* <TabPanel value="5"><Employementdetails/></TabPanel>
        <TabPanel value="6"><Customer/></TabPanel> */}
      </TabContext>
    </Box>
    </div>
  )
}

export default EmployeeTimeLogs;