/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 7-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Transaction Service Layer
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import { ConfigUrl } from "../auth/OidcConfig";
import { executeGetData } from "./ApiBase";



export const getJobLedgerDetails = async (invoiceId: any,accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/paymentReceives/getJobLedgerDetails/invoiceNo/${invoiceId}`;
  return await executeGetData(url,accessToken);
};