import defaultImg from '../assets/images/grey.png';

let iconMap = new Map([
    ['Green', 'arrowgreen.png'],
    ['Orange', 'amber.png'],
    ['Red', 'red.png'],
    ['Blue', 'blue.png'],
    ['Grey', 'grey.png'], 
]);

export const dateFormatter = (value: any) => {
    const date = new Date(value);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    return date.getFullYear() + "-" + months[date.getMonth()] + "-" + ((date.getDate()).toString().length == 1 ? "0" + (date.getDate()).toString() : date.getDate());
}

export const getIcon = (key: string) => {
    let image = defaultImg;
    if (iconMap.has(key)) {
        image = require(`../assets/images/${iconMap.get(key)}`);;
    }
    return image;
};